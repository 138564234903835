import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core'
import { Button } from 'react-bootstrap';
import { TasksTable } from './TasksTable/Table';
import Pagination from '../../../../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { completeTasks } from '../../../../crud/dashboard.crud';
import * as AlertState from '../../../../store/ducks/auth.duck';
import SimpleDropdown from '../../../../components/DropdownComponent';
import catchErrorMessage from '../../../../helpers/errorCatcher';

export const Tasks = ({
    setType,
    data,
    limit,
    setLimit,
    page,
    setPage,
    pagination,
    setPagination,
    lastPage,
    setSort,
    sort,
    setLoader,
    status,
    setStatus,
}) => {
    const dispatch = useDispatch()
    const [tab, setTab] = useState(0);
    const [check, setCheck] = useState([]);
    const accountId = useSelector((state) => state.appData.account.id);

    const handleCompleteTasks = () => {
        setLoader(true);
        completeTasks(accountId, check)
            .then(() => {
                dispatch(
                    AlertState.actions.alert({
                        text: 'The task has been successfully completed',
                        variant: true,
                    })
                );
            })
            .catch(err => {
                let errText = catchErrorMessage(err);
                dispatch(
                    AlertState.actions.alert({
                        text: errText,
                        variant: false,
                    })
                );
            })
            .finally(() => {
                setLoader(false);
                setCheck([]);
            })
    };

    return (
        <>
            <h1 className='title'>Tasks</h1>
            <div className='table-block'>
                <div className='table-block__tabs'>
                    <Tabs
                        value={tab}
                        onChange={(e, value) => setTab(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                        variant="scrollable"
                        style={{ marginBottom: 20 }}
                    >
                        <Tab label={'ALL'} onClick={() => setType(null)} />
                        <Tab label={'METRICS'} onClick={() => setType('complete_metric')} />
                        <Tab label={'FORMS'} onClick={() => setType('complete_form')} />
                        <Tab label={'GOALS'} onClick={() => setType('new_goal')} />
                    </Tabs>
                </div>

                <div className='table-block__status-dropdown'>
                    {check.length === 0 &&
                        <SimpleDropdown
                            fetchOptions={false}
                            options={[
                                { title: 'All', value: null },
                                { title: 'Open', value: 0 },
                                { title: 'Completed', value: 1 }
                            ]}
                            value={status}
                            label="Task status"
                            setFormData={val => setStatus(val)}
                            width={'100%'}
                            getOptionSelected={(option, value) => option.title === value.title}
                        />
                    }

                    {check.length > 0 &&
                        <Button
                            variant="primary"
                            className="btn-blue"
                            style={{ width: '100px', height: '40px' }}
                            onClick={handleCompleteTasks}
                        >Complete</Button>
                    }
                </div>

                <TasksTable
                    data={data}
                    check={check}
                    setCheck={setCheck}
                    sort={sort}
                    setSort={setSort}
                />

                <Pagination
                    show={limit}
                    setShow={setLimit}
                    page={page}
                    setPage={setPage}
                    pagination={pagination}
                    setPagination={setPagination}
                    lastPage={lastPage}
                    data={data}
                />
            </div>
        </>
    )
};
