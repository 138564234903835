export const validateForm = (formik, video_type, dispatch, AlertState) => {
    let isValid = true;

    if (formik.values.video_source && !video_type) {
        dispatch(AlertState.actions.alert({
            text: 'Video platform is required if video source is selected',
            variant: false
        }));
        isValid = false;
    };

    if (video_type && !formik.values.video_source) {
        dispatch(AlertState.actions.alert({
            text: 'Video source is required if video platform is selected',
            variant: false
        }));
        isValid = false;
    };



    return isValid;
};
