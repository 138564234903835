import React from 'react';
import { TableRow } from './TableRow';
import { Checkbox } from '@material-ui/core';
import { tableHead } from './tableHead';
import ArrowDown from '@material-ui/icons/ArrowDropDown';

export const TasksTable = ({
    data,
    check,
    setCheck,
    sort,
    setSort,
    setCurrentForm,
    setFormViewModal,
}) => {
    const checked = data.length && check.length === data.length;

    const handleCheck = () => {
        if (data.length) {
            if (!checked) {
                setCheck(data.map(elem => elem.id))
            } else {
                setCheck([])
            }
        }
    };

    const sortEdit = (item) => {
        item.sort && setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
    };

    return (
        <div className="settingtable nowrap">
            <table>
                <thead>
                    <tr>
                        <td>
                            <Checkbox
                                color="primary"
                                checked={checked}
                                onChange={handleCheck}
                            />
                        </td>
                        {tableHead.map((item, index) => (
                            <td
                                key={index}
                                onClick={() => {
                                    setSort(item.value)
                                    sortEdit(item)
                                }}
                                className={
                                    `${item.left ? 'left-content' : ''}`
                                }
                                style={{ cursor: !item.sort && 'default' }}
                            >
                                {item.label}
                                {item.sort &&
                                    <ArrowDown
                                        className={`sort-arrow
                                            ${sort[0] === item.value
                                                ? ' active'
                                                : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}
                                            `}
                                    />
                                }
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item) => (
                        <TableRow
                            key={item.id}
                            item={item}
                            check={check}
                            setCheck={setCheck}
                            setCurrentForm={setCurrentForm}
                            setFormViewModal={setFormViewModal}
                        />
                    ))}
                </tbody>
            </table>

            {!data.length &&
                <div
                    style={{
                        margin: '20px auto',
                        textAlign: 'center'
                    }}
                >
                    No data available in table
                </div>
            }
        </div>
    )
};
