import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import Logout from '../auth/Logout';
import styled from 'styled-components';
import {
    getPlanList,
    getInvoicesList,
    getCurrentSubscription,
    getPaymentSource
} from '../../crud/biling.crud';
import {
    Portlet,
    PortletBody,
} from "../../partials/content/Portlet";
import {
    Tab,
    Tabs
} from '@material-ui/core';
import YourPlan from './UpgradePlanTabs/YourPlan';
import Invoices from './UpgradePlanTabs/Invoices';
import Loader from '../../components/Loader';

export default () => {
    const [key, setKey] = useState(0);
    const [plan, setPlan] = useState([]);
    const [currentPlan, setCurrentPlan] = useState({
        "id": '',
        "status": "",
        "next_billing_at": 0,
        "plan": null,
        "payment_source": null
    });
    const [invoices, setInvoices] = useState([]);
    const [logout, setLogout] = useState(null);
    const [loaderPlanList, setLoaderPlanList] = useState(true);
    const [loaderInvoicesList, setLoaderInvoicesList] = useState(true);
    const [loaderCurrentSubscription, setLoaderCurrentSubscription] = useState(true);
    const [loaderEditSubscription, setLoaderEditSubscription] = useState(false);
    const [loader, setLoader] = useState(false);

    document.title = 'TrainerMetrics - Billing Info'

    useEffect(() => {
        getPaymentSource()
        getPlanList()
            .then(res => {
                setLoaderPlanList(false)
                sortPlan(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderPlanList(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })

        getInvoicesList()
            .then(res => {
                setLoaderInvoicesList(false)
                setInvoices(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderInvoicesList(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })

        getCurrentSubscription()
            .then(res => {
                setLoaderCurrentSubscription(false)
                setCurrentPlan(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderCurrentSubscription(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                }
            })
    }, [key]);

    const refreshCurrentSubscription = () => {
        getCurrentSubscription()
            .then(res => {
                setLoaderCurrentSubscription(false)
                setCurrentPlan(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderCurrentSubscription(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                }
            })
    };

    const sortPlan = (list) => {
        let sort = {
            trainer: {
                year: [],
                month: []
            },
            gym: {
                year: [],
                month: []
            }
        }

        sort.trainer.year = list.filter(item => item.period === 'year' && item.name === 'Trainer')
        sort.trainer.month = list.filter(item => item.period === 'month' && (item.name === 'Trainer' || item.name === ''))
        sort.gym.year = list.filter(item => item.period === 'year' && item.name === 'Gym')
        sort.gym.month = list.filter(item => item.period === 'month' && item.name === 'Gym')
        setPlan(sort)
    }

    return (
        <StyledUpgrade>
            <Loader
                visible={loaderPlanList ||
                    loaderInvoicesList ||
                    loaderCurrentSubscription ||
                    loaderEditSubscription ||
                    loader
                }
            />
            {logout}
            <Portlet>
                <PortletBody>
                    <Tabs
                        className="tabs-top"
                        value={key}
                        onChange={(e, value) => setKey(value)}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="auto"
                        variant="scrollable"
                    >
                        <Tab label={'Your Plan'} />
                        <Tab label={'Invoices'} />
                    </Tabs>

                    {key === 0
                        ? <YourPlan
                            plan={plan}
                            setLoaderEditSubscription={setLoaderEditSubscription}
                            setLogout={setLogout}
                            currentPlan={currentPlan}
                            setCurrentPlan={setCurrentPlan}
                            invoices={invoices.data}
                            refreshCurrentSubscription={refreshCurrentSubscription}
                            setLoader={setLoader}
                        />
                        : key === 1
                            ? <Invoices
                                data={invoices}
                            />
                            : null
                    }

                </PortletBody>
            </Portlet>
        </StyledUpgrade>
    )
};

const StyledUpgrade = styled.div`
    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;
    }
    
    .tabs-top {
        margin-bottom: 30px;
    }
`;
