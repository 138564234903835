import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL_V2;

export function getLeadsTotal(params) {
  return axios.get(`${BASE_URL}sales/leads/total`, { params });
}

export const getLeadsList = (params) =>
  axios.get(`${BASE_URL}sales/leads`, { params });

export const updatLeadsNote = (profileId, params) =>
  axios.patch(`${BASE_URL}sales/profiles/${profileId}/notes`, params);
