import { editEvent } from '../../../../crud/dashboard.crud';
import { createEvent } from '../../../../crud/dashboard.crud';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import * as AlertState from '../../../../store/ducks/auth.duck';
import moment from 'moment';

export const handleSubmit = (
    formik,
    setLoader,
    setSelectedEventId,
    loadEvents,
    accountId,
    handleClose,
    selectedEventId,
    dispatch
) => {
    setLoader(true);

    const date = moment(formik.values.date).format('MM/DD/YYYY');
    // const startTime = `${formik.values.start_time.split(' ')[0]}:00 ${formik.values.start_time.split(' ')[1]}`;
    // const endTime = `${formik.values.end_time.split(' ')[0]}:00 ${formik.values.end_time.split(' ')[1]}`;

    const data = {
        ...formik.values,
        type: 'default',
        start_at: moment(`${date} ${formik.values.start_time}`)
            .utc()
            .format('YYYY/MM/DD HH:mm:ss'),
        end_at: moment(`${date} ${formik.values.end_time}`)
            .utc()
            .format('YYYY/MM/DD HH:mm:ss'),
        workout_id: formik.values.workout?.id,
        metrics: formik.values.metrics.map((elem) => elem.id),
        profiles: formik.values.profiles.map((elem) => elem.value.profile_id),
        users: formik.values.users.map((elem) => elem.id),
    };

    delete data.start_time;
    delete data.end_time;
    delete data.workout;
    delete data.date;

    if (selectedEventId) {
        editEvent(accountId, selectedEventId, data)
            .then(() => {
                handleClose();
                loadEvents();
                setSelectedEventId('');
                dispatch(
                    AlertState.actions.alert({
                        text: 'Event successfully updated',
                        variant: true,
                    })
                );
            })
            .catch((err) => {
                let errorMessage = catchErrorMessage(err);
                if (errorMessage === 'The title has already been taken.') {
                    formik.setErrors({
                        ...formik.errors,
                        title: 'The title has already been taken.',
                    });
                    return;
                }
                dispatch(
                    AlertState.actions.alert({
                        text: errorMessage,
                        variant: false,
                    })
                );
            })
            .finally(() => {
                setLoader(false);
            });
        return;
    }

    createEvent(accountId, data)
        .then(() => {
            handleClose();
            loadEvents();
            setSelectedEventId('');
            dispatch(
                AlertState.actions.alert({
                    text: 'Event successfully created',
                    variant: true,
                })
            );
        })
        .catch((err) => {
            let errorMessage = catchErrorMessage(err);
            if (errorMessage === 'The title has already been taken.') {
                formik.setErrors({
                    ...formik.errors,
                    title: 'The title has already been taken.',
                });
                return;
            }
            dispatch(
                AlertState.actions.alert({
                    text: errorMessage,
                    variant: false,
                })
            );
        })
        .finally(() => {
            setLoader(false);
        });
};
