import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { CRMSync } from './Sync';
import { CRMFields } from './Fields/Fields';
import { CRMMappings } from './Mappings/Mappings';
import { useIsMount } from '../../../../hooks/useIsMount';
import { Redirect } from 'react-router';
import Loader from '../../../../components/Loader';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCRMSingle } from '../../../../crud/crm.crud';
import '../crm.scss';

const keyList = [
    { name: 'Sync' },
    { name: 'Fields' },
    { name: 'Mappings' },
];

const СRMConnect = () => {
    const isFirstRender = useIsMount();
    const dispatch = useDispatch();
    const [redirect, setRedirect] = useState(false);
    const [key, setKey] = useState(0);
    const [loader, setLoader] = useState(false);
    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.is_activated === true));
    const role = useSelector(state => state.auth.user.roles);
    const plan = useSelector(state => state.user_info?.subscription?.plan?.name);
    const crmList = useSelector(state => state.user_info.CRM_list);
    const [activated, setActivated] = useState('pending');
    const accountId = useSelector(state => state.appData?.account?.id);
    const [crmData, setCrmData] = useState(null);

    useEffect(() => {
        if (isFirstRender) return;
        if (role === 'staff' || role === 'supervisor' || plan === 'Gym') {
            let errorText = plan === 'Gym' ? 'User does not have the right plan' : role === 'supervisor' || role === 'staff' ? 'User does not have the right roles' : 'Error'
            setRedirect(true);
            dispatch(AlertState.actions.alert({
                text: errorText,
                variant: false,
            }));
        }
    }, [crm, plan]);

    useEffect(() => {
        if (crmList.length && crm) {
            setActivated('yes');
        } else if (crmList.length && !crm) {
            setActivated('no')
        }
    }, [crm, crmList]);

    useEffect(() => {
        if (activated === 'no') {
            setRedirect(true);
            dispatch(AlertState.actions.alert({
                text: `CRM is not activated yet`,
                variant: false,
            }));
        }
    }, [activated]);

    const loadCRMSingle = () => {
        fetchCRMSingle(accountId, crm.id)
            .then(res => {
                setCrmData(res.data.single);
            })
    };

    useEffect(() => {
        if (crm) loadCRMSingle();
    }, [crm]);

    return (
        <div className="crm">
            {redirect && <Redirect to="/profile-list" />}
            <Loader visible={loader} />
            <div className="header-list">
                <Tabs
                    value={key}
                    onChange={(e, value) => setKey(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    {keyList.map(item => {
                        return (
                            <Tab
                                label={item.name}
                                key={item.name}
                                className="tab"
                            />
                        )
                    })}
                </Tabs>
            </div>

            <div className="main">
                {(function () {
                    switch (key) {
                        case 0:
                            return <CRMSync setLoader={setLoader} crm={crm} />
                        case 1:
                            return <CRMFields setLoader={setLoader} crm={crm} />
                        case 2:
                            return (
                                <CRMMappings
                                    setLoader={setLoader}
                                    crm={crm}
                                    crmData={crmData}
                                    loadCRMSingle={loadCRMSingle}
                                />
                            )
                        default:
                            return;
                    }
                })()}
            </div>
        </div>
    )
};

export default СRMConnect;
