import React, { useState, useRef } from 'react';
import { useClickOutside } from '../../../../../../hooks/useClickOutside';
import CustomTimeInput from '../../../../../../components/CustomTimeInput/CustomTimeInput';
import './exercise-component.scss';

export const ExerciseComponent = ({
    exerciseItem,
    sectionsAndExercises,
    setSectionsAndExercises,
    exerciseIndex,
}) => {
    const [opened, setOpened] = useState(null);
    const ref = useRef();
    const variables = exerciseItem?.variables?.reduce((acc, current) => {
        return {
            ...acc,
            [current]: true,
        }
    }, {});

    useClickOutside(ref, () => {
        if (opened) setOpened(false);
    });

    const handleChangeReps = (e, setIndex) => {
        e.persist();

        let value = e.target.value;
        if (+value > 100) value = 100;
        if (+value < 0) value = 0;

        setSectionsAndExercises(prev => {
            const data = [...prev]
            data[exerciseIndex].sets[setIndex].reps = value;
            return data;
        })
    };

    const handleChangeWeight = (e, setIndex) => {
        e.persist();

        let value = e.target.value;
        if (+value > 5000) value = 5000;
        if (+value < 0) value = 0;

        setSectionsAndExercises(prev => {
            const data = [...prev]
            prev[exerciseIndex].sets[setIndex].weight = value;
            return data;
        })
    };

    const handleChangeDistance = (e, setIndex) => {
        e.persist();

        let value = e.target.value;
        if (+value > 100) value = 100;
        if (+value < 0) value = 0;

        setSectionsAndExercises(prev => {
            const data = [...prev]
            data[exerciseIndex].sets[setIndex].distance = value;
            return data;
        })
    };

    const handleChangeTime = (value, setIndex) => {
        let val = value;
        if (+val.split(':')[0] > 24) val = '20:00:00';

        setSectionsAndExercises(prev => {
            const data = [...prev]
            data[exerciseIndex].sets[setIndex].time = val
            return data
        })
    };

    const onBlurTime = (setIndex) => {
        if (sectionsAndExercises[exerciseIndex].sets[setIndex].time.split(':')[0] > 24) {
            setSectionsAndExercises(prev => {
                const data = [...prev]
                data[exerciseIndex].sets[setIndex].time = '24:00:00';
                return data;
            })
        }
    };

    const handleChangeUnit = (e, field, unit) => {
        e.persist();
        setSectionsAndExercises(prev => {
            const data = [...prev]
            prev[exerciseIndex].sets.forEach((elem, index) => {
                data[exerciseIndex].sets[index][field] = unit;
            })
            return data;
        })
        setOpened(null);
    };

    return (
        <div className='create-workout-exercise-table'>
            <div className='create-workout-exercise-table__head'>
                {variables?.reps && (
                    <div className='create-workout-exercise-table__head-elem'>Reps</div>
                )}

                {variables?.weight && (
                    <div className='create-workout-exercise-table__head-elem d-flex align-items-center'>
                        <div onClick={() => setOpened(prev => prev ? null : 'weight')}>
                            {`Weight (${exerciseItem.sets[0]?.weight_unit || ''})`}
                        </div>
                        <div
                            className={`create-workout-exercise-table__head-elem-arrow ${opened === 'weight' ? 'create-workout-exercise-table__head-elem-arrow_active' : ''}`}
                            onClick={() => setOpened(prev => prev ? null : 'weight')}
                        >{'>'}</div>

                        {opened === 'weight' && (
                            <div className='create-workout-exercise-table__head-select' ref={ref}>
                                <div
                                    className='create-workout-exercise-table__head-select-elem'
                                    onClick={(e) => handleChangeUnit(e, 'weight_unit', 'kg')}
                                >Weight (kg)</div>
                                <div
                                    className='create-workout-exercise-table__head-select-elem'
                                    onClick={(e) => handleChangeUnit(e, 'weight_unit', 'lbs')}
                                >Weight (lbs)</div>
                            </div>
                        )}
                    </div>
                )}

                {variables?.distance && (
                    <div className='create-workout-exercise-table__head-elem d-flex align-items-center'>
                        <div onClick={() => setOpened(prev => prev ? null : 'distance')}>
                            {`Distance (${exerciseItem.sets[0]?.distance_unit || ''})`}
                        </div>
                        <div
                            className={`create-workout-exercise-table__head-elem-arrow ${opened === 'distance' ? 'create-workout-exercise-table__head-elem-arrow_active' : ''}`}
                            onClick={() => setOpened(prev => prev ? null : 'distance')}
                        >{'>'}</div>

                        {opened === 'distance' && (
                            <div className='create-workout-exercise-table__head-select' ref={ref}>
                                <div
                                    className='create-workout-exercise-table__head-select-elem'
                                    onClick={(e) => handleChangeUnit(e, 'distance_unit', 'km')}
                                >Distance (km)</div>
                                <div
                                    className='create-workout-exercise-table__head-select-elem'
                                    onClick={(e) => handleChangeUnit(e, 'distance_unit', 'mi')}
                                >Distance (mi)</div>
                                <div
                                    className='create-workout-exercise-table__head-select-elem'
                                    onClick={(e) => handleChangeUnit(e, 'distance_unit', 'ft')}
                                >Distance (ft)</div>
                                <div
                                    className='create-workout-exercise-table__head-select-elem'
                                    onClick={(e) => handleChangeUnit(e, 'distance_unit', 'in')}
                                >Distance (in)</div>
                                <div
                                    className='create-workout-exercise-table__head-select-elem'
                                    onClick={(e) => handleChangeUnit(e, 'distance_unit', 'yd')}
                                >Distance (yd)</div>
                                <div
                                    className='create-workout-exercise-table__head-select-elem'
                                    onClick={(e) => handleChangeUnit(e, 'distance_unit', 'm')}
                                >Distance (m)</div>
                            </div>
                        )}
                    </div>
                )}

                {variables?.time && (
                    <div className='create-workout-exercise-table__head-elem'>Time</div>
                )}
            </div>

            {exerciseItem.sets?.map((elem, index) => {
                return (
                    <div className='create-workout-exercise-table__row' key={index}>
                        {variables?.reps && (
                            <div className='create-workout-exercise-table__row-elem'>
                                <input
                                    type='number'
                                    value={elem.reps}
                                    min={1}
                                    max={999}
                                    maxLength={3}
                                    onChange={(e) => handleChangeReps(e, index)}
                                    className="create-workout-exercise-table__row-elem-input"
                                    onKeyPress={(e) => {
                                        if ((+e.which !== 8 && +e.which !== 0 && +e.which < 48) || +e.which > 57) {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </div>
                        )}

                        {variables?.weight && (
                            <div className='create-workout-exercise-table__row-elem'>
                                <input
                                    type='number'
                                    value={elem.weight}
                                    onChange={(e) => handleChangeWeight(e, index)}
                                    className="create-workout-exercise-table__row-elem-input"
                                />
                            </div>
                        )}

                        {variables?.distance && (
                            <div className='create-workout-exercise-table__row-elem'>
                                <input
                                    type='number'
                                    value={elem.distance}
                                    onChange={(e) => handleChangeDistance(e, index)}
                                    className="create-workout-exercise-table__row-elem-input"
                                />
                            </div>
                        )}

                        {variables?.time && (
                            <div className='create-workout-exercise-table__row-elem'>
                                <CustomTimeInput
                                    value={elem.time}
                                    onChange={(value) => handleChangeTime(value, index)}
                                    className="create-workout-exercise-table__row-elem-input"
                                    onBlurTime={() => onBlurTime(index)}
                                    inputProps={{
                                        min: '00:00:00',
                                        max: '20:00:00'
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
};
