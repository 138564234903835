import React from 'react'
import styled from 'styled-components'

export default (props) => {

    return (
        <StyledRMO className='settingtable nowrap'>
            <thead>
                <tr>
                    <td>DATE</td>
                    {
                        props.data.map((item, index) => {
                            return (
                                <td key={index}>
                                    {item.date}
                                </td>
                            )
                        })
                    }
                </tr>
            </thead>
            {
                props.data && props.data[0] && props.data[0].value && typeof props.data[0].value === 'object'
                    ? <tbody>
                        {
                            props.data[0].value.map((item, index) => {
                                return <tr key={'label-' + index}>
                                    <td>
                                        {item.label}
                                    </td>
                                    {
                                        props.data.map((i, index2) => {
                                            return <td key={index + '-value-' + index2}>
                                                {props.data[index2].value[index].value || (props.data[index2].value[index].value === 0)
                                                    ? parseFloat(props.data[index2].value[index].value).toFixed(2)
                                                    : '--'
                                                }
                                            </td>
                                        })
                                    }
                                </tr>
                            })
                        }
                    </tbody>
                    : <tbody>
                        <tr>
                            <td>
                                {props.label}
                            </td>
                            {
                                props.data.map((item, index) => {
                                    return (
                                        <td className={"theme-data"} key={index}>
                                            {(item.value || (item.value === 0)) ? item.value : '--'}
                                        </td>
                                    )
                                })
                            }
                        </tr>
                        {
                            (props.data[0] && props.data[0].classification) && (<tr>
                                <td>
                                    {props.label2} Classification
                                </td>
                                {
                                    props.data.map((item, index) => {
                                        return (
                                            <td key={index}>
                                                {(item.classification || (item.classification === 0)) ? item.classification : '--'}
                                            </td>
                                        )
                                    })
                                }
                            </tr>)
                        }
                        {
                            (props.data[0] && props.data[0].deviations) && (<tr>
                                <td>
                                    Deviations
                                </td>
                                {
                                    props.data.map((item, index) => {
                                        return (
                                            <td className={"theme-data"} key={index}>
                                                {
                                                    item.deviations.map((d, i) => {
                                                        return (<span>{ d.label }<br/></span>)
                                                    })
                                                }
                                            </td>
                                        )
                                    })
                                }
                            </tr>)
                        }
                    </tbody>
            }
        </StyledRMO>
    )
}

const StyledRMO = styled.table`

`
