import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import styled from 'styled-components'
import { Redirect, useHistory } from "react-router-dom";
import moment from 'moment'
import {
  Checkbox,
  Avatar,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  OutlinedInput,
  TextField,
  FormHelperText
} from '@material-ui/core'
import {
  Modal,
  Button
} from 'react-bootstrap'
import { updateProfile } from '../../../crud/profile.crud'
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CancelCircle from '@material-ui/icons/Cancel';
import ErrorCircle from '@material-ui/icons/Error';
import { Formik } from 'formik'
import Logout from '../../auth/Logout'
import { getImg } from '../../../../_metronic/utils/utils'
import * as media from '../../../crud/media.crud'
import { useSelector } from 'react-redux';

const ImgComponent = ({ src, alt, className, style }) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    src && media.getImg(src)
      .then(res => setUrl(res))
      .catch(err => setUrl(err))
  }, [src])
  return url && <img src={url} alt={alt} className={className} style={style} />
}

export default ({ data, check, setCheck, sort, setSort, plan, groupVisible }) => {
  const dispatch = useDispatch();
  const [redirect, setRedirect] = useState(null)
  const [modal, setModal] = useState(false)
  const [errors, setErrors] = useState({ heightFt: 'ewc' })
  const [currentId, setCurrentId] = useState(null)

  const defRow = [
    {
      label: "Name",
      value: 'name',
      left: true,
      sort: true
    },
    {
      label: "Age",
      value: 'birthday',
      sort: true
    },
    {
      label: "Staff",
      // value: 'staff',
    },
    {
      label: "PAR-Q",
      value: 'parq',
    },
    {
      label: "Last Tested",
      value: 'last_test',
      left: true,
      sort: true
    },
    {
      label: "Next Goal",
      value: 'next_goal',
      left: true,
      sort: true
    },
    {
      label: "Overdue Metrics",
      value: 'overdue_metrics',
      left: true,
      sort: true
    },
    {
      label: "Email",
      value: 'email',
      left: true,
      sort: true
    },
    {
      label: "Groups",
      value: 'groups',
      left: true,
      sort: true,
      hide: true
    }
  ]

  const accountMeasurementSystem = useSelector(state => state.appData.account.measurement_system);
  const [measurement_system, set_measurement_system] = useState('imperial');

  useEffect(() => {
    if (accountMeasurementSystem) {
      set_measurement_system(accountMeasurementSystem);
    }
  }, [accountMeasurementSystem]);

  const sortEdit = item => {
    item.sort && setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
  }

  const chbAll = () => {
    if (check.length === data.length) {
      setCheck([])
    } else {
      setCheck(data.map(item => item.profile_id))
    }
  }

  const handleSwitch = (e) => {
    set_measurement_system(e.target.value);
  };

  return (
    <StyledTablePl className="settingtable nowrap">
      {redirect}

      <Formik
        enableReinitialize={true}
        initialValues={{
          birthday_day: moment().format('DD'),
          birthday_month: moment().format('MM'),
          birthday_year: moment().format('YYYY'),
          heightIn: '',
          heightFt: '',
          gender: '',
          heightM: '',
          measurement_system: 'imperial',
        }}
        validate={values => {
          const temp = {};
          values.birthday = `${values.birthday_year}-${values.birthday_month}-${values.birthday_day}`

          if (!values.birthday) {
            temp.birthday = 'This value should not be blank'
          } else if (moment(values.birthday, 'YYYY-MM-DD').toDate() >= moment(moment().format("YYYY-MM-DD"), 'YYYY-MM-DD').toDate()) {
            temp.birthday = 'Please fill the valid data'
          }

          if (!values.gender) {
            temp.gender = 'This value should not be blank'
          }

          values.measurement_system = measurement_system;

          if (measurement_system === 'imperial') {
            if (!values.heightFt && `${values.heightFt}` !== '0') {
              temp.heightFt = 'This value should not be blank'
            } else if (/[^0-9]/g.test(values.heightFt)) {
              temp.heightFt = 'The value can be from 1 to 8'
            } else if (parseInt(values.heightFt) > 8 || parseInt(values.heightFt) < 1) {
              temp.heightFt = 'The value can be from 1 to 8'
            }

            if (!values.heightIn && `${values.heightIn}` !== '0') {
              temp.heightIn = 'This value should not be blank'
            } else if (/[^0-9]/g.test(values.heightIn)) {
              temp.heightIn = 'The value can be from 0 to 11'
            } else if (parseInt(values.heightIn) > 11 || parseInt(values.heightIn) < 0) {
              temp.heightIn = 'The value can be from 0 to 11'
            }
          } else {
            if (!values.heightM && `${values.heightM}` !== '0') {
              temp.heightM = 'This value should not be blank'
            } else if (+values.heightM < 1 || +values.heightM > 3) {
              temp.heightM = 'The value can be from 1 to 3'
            }
          }

          setErrors(temp)

          return temp;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          updateProfile(currentId, values)
            .then(res => {
              setStatus(false)
              setSubmitting(false)
              setModal(false)
              dispatch(AlertState.actions.refreshStatistic())
              dispatch(AlertState.actions.alert({
                text: 'Profile info is added',
                variant: true
              }));
              setRedirect(<Redirect to={`/profile/${res.data.data.id}/overview`} />)
            })
            .catch(({ response }) => {
              response &&
                response.data &&
                dispatch(AlertState.actions.alert({
                  text: response.data.message || response.data.error,
                  variant: false
                }));
              if (response && (response.status === 401)) {
                setRedirect(<Logout />)
              } else if (response && (response.status === 403)) {
                setRedirect(<Redirect to="/profile-list" />)
              }
              setErrors({
                birthday: response.data.errors.birthday && response.data.errors.birthday[0],
                gender: response.data.errors.gender && response.data.errors.gender[0],
                heightFt: response.data.errors.heightFt && response.data.errors.heightFt[0],
                heightIn: response.data.errors.heightIn && response.data.errors.heightIn[0]
              })
              setSubmitting(false);
              setStatus(response.data.message)
            })
        }}
      >
        {({
          values,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Add Profile Info</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form root"
                onSubmit={handleSubmit}
              >
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: '16px',
                  marginBottom: '8px',
                }}>
                  <FormControl variant="outlined" error={Boolean((touched.birthday_year || touched.birthday_month || touched.birthday_day) && errors.birthday)} style={{ width: '30%' }}>
                    <InputLabel htmlFor="birthday_month">
                      Month
                    </InputLabel>
                    <Select
                      value={values.birthday_month} onChange={e => {
                        handleChange(e)
                        touched.birthday_month = true
                      }}
                      input={<OutlinedInput labelWidth={42} name="birthday_month" id="birthday_month" />}
                    >
                      <MenuItem value={'01'}>January</MenuItem>
                      <MenuItem value={'02'}>February</MenuItem>
                      <MenuItem value={'03'}>March</MenuItem>
                      <MenuItem value={'04'}>April</MenuItem>
                      <MenuItem value={'05'}>May</MenuItem>
                      <MenuItem value={'06'}>June</MenuItem>
                      <MenuItem value={'07'}>July</MenuItem>
                      <MenuItem value={'08'}>August</MenuItem>
                      <MenuItem value={'09'}>September</MenuItem>
                      <MenuItem value={'10'}>October</MenuItem>
                      <MenuItem value={'11'}>November</MenuItem>
                      <MenuItem value={'12'}>December</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" error={Boolean((touched.birthday_year || touched.birthday_month || touched.birthday_day) && errors.birthday)} style={{ width: '30%' }}>
                    <InputLabel htmlFor="birthday_day">
                      Day
                    </InputLabel>
                    <Select
                      value={values.birthday_day}
                      onChange={e => {
                        handleChange(e)
                        touched.birthday_day = true
                      }}
                      input={<OutlinedInput labelWidth={28} name="birthday_day" id="birthday_day" />}
                    >
                      {
                        [...new Array(31)].map((item, index) => <MenuItem value={index + 1 < 10 ? `0${index + 1}` : `${index + 1}`} key={index}>{1 + index}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" error={Boolean((touched.birthday_year || touched.birthday_month || touched.birthday_day) && errors.birthday)} style={{ width: '30%' }}>
                    <InputLabel htmlFor="birthday_year">
                      Year
                    </InputLabel>
                    <Select
                      value={values.birthday_year}
                      onChange={e => {
                        handleChange(e)
                        touched.birthday_year = true
                      }}
                      input={<OutlinedInput labelWidth={30} name="birthday_year" id="birthday_year" />}
                    >
                      {
                        [...new Array(121)].map((item, index) => <MenuItem value={index + 1900} key={index}>{1900 + index}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </div>
                {
                  Boolean((touched.birthday_year || touched.birthday_month || touched.birthday_day) && errors.birthday) &&
                  <div style={{
                    color: '#fd397a',
                    margin: '8px 14px 0',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    lineHeight: '1em'
                  }}>
                    {errors.birthday}
                  </div>
                }

                <FormControl variant="outlined" className="MuiFormControl-marginNormal MuiTextField-root">
                  <InputLabel htmlFor="system" className="MuiFormLabel-root">System</InputLabel>
                  <Select
                    value={measurement_system}
                    onChange={handleSwitch}
                    input={
                      <OutlinedInput
                        id="system"
                        name="system"
                        variant="outlined"
                        margin="normal"
                        className="kt-width-full"
                      />
                    }
                  >
                    <MenuItem value={"imperial"}>Imperial</MenuItem>
                    <MenuItem value={"metric"}>Metric</MenuItem>
                  </Select>
                </FormControl>

                {measurement_system === 'imperial'
                  ? (
                    <div style={{ display: 'flex' }}>
                      <TextField
                        key='46'
                        style={{ marginRight: 10 }}
                        type="number"
                        label="Height Ft"
                        variant="outlined"
                        margin="normal"
                        className="kt-width-full"
                        name="heightFt"
                        onBlur={handleBlur}
                        onChange={e => {
                          let temp = { ...e }
                          temp.target.value = Math.floor(e.target.value)
                          handleChange(temp)
                        }}
                        value={values.heightFt}
                        helperText={touched.heightFt && errors.heightFt}
                        error={Boolean(touched.heightFt && errors.heightFt)}
                        inputProps={{
                          type: 'number',
                          max: 7,
                          min: 2,
                        }}
                      />

                      <TextField
                        key='47'
                        variant="outlined"
                        type="number"
                        label="Height In"
                        margin="normal"
                        className="kt-width-full"
                        name="heightIn"
                        onBlur={handleBlur}
                        onChange={e => {
                          let temp = { ...e }
                          temp.target.value = Math.floor(e.target.value)
                          handleChange(temp)
                        }}
                        value={values.heightIn}
                        helperText={touched.heightIn && errors.heightIn}
                        error={Boolean(touched.heightIn && errors.heightIn)}
                        inputProps={{
                          max: 12,
                          min: 0,
                        }}
                      />
                    </div>
                  )
                  : (
                    <div style={{ display: 'flex' }}>
                      <TextField
                        key='20'
                        variant="outlined"
                        label="Height M"
                        margin="normal"
                        className="kt-width-full"
                        name="heightM"
                        onBlur={handleBlur}
                        onChange={e => {
                          let temp = { ...e }
                          temp.target.value = e.target.value
                          handleChange(temp)
                        }}
                        value={values.heightM}
                        helperText={touched.heightM && errors.heightM}
                        error={Boolean(touched.heightM && errors.heightM)}
                        inputProps={{
                          type: "number",
                          step: "0.1",
                          min: 1,
                          max: 3,
                        }}
                      />
                    </div>
                  )
                }

                <FormControl variant="outlined" className="MuiFormControl-marginNormal MuiTextField-root" error={Boolean(touched.gender && errors.gender)}>
                  <InputLabel htmlFor="gender-simple">Gender</InputLabel>
                  <Select
                    disabled={data.can_update}
                    value={values.gender}
                    onChange={handleChange}
                    input={<OutlinedInput labelWidth={45} />}
                    inputProps={{
                      name: 'gender',
                      id: 'gender-simple',
                    }}
                  >
                    <MenuItem value={'Male'}>Male</MenuItem>
                    <MenuItem value={'Female'}>Female</MenuItem>
                  </Select>
                  {
                    touched.gender && errors.gender &&
                    <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
                  }
                </FormControl>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={() => setModal(false)}>
                Close
              </Button>
              <Button variant="primary" type="submit" disabled={isSubmitting} className="btn-blue" onClick={() => {
                touched.birthday_day = true
                touched.birthday_month = true
                touched.birthday_year = true
                touched.heightIn = true
                touched.heightFt = true
                touched.gender = true
                handleSubmit()
              }}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
      <table>
        <thead>
          <tr>
            <td>
              <Checkbox
                checked={check.length === data.length && check.length !== 0}
                onChange={chbAll}
                color="primary"
              />
            </td>
            {defRow.map((item, index) => {
              if (item.label === 'Staff' && plan !== 'Enterprise' && plan !== 'Gym') {
                return '';
              } else if (item.hide && !groupVisible) {
                return ''
              } else return (
                <td key={index} onClick={() => sortEdit(item)} className={`${item.left ? 'left-content' : ''}${sort[0] === item.value ? ' active' : ''}`} style={{ cursor: !item.sort && 'default' }}>
                  {item.label}
                  {item.sort &&
                    <ArrowDown
                      className={`sort-arrow${sort[0] === item.value ? ' active' : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}`}
                    />
                  }
                </td>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {
            data.map((item, index) => {
              return (
                <TableRow
                  item={item}
                  plan={plan}
                  key={index + item.email}
                  check={check}
                  setCheck={setCheck}
                  setRedirect={setRedirect}
                  setModal={setModal}
                  setCurrentId={setCurrentId}
                />
              )
            })
          }

        </tbody>
      </table>
      {
        data.length === 0
          ? (
            <div
              style={{
                margin: '20px auto',
                textAlign: 'center'
              }}
            >
              No data available in table
            </div>
          ) : null
      }
    </StyledTablePl>
  )
}

const StyledTablePl = styled.div`
  cursor: pointer;

  td.active {
    font-weight: 600;
  }
  
  .sort-arrow {
    opacity: 0.7;
    transition: all .3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg)
    }
  }

  table thead tr td.left-content {
    text-align: left;
  }

  table tbody tr td.left-content {
    text-align: left;
  }

  .birthday-row {
    display: flex;
    justify-content: space-between;    
    margin-top: 16px;
    margin-bottom: 8px;

    &__error {
      color: #fd397a;
      margin: 8px 14px 0;
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1em;
    }

    & > div {
      width: 30%;
    }
  }
`;

const TableRow = ({ item, check, setCheck, setRedirect, setModal, setCurrentId, plan }) => {
  const history = useHistory();

  const getCheck = () => {
    return check.findIndex(id => id === item.profile_id) !== -1
  };

  const editCheck = () => {
    let temp = [...check]
    temp.splice(check.indexOf(item.profile_id), 1)
    getCheck()
      ? setCheck(temp)
      : setCheck([...check, item.profile_id])
  };

  const goTo = () => {
    history.push(`/profile/${item.profile_id}/overview`);
  };

  const [pic, setPic] = useState(null)
  useEffect(() => {
    !!item.pic && getImg(item.pic)
      .then(res => {
        setPic(res)
      })
  }, [item.pic]);

  const renderStaffColumn = () => {
    if (!item.staff) {
      return;
    } else if (plan !== 'Enterprise' && plan !== 'Gym') {
      return;
    } else return (
      <td>
        {item.staff.length > 0
          ? item.staff.map((elem, index) => {
            if (index === 0) {
              return elem.name;
            } else return elem.name;
          }).join(', ')
          : '--'
        }
      </td>
    )
  }

  return (
    <Row>
      <td>
        <Checkbox
          checked={getCheck()}
          onChange={() => editCheck()}
          color="primary"
        />
      </td>
      <td className="left-content" onClick={goTo}>
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {
            !!pic
              ? <ImgComponent src={pic} alt="img" className="profile-photo" />
              : <Avatar className="profile-photo">{item.first_name && item.first_name[0]}{item.last_name && item.last_name[0]}</Avatar>
          }
          {item.first_name} {item.last_name}
        </div>
      </td>
      <td onClick={goTo}>
        {item.age}
      </td>

      {renderStaffColumn()}

      <td onClick={goTo}>
        {
          item.is_parq_completed === 1
            ? <CheckCircle />
            : item.is_parq_completed === 0
              ? <ErrorCircle />
              : <CancelCircle />
        }
      </td>
      <td className="left-content" onClick={goTo}>
        {item.last_test ? moment(item.last_test).format('MM/DD/YYYY') : '--'}
      </td>
      <td className="left-content" onClick={goTo}>
        {item.last_goal ? moment(item.last_goal).format('MM/DD/YYYY') : '--'}
      </td>
      <td className="left-content" onClick={goTo}>
        {item.overdue_metrics}
      </td>
      <td className="left-content" onClick={goTo}>
        {item.email}
      </td>
    </Row>
  )
};

const Row = styled.tr`
  .profile-photo {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 10px;
    font-size: 14px;
    text-transform: uppercase;
  }
`;
