import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
} from "../../../../../../partials/content/Portlet";

const DataOutputElem = (props) => (
  <StyledDataOutput>
    <div className="dataoutput-data">
      <p style={{ color: props.color }}>{props.data}</p>
    </div>
    <div className="datainput-title">
      <div className="dataoutput-title__elem">{props.title2}</div>
    </div>
  </StyledDataOutput>
);

export const OpportunitiesSummary = (props) => {
  const customColor = useSelector(
    (state) => state.builder.layoutConfig?.custom_color
  );
  return (
    <StyledDataOutputWrap className="row row-full-height">
      {props.items.map((item) => {
        return (
          <div className="col-3" key={item.title} style={{ display: "flex" }}>
            <Portlet>
              <PortletBody>
                <DataOutputElem
                  data={item.value}
                  title1={item.value}
                  title2={item.title}
                  color={customColor || "#607CF7"}
                />
              </PortletBody>
            </Portlet>
          </div>
        );
      })}
    </StyledDataOutputWrap>
  );
};

const StyledDataOutputWrap = styled.div`
  .active {
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.25);
    background-color: #f0f0f0;
  }

  .kt-portlet .kt-portlet__body {
    padding: 10px 5px;
  }
`;

const StyledDataOutput = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .dataoutput {
    &-data {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Poppins";
      font-weight: 500;
      font-size: 25px;
    }

    &-title {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 15px;

      &__elem {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #595d6e;
        text-align: center;
      }
    }
  }
`;
