import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import {
    Modal,
    Button,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCRMList, checkActivation, fetchCRMSingle } from '../../../crud/crm.crud';
import { setCRMAction } from '../../../store/actions';
import * as AlertState from '../../../store/ducks/auth.duck';
import { useHistory } from 'react-router';
import Loader from '../../../components/Loader';

const ModalCRMSync = ({
    modal,
    setModal,
    crm,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const accountId = useSelector(state => state.appData?.account?.id);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (crm && accountId) {
            setLoader(true);
            fetchCRMSingle(accountId, crm.id)
                .then(res => {
                    setData(res.data.single);
                })
                .finally(() => setLoader(false))
        }
    }, [crm, accountId]);

    const handleClose = () => {
        setModal(false);
    };

    const handleContinue = () => {
        setLoader(true);
        return checkActivation(accountId, 'mindbody')
            .then(() => {
                fetchCRMList(accountId)
                    .then((res) => {
                        const crm = res.data.list.find(elem => elem.id === 'mindbody');
                        if (crm && crm.is_activated === true) {
                            dispatch(AlertState.actions.alert({
                                text: "CRM is activated successfully",
                                variant: true
                            }));
                            handleClose();
                            setTimeout(() => {
                                history.push('/account/crm/connect');
                            }, 500);
                        } else {
                            dispatch(AlertState.actions.alert({
                                text: "Please follow the activation steps below and click Continue",
                                variant: false
                            }));
                        }
                        dispatch(setCRMAction(res.data.list));
                    })
                    .catch(err => {
                        dispatch(AlertState.actions.alert({
                            text: "Failed to get CRM list from server",
                            variant: false
                        }));
                    })
                    .finally(() => setLoader(false))
            })
            .catch(() => {
                dispatch(AlertState.actions.alert({
                    text: "Failed to activate CRM",
                    variant: false
                }));
            })
            .finally(() => setLoader(false))
    };

    return (
        <Modal show={modal !== false} onHide={handleClose}>
            <Loader visible={loader} />
            <StyledModalAddCRM>
                <div
                    className="close-button"
                    onClick={handleClose}
                >×</div>
                <Modal.Title>
                    <h5 className="title">Activate CRM Sync</h5>
                </Modal.Title>
                <Modal.Title>
                    <h5 className="subtitle">Mindbody Online Activations Steps</h5>
                </Modal.Title>
                <Modal.Body>
                    <Typography className="static-text">
                        <ol>
                            <li>Copy your activation code</li>
                            <li>Click the activation URL</li>
                            <li>{`Once your ${crm?.title} account opens, paste your Activation Code and select Submit`}</li>
                        </ol>
                    </Typography>
                    <Typography className="static-text">
                        Activation URL:{` `}
                        <a
                            target="_blank"
                            href={data?.activation?.link}
                            className="link"
                            rel="noopener noreferrer"
                        >Click Here</a>
                    </Typography>
                    <Typography className="static-text">
                        Activation Code:
                        <span
                            className={data?.activation?.code ? 'pointer' : ''}
                            onClick={() => {
                                if (data?.activation?.code) {
                                    navigator.clipboard.writeText(data?.activation?.code)
                                        .then(() => {
                                            dispatch(AlertState.actions.alert({
                                                text: "Code copied",
                                                variant: true
                                            }));
                                        })
                                        .catch(err => {
                                            let errText = 'request failed';
                                            if (err.response?.data?.error) {
                                                errText = err.response?.data?.error;
                                            } else if (err.response?.data?.message) {
                                                errText = err.response.data.message;
                                            }
                                            dispatch(AlertState.actions.alert({
                                                text: errText,
                                                variant: false,
                                            }));
                                        })
                                } else return;
                            }}
                        >{` `}{data?.activation?.code || '--'}</span>
                    </Typography>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleContinue}
                        className="btn-blue"
                    >
                        Continue
                    </Button>
                </Modal.Footer>

                <div className="bottom-text">
                    Click continue after completing the activation steps above*
                </div>
            </StyledModalAddCRM>
        </Modal >
    )
}

const StyledModalAddCRM = styled.div`
    .close-button {
        position: absolute;
        top: 7px;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        margin: 20px 0 0 20px;
    }

    .subtitle {
        margin: 20px 0 0 20px;
        color: #000;
        font-weight: 600;
    }

    .static-text {
        font-size: 1.2em;
        margin: 5px;
        color: #000;
    }

    ol {
        padding-left: 15px;
    }

    li {
        padding: 5px;
    }

    .link {
        color: blue;
        text-decoration: underline;
    }

    .bottom-text {
        color: grey;
        margin: 10px 0 10px 45px
    }
`;

export default ModalCRMSync;