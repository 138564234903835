import React, { useEffect, useRef, useState } from 'react';
import { CardComponent } from "@chargebee/chargebee-js-react-wrapper";
import styled from 'styled-components';
import {
    Modal,
    Button
} from 'react-bootstrap';
import {
    createPaymentSource
} from '../../../crud/biling.crud';
import Loader from '../../../components/Loader';

export default ({
    modal,
    setModal,
    data,
    setCurrentPlan,
    tempPlan,
    setTempPlan,
    setModalPlan,
    setModalPlanType
}) => {
    const ref = useRef();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.Chargebee.init({
            site: process.env.REACT_APP_CHARGEBEE_SITE,
            publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY
        })
    }, []);

    const submit = () => {
        setLoader(true)
        ref.current.tokenize()
            .then((data) => {
                createPaymentSource({ token: data.token })
                    .then(res => {
                        setLoader(false)
                        setModal(false)
                        setCurrentPlan(val => ({ ...val, payment_source: res.data.data }))
                        if (tempPlan.length === 2) {
                            setModalPlan(tempPlan[0])
                            setModalPlanType(tempPlan[1])
                            setTempPlan([])
                        }
                    })
                    .catch(() => {
                        setLoader(false)
                    })
            })
            .catch((error) => {
                setLoader(false)
            });
    };

    return (
        <Modal
            show={modal}
            onHide={e => {
                setModal(false)
            }}
        >
            <StyledModal>
                <Modal.Header closeButton>
                    <Modal.Title>Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Loader visible={loader} />

                    <CardComponent ref={ref} />

                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={e => {
                            setModal(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={submit}
                        className="btn-blue"
                    >
                        Update
                    </Button>
                </Modal.Footer>
            </StyledModal>
        </Modal>
    )
};

const StyledModal = styled.div`
    .CbHosted {
        iframe {
            height: auto!important;
        }
    } 
`;
