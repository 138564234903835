import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL_V2;

export function getOpportunitiesTotal(params) {
  return axios.get(`${BASE_URL}sales/opportunities/total`, { params });
}

export const getOpportunitiesList = (params) =>
  axios.get(`${BASE_URL}sales/opportunities`, { params });

export const updateOpportunitiesField = (profileId, params) =>
  axios.patch(`${BASE_URL}sales/profiles/${profileId}/package-value`, params);

export const updateOpportunitiesNote = (profileId, params) =>
  axios.patch(`${BASE_URL}sales/profiles/${profileId}/notes`, params);

export const updateOpportunitiesPurchaseDate = (profileId, params) =>
  axios.patch(`${BASE_URL}sales/profiles/${profileId}/purchase-date`, params);

export const updateOpportunitieStatus = (profileId, params) =>
  axios.patch(
    `${BASE_URL}sales/profiles/${profileId}/opportunity-status`,
    params
  );
