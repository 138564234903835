import React, { useState } from 'react';
import {
    Button,
    Modal
} from 'react-bootstrap';
import Dropdown from '../../../../components/DropdownComponent';
import Loader from '../../../../components/Loader';
import { fetchForms } from '../../../../crud/forms';
import '../../style.scss';
import './email-form-modal.scss';

export const SendFormModal = ({ selectedForm, setSelectedForm, sendFormModal, setSendFormModal, setEmailFormModal }) => {
    const [loader, setLoader] = useState(false);
    const [options, setOptions] = useState([]);

    const loadData = search => {
        setLoader(true);
        fetchForms({ search, page: 1, perPage: 100 })
            .then(res => {
                setOptions(res.data.forms.map(elem => ({ title: elem.name, value: elem.id })));
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            })
    };

    const handleCountinue = () => {
        setSendFormModal(false);
        setEmailFormModal(true);
    };

    return (
        <div className='email-form-modal'>
            <Loader visible={loader} />
            <Modal
                show={sendFormModal}
                onHide={e => {
                    setSendFormModal(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>All Forms</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='dropdown-container email-form-modal-dropdown' style={{ height: '36px' }}>
                        <Dropdown
                            width="100%"
                            name="access"
                            label="Search Forms"
                            value={selectedForm}
                            fetchOptions={loadData}
                            options={options}
                            setFormData={val => setSelectedForm(val)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="btn-blue"
                        onClick={handleCountinue}
                        disabled={!selectedForm}
                    >Continue</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};
