import * as React from 'react';
import { Checkbox, ListItemText, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import styled from 'styled-components';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 25;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: null
};

export default function MultipleSelectCheckmarks({ options, value, setValue, label, width }) {
    const handleChange = (event, symbol) => {
        if (
            symbol?.props?.value === 'All' &&
            event.target.value.some(elem => elem === 'All')
        ) {
            setValue(options);
        } else if (
            symbol?.props?.value === 'All' &&
            event.target.value.filter(elem => elem !== 'All').length === options.length - 1
        ) {
            setValue([]);
        } else if (
            symbol?.props?.value !== 'All' &&
            event.target.value.filter(elem => elem !== 'All').length === options.length - 1
        ) {
            setValue(options);
        } else if (
            symbol?.props?.value !== 'All' &&
            event.target.value.some(elem => elem !== symbol?.props?.value)
        ) {
            setValue(event.target.value.filter(elem => elem !== 'All'));
        } else {
            setValue(event.target.value);
        }
    };

    return (
        <StyledDropdown>
            <Select
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput label={label || 'Select'} />}
                renderValue={selected => {
                    if (value.some(elem => elem === 'All')) {
                        return 'All';
                    } else return selected.join(', ');
                }}
                MenuProps={MenuProps}
                style={{ width: width || 250 }}
            >
                {options.map((item) => (
                    <MenuItem key={item} value={item}>
                        <Checkbox checked={value.indexOf(item) > -1} color='primary' />
                        <ListItemText primary={item} />
                    </MenuItem>
                ))}
            </Select>
        </StyledDropdown>
    );
};

const StyledDropdown = styled.div`
    position: relative;

    legend {
      visibility: visible;
      max-width: fit-content; 
    }
`;
