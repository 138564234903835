import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../store/ducks/auth.duck";
import { Redirect } from "react-router-dom";
import Logout from "../../auth/Logout";
import { useSelector } from "react-redux";
import styled from "styled-components";
import DropZone from "../../../components/DropZone";
import Cancel from "@material-ui/icons/Cancel";
// import Imggg from '../../../../_metronic/_assets/media/logo.svg'
import { TextField, InputAdornment } from "@material-ui/core";
import { ChromePicker } from "react-color";
import * as builder from "../../../../_metronic/ducks/builder";
import { updateAccount } from "../../../crud/info.crud";
import * as media from "../../../crud/media.crud";

const ImgComponent = ({ src, alt, className, style }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    src &&
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);
  return url && <img src={url} alt={alt} className={className} style={style} />;
};

export default ({ setLoader, setRedirect }) => {
  const dispatch = useDispatch();
  const store = useSelector(({ builder }) => builder);
  const layoutConfigs = store.layoutConfig;
  const headerLogo = builder.selectors.getLogo({ builder: store });
  const [touchImg, setTouchImg] = useState(true);
  const [photo, setPhoto] = useState("");
  const [primaryColor, setPrimaryColor] = useState(
    layoutConfigs.custom_color ?? ""
  );
  const [showPicker, setShowPicker] = useState(false);
  const wrapperRef = useRef(null);

  const [loaderUpdateAccount, setLoaderUpdateAccount] = useState(false);

  useEffect(() => {
    setLoader(loaderUpdateAccount);
    // eslint-disable-next-line
  }, [loaderUpdateAccount]);

  const handleColorChange = (color, event) => {
    const colorVal = color.hex;
    setPrimaryColor(colorVal);
    // setLoaderUpdateAccount(true)
    updateAccount({ primary_color: colorVal })
      .then((res) => {
        setLoaderUpdateAccount(false);
        dispatch(builder.actions.setLayoutConfigs({ custom_color: colorVal }));
        dispatch(
          AlertState.actions.alert({
            text: "Branding is updated",
            variant: true,
          })
        );
      })
      .catch(({ response }) => {
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
        setLoaderUpdateAccount(false);
        if (response && response.status === 401) {
          setRedirect(<Logout />);
        } else if (response && response.status === 403) {
          setRedirect(<Redirect to="/profile-list" />);
        }
      });
  };

  const handleInputClick = () => {
    setShowPicker(!showPicker);
  };

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleLogo = (photoItem) => {
    setPhoto(photoItem);
    setLoaderUpdateAccount(true);
    updateAccount({ file_id: photoItem.id })
      .then((res) => {
        setLoaderUpdateAccount(false);
        const data = res.data.data;
        dispatch(
          builder.actions.setLayoutConfigs({
            custom_logo: data.file_logo.path + `?up=${new Date()}`,
          })
        );
      })
      .catch(({ response }) => {
        setLoaderUpdateAccount(false);
        if (response && response.status === 401) {
          setRedirect(<Logout />);
        } else if (response && response.status === 403) {
          setRedirect(<Redirect to="/profile-list" />);
        }
      });
  };
  const handleLogoRemove = () => {
    setTouchImg(false);
    setLoaderUpdateAccount(true);
    updateAccount({ file_id: null })
      .then((res) => {
        setLoaderUpdateAccount(false);
        dispatch(builder.actions.setLayoutConfigs({ custom_logo: null }));
      })
      .catch(({ response }) => {
        setLoaderUpdateAccount(false);
        if (response && response.status === 401) {
          setRedirect(<Logout />);
        } else if (response && response.status === 403) {
          setRedirect(<Redirect to="/profile-list" />);
        }
      });
  };
  return (
    <TabWrap>
      <div className="row" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className={"col-4"}>
          <h4>Primary Color</h4>
        </div>
        <div className={"col-8"}>
          <div className="color-info">
            <TextField
              key="48"
              id="primary_color"
              className={"color_field"}
              variant="outlined"
              value={primaryColor}
              InputProps={{
                endAdornment: (
                  <InputAdornment className={"color_preview"}>
                    <span style={{ backgroundColor: primaryColor }}></span>
                  </InputAdornment>
                ),
              }}
              onClick={handleInputClick}
            />
            {showPicker && (
              <div ref={wrapperRef}>
                <ChromePicker
                  className={"color_picker"}
                  color={primaryColor}
                  onChangeComplete={handleColorChange}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row info-block" style={{ marginLeft: 0, marginRight: 0 }}>
        <div className={"col-4"}>
          <h4>Logo</h4>
        </div>
        <div className={"col-8"}>
          <div className="info-block__photo">
            {touchImg ? (
              <div
                className="info-block__photo-preview"
                style={{ height: "auto" }}
              >
                <ImgComponent
                  src={headerLogo}
                  alt="propfile"
                  style={{ height: "35px" }}
                />
                <div className="delete">
                  <Cancel onClick={handleLogoRemove} />
                </div>
              </div>
            ) : (
              <DropZone submit={handleLogo} photo={photo} />
            )}
          </div>
        </div>
      </div>
    </TabWrap>
  );
};

const TabWrap = styled.div`
  .row {
    margin-top: 30px;
  }
  #primary_color {
    padding: 12px;
  }
  .color_field > div {
    padding-right: 0;
  }
  .color_preview {
    width: 60px;
    height: 60px;
    max-height: 3em;
    background-color: #cecece;
  }
  .color_preview span {
    width: 50%;
    height: 50%;
    margin: 0 10px;
  }
  .color-info {
    position: relative;
  }
  .color-info .color_picker {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 2;
  }
  .info-block {
    &__photo {
      margin-top: 30px;

      &-preview {
        border: 1px dashed grey;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        width: 100%;
        padding: 30px;
        position: relative;

        .delete {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer;
        }

        img {
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
`;
