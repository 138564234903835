import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';
import { PDFObject } from 'react-pdfobject';
import { printEvent } from '../../crud/dashboard.crud';

export const EventPdf = ({ match }) => {
    const [pdf, setPdf] = useState();
    const accountId = useSelector((state) => state.appData.account.id);

    useEffect(() => {
        if (accountId) {
            printEvent(accountId, match.params.id)
                .then(res => {
                    const file = new Blob(
                        [res.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    setPdf(fileURL);
                })
        }
    }, [accountId]);

    return <PDFwrap>
        {pdf
            ? <PDFObject url={pdf} />
            : <div className="loaderpdf">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        }
    </PDFwrap>
}

const PDFwrap = styled.div`
    height: 100%;

    .loaderpdf {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #pdfobject {
        height: 100%;
    }
`;
