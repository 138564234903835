import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../store/ducks/auth.duck";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { Portlet, PortletBody } from "../../../partials/content/Portlet";
import {
  TextField,
  ListItemText,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@material-ui/core";
import { Formik } from "formik";
import Logout from "../../auth/Logout";
import { fieldsUser, createUser } from "../../../crud/info.crud";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";
import { saveMapping } from "../../../crud/crm.crud";

export default () => {
  const dispatch = useDispatch();
  document.title = "TrainerMetrics - Create User";
  const [errors, setErrors] = useState({});
  const [redirect, setRedirect] = useState(null);
  const [groups, setGroups] = useState([]);
  const [type, setType] = useState([]);
  const [typeData, setTypeData] = useState("");
  const [groupsData, setGroupsData] = useState([]);

  const [loaderFieldsUser, setLoaderFieldsUser] = useState(false);
  const [loaderCreateUser, setLoaderCreateUser] = useState(false);
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const fields = useSelector((state) => state.crmFields || "");
  const crm = useSelector((state) =>
    state.user_info.CRM_list.find((elem) => elem.is_activated === true)
  );
  const accountId = useSelector((state) => state.appData?.account?.id);

  const getGroups = () => {
    setLoaderFieldsUser(true);
    fieldsUser()
      .then((res) => {
        setLoaderFieldsUser(false);
        const gr = res.data.data.find((item) => item.name === "groups");
        setGroups(gr && gr.options && gr.options.length ? gr.options : []);
        const type = res.data.data.find((item) => item.name === "type");
        setType(type.options);
        if (type.options.length === 1) {
          setTypeData(type.options[0].name);
        }
      })
      .catch(({ response }) => {
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
        setLoaderFieldsUser(false);
        if (response && response.status === 401) {
          setRedirect(<Logout />);
        } else if (response && response.status === 403) {
          setRedirect(<Redirect to="/profile-list" />);
        }
      });
  };

  useEffect(() => {
    getGroups();
  }, []);

  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel]);

  const inputLabel_2 = useRef();
  const [labelWidth_2, setLabelWidth_2] = useState(0);

  useEffect(() => {
    inputLabel_2.current && setLabelWidth_2(inputLabel_2.current.offsetWidth);
  }, [inputLabel_2]);

  return (
    <StyledCreate>
      <Loader visible={loaderFieldsUser || loaderCreateUser} />
      {redirect}
      <Portlet>
        <PortletBody>
          <Formik
            initialValues={{
              email: fields?.email || "",
              first_name: fields?.first_name || "",
              last_name: fields?.last_name || "",
              type: fields?.type || "",
              groups: [],
            }}
            validate={(values) => {
              const temp = {};
              values.type = typeData;
              values.groups = groupsData;

              if (!values.first_name) {
                temp.first_name = "This value should not be blank";
              } else if (values.first_name.length > 50) {
                temp.first_name = "Field is not valid";
              } else if (values.first_name.length < 1) {
                temp.first_name =
                  "The First Name should be at least 1 of characters";
              }

              if (!values.last_name) {
                temp.last_name = "This value should not be blank";
              } else if (values.last_name.length > 50) {
                temp.last_name = "Field is not valid";
              } else if (values.last_name.length < 1) {
                temp.last_name =
                  "The Last Name should be at least 1 of characters";
              }

              if (!values.email) {
                temp.email = "This value should not be blank";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                temp.email = "Invalid email address";
              }

              if (!values.type) {
                temp.type = "This value should not be blank";
              }

              if (plan === "Enterprise") {
                if (groups.length > 0) {
                  if (groupsData.length === 0) {
                    temp.groups = "This value should not be blank";
                  }
                }
              }

              setErrors(temp);
              return temp;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              values.type = typeData;
              if (groups) {
                values.groups = groupsData;
              } else {
                delete values.groups;
              }
              setLoaderCreateUser(true);
              createUser(values)
                .then((res) => {
                  setLoaderCreateUser(false);
                  setStatus(false);
                  dispatch(
                    AlertState.actions.alert({
                      text: "User is created",
                      variant: true,
                    })
                  );

                  if (fields?.id) {
                    saveMapping(accountId, crm.id, "users", {
                      internal_id: res.data.data.id,
                      external_id: fields.id,
                    })
                      .then(() => {
                        setTimeout(() => {
                          dispatch(
                            AlertState.actions.alert({
                              text: "Mapping created successfully",
                              variant: true,
                            })
                          );
                        }, 1000);
                      })
                      .catch(() => {
                        setTimeout(() => {
                          dispatch(
                            AlertState.actions.alert({
                              text: "Mapping creation error",
                              variant: false,
                            })
                          );
                        }, 1000);
                      });
                  }

                  setRedirect(<Redirect to={`/user-list`} />);
                })
                .catch(({ response }) => {
                  response &&
                    response.data &&
                    dispatch(
                      AlertState.actions.alert({
                        text: response.data.message || response.data.error,
                        variant: false,
                      })
                    );
                  setLoaderCreateUser(false);
                  if (response && response.status === 401) {
                    setRedirect(<Logout />);
                  } else if (response && response.status === 403) {
                    setRedirect(<Redirect to="/profile-list" />);
                  }
                  !!response &&
                    !!response.data &&
                    !!response.data.errors &&
                    setErrors({
                      email:
                        response.data.errors.email &&
                        response.data.errors.email[0],
                      last_name:
                        response.data.errors.last_name &&
                        response.data.errors.last_name[0],
                      first_name:
                        response.data.errors.first_name &&
                        response.data.errors.first_name[0],
                      type:
                        response.data.errors.type &&
                        response.data.errors.type[0],
                      groups:
                        response.data.errors.groups &&
                        response.data.errors.groups[0],
                    });
                  setSubmitting(false);
                  setStatus(response.data.message);
                });
            }}
          >
            {({
              values,
              status,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form root"
                onSubmit={handleSubmit}
              >
                <div className="row">
                  <div className="col-12 col-md-6 info-block">
                    {status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                    )}
                    <div className="form-group">
                      <TextField
                        key="51"
                        variant="outlined"
                        type="text"
                        label="First Name"
                        margin="normal"
                        className="kt-width-full"
                        name="first_name"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.first_name}
                        helperText={touched.first_name && errors.first_name}
                        error={Boolean(touched.first_name && errors.first_name)}
                      />

                      <TextField
                        key="52"
                        variant="outlined"
                        type="text"
                        label="Last Name"
                        margin="normal"
                        className="kt-width-full"
                        name="last_name"
                        inputProps={{
                          maxLength: 50,
                        }}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.last_name}
                        helperText={touched.last_name && errors.last_name}
                        error={Boolean(touched.last_name && errors.last_name)}
                      />

                      <TextField
                        key="53"
                        variant="outlined"
                        type="text"
                        label="Email"
                        margin="normal"
                        className="kt-width-full"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.email}
                        helperText={touched.email && errors.email}
                        error={Boolean(touched.email && errors.email)}
                      />

                      <div className="MuiFormControl-root MuiTextField-root kt-width-full MuiFormControl-marginNormal">
                        <FormControl
                          variant="outlined"
                          className="formControl"
                          error={Boolean(touched.type && errors.type)}
                        >
                          <InputLabel
                            ref={inputLabel}
                            htmlFor="select-multiple-checkbox"
                          >
                            Type
                          </InputLabel>
                          <Select
                            disabled={type.length === 1}
                            value={typeData}
                            name="type"
                            onChange={(e) => setTypeData(e.target.value)}
                            input={
                              <OutlinedInput
                                labelWidth={labelWidth}
                                name="type"
                              />
                            }
                            renderValue={(selected) =>
                              type.find((elem) => elem.name === selected) &&
                              type.find((elem) => elem.name === selected).title
                            }
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 250,
                                  width: 250,
                                },
                              },
                            }}
                          >
                            {type.map((item) => (
                              <MenuItem key={item.name} value={item.name}>
                                {item.title}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText>
                            {(touched.type && errors.type) || "Select one"}
                          </FormHelperText>
                        </FormControl>
                      </div>
                      {plan === "Enterprise" && groups && (
                        <div className="MuiFormControl-root MuiTextField-root kt-width-full MuiFormControl-marginNormal">
                          <FormControl
                            variant="outlined"
                            className="formControl"
                            error={Boolean(touched.groups && errors.groups)}
                          >
                            <InputLabel
                              ref={inputLabel_2}
                              htmlFor="select-multiple-checkbox"
                            >
                              Groups
                            </InputLabel>
                            <Select
                              multiple
                              onOpen={getGroups}
                              value={groupsData}
                              onChange={(e) => setGroupsData(e.target.value)}
                              input={
                                <OutlinedInput
                                  labelWidth={labelWidth_2}
                                  id={`select-multiple-checkbox`}
                                />
                              }
                              renderValue={(selected) =>
                                selected
                                  .map(
                                    (item) =>
                                      groups.find((elem) => elem.id === item)
                                        .title
                                  )
                                  .join(", ")
                              }
                              MenuProps={{
                                PaperProps: {
                                  style: {
                                    maxHeight: 250,
                                    width: 250,
                                  },
                                },
                              }}
                            >
                              {groups.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  <ListItemText primary={item.title} />
                                </MenuItem>
                              ))}
                              {groups.length === 0 && (
                                <MenuItem disabled>None</MenuItem>
                              )}
                            </Select>
                            <FormHelperText>
                              {(touched.groups && errors.groups) ||
                                "Select one or more"}
                            </FormHelperText>
                          </FormControl>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="kt-login__actions" style={{ marginTop: 20 }}>
                  <button
                    type="submit"
                    className="btn btn-blue btn-elevate kt-login__btn-primary"
                    disabled={isSubmitting}
                  >
                    Create User
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </PortletBody>
      </Portlet>
    </StyledCreate>
  );
};

const StyledCreate = styled.div`
  .nav.nav-tabs {
    margin: 0;
    font-size: 14px;
    font-weight: 450;
  }
`;
