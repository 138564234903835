export const tableHead = [
    {
        label: 'checkbox',
        left: true,
        sort: false,
    },
    {
        label: 'Exercise Name',
        value: 'title',
        left: true,
        sort: true,
    },
    {
        label: 'Body Segment',
        value: 'body_segment',
        left: true,
        sort: true,
    },
    {
        label: 'Primary Muscle(s)',
        value: 'muscle',
        left: true,
        sort: true,
    },
    {
        label: 'Equipment',
        value: 'equipment',
        left: true,
        sort: true,
    },
    {
        label: 'Mechanics',
        value: 'mechanic',
        left: true,
        sort: true,
    },
    {
        label: 'Force',
        value: 'force',
        left: true,
        sort: true,
    },
    {
        label: 'Units of Measure',
        value: '',
        left: true,
        sort: false,
    },
];
