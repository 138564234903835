import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap'
import { useDispatch } from "react-redux";
import * as AlertState from '../../store/ducks/auth.duck'
import {
  // FormattedMessage, 
  injectIntl
} from "react-intl";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  FormHelperText,
  FormControl,
  FormGroup,
  Select,
  MenuItem,
  InputLabel   
} from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { register } from "../../crud/auth.crud";
import { createCoupon } from "../../crud/biling.crud";
import './style.scss'
import Footer from '../../../_metronic/layout/footer/FooterAuth'
import MuiPhoneNumber from 'material-ui-phone-number'
import {
  createPaymentSource,
  updateSubscription,
  getPlanList
} from '../../crud/biling.crud'
import { CardComponent } from "@chargebee/chargebee-js-react-wrapper";
import moment from 'moment'
import jQuery from 'jquery'
import Cookies from 'js-cookie'

const usersData = [
  {
    name: '-Daryll Joseph, Swagger State Athletics',
    text: '"We use TrainerMetrics for comprehensive data collection, scientifically-informed exercise prescription, movement mastery and meaning along with motivational tracking and reporting."',
    icon: 'https://www.trainermetrics.com/wp-content/uploads/2020/06/testimonial-daryll.png'
  },
  {
    name: '-Angie Botts, Up Lift Training',
    text: '“The integration of TrainerMetrics into Up Lift Training`s session workflow was seamless and proved to take the business to the next level.”',
    icon: 'https://www.trainermetrics.com/wp-content/uploads/2020/06/testimonial-angie.png'
  },
  {
    name: '-Ken Ward, 24 Hour Fitness',
    text: '"Finally I can analyze my personal training staffs` effectiveness. Our trainers already take their client`s measurements, now all of that data is in one place."',
    icon: 'https://www.trainermetrics.com/wp-content/uploads/2020/06/testimonial-ken.png'
  },
]

const RegistrationBilling = (props) => {
  const [isFirstRun, setFirstRun] = useState(true)
  const nextPay = moment(moment().add('month', 1)).format('MMMM Do')
  const [planPrice, setPlanPrice] = useState(0)
  const [period, setPeriod] = useState(0)
  const [id, setId] = useState(0)

  if (isFirstRun) {
    window.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE,
      publishableKey: process.env.REACT_APP_CHARGEBEE_PUBLISHABLE_KEY
    })
    setFirstRun(false)
  }

  useEffect(() => {
    getPlanList()
      .then(res => {
        const query = queryToObject()
        const currentPlan = res.data.data.filter(item => {
          const getPlan = (query.tier === 'lite'
            ? 'Trainer'
            : query.tier === 'gym'
              ? 'Gym'
              : '')
          return (item.period === query.interval) &&
            (item.active_profiles >= parseInt(query.limit)) &&
            (item.name === getPlan)
          // && (parseFloat(`${item.price}`) <= parseFloat(`${query.price}`))
        })
        // const currentPlan = res.data.data.find(item => item.id === parseInt(props.match.params.id))
        if (currentPlan[0]) {
          setPlanPrice(currentPlan[0].price)
          setPeriod(currentPlan[0].period)
          setId(currentPlan[0].id)
        } else {
          setPlanPrice(res.data.data[0].price)
          setPeriod(res.data.data[0].period)
          setId(res.data.data[0].id)
        }
      })
      .catch(({ response }) => {
        // setLoaderPlanList(false)
        // if (response && (response.status === 401)) {
        //     setLogout(<Logout />)
        // } else if(response && (response.status === 403)) {
        //     setLogout(<Redirect to="/profile-list" />)
        // }
      })
  }, []) // eslint-disable-line

  const { intl } = props
  document.title = 'TrainerMetrics - Sign Up'
  const dispatch = useDispatch();

  const ref = useRef()

  const [error, setError] = useState({})

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const next = () => {
    return period === 'month'
      ? planPrice.toFixed(2)
      : (planPrice * 12).toFixed(2)
  }

  const queryToObject = () => {
    if (window.location.search.length > 0) {
      var search = window.location.search.substring(1);

      return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
    } else {
      return {}
    };
  };

  useEffect(() => {
    function isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key))
          return false;
      }
      return true;
    };



    var original_ref = document.referrer;

    function extractDomain(url) {
      var domain;

      if (url.indexOf("://") > -1) {
        domain = url.split('/')[2];
      } else {
        domain = url.split('/')[0];
      }

      domain = domain.split(':')[0];
      return domain;
    };

    var ref = extractDomain(original_ref);

    if (ref.indexOf(window.location.host) > -1) {
      ref = false;
    };

    var sourceCookieValue = Cookies.get("tm_source_string") || '';

    var currentParams = queryToObject();

    function pipeIfNotBlank() {
      if (sourceCookieValue !== "") {
        sourceCookieValue += "|";
      }
    };

    if (currentParams["utm_source"]) {
      pipeIfNotBlank();
      sourceCookieValue += "utm_source=" + currentParams["utm_source"];
    } else if (currentParams["gclid"]) {
      pipeIfNotBlank();
      sourceCookieValue += "adwords_gclid=" + currentParams["gclid"];
    } else if (ref) {
      pipeIfNotBlank();
      sourceCookieValue += "referral=" + ref;
    };

    Cookies.set("tm_source_string", sourceCookieValue, { expires: 730 });

    function saveUTMs() {
      var currentParams = queryToObject();

      if (!isEmpty(currentParams)) {
        let paramsToCookie = {};
        var eligibleParams = ["utm_campaign", "utm_medium", "utm_source", "utm_content"];

        eligibleParams.forEach(function (p) {
          if (currentParams[p]) {
            paramsToCookie[p] = currentParams[p];
          } else {
            paramsToCookie[p] = "not set";
          }
        });

        Cookies.set('tm_cam_trk', paramsToCookie);
      };
    }

    saveUTMs();

    function pollForForms() {
      var count = 0;
      var formInt = setInterval(function () {
        var forms = jQuery(".sign-form");
        if (forms.length) {
          clearInterval(formInt);
          bindFormEvents(forms);
        } else if (count > 120) {
          clearInterval(formInt);
        } else {
          count++;
        };
      }, 500);
    };

    pollForForms();

    function bindFormEvents(forms) {

      let acFieldNames = ['field[11]', 'field[12]', 'field[13]', 'field[14]'];
      let realFieldNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];

      var cookiedUTMs = Cookies.getJSON("tm_cam_trk") || {};

      if (!isEmpty(cookiedUTMs)) {
        for (let i = 0; i < acFieldNames.length; i++) {
          if (cookiedUTMs[realFieldNames[i]]) {
            jQuery("input[name='" + acFieldNames[i] + "']").val(cookiedUTMs[realFieldNames[i]]);
          };
        };
      };

      var sourceString = Cookies.get("tm_source_string") || "";

      var touches = sourceString.split("|");
      if (typeof (touches[0]) != "undefined") {
        jQuery("input[name='field[42]']").val(touches[0]);
      };
      if (touches.length > 0) {
        jQuery("input[name='field[43]']").val(touches[touches.length - 1]);
      };

      jQuery("input[name='field[10]']").val(sourceString);
    };
  }, [])

  const afterSubmit = () => {
    if (`${typeof window.dataLayer}` !== 'undefined') {
      window.dataLayer.push({
        "event": "gaTriggerEvent",
        "gaEventCategory": 'Form Submit',
        "gaEventAction": jQuery(".sign-form").attr('class'),
        "gaEventLabel": window.location.pathname
      });
    }
  }

  return (<><div style={{ background: 'white', height: '100%' }}>
    <div className="registration-top">
      <h1 className="registration-top__h1">Sign Up For A Free Account</h1>
      <h3 className="registration-top__h3">Trusted By Trainers And Organizations All Over The Globe</h3>
    </div>
    <div className="registration" style={{ padding: 0, background: '#ffffff' }}>
      <Formik
        initialValues={{
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
          companyname: "",
          // password: "",
          acceptTerms: false,
          // confirmPassword: ""
          coupon: "",
          usertitle: "",
          totalclients: ""
        }}
        validate={values => {
          const errors = {};
          const emailField = document.querySelector('#neverbounce-valid-checkbox')

          if (!values.email) {
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address"
          } else if (
            emailField && (`${emailField.value}` === 'false') && process.env.REACT_APP_NEVERBOUNCE !== 'disable'
          ) {
            errors.email = "Invalid email address"
          }

          if (!values.firstname) {
            errors.firstname = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (values.firstname.length > 15) {
            errors.firstname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          } else if (values.firstname.length < 2) {
            errors.firstname = 'The First name should be at least 2 of characters'
          } else if (/[^A-Za-z0-9]/g.test(values.firstname)) {
            errors.firstname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }

          if (!values.lastname) {
            errors.lastname = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (values.lastname.length > 15) {
            errors.lastname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          } else if (values.lastname.length < 2) {
            errors.lastname = 'The Last name should be at least 2 of characters'
          } else if (/[^A-Za-z0-9]/g.test(values.lastname)) {
            errors.lastname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }

          if (values.companyname && values.companyname.length > 50) {
            errors.companyname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          } else if (values.companyname && values.companyname.length < 2) {
            errors.companyname = 'The Сompany name should be at least 2 of characters'
          }

          if (!values.phone) {
            errors.phone = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (values.phone.length > 19 || values.phone.length < 7) {
            errors.phone = "Phone number is invalid"
          } else if (/[^0-9 ()+-]/g.test(values.phone)) {
            errors.phone = "Phone number is invalid"
          }

          if (!values.coupon) {
          } else if (values.coupon.length > 50 || values.coupon.length < 2) {
            errors.coupon = "Coupon is invalid"
          }

          if (!values.acceptTerms) {
            errors.acceptTerms = "Accept Terms";
          }
          setError(errors)
          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          let temp = [11, 12, 13, 14, 42, 43]
          let field = []
          temp.map(item => {
            field[item] = jQuery("input[name='field[" + item + "]']").val()
            return item
          })
          enableLoading()
          window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LeJua4ZAAAAACQMDff1rwrfBCzLxe8fu7R7zUi6', { action: 'signUpFree' })
              .then(function (token) {
                const emailField = document.querySelector('#neverbounce-valid-checkbox')

                ref.current.tokenize()
                  .then((data) => {
                    register(
                      values.email,
                      values.firstname,
                      values.lastname,
                      values.phone,
                      values.companyname,
                      token,
                      'signUpFree',
                      emailField && emailField.value,
                      field
                    )
                      .then(async res => {
                        await props.login(res.data.data.accessToken);
                        createPaymentSource({ token: data.token })
                          .then(res2 => {
                            updateSubscription(id)
                              .then(res3 => {
                                disableLoading()
                                afterSubmit()
                                dispatch(AlertState.actions.alert({
                                  text: "User is created",
                                  variant: true
                                }));
                                createCoupon(values.coupon)
                              })
                              .catch(({ response }) => {
                                disableLoading()
                                response.data.error &&
                                  setSubmitting(false);
                                dispatch(AlertState.actions.alert({
                                  text: response.data.message || (typeof response.data.error === 'string' && response.data.error),
                                  variant: false
                                }));
                              })
                          })
                          .catch(({ response }) => {
                            disableLoading()
                            dispatch(AlertState.actions.alert({
                              text: response.data.message || (typeof response.data.error === 'string' && response.data.error),
                              variant: false
                            }));
                          })
                      })
                      .catch(({ response }) => {
                        response.data.error && response.data.error && setError({
                          email: response.data.error.email && response.data.error.email[0],
                          firstname: response.data.error.first_name && response.data.error.first_name[0],
                          lastname: response.data.error.last_name && response.data.error.last_name[0],
                          phone: response.data.error.phone && response.data.error.phone[0],
                          companyname: response.data.error.companyName && response.data.error.companyName[0]
                        })
                        disableLoading()
                        setSubmitting(false);
                        setStatus(response.data.message || (typeof response.data.error === 'string' && response.data.error));
                      });
                  })
                  .catch(({ response }) => {
                    disableLoading()
                    setSubmitting(false);
                    response && setStatus(response.data.message || (typeof response.data.error === 'string' && response.data.error));
                    !response && setError({ card: 'Field is not valid' })
                  });
              })
          })
            .catch(() => {
              disableLoading();
              setSubmitting(false);
            })
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <div className="registration-form" style={{ margin: '0 18px 30px' }}>
            <div className="login-top">Create Your Account</div>
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <input id="field10" name="field[10]" defaultValue="" style={{ display: 'none' }} />
              <input id="field11" name="field[11]" defaultValue="" style={{ display: 'none' }} />
              <input id="field12" name="field[12]" defaultValue="" style={{ display: 'none' }} />
              <input id="field13" name="field[13]" defaultValue="" style={{ display: 'none' }} />
              <input id="field14" name="field[14]" defaultValue="" style={{ display: 'none' }} />
              <input id="field42" name="field[42]" defaultValue="" style={{ display: 'none' }} />
              <input id="field43" name="field[43]" defaultValue="" style={{ display: 'none' }} />

              <div className="form-group mb-0">
                <TextField
                  key='12'
                  variant="outlined"
                  margin="normal"
                  label="First Name"
                  className="kt-width-full"
                  name="firstname"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 15
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstname}
                  helperText={touched.firstname && error.firstname}
                  error={Boolean(touched.firstname && error.firstname)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  key='13'
                  variant="outlined"
                  margin="normal"
                  label="Last Name"
                  className="kt-width-full"
                  name="lastname"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 15
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastname}
                  helperText={touched.lastname && error.lastname}
                  error={Boolean(touched.lastname && error.lastname)}
                />
              </div>

              <input id="neverbounce-valid-checkbox" type="text" style={{ display: 'none' }} />

              <div className="form-group mb-0">
                <TextField
                  key='14'
                  variant="outlined"
                  label="Email"
                  margin="normal"
                  className="kt-width-full"
                  name="email"
                  inputProps={{
                    maxLength: 40,
                    'data-nb': true
                  }}
                  autoComplete={`off`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && error.email}
                  error={Boolean(touched.email && error.email)}
                />
              </div>

              <div className="form-group mb-0">
                <MuiPhoneNumber
                  key='15'
                  variant="outlined"
                  label="Phone"
                  margin="normal"
                  className="kt-width-full"
                  name="phone"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 19
                  }}
                  onBlur={handleBlur}
                  onChange={(value) => handleChange({ target: { value, name: 'phone' } })}
                  value={values.phone}
                  helperText={touched.phone && error.phone}
                  error={Boolean(touched.phone && error.phone)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  key='16'
                  variant="outlined"
                  margin="normal"
                  label="Company Name"
                  className="kt-width-full"
                  name="companyname"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 50
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyname}
                  helperText={touched.companyname && error.companyname}
                  error={Boolean(touched.companyname && error.companyname)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  key='16'
                  variant="outlined"
                  margin="normal"
                  label="Coupon"
                  className="kt-width-full"
                  name="coupon"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 50
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.coupon}
                  helperText={touched.coupon && error.coupon}
                  error={Boolean(touched.coupon && error.coupon)}
                />
              </div>

              <FormControl
                variant="outlined"
                className="MuiFormControl-marginNormal MuiTextField-root"
              >
                <InputLabel>Title</InputLabel>
                <Select
                  name="usertitle"
                  value={values.usertitle}
                  onBlur={handleBlur}
                  onChange={handleChange}                  
                >
                  <MenuItem value={'Personal Trainer'}>Personal Trainer</MenuItem>
                  <MenuItem value={'Manager'}>Manager</MenuItem>
                  <MenuItem value={'Owner / Operator'}>Owner / Operator</MenuItem>
                  <MenuItem value={'Executive'}>Executive</MenuItem>
                </Select>
              </FormControl>              

              <FormControl
                variant="outlined"
                className="MuiFormControl-marginNormal MuiTextField-root"
              >
                <InputLabel>Total Clients</InputLabel>
                <Select
                  name="totalclients"
                  value={values.totalclients}
                  onBlur={handleBlur}
                  onChange={handleChange}                  
                >
                  <MenuItem value={'0 - 50'}>0 - 50</MenuItem>
                  <MenuItem value={'50 - 100'}>50 - 100</MenuItem>
                  <MenuItem value={'100 - 250 / Operator'}>100 - 250</MenuItem>
                  <MenuItem value={'250 - 500'}>250 - 500</MenuItem>
                  <MenuItem value={'500 - 1000'}>500 - 1000</MenuItem>
                  <MenuItem value={'1000+'}>1000+</MenuItem>
                </Select>
              </FormControl>

              <div
                style={{
                  width: '100%',
                  height: 1,
                  background: '#eeeeee',
                  margin: '30px 0'
                }}
              />

              {planPrice !== 0 && <div role="alert" className="alert alert-primary">
                <div className="alert-text">You will be charged a plan rate of ${next()} starting {nextPay}, then ${next()} every {period} on {nextPay} until you cancel.</div>
              </div>}

              <CardComponent ref={ref} />

              <div style={{ color: '#fd397a', margin: '8px 14px 10px', fontSize: '0.75rem' }}>
                {error.card}
              </div>

              <FormControl required error={Boolean(touched.acceptTerms && error.acceptTerms)} component="fieldset" style={{ marginBottom: 10 }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        name="acceptTerms"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        checked={values.acceptTerms}
                      />
                    }
                    style={{ margin: 0 }}
                    label={
                      <>
                        I agree the{" "}
                        <a
                          href="https://www.trainermetrics.com/terms-of-service/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="kt-footer__menu-link kt-link"
                        >
                          {'Terms of Service'}
                        </a>
                        {' and '}
                        <a
                          href="https://www.trainermetrics.com/privacy-policy/"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="kt-footer__menu-link kt-link"
                        >
                          {'Privacy Policy'}
                        </a>
                      </>
                    }
                  />
                </FormGroup>
                {
                  touched.acceptTerms && error.acceptTerms && (
                    <FormHelperText style={{ color: '#fd397a', margin: '0 0 10px 10px' }}>Please accept Terms and Conditions to continue</FormHelperText>
                  )
                }
              </FormControl>

              <div className="kt-login__actions registration-btn">
                <Button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={isSubmitting}
                  className={`btn btn-elevate kt-login__btn-primary btn-login btn-blue ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  Continue
                  </Button>
              </div>
            </form>
          </div>
        )}
      </Formik>
      <div className="registration-info" style={{ margin: '0 18px' }}>
        {
          usersData.map((item, index) => {
            return (
              <div className="registration-info__wrap" key={index}>
                <img src={item.icon} alt="user" className="registration-info__img" />
                <div className="registration-info__text">
                  <div className="registration-info__text-top">
                    {item.text}
                  </div>
                  <div className="registration-info__text-autor">
                    {item.name}
                  </div>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  </div>
    <Footer /></>);
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(RegistrationBilling)
);
