export const tableHead = [
    {
        label: 'Workout Name',
        value: 'workout-title',
        left: true,
        sort: true,
    },
    {
        label: 'Program Name',
        value: 'program-title',
        left: true,
        sort: true,
    },
    {
        label: 'Status',
        value: 'is-completed',
        left: true,
        sort: true,
    },
    {
        label: 'Completed by',
        value: 'completed-by',
        left: true,
        sort: true,
    },
    {
        label: 'Completed Date',
        value: 'completed-at',
        left: true,
        sort: true,
    },
];
