export const tableHead = [
    {
        label: 'Event Name',
        value: 'title',
        left: true,
        sort: true,
    },
    {
        label: 'Start Time',
        value: 'start_at',
        left: true,
        sort: true,
    },
    {
        label: 'End Time',
        value: 'end_at',
        left: true,
        sort: true,
    },
    {
        label: 'Profile',
        value: 'profiles',
        left: true,
        sort: true,
    },
    {
        label: 'User',
        value: 'users',
        left: true,
        sort: true,
    },
];
