import React, { useState, useEffect } from 'react';
import { TableRow } from './TableRow';
import { Checkbox } from '@material-ui/core';
import { tableHead } from './tableHead';
import ArrowDown from '@material-ui/icons/ArrowDropDown';

export const Table = ({
    data,
    check,
    setCheck,
    sort,
    setSort,
    filter,
    setViewModal,
    setCurrent,
}) => {
    const checked = data.length && check.length === data.length;
    const [filteredHead, setFilteredHead] = useState(filter || []);
    const [filteredData, setFilteredData] = useState(data || []);
    const [filterObject, setFilterObject] = useState({});

    useEffect(() => {
        if (filter && filter.length) {
            let arr = [...tableHead];
            arr = arr.filter(elem => {
                return filter.some(item => item === elem.label);
            });
            arr.unshift({
                label: "Exercise Name",
                left: true,
                sort: true,
                value: "title",
            });
            setFilteredHead(arr);
        };

        if (!filter.length) {
            setFilteredHead([{
                label: "Exercise Name",
                left: true,
                sort: true,
                value: "title",
            }])
        };

        if (data) {
            setFilteredData(data);
        };
    }, [filter, data]);

    useEffect(() => {
        if (filter) {
            let obj = {
                'Exercise Name': true,
                'Body Segment': false,
                'Primary Muscle(s)': false,
                'Equipment': false,
                'Mechanics': false,
                'Force': false,
                'Units of Measure': false,
            };
            filter.forEach(elem => {
                obj[elem] = true;
            });
            obj['Exercise Name'] = true;
            setFilterObject(obj);
        }
    }, [filter]);

    const handleCheck = () => {
        if (data.length) {
            if (!checked) {
                setCheck(data.map(elem => ({ id: elem.id, is_editable: elem.is_editable })))
            } else {
                setCheck([])
            }
        }
    };

    const sortEdit = (item) => {
        item.sort && setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
    };

    return (
        <div className="settingtable nowrap">
            <table>
                <thead>
                    <tr>
                        <td>
                            <Checkbox
                                color="primary"
                                checked={!!checked}
                                onChange={handleCheck}
                            />
                        </td>
                        {filteredHead?.map((item, index) => (
                            <td
                                key={index}
                                onClick={() => sortEdit(item)}
                                className={
                                    `${item.left ? 'left-content' : ''}`
                                }
                                style={{ cursor: !item.sort && 'default' }}
                            >
                                {item.label}
                                {item.sort &&
                                    <ArrowDown
                                        className={`sort-arrow
                                            ${sort[0] === item.value
                                                ? ' active'
                                                : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}
                                            `}
                                    />
                                }
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {filteredData?.map((item) => (
                        <TableRow
                            item={item}
                            check={check}
                            setCheck={setCheck}
                            onClick={() => {
                                setViewModal(true);
                                setCurrent(item.id);
                            }}
                            filterObject={filterObject}
                            key={item.id}
                        />
                    ))}
                </tbody>
            </table>

            {!data.length &&
                <div
                    style={{
                        margin: '20px auto',
                        textAlign: 'center'
                    }}
                >
                    No data available in table
                </div>
            }
        </div>
    )
};
