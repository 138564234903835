import * as media from "../../../../crud/media.crud";
import React, { useState, useEffect } from "react";

export const ImgComponent = ({ src, alt, className, style }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    src &&
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);

  return url && <img src={url} alt={alt} className={className} style={style} />;
};
