/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker from '../../../components/DatePicker';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getProfileMetric } from '../../../crud/profile.crud';
import { getNote, createNote, updateNote, getNotesV2 } from '../../../crud/notes.crud';
import { fetchProfileExercises } from '../../../crud/library.crud';
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom';
import { CustomRadio } from '../../../components/CustomRadio/CustomRadio';
import { useSelector } from 'react-redux';

export default ({
  id,
  noteId,
  updateModal,
  setUpdateModal,
  clearCheck,
  setLoader,
  fetchData,
  AlertState,
  dispatch,
  setOpenInfoModal,
  currentItem,
  setCurrentItem,
  check,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [metrics, setMetrics] = useState();
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState({});
  const [errorsMetric, setErrorsMetric] = useState('');
  const [date, setDate] = useState(moment().format('MM/DD/YYYY'));
  const [metric, setMetric] = useState(null);
  const [text, setText] = useState('');
  const [errorsNote, setErrorsNote] = useState('');
  const [exercisesList, setExercisesList] = useState([]);
  const [exercise, setExercise] = useState(null);
  const [exerciseInputValue, setExerciseInputValue] = useState('');
  const [notes, setNotes] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [is_private, set_is_private] = useState('Shared to Users and Profiles');
  const location = useLocation();
  const role = useSelector(state => state.auth.user.roles);

  const loadExercises = () => {
    setLoader(true);
    fetchProfileExercises(id, { page: 1, perPage: 1000 })
      .then(res => {
        setExercisesList(res.data.exercises);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      })
  };

  useEffect(() => {
    if (updateModal) {
      loadExercises();
    };
    if (!updateModal) {
      setIsEdit(false);
      setExercise(null);
    }
  }, [updateModal]);

  useEffect(() => {
    if (currentItem && updateModal) {
      setExercise(currentItem.exercise || null);

      let item = metrics?.find(elem => elem.metric === currentItem.metric?.name)
      if (item && check.length < 2) {
        setMetric({ ...item, title: item.metric });
      } else {
        setMetric({ title: 'General', metric: "General" });
      }
    }
  }, [check.length, currentItem, metrics, updateModal]);

  useEffect(() => {
    if (exercise && updateModal) {
      const profileId = location.pathname.split('/')[2];
      setLoader(true);
      getNotesV2(profileId, { exercise_id: exercise.id })
        .then(res => {
          if (res.data.data.length) {
            setIsEdit(true);
            setNotes(res.data.data);
            if (res.data.data.length > 0) {
              setText(res.data.data[0].note_text);
              res.data.data[0].is_private === 1
                ? set_is_private('Private to only Users')
                : set_is_private('Shared to Users and Profiles')
            }
          }
        })
        .finally(() => setLoader(false));
    }

    if (updateModal && !exercise) {
      setIsEdit(false);
    }
  }, [exercise, updateModal]);

  const handleChangeMetric = (c, value) => {
    setErrorsMetric('');
    setMetric(value);
  };

  const handleChangeExercise = (e, value) => {
    setExercise(value);
  };

  const handleChangeText = (event) => {
    setErrorsNote('')
    setText(event.target.value);
  };

  const validate = () => {
    let tempError = {}

    if (date && (
      (parseInt(moment(date, 'MM/DD/YYYY').format('YYYY')) < 1970) ||
      (moment(date, 'MM/DD/YYYY') === 'Invalid date') ||
      date.indexOf(' ') !== -1
    )) {
      setIsSubmitting(false)
      tempError = { ...tempError, note_date: 'The minimum value of the year is 1970' }
    } else {
      setErrors({})
      setIsSubmitting(false)
    }
    setErrors(tempError)
    return JSON.stringify(tempError) === '{}'
  };

  const handleSubmit = () => {
    setLoader(true)
    setIsSubmitting(true)
    if (validate()) {
      noteId || isEdit
        ? updateNote(id, {
          id: notes[0]?.id || noteId,
          note_date: moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          metric_id: metric && metric.title !== 'General' ? metric.metric_id : null,
          note_text: text,
          exercise_id: exercise ? exercise.id : null,
          is_private: is_private === 'Private to only Users' ? 1 : 0,
        })
          .then(res => {
            setMetric({ title: 'General', metric: "General" });
            setLoader(false)
            clearCheck()
            setIsSubmitting(false)
            setUpdateModal(false)
            fetchData()
            dispatch(AlertState.actions.alert(
              { text: 'Note updated successfully', variant: true }));

          })
          .catch(({ response, errors }) => {
            if (response.status === 422) {
              let er = response?.data?.errors?.metric_id;
              let erNote = response?.data?.errors?.note_text
              if (erNote) setErrorsNote(erNote[0])
              if (er) setErrorsMetric(er[0]);
            }
            if (response.status === 404) {
              setUpdateModal(false)
              setOpenInfoModal(true)
              fetchData();
              clearCheck();
            }
            setLoader(false)
            setIsSubmitting(false)
            //parseError(response)
          })

        : createNote(id, {
          note_date: moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD'),
          metric_id: metric && metric.title !== 'General' ? metric.metric_id : null,
          note_text: text,
          exercise_id: exercise ? exercise.id : null,
          is_private: is_private === 'Private to only Users' ? 1 : 0,
        })
          .then(res => {
            setMetric({ title: 'General', metric: "General" });
            setLoader(false)
            setIsSubmitting(false)
            setUpdateModal(false)
            fetchData()
            dispatch(AlertState.actions.alert(
              { text: 'Note created successfully', variant: true }));
          })
          .catch(err => {
            const { response } = err
            if (response?.status === 422) {
              let er = response?.data?.errors?.metric_id;
              let erNote = response?.data?.errors?.note_text
              if (erNote) setErrorsNote(erNote[0])
              if (er) setErrorsMetric(er[0]);
            }
            dispatch(AlertState.actions.alert({
              text: response?.data?.errors?.note_text || `Server error ${response?.status}`,
              variant: false,
            }))
            setLoader(false)
            setIsSubmitting(false)
            clearCheck()
          })
    }
  }

  useEffect(() => {
    setDate(moment().format('MM/DD/YYYY'))
    setText('')
    setErrors({})
    setLoader(true)
    getProfileMetric(id)
      .then(res => {
        setErrorsMetric("")
        setErrorsNote('')
        setLoader(false)
        let arr = res.data.data
          .map(elem => ({ ...elem, title: elem.metric }))
          .filter(elem => elem.is_show === 1)
        setMetrics(arr);
        noteId && getNote(id, noteId)
          .then(res2 => {
            setDate(moment(res2.data.data.note_date, 'YYYY-MM-DD').format('MM/DD/YYYY'))
            setText(res2.data.data.note_text)
            res2.data.data.is_private === 1
              ? set_is_private('Private to only Users')
              : set_is_private('Shared to Users and Profiles')
          })
      })
      .catch(err => {
        setLoader(false)
      })
    // eslint-disable-next-line
  }, [updateModal]);

  const handlePrivate = (value) => {
    set_is_private(value)
  };

  const useStyles = makeStyles((theme) => ({
    root: {},
    inputRoot: {
      paddingTop: '1px!important',
      paddingBottom: '1px!important',
      paddingLeft: '0px!important',
      '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        paddingLeft: 0
      },
    },
  }));

  const classes = useStyles();

  return (
    <div>
      <Button variant="primary" className="btn-blue" onClick={() => { setUpdateModal(true); clearCheck() }}>Create Note</Button>
      <Modal
        show={updateModal}
        onHide={e => {
          setCurrentItem({});
          setUpdateModal(false);
          setMetric({ title: 'General', metric: "General" });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{noteId || isEdit ? 'Update' : 'Create'} Note</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <DatePicker
            future={true}
            past={false}
            label="Note Date"
            value={date}
            format={'MM/DD/YYYY'}
            onChange={(value) => {
              setDate(value, 0)
              validate()
            }}
            error={!!errors.note_date}
            helperText={errors.note_date ? errors.note_date : ''}
          />

          <Autocomplete
            style={{ margin: '20px 0' }}
            classes={classes}
            value={metric}
            options={metrics?.length ? [{ title: 'General', metric: 'General' }, ...metrics] : []}
            getOptionLabel={option => option.title}
            onChange={handleChangeMetric}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  label={'Metric'}
                  variant="outlined"
                  error={!!errorsMetric}
                  helperText={errorsMetric ? errorsMetric : ''}
                />
              )
            }}
          />

          <Autocomplete
            options={exercisesList}
            classes={classes}
            style={{ margin: '20px 0' }}
            value={exercise}
            getOptionLabel={option => option.title}
            onChange={handleChangeExercise}
            inputValue={exerciseInputValue}
            onInputChange={(event, newInputValue) => setExerciseInputValue(newInputValue)}
            renderInput={params => {
              return (
                <TextField
                  {...params}
                  label={'Exercise'}
                  variant="outlined"
                />
              )
            }}
          />

          <TextField
            label="Note Text"
            multiline
            rows={4}
            variant="outlined"
            style={{ width: '100%' }}
            value={text}
            onChange={handleChangeText}
            error={!!errorsNote}
            helperText={errorsNote ? errorsNote : ''}
            inputProps={{
              maxLength: 1000,
            }}
          />

          {role !== 'client' && (
            <div style={{ marginTop: 20 }}>
              <CustomRadio
                data={['Shared to Users and Profiles', 'Private to only Users']}
                value={is_private}
                onChange={handlePrivate}
                style={{ fontSize: 13 }}
              />
            </div>
          )}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={e => {
            setCurrentItem({});
            setUpdateModal(false);
            setMetric({ title: 'General', metric: "General" });
          }}>
            Close
          </Button>
          <Button className="btn-blue" disabled={isSubmitting} onClick={e => {
            setErrorsNote('');
            setErrorsMetric("");
            handleSubmit();
          }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
};
