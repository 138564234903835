import React from "react";
import styled from "styled-components";
import { CustomRadio } from "../../../components/CustomRadio/CustomRadio";
import { TextField } from "@material-ui/core";
import { SignatureImageArea } from "../../../components/SignatureModal";
import { SignatureArea } from "../../../components/SignatureModal";

const PARRow = ({
  item,
  change,
  data,
  index,
  handleSetSignature,
  onDeleteSignature,
  signature,
  profileName,
}) => {
  const handleChange = (elem) => {
    if (elem === "Yes") {
      change(item.id, 1);
    } else {
      change(item.id, 0);
    }
  };

  const handleTextChange = (e) => {
    change(item.id, e.target.value);
  };

  const current = () => {
    return data.find((elem) => elem.parq_id === item.id) || "";
  };

  return (
    <StyledPARRow className="parrow">
      <div className="parq-text">{`${index + 1}. ${item.text}`}</div>

      {item.type === "textarea" && (
        <div className="textarea">
          <TextField
            style={{ width: "100%" }}
            variant="outlined"
            type="text"
            multiline={true}
            rows={3}
            onChange={handleTextChange}
            value={current.value}
          />
        </div>
      )}

      {item.type === "radio" && (
        <CustomRadio
          value={current.value === 1 ? "Yes" : current.value === 0 ? "No" : ""}
          data={["Yes", "No"]}
          onChange={handleChange}
        />
      )}
      {item.type === "signature" &&
        (signature?.path ? (
          <SignatureImageArea
            onDelete={onDeleteSignature}
            path={signature?.path}
          />
        ) : (
          <SignatureArea text="E-Signature" onClick={handleSetSignature} />
        ))}
    </StyledPARRow>
  );
};

const StyledPARRow = styled.div`
  @media (max-width: 420px) {
    width: 330px;
    padding: 0 20px 0 20px;
  }

  .parq-text {
    max-width: 1110px;
    width: 80%;
    justify-content: flex-start;
    font-weight: 600;
    margin: 10px 0 10px 0;
    font-size: 14px;

    @media (max-width: 420px) {
      width: 100%;
    }
  }

  .textarea {
    width: 80%;

    @media (max-width: 420px) {
      width: 100%;
    }
  }
`;

export default PARRow;
