import React from "react";
import { SignatureAreaWrapper } from "../SignatureModal.styles";

export const SignatureArea = ({ text, onClick }) => {
  return (
    <SignatureAreaWrapper onClick={onClick}>
      <p>{text}</p>
    </SignatureAreaWrapper>
  );
};
