import React, { useState, useRef, useCallback } from "react";

import { Modal, Tabs, Tab, Button } from "react-bootstrap";

import { useDispatch, useSelector } from "react-redux";

import { uploadImg } from "../../crud/media.crud";

import * as AlertState from "../../store/ducks/auth.duck";
import Loader from "../Loader";
import { generateImageFromText, dataURLToBlob } from "./helpers";
import { TYPE, TYPE_TITLE } from "./constants";
import { Wrapper } from "./SignatureModal.styles";
import { SignatureType } from "./components/SignatureType";
import { SignatureDraw } from "./components/SignatureDraw";
import { SignatureUpload } from "./components/SignatureUpload";

export const SignatureModal = ({
  open,
  handleClose,
  handleSave,
  profileName,
}) => {
  const dispatch = useDispatch();
  // const authUser = useSelector((state) => state.auth.user);
  const currentProfile = useSelector((state) => state.appData.profile);
  const padRef = useRef(null);
  const [canvas, setCanvas] = useState(undefined);
  const [canvasVisibility, setCanvasVisibility] = useState(false);
  const [key, setKey] = useState(TYPE.TYPE);

  const [type, setType] = useState(
    profileName
      ? profileName
      : `${currentProfile?.first_name || ""} ${currentProfile?.last_name || ""}`
  );
  const [selectedType, setSelectedType] = useState(1);
  const [isPhotoLoading, setIsPhotoLoading] = useState(false);
  const [photo, setPhoto] = useState(null);

  const handleChangeType = (e) => {
    const { value } = e.target;
    setType(value);
  };
  const handleSelectType = (type) => {
    setSelectedType(type);
  };

  const clearSignatureCanvas = useCallback(() => {
    padRef.current.clear();
    setCanvas(undefined);
    setCanvasVisibility(false);
  }, []);

  const handleGetCanvas = useCallback(() => {
    const canvas = padRef?.current?.toDataURL();

    setCanvas(canvas);
    setCanvasVisibility(true);
  }, []);

  const handleUploadCanvas = async () => {
    const canvas = padRef?.current?.toDataURL();

    const blob = await (await fetch(canvas)).blob();

    const formData = new FormData();
    formData.append("file", blob, "signature.png");

    try {
      const res = await uploadImg(formData);

      setPhoto(res.data.data);

      return res.data.data;
    } catch (error) {
      console.error("Ошибка сети:", error);
    }
  };

  const onSave = async () => {
    setIsPhotoLoading(true);

    try {
      if (+key === TYPE.DRAW) {
        const img = await handleUploadCanvas();
        handleSave(img);
      }

      if (+key === TYPE.UPLOAD) {
        handleSave(photo);
      }

      if (+key === TYPE.TYPE) {
        const fontFamilies = [
          "Great Vibes",
          "Courgette",
          "Sacramento",
          "Cookie",
        ];
        const fontFamily = fontFamilies[selectedType - 1];

        const dataURL = generateImageFromText(type, fontFamily);
        const blob = dataURLToBlob(dataURL);

        const formData = new FormData();
        formData.append("file", blob, "signature.png");

        const res = await uploadImg(formData);

        handleSave(res.data.data);
      }
    } catch (error) {
      dispatch(
        AlertState.actions.alert({
          text: "Error",
          variant: false,
        })
      );
    } finally {
      setIsPhotoLoading(false);
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <Loader visible={isPhotoLoading} />
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="title">{`${TYPE_TITLE[key]} Your Signature`}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Tabs
            defaultActiveKey="profile"
            id="justify-tab-example"
            className="mb-3"
            justify
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey={TYPE.TYPE} title="Type">
              <SignatureType
                handleChangeType={handleChangeType}
                type={type}
                handleSelectType={handleSelectType}
                selectedType={selectedType}
              />
            </Tab>
            <Tab eventKey={TYPE.DRAW} title="Draw">
              <SignatureDraw ref={padRef} photo={photo} />
            </Tab>
            <Tab eventKey={TYPE.UPLOAD} title="Upload">
              <SignatureUpload
                setIsPhotoLoading={setIsPhotoLoading}
                setPhoto={setPhoto}
                photo={photo}
              />
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          {+key === TYPE.DRAW && (
            <Button
              className="btn btn-light"
              variant="secondary"
              onClick={clearSignatureCanvas}
            >
              Clear
            </Button>
          )}

          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={isPhotoLoading}
            className="btn-blue"
            variant="primary"
            onClick={onSave}
          >
            Use Signature
          </Button>
        </Modal.Footer>
      </Wrapper>
    </Modal>
  );
};
