import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

export default ({ value, setValue }) => {
    const textarea = useRef()

    useEffect(() => {
        textarea.current.value = value || '';
    }, [value])

    const changeTextarea = event => {
        if (textarea && textarea.current && textarea.current.value) {
            let charCode = (event.which) ? event.which : event.keyCode;
            if (!(charCode >= 65 && charCode <= 90) && !(charCode >= 97 && charCode <= 122) && (charCode != 32 && charCode != 8) && !(charCode >= 48 && charCode <= 57) && !(charCode == 95) && !(charCode == 9)) {
                return false;
            } else {
                setTimeout(() => {
                    if (charCode === 8) setValue(textarea.current.value)
                    else setValue(textarea.current.value)
                    return true;
                })
            }
        };
    }

    return <NotepadStyled>
        <textarea
            className="notepad-textarea"
            ref={textarea}
            defaultValue=""
            onKeyDown={changeTextarea}
            maxLength="1000"
        />
    </NotepadStyled>
}

const NotepadStyled = styled.div`
    width: 100%;
    height: 100%;
    
    .notepad {

        &-textarea {
            width: 100%;
            height: 100%;
            min-height: 380px;
            outline: none;
            resize: none;
            padding: 30px;
            margin-top: 20px;
            font-size: 16px;
            border: 2px solid #595d6e;
            border-radius: 3px;
        }
    }
`