import { useEffect, useState } from "react";
import moment from "moment";

import {
  getOpportunitiesList,
  getOpportunitiesTotal,
} from "../../../../crud/opportunities.crud";
import useDebounce from "../../../../hooks/useDebounce";
import { sendDataToBackend } from "../helpers/transfromDateFilters";

const initPagination = {
  current_page: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
  from: 0,
  to: 10,
};

const tableDTO = (data) => {
  return data.map((item) => ({ values: item }));
};

export const useOpportuninties = ({
  initialSort = "",
  withTotals = true,
  profileId = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(initialSort);
  const [search, setSearch] = useState("");
  const [groups, setGroups] = useState([0]);
  const [staff, setStaff] = useState([0]);

  const [year, setYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(moment().format("MM"));
  const [pagination, setPagination] = useState(initPagination);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [totals, setTotals] = useState([]);

  const debouncedSearch = useDebounce(search);

  const date = sendDataToBackend(month, year);

  const refetchTotals = async () => {
    const params = {
      ...date,
      page,
      per_page: perPage,
      group: groups.filter(Boolean)?.length
        ? groups.filter(Boolean)
        : undefined,
      staff: staff.filter(Boolean)?.length ? staff.filter(Boolean) : undefined,
      search: debouncedSearch,
      sort_field: sort?.[0],
      sort_direction: sort?.[1],
      profile: profileId ? [profileId] : undefined,
    };
    const totals = await getOpportunitiesTotal(params);
    setTotals(totals.data.data);
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      try {
        const params = {
          ...date,
          page,
          per_page: perPage,
          group: groups.filter(Boolean),
          staff: staff.filter(Boolean),
          search: debouncedSearch,
          sort_field: sort?.[0],
          sort_direction: sort?.[1],
          profile: profileId ? [profileId] : undefined,
        };
        const res = await getOpportunitiesList(params);

        setData(tableDTO(res.data.data));

        setPagination(res.data.meta);

        if (withTotals) {
          const totals = await getOpportunitiesTotal(params);
          setTotals(totals.data.data);
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [groups, staff, debouncedSearch, year, month, page, perPage, sort]);

  return {
    loading,
    error,
    data,
    search,
    setSearch,
    setSort,
    sort,
    groups,
    setGroups,
    staff,
    setStaff,

    year,
    setYear,
    month,
    setMonth,
    pagination,
    setPagination,
    page,
    perPage,
    setPage,
    setPerPage,
    totals,
    date,
    setData,
    refetchTotals,
  };
};
