import React, { useEffect, useState } from 'react';
import ConfirmModal from '../../../components/ConfirmationModal';
import * as AlertState from '../../../../app/store/ducks/auth.duck';
import trash from '../../../../assets/icons/delete.svg';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { deleteMapping } from '../../../crud/crm.crud';
import { useSelector } from 'react-redux';
import { setCurrentMapping } from '../../../store/actions';

const defRow = [
    {
        label: 'TrainerMetrics Profiles',
        left: true,
        sort: false
    },
    {
        label: 'CRM Profiles',
        left: true,
        sort: false
    },
    {
        label: 'Last Sync',
        left: true,
        sort: false
    },
    {
        label: 'Remove',
        left: true,
        sort: false
    },
];

const TableRow = ({ item, handleModal, permission }) => {
    return (
        <tr>
            <td className="left-content cell-25">
                {item.internal?.text}
            </td>
            <td className="left-content cell-25">
                {item.external?.text}
            </td>
            <td className="left-content cell-25">
                {item.sync_at ? moment(item.sync_at).format('MM/DD/YYYY hh:mm:ss a') : '--'}
            </td>
            {permission &&
                <td className="left-content cell-25">
                    <img
                        src={trash}
                        alt="delete"
                        onClick={handleModal}
                        className="pointer"
                    />
                </td>
            }
        </tr>
    )
};

export const CRMTab = ({
    setLoader,
    mapping,
    setKey,
    loadMappings,
    accountId,
    crm,
}) => {
    const permission = useSelector(state => state.auth.user.permissions.find(elem => elem === 'update_profile_info_crm_mapping'));
    const id = useSelector(state => state.user_info?.mapping?.id);
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        loadMappings();
    }, []);

    const handleDelete = () => {
        setLoader(true);
        deleteMapping(accountId, crm.id, 'profiles', id)
            .then(() => {
                dispatch(AlertState.actions.alert({
                    text: 'Mapping was removed',
                    variant: true,
                }))
                dispatch(setCurrentMapping(null));
                setTimeout(() => {
                    setKey(0);
                }, 1000);
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error && typeof err.response?.data?.error === 'string') {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message && typeof err.response?.data?.error === 'string') {
                    errText = err.response.data.message;
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => setLoader(false))
    };

    return (
        <>
            <ConfirmModal
                modal={modal}
                setModal={setModal}
                handleConfirm={handleDelete}
                title="Are you sure you want to remove this mapping?"
                submitText="Remove"
            />

            <div className="settingtable nowrap">
                <table>
                    <thead>
                        <tr>
                            {defRow.map((item, index) => {
                                if (item.label === 'Remove' && !permission) {
                                    return null
                                } else {
                                    return (
                                        <td
                                            key={index}
                                            className={`${item.left ? 'left-content' : ''}`}
                                            style={{ cursor: 'default' }}
                                        >
                                            {item.label}
                                        </td>
                                    )
                                }
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <TableRow
                            item={mapping}
                            handleModal={() => setModal(true)}
                            permission={permission}
                        />
                    </tbody>
                </table>

                {!mapping &&
                    <div
                        style={{
                            margin: '20px auto',
                            textAlign: 'center'
                        }}
                    >
                        No data available in table
                    </div>
                }
            </div>
        </>
    )
};
