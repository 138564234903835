import React, { useRef, useEffect } from 'react';
import moment from 'moment';

export const Event = ({
  item,
  getHeight,
  getEventWidth,
  getMinWidth,
  getTop,
  getLeft,
  onClick,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (ref && item && item.scrollId) {
      ref.current.scrollIntoView();
      window.scrollBy(0, -70);
    }
  }, [item, ref]);

  return (
    <div
      className="event"
      key={item.id}
      ref={ref}
      style={{
        height: getHeight(item),
        width: getEventWidth(item),
        top: getTop(item),
        left: getLeft(item),
        minWidth: getMinWidth ? getMinWidth(item) : '100px',
      }}
      onClick={() => onClick(item)}
    >
      <div className="event__title">{item.title}</div>
      <div className="event__time">
        {moment(item.start_at).format('hh:mm a')}
      </div>
      <div className="event__time">{moment(item.end_at).format('hh:mm a')}</div>
    </div>
  );
};
