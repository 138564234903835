import React, { useState } from "react";
import { Select, MenuItem } from "@material-ui/core";

import { statuses, statusesNum } from "../../constants/constants";
import styled from "styled-components";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const getStyles = (status) => {
  return {
    fontWeight: "400",
    width: "70px",
    color: statusesNum[status].color,
    backgroundColor: statusesNum[status].bg,
  };
};

export const OpportunitiesStatus = ({ onSave, value }) => {
  const [status, setStatus] = useState(value);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setStatus(value);
    onSave(value);
  };
  return (
    <Wrapper>
      <Select
        labelId="demo-multiple-chip-label"
        id="demo-multiple-chip"
        value={status}
        onChange={handleChange}
        displayEmpty
        renderValue={(selected) => (
          <StatusBoxWrapper>
            <StatusBox style={getStyles(selected)}>
              <p>{statusesNum[selected].name || "--"}</p>
            </StatusBox>
          </StatusBoxWrapper>
        )}
        MenuProps={MenuProps}
      >
        {statuses.map((status) => (
          <MenuItem key={status.value} value={status.value}>
            {status.name}
          </MenuItem>
        ))}
      </Select>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  .MuiInputBase-root {
    border: none;
  }
`;

const StatusBoxWrapper = styled.div``;
const StatusBox = styled.div`
  height: 20px;
  padding: 2px 12px 2px 12px;
  gap: 10px;
  border-radius: 28px;
  opacity: 0px;
  & p {
    margin: 0;
    text-align: center;
  }
`;
