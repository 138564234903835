import {
  Portlet,
  PortletBody,
} from "../../../../../../partials/content/Portlet";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as AlertState from "../../../../../../store/ducks/auth.duck";

import Loader from "../../../../../../components/Loader";
import styled from "styled-components";
import { Pagination } from "react-bootstrap";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  OutlinedInput,
  Typography,
} from "@material-ui/core";

import TableList from "../../../../../../components/TableList";

import { useSessions } from "../../../hooks/useSessions";

import { PencilEditIcon } from "../../../../../../../assets";

import {
  EditFieldModal,
  FIELDS_TYPE,
} from "../../../modals/EditFieldModal/EditFieldModal";
import { SESSIONS_TABLE_TYPE } from "../../../constants/constants";
import { updateSessionsField } from "../../../../../../crud/session.crud";
import { renderTextWithTooltip } from "../../../helpers/renderTextWithTooltip";
import { updateSessionsNote } from "../../../../../../crud/session.crud";
import { NOTES_TABLE_TYPE } from "../../../constants/constants";
import { renderNumericValue } from "../../../helpers/renderNumericValue";

export const SessionsTab = (props) => {
  document.title = "TrainerMetrics - Sessions";

  const initSetting = {
    withTotals: false,
    profileId: props?.profileId,
    tableStatus: SESSIONS_TABLE_TYPE.ALL,
  };

  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);

  const dispatch = useDispatch();

  const {
    loading,
    error,
    data,
    search,
    setSearch,
    sort,
    setSort,
    year,
    setYear,
    month,
    setMonth,
    pagination,
    perPage,
    setPage,
    setPerPage,
    date,
    setData,
    refetchTotals,
  } = useSessions(initSetting);

  const [clickedRow, setClikedRow] = useState(null);
  const [openModal, setOpenModal] = useState(null);
  const [fetching, setFetching] = useState(false);

  const handleClickOnRow = (value, name, record, title, type) => {
    setClikedRow({ value, name, record, title, type });
    setOpenModal(true);
  };

  const handleClose = () => {
    setClikedRow(null);
    setOpenModal(false);
  };

  const handleUpdateField = async (value) => {
    const profileId = clickedRow.record?.profile?.id;
    const staffId = clickedRow.record?.staff?.id;

    try {
      setFetching(true);
      if (clickedRow.name === "session_notes") {
        const req = {
          ...date,
          text: value,
          user_id: staffId,
          type: NOTES_TABLE_TYPE.SESSION,
        };
        await updateSessionsNote(profileId, req);
      } else {
        const req = {
          ...date,
          value,
          user_id: staffId,
        };
        await updateSessionsField(profileId, req);
      }

      const updateData = data.map((item) =>
        item.values.profile.id === profileId &&
        (!staffId || item.values.staff.id === staffId)
          ? { values: { ...item.values, [clickedRow.name]: value } }
          : item
      );

      setData(updateData);
      refetchTotals();
      handleClose();
      dispatch(
        AlertState.actions.alert({
          text: "Session info is updated",
          variant: true,
        })
      );
    } catch (error) {
      console.dir(error);
      dispatch(
        AlertState.actions.alert({
          text: error?.message || "Something went wrong!",
          variant: false,
        })
      );
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    if (error) {
      dispatch(
        AlertState.actions.alert({
          text: error?.response?.data?.message || "Something went wrong!",
          variant: false,
        })
      );
    }
  }, [error]);

  const header = [
    {
      name: "staff",
      title: "Staff",

      render: (value) => renderTextWithTooltip(value || "--"),
      // render: (staff, name, record) => {
      //   return staff ? (
      //     <NameWrapper>
      //       <Avatar className="profile-photo">{`${staff?.first_name[0]}${staff?.last_name[0]}`}</Avatar>
      //       <p>
      //         {staff?.first_name} {staff?.last_name}
      //       </p>
      //     </NameWrapper>
      //   ) : (
      //     "--"
      //   );
      // },
    },

    {
      name: "sessions_goal",
      title: "Sessions Goal",
      render: (value, name, record) => (
        <EditableCol>
          <span>{value || "--"}</span>

          <span
            className="pencil-icon"
            onClick={() =>
              handleClickOnRow(
                value,
                name,
                record,
                "Sessions Goal",
                FIELDS_TYPE.INPUT
              )
            }
          >
            <PencilEditIcon />
          </span>
        </EditableCol>
      ),
    },
    {
      name: "sessions_estimated",
      title: "Sessions Estimated",
      render: renderNumericValue,
    },

    {
      name: "sessions_completed",
      title: "Sessions Completed",
      render: renderNumericValue,
    },
    {
      name: "sessions_remaining",
      title: "Sessions Remaining",
      render: renderNumericValue,
    },
    {
      name: "session_notes",
      title: "Sessions Notes",
      render: (value, name, record) => (
        <EditableCol>
          {renderTextWithTooltip(value || "--")}
          <span
            className="pencil-icon"
            onClick={() =>
              handleClickOnRow(
                value,
                name,
                record,
                "Sessions Notes",
                FIELDS_TYPE.AREA
              )
            }
          >
            <PencilEditIcon />
          </span>
        </EditableCol>
      ),
    },
  ];

  return (
    <StyledGroupLists className="nowrap">
      <Portlet>
        <PortletBody>
          <FiltersBlock>
            <div className="btn-toolbar__group"></div>

            <div
              className={`search-wrap`}
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <div className="btn-toolbar__group">
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel htmlFor="month">Month</InputLabel>
                  <Select
                    value={month}
                    onChange={(e) => setMonth(e.target.value)}
                    input={
                      <OutlinedInput labelWidth={42} name="month" id="month" />
                    }
                  >
                    <MenuItem value={"01"}>January</MenuItem>
                    <MenuItem value={"02"}>February</MenuItem>
                    <MenuItem value={"03"}>March</MenuItem>
                    <MenuItem value={"04"}>April</MenuItem>
                    <MenuItem value={"05"}>May</MenuItem>
                    <MenuItem value={"06"}>June</MenuItem>
                    <MenuItem value={"07"}>July</MenuItem>
                    <MenuItem value={"08"}>August</MenuItem>
                    <MenuItem value={"09"}>September</MenuItem>
                    <MenuItem value={"10"}>October</MenuItem>
                    <MenuItem value={"11"}>November</MenuItem>
                    <MenuItem value={"12"}>December</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="btn-toolbar__group">
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <InputLabel htmlFor="year">Year</InputLabel>
                  <Select
                    value={year}
                    onChange={(e) => setYear(e.target.value)}
                    input={
                      <OutlinedInput labelWidth={30} name="year" id="year" />
                    }
                  >
                    {[...new Array(6)].map((item, index) => (
                      <MenuItem value={index + 2020} key={index}>
                        {2020 + index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <TextField
                key={"search"}
                className="formControl"
                label="Search"
                margin="normal"
                variant="outlined"
                value={search}
                style={{ marginLeft: 0 }}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setSearch(e.target.value);
                  }
                }}
              />
            </div>
          </FiltersBlock>
          <Loader visible={loading} />
          <TableList
            header={header}
            data={data}
            check={[]}
            sort={sort}
            setSort={(newSort) => {
              if (!sort) {
                return setSort(newSort);
              }
              if (sort[0] !== newSort[0]) {
                return setSort([newSort[0], "asc"]);
              }

              if (sort[1] === "desc") {
                return setSort([undefined, undefined]);
              }
              setSort(newSort);
            }}
            plan={plan}
          />
          {!!pagination?.total && (
            <div className="pagination-wrap">
              <Pagination>
                <Pagination.First
                  onClick={() => setPage(1)}
                  disabled={pagination.current_page === 1}
                />
                <Pagination.Prev
                  onClick={() => setPage((prev) => prev - 1)}
                  disabled={pagination.current_page === 1}
                />

                <Pagination.Item>{pagination.current_page}</Pagination.Item>

                <Pagination.Next
                  onClick={() => setPage((prev) => prev + 1)}
                  disabled={pagination.last_page === pagination.current_page}
                />
                <Pagination.Last
                  onClick={() => setPage(pagination.last_page)}
                  disabled={pagination.last_page === pagination.current_page}
                />
              </Pagination>

              <div className="pagination-show">
                <FormControl variant="outlined" className="formControl">
                  <InputLabel>Show Entries</InputLabel>
                  <Select
                    value={perPage}
                    input={<OutlinedInput />}
                    onChange={(e) => {
                      setPerPage(e.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
                <Typography variant="body1" gutterBottom>
                  {`Showing ${pagination.from} - ${pagination.to} fo ${pagination.total}`}
                </Typography>
              </div>
            </div>
          )}
        </PortletBody>
      </Portlet>
      {openModal && (
        <EditFieldModal
          title={clickedRow?.title}
          value={clickedRow?.value}
          type={clickedRow?.type}
          open={openModal}
          onClose={handleClose}
          onSave={handleUpdateField}
          loading={fetching}
        />
      )}
    </StyledGroupLists>
  );
};

const StyledGroupLists = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .btn {
    margin-right: 15px;
  }

  .btn-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &__group {
      display: flex;
      align-items: center;

      .formControl {
        margin-left: 0;
      }
    }
  }

  .edit-all {
    height: 100%;
    border-radius: 4px;
    background-color: #eef1ff;
    padding: 0 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5d78ff;
    display: flex;
    align-items: center;
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
      min-width: 290px;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }
`;

const TabsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FiltersBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
`;

const EditableCol = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & .pencil-icon {
    cursor: pointer;
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  & p {
    margin-bottom: 0;
  }
`;
