import React, { useEffect, useState } from 'react';
import { Table } from './Table/Table';
import Pagination from '../../../../../components/Pagination';
import { Button } from 'react-bootstrap';
import { SpacerVertical } from '../../../../../components/Spacer';
import AddMappingModal from './AddMappingModal';
import { useDispatch, useSelector } from 'react-redux';
import * as AlertState from '../../../../../../app/store/ducks/auth.duck';
import { fetchMappings, postRefresh } from '../../../../../crud/crm.crud';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import question from '../../../../../../_metronic/_assets/media/svg-icon/question.svg';

const StyledToolTip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export const CRMMappings = ({ setLoader, crm, crmData, loadCRMSingle }) => {
  const [show, setShow] = useState('10');
  const [page, setPage] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(['internal_text', 'asc']);
  const [lastPage, setLastPage] = useState(1);
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.appData?.account?.id);

  const getMappings = () => {
    if (!accountId || !crm) return;
    setLoader(true);
    fetchMappings(accountId, crm.id, 'events', {
      page: pagination,
      limit: show,
      'sort[by]': sort[0],
      'sort[direction]': sort[1],
    })
      .then((res) => {
        setData(res.data.list);
        setLoader(false);
        setLastPage(res.data.pagination.last_page);
        setPage({
          ...res.data.pagination,
          last_page: res.data.pagination.last_page,
          per_page: res.data.pagination.limit,
          current_page: res.data.pagination.page,
        });
      })
      .catch((err) => {
        let errText = 'Not exist data mapped';
        if (err.response?.data?.error) {
          errText = err.response?.data?.error;
        } else if (err.response?.data?.message) {
          errText = err.response?.data?.message;
        }

        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
        setLoader(false);
      });
  };

  useEffect(() => {
    getMappings();
  }, [accountId, crm, show, pagination, sort]);

  const handleRefresh = () => {
    setLoader(true);
    postRefresh(accountId, crm.id, 'events')
      .then(() => {
        setTimeout(() => {
          getMappings();
        }, 10000);
        dispatch(
          AlertState.actions.alert({
            text: `Sync has started`,
            variant: true,
          })
        );
        loadCRMSingle();
      })
      .catch((err) => {
        let errText = 'request failed';
        if (err.response?.data?.error) {
          errText = err.response?.data?.error;
        } else if (err.response?.data?.message) {
          errText = err.response.data.message;
        }
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setLoader(false));
  };

  return (
    <>
      <div className="top-buttons">
        {crmData?.syncs?.events && (
          <div className="d-flex align-items-center">
            <div className="last-sync-block">
              {`Last Sync Started: ${moment(
                crmData?.syncs?.events?.sync_started_at
              ).format('MM/DD/YYYY hh:mm:ss a')}`}
            </div>
            {/* <StyledToolTip
              title={
                <>
                  Synchronization may take some time. <br />
                  To see if the synchronization is complete, please refresh the
                  page and observe the changes in this table.
                </>
              }
              placement="bottom"
            >
              <img
                src={question}
                style={{
                  cursor: 'pointer',
                  margin: '0 5px 0 10px',
                  height: '18px',
                }}
                alt="tooltip"
              />
            </StyledToolTip> */}
          </div>
        )}
        {/* <Button
                    variant="info"
                    className="button"
                    onClick={handleRefresh}
                >Refresh</Button> */}
        <SpacerVertical width="20px" />
        <Button
          variant="primary"
          className="button btn-blue desktop-vis"
          onClick={() => setModal(true)}
        >
          Add
        </Button>
      </div>

      <Table
        data={data}
        setLoader={setLoader}
        pagination={pagination}
        setPagination={setPagination}
        getMappings={getMappings}
        sort={sort}
        setSort={setSort}
        accountId={accountId}
        crm={crm}
      />

      <Pagination
        show={show}
        setShow={setShow}
        page={page}
        pagination={pagination}
        setPagination={setPagination}
        lastPage={lastPage}
        setPage={setPage}
        data={data}
      />

      {modal && (
        <AddMappingModal
          modal={modal}
          setModal={setModal}
          getMappings={getMappings}
          setLoader={setLoader}
        />
      )}
    </>
  );
};
