import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';

export const TableRow = ({ item, setModal, setItem }) => {
    return (
        <tr>
            <td>
                {item.id}
            </td>
            <td className="left-content">
                {moment(item.created_at).format('MM/DD/YYYY hh:mm:ss a') || '--'}
            </td>
            <td className="left-content">
                {item.type || '--'}
            </td>
            <td className="left-content">
                {item.sync_type ? `${item.action} ${item.sync_type}` : item.action}
            </td>
            <td className="left-content">
                {item.user || '--'}
            </td>
            <td className="left-content">
                <Button
                    variant="primary"
                    className="btn-blue"
                    onClick={() => {
                        setModal(true);
                        setItem(item.id);
                    }}
                >Show Data</Button>
            </td>
        </tr>
    )
};
