import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import React, { useState, useEffect } from "react";

import styled from "styled-components";

import { Tab, Tabs } from "@material-ui/core";

import DataOutput from "../../../../components/DataOutput";
import UserInfo from "../../../../components/UserInfo";

import "../../table.scss";
import "../../style.scss";
import { SessionsTab } from "./components/Sessions/SessionTab";
import { OpportunitiesTab } from "./components/Opportunities/OpportunitiesTab";
import { useSelector, useDispatch } from "react-redux";

import * as AlertState from "../../../../store/ducks/auth.duck";
import { Redirect } from "react-router-dom";

const TabsNum = {
  SALES: 0,
  OPPORTUNITIES: 1,
};

export const ProfileSales = (props) => {
  document.title = "TrainerMetrics - Sales";

  const id = props.match.params.id;
  const dispatch = useDispatch();

  const role = useSelector((state) => state.auth?.user?.roles);

  const [logout, setLogout] = useState(null);

  const [typeKey, setTypeKey] = useState(TabsNum.SALES);

  const renderContent = () => {
    if (TabsNum.SALES === typeKey) {
      return <SessionsTab profileId={id} />;
    }
    if (TabsNum.OPPORTUNITIES === typeKey) {
      return <OpportunitiesTab profileId={id} />;
    }
  };

  useEffect(() => {
    if (role === "staff" || role === "client") {
      dispatch(
        AlertState.actions.alert({
          text: "User does not have the right roles",
          variant: false,
        })
      );
      setLogout(<Redirect to="/profile-list" />);
    }
  }, [role]);

  return (
    <StyledGroupLists>
      {logout}

      <div className="row flex-md-row-reverse" style={{ marginBottom: "20px" }}>
        <DataOutput id={id} />
        <UserInfo id={id} />
      </div>

      <Portlet>
        <PortletBody>
          <TabsBlock className="users-list__header-list">
            <Tabs
              value={typeKey}
              onChange={(e, value) => setTypeKey(value)}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              <Tab label="Sessions"></Tab>
              <Tab label="Opportunities"></Tab>
            </Tabs>
          </TabsBlock>
          {renderContent()}
        </PortletBody>
      </Portlet>
    </StyledGroupLists>
  );
};

const StyledGroupLists = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .btn {
    margin-right: 15px;
  }

  .btn-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &__group {
      display: flex;
      align-items: center;

      .formControl {
        margin-left: 0;
      }
    }
  }

  .edit-all {
    height: 100%;
    border-radius: 4px;
    background-color: #eef1ff;
    padding: 0 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5d78ff;
    display: flex;
    align-items: center;
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
      min-width: 290px;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }
`;

const TabsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
