import React from 'react';
import styled from 'styled-components';
import {
    Modal,
    Button,
} from 'react-bootstrap';
import Loader from './Loader';

const ConfirmModal = ({
    modal,
    setModal,
    handleConfirm,
    title,
    submitText,
    isCancelButton = true,
    loader = false,
}) => {
    const handleClose = () => {
        setModal(false);
    };

    return (
        <Modal show={modal !== false} onHide={() => setModal(false)}>
            <Loader visible={loader} />

            <StyledAddConfirmModal>
                <div
                    className="close-button"
                    onClick={() => setModal(false)}
                >×</div>
                <Modal.Title>
                    <h5 className="title">{title}</h5>
                </Modal.Title>
                <Modal.Footer>
                    {isCancelButton && (
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button
                        variant="primary"
                        onClick={handleConfirm}
                        className="btn-blue"
                    >
                        {submitText ? submitText : 'Continue'}
                    </Button>
                </Modal.Footer>
            </StyledAddConfirmModal>
        </Modal >
    )
}

const StyledAddConfirmModal = styled.div`
    .close-button {
        position: absolute;
        top: 9px;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        margin: 20px 60px 30px 30px;
        font-size: 1.4rem;
    }
`;

export default ConfirmModal;