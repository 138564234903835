export const sortedUsersList = (users) => {
  const list = users?.map((elem) => ({
    ...elem,
    title: `${elem.first_name} ${elem.last_name}`,
  }));

  const sorted = list.sort((a, b) => {
    let first = a.title.toLowerCase();
    let second = b.title.toLowerCase();
    if (first < second) {
      return -1;
    }
    if (first > second) {
      return 1;
    }
    return 0;
  });

  return sorted;
};
