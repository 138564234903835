import React from "react";

export const PencilEditIcon = ({
  width = 16,
  height = 16,
  color = "#4D5E93",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16699 14.6667C2.16699 14.3905 2.39085 14.1667 2.66699 14.1667H13.3337C13.6098 14.1667 13.8337 14.3905 13.8337 14.6667C13.8337 14.9428 13.6098 15.1667 13.3337 15.1667H2.66699C2.39085 15.1667 2.16699 14.9428 2.16699 14.6667Z"
        fill={color}
      />
      <path
        d="M7.68037 9.95262L7.68038 9.95261L11.6248 6.00815C11.088 5.78471 10.4522 5.41769 9.85081 4.81634C9.24937 4.2149 8.88232 3.57896 8.6589 3.04206L4.71437 6.98659L4.71434 6.98662C4.40654 7.29442 4.25263 7.44833 4.12027 7.61802C3.96413 7.81821 3.83026 8.03481 3.72104 8.26399C3.62845 8.45828 3.55962 8.66477 3.42196 9.07775L2.69603 11.2555C2.62829 11.4588 2.68118 11.6828 2.83266 11.8343C2.98414 11.9858 3.20821 12.0387 3.41144 11.9709L5.58922 11.245C6.0022 11.1074 6.2087 11.0385 6.40298 10.9459C6.63216 10.8367 6.84876 10.7028 7.04895 10.5467C7.21864 10.4143 7.37257 10.2604 7.68037 9.95262Z"
        fill={color}
      />
      <path
        d="M12.7194 4.91361C13.5384 4.09457 13.5384 2.76664 12.7194 1.94759C11.9003 1.12855 10.5724 1.12855 9.75336 1.94759L9.28028 2.42068C9.28675 2.44024 9.29347 2.46008 9.30044 2.48017C9.47385 2.97998 9.80102 3.63518 10.4165 4.25066C11.032 4.86613 11.6872 5.19331 12.187 5.36671C12.207 5.37365 12.2267 5.38034 12.2462 5.38679L12.7194 4.91361Z"
        fill={color}
      />
    </svg>
  );
};

export const CalendarIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M4.16634 0.666687C4.16634 0.390545 3.94248 0.166687 3.66634 0.166687C3.3902 0.166687 3.16634 0.390545 3.16634 0.666687V1.71953C2.20679 1.79636 1.57686 1.98494 1.11406 2.44774C0.651257 2.91053 0.462683 3.54047 0.385846 4.50002H13.6135C13.5367 3.54047 13.3481 2.91053 12.8853 2.44774C12.4225 1.98494 11.7926 1.79636 10.833 1.71953V0.666687C10.833 0.390545 10.6091 0.166687 10.333 0.166687C10.0569 0.166687 9.83301 0.390545 9.83301 0.666687V1.67529C9.3895 1.66669 8.89236 1.66669 8.33301 1.66669H5.66634C5.10698 1.66669 4.60985 1.66669 4.16634 1.67529V0.666687Z"
        fill="#4D5E93"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.333008 7.00002C0.333008 6.44066 0.333008 5.94353 0.341609 5.50002H13.6577C13.6663 5.94353 13.6663 6.44066 13.6663 7.00002V8.33335C13.6663 10.8475 13.6663 12.1046 12.8853 12.8856C12.1042 13.6667 10.8472 13.6667 8.33301 13.6667H5.66634C3.15218 13.6667 1.89511 13.6667 1.11406 12.8856C0.333008 12.1046 0.333008 10.8475 0.333008 8.33335V7.00002ZM10.333 8.33335C10.7012 8.33335 10.9997 8.03488 10.9997 7.66669C10.9997 7.2985 10.7012 7.00002 10.333 7.00002C9.96482 7.00002 9.66634 7.2985 9.66634 7.66669C9.66634 8.03488 9.96482 8.33335 10.333 8.33335ZM10.333 11C10.7012 11 10.9997 10.7015 10.9997 10.3334C10.9997 9.96516 10.7012 9.66669 10.333 9.66669C9.96482 9.66669 9.66634 9.96516 9.66634 10.3334C9.66634 10.7015 9.96482 11 10.333 11ZM7.66634 7.66669C7.66634 8.03488 7.36786 8.33335 6.99967 8.33335C6.63148 8.33335 6.33301 8.03488 6.33301 7.66669C6.33301 7.2985 6.63148 7.00002 6.99967 7.00002C7.36786 7.00002 7.66634 7.2985 7.66634 7.66669ZM7.66634 10.3334C7.66634 10.7015 7.36786 11 6.99967 11C6.63148 11 6.33301 10.7015 6.33301 10.3334C6.33301 9.96516 6.63148 9.66669 6.99967 9.66669C7.36786 9.66669 7.66634 9.96516 7.66634 10.3334ZM3.66634 8.33335C4.03453 8.33335 4.33301 8.03488 4.33301 7.66669C4.33301 7.2985 4.03453 7.00002 3.66634 7.00002C3.29815 7.00002 2.99967 7.2985 2.99967 7.66669C2.99967 8.03488 3.29815 8.33335 3.66634 8.33335ZM3.66634 11C4.03453 11 4.33301 10.7015 4.33301 10.3334C4.33301 9.96516 4.03453 9.66669 3.66634 9.66669C3.29815 9.66669 2.99967 9.96516 2.99967 10.3334C2.99967 10.7015 3.29815 11 3.66634 11Z"
        fill="#4D5E93"
      />
    </svg>
  );
};

export const ArrowBottomIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.3085 1.09327C13.5331 1.35535 13.5028 1.74991 13.2407 1.97455L7.40738 6.97455C7.17333 7.17517 6.82795 7.17517 6.59389 6.97455L0.760561 1.97455C0.498482 1.74991 0.468131 1.35535 0.692769 1.09327C0.917408 0.831188 1.31197 0.800837 1.57405 1.02548L7.00064 5.67684L12.4272 1.02548C12.6893 0.800837 13.0839 0.831188 13.3085 1.09327Z"
        fill="#607CF7"
      />
    </svg>
  );
};
