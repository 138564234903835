import React, { useEffect, useState } from "react";
import Dropdown2 from "../../../components/DropdownComponent";
import { Tabs, Tab } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import Dropdown from "../../../components/DropdownComponent";
import { WeekCalendar } from "./CalendarComponent/WeekCalendar";
import { DayCalendar } from "./CalendarComponent/DayCalendar";
import { DayAgenda } from "./AgendaComponent/DayAgenda";
import { WeekAgenda } from "./AgendaComponent/WeekAgenda";
import { CreateModal } from "../Calendar/CreateModal/CreateModal";
import {
  fetchEventsList,
  fetchEventsListForProfile,
  fetchEventsListTiny,
} from "../../../crud/dashboard.crud";
import {
  getGroupsByAccountId,
  getUsersV3,
  getOwnGroups,
} from "../../../crud/info.crud";
import Pagination from "../../../components/Pagination";
import moment from "moment";
import { SubHeader } from "./SubHeader/SubHeader";
import { useIsMount } from "../../../hooks/useIsMount";
import "./calendar.scss";

export const Calendar = ({ profileId }) => {
  const [tab, setTab] = useState(0);
  const [createModal, setCreateModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isDay, setIsDay] = useState(false);
  const [eventsDay, setEventsDay] = useState([]);
  const [eventsWeek, setEventsWeek] = useState([]);
  const [selectedEventId, setSelectedEventId] = useState();
  const [dateFrom, setDateFrom] = useState(moment().format("MM/DD/YYYY"));
  const [dateTo, setDateTo] = useState(moment().format("MM/DD/YYYY"));
  const [groups, setGroups] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState();
  const accountId = useSelector((state) => state.appData.account.id);
  const role = useSelector((state) => state.auth.user.roles);
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const userId = useSelector((state) => state.auth?.user?.id);
  const isFirstRender = useIsMount();
  const [isGroupLoading, setIsGroupLoading] = useState(true);
  const [isUsersLoading, setIsUsersLoading] = useState(true);

  // states for day agenda table
  const [sort, setSort] = useState([null, null]);
  const [check, setCheck] = useState([]);
  const [show, setShow] = useState(10);
  const [page, setPage] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const status =
    tab === 0 && isDay
      ? "isDayCalendar"
      : tab === 0 && !isDay
      ? "isWeekCalendar"
      : tab === 1 && isDay
      ? "isDayAgenda"
      : tab === 1 && !isDay
      ? "isWeekAgenda"
      : "";

  const FetchUsers = (selectedGroup) => {
    if (role === "client" || role === "staff") setIsUsersLoading(false);
    getUsersV3({ group_id: selectedGroup?.id || null, limit: 500 })
      .then((res) => {
        const list = res.data?.list.map((elem) => ({
          ...elem,
          title: `${elem.first_name} ${elem.last_name}`,
        }));
        const sorted = list.sort((a, b) => {
          let first = a.title.toLowerCase();
          let second = b.title.toLowerCase();
          if (first < second) {
            return -1;
          }
          if (first > second) {
            return 1;
          }
          return 0;
        });
        setUsers(sorted);
      })
      .finally(() => setIsUsersLoading(false));
  };

  useEffect(() => {
    // Fetch groups
    if (role === "client") setIsGroupLoading(false);
    if (!accountId || !role) return;
    if (role !== "admin" && role !== "super-admin" && role !== "supervisor") {
      setIsGroupLoading(false);
      return;
    }

    if (role === "supervisor") {
      getOwnGroups(userId)
        .then((res) => {
          if (res.data.list.length > 0) {
            setGroups(
              res.data.list.map((elem) => {
                return {
                  ...elem,
                  title: `${elem.name}`,
                };
              })
            );
            setSelectedGroup({
              ...res.data.list[0],
              title: `${res.data.list[0].name}`,
            });
          }
          FetchUsers();
        })
        .finally(() => setIsGroupLoading(false));
      return;
    }

    getGroupsByAccountId(accountId)
      .then((res) => {
        setGroups(
          res.data.list.map((elem) => {
            return {
              ...elem,
              title: `${elem.name}`,
            };
          })
        );
        if (res.data.list.length > 0) {
          setSelectedGroup({
            ...res.data.list[0],
            title: `${res.data.list[0].name}`,
          });
        }
        FetchUsers({
          ...res.data.list[0],
          title: `${res.data.list[0]?.name}`,
        });
      })
      .finally(() => setIsGroupLoading(false));
  }, [role, accountId]);

  const setIdToUpperEvent = (list) => {
    if (!list.length) return [];

    let result = list;
    let elemIndex = 0;

    result.forEach((elem, index) => {
      let time1 = moment(elem.start_at).format("HH:mm");
      let time2 = moment(list[elemIndex].start_at).format("HH:mm");
      time1 = moment(time1, "HH:mm");
      time2 = moment(time2, "HH:mm");

      if (time1 !== time2 && time1.isBefore(moment(time2))) {
        elemIndex = index;
      }
    });

    result[elemIndex].scrollId = true;
    return result;
  };

  const loadEvents = () => {
    const isClient = profileId && !accountId && role === "client";

    if (dateFrom === "  /  /    " || dateTo === "  /  /    ") return;

    setLoader(true);

    if (selectedGroup && users.length < 1) {
      setEventsDay([]);
      setEventsWeek([]);
      setLoader(false);
    }

    let usersIds = [];

    if (selectedUsers) {
      usersIds = [selectedUsers.id];
    }

    if (selectedGroup && users.length > 0 && !selectedUsers) {
      usersIds = users.map((elem) => elem.id);
    }

    let params = {
      user_ids: usersIds,
    };

    if (sort[1]) {
      params.sort_field = sort[0].value;
      params.sort_direction = sort[1];
    }

    if (profileId) {
      params.profile_ids = [+profileId];
      params.user_ids = null;
    }

    if (status === "isWeekAgenda") {
      if (
        moment(dateFrom).format("YYYY-MM-DD") ===
        moment(dateTo).format("YYYY-MM-DD")
      ) {
        params.date_from = moment(dateFrom).format("YYYY-MM-DD") + " 00:00:00";
        params.date_from = moment(params.date_from)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
        params.date_to = moment(dateTo).format("YYYY-MM-DD") + " 23:59:59";
        params.date_to = moment(params.date_to)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
      } else {
        params.date_from = moment(dateFrom).format("YYYY-MM-DD") + " 00:00:00";
        params.date_from = moment(params.date_from)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
        params.date_to = moment(dateTo).format("YYYY-MM-DD") + " 23:59:59";
        params.date_to = moment(params.date_to)
          .utc()
          .format("YYYY-MM-DD HH:mm:ss");
      }
    }

    if (status === "isWeekCalendar") {
      const date1 = new Date(currentDate);
      const date2 = new Date(currentDate);
      params.date_from =
        moment(date1.setDate(date1.getDate() - date1.getDay())).format(
          "YYYY-MM-DD"
        ) + " 00:00:00";
      params.date_from = moment(params.date_from)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      params.date_to =
        moment(date2.setDate(date2.getDate() + (6 - date2.getDay()))).format(
          "YYYY-MM-DD"
        ) + " 23:59:59";
      params.date_to = moment(params.date_to)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }

    if (status === "isDayCalendar" || status === "isDayAgenda") {
      const dateNow = moment(currentDate).format("YYYY-MM-DD");
      params.date_from = moment(dateNow).format("YYYY-MM-DD") + " 00:00:00";
      params.date_from = moment(params.date_from)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      params.date_to = moment(dateNow).format("YYYY-MM-DD") + " 23:59:59";
      params.date_to = moment(params.date_to)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
    }

    if (status === "isDayAgenda") {
      params.page = pagination;
      params.limit = show;
    }

    if (isClient) {
      return fetchEventsListForProfile(profileId, params)
        .then((res) => {
          const eventsWithIds = setIdToUpperEvent(res.data.list);
          setEventsWeek(eventsWithIds);
          setEventsDay(eventsWithIds);
          setPage({
            ...res.data.pagination,
            per_page: res.data.pagination.limit,
          });
          setLastPage(res.data.pagination.last_page);
        })
        .finally(() => setLoader(false));
    } else {
      setLoader(true);
      if (
        status === "isDayAgenda" ||
        status === "isWeekAgenda" ||
        status === "isDayCalendar"
      ) {
        fetchEventsList(accountId, params)
          .then((res) => {
            const eventsWithIds = setIdToUpperEvent(res.data.list);
            setEventsDay(eventsWithIds);
            setEventsWeek(eventsWithIds);
            setPage({
              ...res.data.pagination,
              per_page: res.data.pagination.limit,
            });
            setLastPage(res.data.pagination.last_page);
          })
          .finally(() => setLoader(false));
      }
      if (status === "isWeekCalendar") {
        fetchEventsListTiny(accountId, params)
          .then((res) => {
            const eventsWithIds = setIdToUpperEvent(res.data.list);
            setEventsWeek(eventsWithIds);
            setPage({
              ...res.data.pagination,
              per_page: res.data.pagination.limit,
            });
            setLastPage(res.data.pagination.last_page);
          })
          .finally(() => setLoader(false));
      }
    }
  };

  useEffect(() => {
    // Fetch events
    if (!role) return;

    if (role === "staff" || role === "client") {
      if (isFirstRender) return;

      loadEvents();
    } else {
      if (isFirstRender || isUsersLoading || isGroupLoading) return;
      loadEvents();
    }
  }, [
    accountId,
    currentDate,
    isDay,
    dateFrom,
    dateTo,
    tab,
    selectedUsers,
    sort,
    show,
    pagination,
    role,
    isUsersLoading,
    isGroupLoading,
    users,
  ]);

  const handleSelectGroup = (val) => {
    setSelectedGroup(val);
    FetchUsers(val);
    setSelectedUsers((prev) => {
      return prev?.length === 0 ? prev : null;
    });
  };

  const handleSelectUsers = (val) => {
    setSelectedUsers(val);
  };

  return (
    <div className="calendar-page">
      <CreateModal
        modal={createModal}
        setModal={setCreateModal}
        selectedEventId={selectedEventId}
        setSelectedEventId={setSelectedEventId}
        events={isDay ? eventsDay : eventsWeek}
        loadEvents={loadEvents}
        profileId={profileId}
        currentDate={currentDate}
      />

      <div className="header">
        <Tabs
          value={tab}
          onChange={(e, value) => setTab(value)}
          indicatorColor="primary"
          textColor="primary"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label="CALENDAR" />
          <Tab label="AGENDA" />
        </Tabs>

        <div className="header__block">
          {!profileId &&
            (role === "admin" ||
              role === "super-admin" ||
              role == "supervisor") &&
            plan !== "Gym" && (
              <div className="calendar-page__sort-input">
                <Dropdown2
                  options={groups}
                  value={selectedGroup}
                  label="Groups"
                  setFormData={handleSelectGroup}
                  getOptionSelected={(option, elem) => option.id === elem.id}
                  width={"200px"}
                />
              </div>
            )}

          {!profileId && role !== "staff" && (
            <div className="header__dropdown">
              <Dropdown
                options={users}
                value={selectedUsers}
                label="All Users"
                setFormData={handleSelectUsers}
                width={"100%"}
                getOptionSelected={(option, elem) => option.id === elem.id}
              />
            </div>
          )}

          {role !== "client" && (
            <Button
              variant="primary"
              onClick={() => setCreateModal(true)}
              className="btn-blue create-event-btn"
              style={{ whiteSpace: "nowrap", height: 40 }}
            >
              Create Event
            </Button>
          )}
        </div>
      </div>

      <SubHeader
        status={status}
        isDay={isDay}
        setIsDay={setIsDay}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
      />

      <div className="body">
        {status === "isDayCalendar" && (
          <DayCalendar
            events={eventsDay}
            users={users}
            selectedUser={selectedUsers}
            setCreateModal={setCreateModal}
            setSelectedEventId={setSelectedEventId}
            selectedGroup={selectedGroup}
          />
        )}

        {status === "isWeekCalendar" && (
          <WeekCalendar
            currentDate={currentDate}
            events={eventsWeek}
            modal={createModal}
            setCreateModal={setCreateModal}
            setSelectedEventId={setSelectedEventId}
          />
        )}

        {status === "isDayAgenda" && (
          <>
            <DayAgenda
              events={eventsDay}
              sort={sort}
              setSort={setSort}
              check={check}
              setCheck={setCheck}
              loadEvents={loadEvents}
              setCreateModal={setCreateModal}
              setSelectedEventId={setSelectedEventId}
              setLoader={setLoader}
            />

            <Pagination
              show={show}
              setShow={setShow}
              page={page}
              setPage={setPage}
              pagination={pagination}
              setPagination={setPagination}
              lastPage={lastPage}
              data={isDay ? eventsDay : eventsWeek}
            />
          </>
        )}

        {status === "isWeekAgenda" && (
          <WeekAgenda
            events={eventsWeek}
            dateFrom={dateFrom}
            dateTo={dateTo}
            setLoader={setLoader}
            loadEvents={loadEvents}
            setSelectedEventId={setSelectedEventId}
            setCreateModal={setCreateModal}
          />
        )}
      </div>

      <Loader visible={loader} />
    </div>
  );
};
