import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL_V2;

export function getSessionsTotal(params) {
  return axios.get(`${BASE_URL}sales/sessions/total`, { params });
}

export const getForecastList = (params) =>
  axios.get(`${BASE_URL}sales/forecasts`, { params });

export const updateSessionsField = (profileId, params) =>
  axios.patch(`${BASE_URL}sales/sessions/profiles/${profileId}/goal`, params);

export const updateSessionsNote = (profileId, params) =>
  axios.patch(`${BASE_URL}sales/profiles/${profileId}/notes`, params);

export const updateForecastField = (profileId, params) => {
  axios.patch(`${BASE_URL}sales/users/${profileId}/forecast-goal`, params);
};
