export const SESSIONS_TABLE_TYPE = {
  ACTIVE: 2,
  ARCHIVED: 3,
  DELETED: 4,
  PRIMARY: 1,
  TEMPLATE: 5,
  ALL: 0,
};

export const NOTES_TABLE_TYPE = {
  SESSION: 1,
  OPPORTUNITIES: 2,
  LEADS: 3,
};

export const OPPORTUNITIES_STATUS = {
  ACTIVE: 1,
  PAUSE: 2,
  CANCEL: 3,
  NO: null,
};

export const CARD_STATUS = {
  ACTIVE: 1,
  DECLINED: 2,
};

export const CARD_STATUS_TITLE = {
  [CARD_STATUS.ACTIVE]: "Active",
  [CARD_STATUS.DECLINED]: "Declined",
};

export const CARD_STATUS_COLOR = {
  [CARD_STATUS.ACTIVE]: {
    color: "rgba(2, 122, 72, 1)",
    bg: "rgba(209, 250, 223, 1)",
  },
  [CARD_STATUS.DECLINED]: {
    color: "rgba(217, 45, 32, 1)",
    bg: "rgba(254, 228, 226, 1)",
  },
};

export const statuses = [
  { name: "Active", value: OPPORTUNITIES_STATUS.ACTIVE },
  { name: "Pause", value: OPPORTUNITIES_STATUS.PAUSE },
  { name: "Cancel", value: OPPORTUNITIES_STATUS.CANCEL },
  { name: "--", value: OPPORTUNITIES_STATUS.NO },
];
export const statusesNum = {
  [OPPORTUNITIES_STATUS.ACTIVE]: {
    name: "Active",
    value: OPPORTUNITIES_STATUS.ACTIVE,
    color: "rgba(2, 122, 72, 1)",
    bg: "rgba(209, 250, 223, 1)",
  },
  [OPPORTUNITIES_STATUS.PAUSE]: {
    name: "Pause",
    value: OPPORTUNITIES_STATUS.PAUSE,
    color: "white",
    bg: "rgba(96, 124, 247, 1)",
  },
  [OPPORTUNITIES_STATUS.CANCEL]: {
    name: "Cancel",
    value: OPPORTUNITIES_STATUS.CANCEL,
    color: "rgba(217, 45, 32, 1)",
    bg: "rgba(254, 228, 226, 1)",
  },
  [OPPORTUNITIES_STATUS.NO]: {
    name: "--",
    value: OPPORTUNITIES_STATUS.CANCEL,
    color: "rgba(77, 94, 147, 1)",
    bg: "",
  },
};

export const NOTES_TYPE = {
  SESSIONS: 1,
  OPPORTUNITY: 2,
  LEAD: 3,
};
