export const tableHead = [
    {
        label: 'Workout Name',
        value: 'title',
        left: true,
        sort: true,
    },
    {
        label: 'Program Name',
        value: 'program',
        left: true,
        sort: true,
    },
    {
        label: 'Created By',
        value: 'created-by',
        left: true,
        sort: true,
    },
    {
        label: 'Created Date',
        value: 'created-at',
        left: true,
        sort: true,
    },
    {
        label: 'Profiles Assigned',
        value: 'profiles-assigned',
        left: true,
        sort: true,
    },
];

