import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal
} from 'react-bootstrap';
import * as media from '../../../../crud/media.crud';
import { addToProgram } from '../../../../crud/library.crud';
import { Avatar } from '@material-ui/core';
import styled from 'styled-components';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import '../../style.scss';
import './add-to-program-modal.scss';
import { useDispatch } from 'react-redux';

const ImgComponent = ({ src, alt, className, style }) => {
    const [url, setUrl] = useState('');

    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src])
    return url && <img src={url} alt={alt} className={className} style={style} />
};

export const AddToProgramModal = ({
    addProgramModal,
    setAddProgramModal,
    selectedProgram,
    data,
    check,
}) => {
    const [profiles, setProfiles] = useState([]);
    const [step, setStep] = useState(1);
    const [addedCount, setAddedCount] = useState(0);
    const [errorsCount, setErrorsCount] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        if (data.length && check.length) {
            setProfiles(data
                .filter(elem => {
                    let res = false;
                    check.forEach(item => {
                        if (item === elem.profile_id) {
                            res = true;
                        };
                    })
                    return res;
                })
            );
        }
    }, [check, data]);

    const handleSubmit = () => {
        setStep(2);

        check.forEach((profileId) => {
            addToProgram(profileId, {
                is_unattach_enabled: false,
                programs: [{
                    id: selectedProgram.id,
                    workouts: selectedProgram.workouts.map(elem => ({ id: elem.id }))
                }]
            })
                .then(() => {
                    setAddedCount(prev => prev + 1);
                })
                .catch(() => {
                    setErrorsCount(prev => prev + 1);
                })
        });
    };

    const handleDone = () => {
        setAddProgramModal(false);
        if (errorsCount === 0) {
            dispatch(AlertState.actions.alert({
                text: 'Program has been added to Profiles',
                variant: true
            }))
        } else {
            dispatch(AlertState.actions.alert({
                text: 'Program has not been added to Profiles',
                variant: false
            }))
        }
    };

    return (
        <>
            <Modal
                show={addProgramModal}
                onHide={e => {
                    setAddProgramModal(false);
                }}
            >
                <StyledEmailFormModal>
                    <Modal.Header closeButton>
                        <Modal.Title>{step === 1 ? 'Add Program' : 'Adding Program'}</Modal.Title>
                    </Modal.Header>
                    {step === 1 && (
                        <>
                            <div className='email-form-title'>
                                {selectedProgram?.title}
                            </div>
                            <div className='modal-body'>
                                {profiles.map((elem, index) => {
                                    return (
                                        <div className='d-flex align-items-center' style={{ marginBottom: '15px' }} key={index}>
                                            <div className='photo-container'>
                                                {!!elem.pic
                                                    ? <ImgComponent src={elem.pic} alt="img" className="profile-photo" />
                                                    : <Avatar className="profile-photo">{elem.first_name[0]}{elem.last_name[0]}</Avatar>
                                                }
                                            </div>
                                            <div className='profile-text'>{`${elem.first_name} ${elem.last_name}`}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <div className='email-form-title'>
                                {`${addedCount + errorsCount} of ${check.length}`}
                            </div>

                            <div className="adding-to-program-progress">
                                <div
                                    className="adding-to-program-progress__value"
                                    style={{ width: `${100 / check.length * (addedCount + errorsCount)}%` }}
                                />
                            </div>

                            <div className='adding-to-program-count'>
                                <div className='adding-to-program-count__text'>Profiles Added</div>
                                <div className='adding-to-program-count__text'>{addedCount}</div>
                            </div>

                            <div className='adding-to-program-count'>
                                <div
                                    className={`adding-to-program-count__text ${errorsCount > 0 ? 'adding-to-program-count__err-text' : ''}`}
                                >Failed</div>
                                <div
                                    className={`adding-to-program-count__text ${errorsCount > 0 ? 'adding-to-program-count__err-text' : ''}`}
                                >{errorsCount}</div>
                            </div>
                        </>
                    )}

                    <Modal.Footer>
                        {step === 1 && (
                            <Button
                                variant="primary"
                                className="btn-blue"
                                onClick={handleSubmit}
                                disabled={!selectedProgram}
                            >Add Program</Button>
                        )}

                        {step === 2 && (
                            <Button
                                variant="primary"
                                className="btn-blue"
                                onClick={handleDone}
                                disabled={addedCount + errorsCount !== check.length}
                            >Done</Button>
                        )}
                    </Modal.Footer>
                </StyledEmailFormModal>
            </Modal>
        </>
    )
};

const StyledEmailFormModal = styled.div`
    .email-form-title {
        font-size: 18px;
        font-weight: 500;
        margin: 15px 0 20px 20px;
    }

    .modal-body {
        max-height: 300px;
        overflow-y: auto;
        padding-top: 0;
    }

    .photo-container {
        width: 45px;
        height: 45px;
        margin-right: 20px;
        background-color: #bdbdbd;
        border-radius: 50%;
    }

    .profile-photo {
        height: 45px;
        width: 45px;
        object-fit: cover;
        border-radius: 50%;
        font-size: 16px;
        text-transform: uppercase;
    }

    .profile-text {
        font-size: 16px;
    }
`;
