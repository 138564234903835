import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export const TableRow = ({ item, setData, setChanged }) => {
    const handleChange = name => {
        setChanged(true);
        setData(prev => {
            let arr = [...prev];
            let index = arr.findIndex(elem => elem.field === item.field);
            arr[index][name] = !prev[index][name];
            return arr;
        });
    };

    return (
        <tr>
            <td className="left-content cell-25">
                {item?.name || 'No data'}
            </td>
            <td className="left-content cell-25">
                {item?.crm_field || 'No data'}
            </td>
            <td className="left-content cell-25">
                <Checkbox
                    checked={item.is_updates_in_enabled}
                    onChange={() => handleChange('is_updates_in_enabled')}
                    defaultChecked
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />
            </td>
            <td className="left-content cell-25">
                <Checkbox
                    checked={item.is_updates_out_enabled}
                    onChange={() => handleChange('is_updates_out_enabled')}
                    defaultChecked
                    color="primary"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                    disabled
                />
            </td>
        </tr>
    )
};
