import React from 'react';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';

export const TableRow = ({
    item,
    check,
    setCheck,
}) => {
    const handleCheck = id => {
        const index = check.findIndex(elem => elem === id);
        if (index !== -1) {
            setCheck(prev => {
                let arr = [...prev];
                arr.splice(index, 1);
                return arr;
            })
        } else {
            setCheck(prev => {
                return [...prev, id];
            })
        }
    };

    return (
        <tr key={item.id}>
            <td>
                <Checkbox
                    color="primary"
                    onChange={() => handleCheck(item.id)}
                    checked={check.find(elem => elem === item.id) !== undefined}
                />
            </td>
            <td className='left-content'>
                {item.title}
            </td>
            <td className='left-content'>
                {moment(item.start_at).format('hh:mm a')}
            </td>
            <td className='left-content'>
                {moment(item.end_at).format('hh:mm a')}
            </td>
            <td className='left-content'>
                {item.profiles?.length
                    ? item.profiles.map(elem => `${elem.first_name} ${elem.last_name}`).join(', ')
                    : '-'
                }
            </td>
            <td className='left-content'>
                {item.users?.length
                    ? item.users.map(elem => `${elem.first_name} ${elem.last_name}`).join(', ')
                    : '-'}
            </td>
        </tr>
    )
};
