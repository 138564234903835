import React from 'react'
import styled from 'styled-components'
import { Portlet, PortletBody } from "../partials/content/Portlet";

const DataOutputElem = props => (
    <StyledDataOutput>
        <div className='dataoutput-data' style={{ color: props.color }}>
            {props.data}
        </div>
        <div className='datainput-title'>
            <div className='dataoutput-title__elem'>
                {props.title1}
            </div>
            <div className='dataoutput-title__elem'>
                {props.title2}
            </div>
        </div>
    </StyledDataOutput>
)

const DataOutput = ({ typeFilter, setTypeFilter, data, setKey }) => {
    return (
        <StyledDataOutputWrap className="row row-full-height">
            {data.map(item => {
                return (
                    <div className="col-3" key={item.title} style={{ display: 'flex' }}>
                        <Portlet
                            onClick={() => {
                                setKey(0);
                                setTypeFilter(item.filter !== typeFilter ? item.filter : '');
                            }}
                            className={`${item.filter === typeFilter ? 'active' : ''}`}
                        >
                            <PortletBody>
                                <DataOutputElem
                                    data={item.value}
                                    title1={item.measurement}
                                    title2={item.title}
                                    color={'#5d78ff'}
                                />
                            </PortletBody>
                        </Portlet>
                    </div>
                )
            })}
        </StyledDataOutputWrap>
    )
}

const StyledDataOutputWrap = styled.div`
    .active {
        box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.25);
        background-color: #f0f0f0;
    }

    .kt-portlet .kt-portlet__body {
        padding: 10px 5px;
    }
`;

const StyledDataOutput = styled.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .dataoutput {
        &-data {
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 25px;
        }

        &-title {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-left: 15px;

            &__elem {
                text-transform: uppercase;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #595d6e;
                text-align: center;
            }
        }
    }
`;

export default DataOutput;
