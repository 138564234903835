import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../store/ducks/auth.duck";
import styled from "styled-components";
import PARrow from "./PARRow";
import Logout from "../../../pages/auth/Logout";
import Loader from "../../../components/Loader";
import { getParQ, updateProfile } from "../../../crud/profile.crud";
import { SignatureModal } from "../../../components/SignatureModal";

const ProfileInfo = ({ parqTemp, id, setParqTemp, profile, setProfile }) => {
  const dispatch = useDispatch();
  const [parqData, setParqData] = useState([]);
  const [logout, setLogout] = useState(null);
  const [parq, setParq] = useState([]);
  const [modalSignature, setModalSignature] = useState(false);
  const [loaderParQ, setLoaderParQ] = useState(false);
  const [loaderUpdateParQ, setLoaderUpdateParQ] = useState(false);
  const [signature, setSignature] = useState(profile?.parq_signature);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    setParq(parqTemp);
  }, [parqTemp]);

  useEffect(() => {
    updateParq();
  }, []);

  const updateParq = () => {
    getParQ()
      .then((res) => {
        setLoaderParQ(false);
        setParqData(res.data.data);
        setTouched(false);
      })
      .catch(({ response }) => {
        setLoaderParQ(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  const update = (id, value) => {
    setTouched(true);
    let tempData = parq && [...parq].filter((item) => !!item);
    // eslint-disable-next-line
    let index = tempData.findIndex((item) => item.id == id);
    index >= 0
      ? (tempData[index] = {
          id: id,
          value: value,
        })
      : tempData.push({
          id: id,
          value: value,
        });

    setParq(tempData);
  };

  const submit = (e) => {
    e.preventDefault();
    setLoaderUpdateParQ(true);
    updateProfile(id, {
      parq_signature: signature,
      parq: parq.map((item) => {
        return {
          parq_id: item.id,
          value: item.value,
        };
      }),
    })
      .then(({ response }) => {
        setTouched(false);
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
        setLoaderUpdateParQ(false);
        setParqTemp(parq);
        dispatch(AlertState.actions.refreshStatistic());
        dispatch(
          AlertState.actions.alert({
            text: "PAR-Q is updated",
            variant: true,
          })
        );
      })
      .catch(({ response }) => {
        setLoaderUpdateParQ(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  const handleOpenSignatureModal = (index) => {
    setModalSignature(true);
  };
  const handleCloseSignatureModal = () => {
    setModalSignature(false);
  };

  const handleSaveSignature = (photo) => {
    setTouched(true);

    setSignature(photo);
    handleCloseSignatureModal();
    const index = parqData.findIndex((item) => item.type === "signature");
    const newData = [...parq];
    newData[index].value = 1;
    setParq(newData);
    setProfile((prev) => ({ ...prev, parq_signature: photo }));
  };

  const handleDeleteSignature = () => {
    setTouched(true);

    setSignature(null);
    const index = parqData.findIndex((item) => item.type === "signature");
    const newData = [...parq];
    newData[index].value = 0;
    setParq(newData);
    setProfile((prev) => ({ ...prev, parq_signature: null }));
  };

  return (
    <StyledPARQ className="par">
      <Loader visible={loaderParQ || loaderUpdateParQ} />
      {logout}

      <h2 style={{ fontSize: "17px", fontWeight: 800 }}>
        General Health Questions
      </h2>

      {parqData.map((item, index) => {
        return (
          <PARrow
            key={`par-${index}`}
            data={item}
            parq={parq}
            update={update}
            index={index}
            handleSetSignature={handleOpenSignatureModal}
            signature={signature}
            onDeleteSignature={handleDeleteSignature}
          />
        );
      })}
      {modalSignature && (
        <SignatureModal
          handleClose={handleCloseSignatureModal}
          handleSave={handleSaveSignature}
          open={modalSignature}
        />
      )}

      {touched && (
        <div className="kt-login__actions" style={{ marginTop: 20 }}>
          <button
            className="btn btn-blue btn-elevate kt-login__btn-primary"
            onClick={submit}
          >
            Save
          </button>
        </div>
      )}
    </StyledPARQ>
  );
};

export default ProfileInfo;

const StyledPARQ = styled.div`
  min-width: 600px;
`;
