import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Button } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import Logo from '../../../_metronic/_assets/media/logo.svg'
import {
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import './style.scss'
import Footer from '../../../_metronic/layout/footer/FooterAuth'

import BG from '../../../_metronic/_assets/media/grid.png'

function Login(props) {
  document.title = 'TrainerMetrics - Log In'
  const { intl } = props;
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const [remember, setRemember] = useState(true)
  const [error, setError] = useState({})
  const [initialValues, setInitialValues] = useState({
    email: "",
    password: ""
  })

  useEffect(() => {
    localStorage.clear();

    let email = document.cookie.split(';').find(item => item.split('=')[0] === 'email' || item.split('=')[0] === ' email')
    let password = document.cookie.split(';').find(item => item.split('=')[0] === 'password' || item.split('=')[0] === ' password')
    if (email && password) {
      setInitialValues({
        email: email.split('=')[1],
        password: password.split('=')[1]
      })
    }
  }, [])

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validate={values => {
          const errors = {};
          setStatus('')
          if (!values.email) {
            // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }

          if (!values.password) {
            errors.password = "The password field is required"
          }

          setError({ ...errors })
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          localStorage.clear();

          if (remember) {
            let date = new Date();
            date.setTime(date.getTime() + (2 * 24 * 60 * 60 * 1000))
            document.cookie = `email=${values.email}; expires=${date.toUTCString()}`
            document.cookie = `password=; expires=${date.toUTCString()}`
            // document.cookie = `password=${values.password}; expires=${date.toUTCString()}`


            // } else {
            //   var cookie_date = new Date ( );  // Текущая дата и время
            //   cookie_date.setTime ( cookie_date.getTime() - 1 );
            //   document.cookie = "email=; expires=" + cookie_date.toGMTString();
            //   document.cookie = "password=; expires=" + cookie_date.toGMTString();
          }
          enableLoading();
          window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LeJua4ZAAAAACQMDff1rwrfBCzLxe8fu7R7zUi6', { action: 'login' })
              .then(function (token) {
                login(values.email, values.password, token, 'login')
                  .then(res => {
                    disableLoading();
                    props.login(res.data.data.accessToken);
                  })
                  .catch(({ response }) => {
                    if(typeof response.data.error === 'string' && response.data.error === 'Recaptcha is not correct') {
                      setStatus('Please try again.');
                      window.location.reload();
                      return;
                    }

                    response.data.error && response.data.error && setError({
                      email: response.data.error.pic && response.data.error.email[0],
                      password: response.data.error.password && response.data.error.password[0],
                    })
                    disableLoading();
                    setSubmitting(false);
                    setStatus(response.data.message || (typeof response.data.error === 'string' && response.data.error));
                  });
              })
              .catch(() => {
                disableLoading();
                setSubmitting(false);
              })
          });

        }}
      >
        {({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <>
            <div className="login" style={{ backgroundImage: `url(${BG})` }}>
              <Link to="/"><img src={Logo} alt="logo" className="login-logo" /></Link>
              <form
                noValidate={true}
                autoComplete="off"
                className="kt-form login-wrap"
                onSubmit={handleSubmit}
              >
                <div className="login-top">
                  Account Login
                </div>
                <div className="login-main">
                  {status && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{status}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <TextField
                      key="8"
                      variant="outlined"
                      type="email"
                      label="Email"
                      margin="normal"
                      className="kt-width-full"
                      name="email"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email}
                      helperText={touched.email && error.email}
                      error={Boolean(touched.email && error.email)}
                    />
                  </div>

                  <div className="form-group" style={{ textAlign: 'right', marginBottom: 0 }}>
                    <TextField
                      key="9"
                      variant="outlined"
                      type="password"
                      margin="normal"
                      label="Password"
                      className="kt-width-full"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.password}
                      helperText={touched.password && error.password}
                      error={Boolean(touched.password && error.password)}
                      style={{ margin: 0 }}
                    />
                    <Link
                      to="/auth/forgot-password"
                      className="kt-login__link-forgot"
                      style={{
                        marginTop: 7,
                        display: 'inline-block',
                        color: '#5d78ff',
                        fontSize: '1rem !important',
                        fontFamily: 'Raleway, sans-serif',
                      }}
                    >
                      <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />?
                    </Link>
                  </div>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={remember}
                        onChange={(e) => {
                          setRemember(e.target.checked)
                        }}
                        color="primary"
                      />
                    }
                    label="Remember me"
                  />

                  <div className="kt-login__actions" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      id="kt_login_signin_submit"
                      disabled={isSubmitting}
                      type="submit"
                      className={`btn btn-elevate kt-login__btn-primary btn-login btn-blue ${clsx(
                        {
                          "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                        }
                      )}`}
                      style={loadingButtonStyle}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </form>
              {/*
              <div className="kt-login__head" style={{ marginTop: 20 }}>
                <span className="kt-login__signup-label">
                  Don't have an account?
                </span>
                &nbsp;&nbsp;
                <Link
                  to="/auth/signup"
                  className="kt-login__signup-link"
                  style={{ display: 'inline-block' }}
                >
                  Sign Up!
                </Link>
              </div>
              */}
            </div>
            <Footer style={{ position: 'fixed', bottom: 0 }} />
          </>
        )}
      </Formik>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);
