import React from 'react';
import moment from 'moment';
import DatePicker from '../../../../components/DatePicker';
import { Button } from 'react-bootstrap';

export const SubHeader = ({
    status,
    isDay,
    setIsDay,
    currentDate,
    setCurrentDate,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
}) => {
    const getCurrentWeek = () => {
        if (currentDate instanceof Date && isNaN(currentDate)) return 'Choose date';
        const date1 = new Date(currentDate);
        const date2 = new Date(currentDate);
        const month = moment(currentDate).format('MMMM');
        const sunday = date1.setDate(date1.getDate() - date1.getDay());
        const saturday = date2.setDate(date2.getDate() + (6 - date2.getDay()));
        const year = moment(currentDate).format('YYYY');

        if (moment(sunday).format('MM') !== moment(saturday).format('MM')) {
            return `${moment(sunday).format('MMMM')} ${moment(sunday).format('DD')} - ${moment(saturday).format('MMMM')} ${moment(saturday).format('DD')}, ${year}`;
        }

        return `${month} ${moment(sunday).format('DD')} - ${moment(saturday).format('DD')}, ${year}`;
    };

    const handleChangeDate = (value) => {
        if (!value || value === '  /  /    ') {
            return setCurrentDate(new Date())
        }
        setCurrentDate(new Date(value))
    };

    return (
        <div className='subheader'>
            <div className='subheader__block' style={{ justifyContent: 'flex-start' }}>
                {(status === 'isWeekCalendar' ||
                    status === 'isDayCalendar' ||
                    status === 'isDayAgenda') &&
                    <div className='subheader__datepicker'>
                        <DatePicker
                            label="Date"
                            future={true}
                            format={'MM/DD/YYYY'}
                            value={moment(currentDate).format('MM/DD/YYYY')} 
                            onChange={handleChangeDate}
                        />
                    </div>
                }

                <div className='today-datepicker'>
                    {(status === 'isWeekCalendar' ||
                        status === 'isDayCalendar' ||
                        status === 'isDayAgenda') &&
                        <Button
                            variant="primary"
                            onClick={() => setCurrentDate(new Date())}
                            style={{ whiteSpace: 'nowrap', height: 40 }}
                            >Today
                        </Button>
                    }

                    {status === 'isWeekAgenda' && (
                        <div className='agenda-datepickers'>
                            <div className='agenda-datepicker'>
                                <div className='agenda-datepicker__text'>Date Start</div>
                                <div className='agenda-datepicker__input'>
                                    <DatePicker
                                        value={moment(dateFrom).format('MM/DD/YYYY')}
                                        format={'MM/DD/YYYY'}
                                        onChange={setDateFrom}
                                        helperText=""
                                        future={true}
                                    />
                                </div>
                            </div>
                            <div className='agenda-datepicker'>
                                <div className='agenda-datepicker__text'>Date End</div>
                                <div className='agenda-datepicker__input'>
                                    <DatePicker
                                        value={moment(dateTo).format('MM/DD/YYYY')}
                                        format={'MM/DD/YYYY'}
                                        onChange={setDateTo}
                                        helperText=""
                                        future={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className='subheader__block subheader__second-block' style={{ justifyContent: 'center' }}>
                {status === 'isWeekCalendar' && getCurrentWeek()}
                {(status === 'isDayCalendar' || status === 'isDayAgenda') && moment(currentDate).format('MMMM DD')}
            </div>
            <div className='subheader__block' style={{ justifyContent: 'flex-end' }}>
                <Button
                    variant="primary"
                    onClick={() => setIsDay(true)}
                    style={{ whiteSpace: 'nowrap', height: 40 }}
                    className={isDay ? 'btn-blue' : ''}
                >Day</Button>
                <Button
                    variant="primary"
                    onClick={() => setIsDay(false)}
                    style={{ marginLeft: 20, whiteSpace: 'nowrap', height: 40 }}
                    className={isDay ? '' : 'btn-blue'}
                >{status === 'isWeekAgenda' || status === 'isDayAgenda' ? 'Period' : 'Week'}</Button>
            </div>
        </div>
    )
}