import React, { useState, useEffect, useRef } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import { DropdownComponent } from "./DropdownComponent";
import { connect, useSelector } from "react-redux";
import "./create-report.scss";
import {
  getGroup,
  getMetric,
  getAllUsers,
  getAllGroup,
} from "../../../../crud/info.crud";
import {
  runReport,
  saveReport,
  exportReport,
  getReport,
  getTypes,
  showTypes,
  showReport,
} from "../../../../crud/analytics.crud";
import { fetchForms } from "../../../../crud/forms";
import DatePicker from "./DatePicker";
import { Details } from "./Details/Details";
import { SaveModal } from "./SaveModal/SaveModal";
import { DropdownMultiselect } from "./DropdownMultiselect";
import moment from "moment";
import Loader from "../../../../components/Loader";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../../store/ducks/auth.duck";
import { getUsersV3 } from "../../../../crud/info.crud";

const CreateReport = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  let role = useSelector((store) => store.auth.user.roles);
  let currentUser = useSelector((store) => store.auth.user);
  const [errors, setErrors] = useState({});
  const [data, setData] = useState({
    sex: null,
    date_start: moment()
      .subtract(1, "months")
      .format("MM/DD/YYYY"),
    date_end: moment().format("MM/DD/YYYY"),
  });
  const [prevData, setPrevData] = useState({
    sex: null,
    date_start: moment()
      .subtract(1, "months")
      .format("MM/DD/YYYY"),
    date_end: moment().format("MM/DD/YYYY"),
  });

  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [groupsVis, setGroupsVis] = useState(false);
  const [usersVis, setUsersVis] = useState(false);
  const [isReportDetails, setIsReportDetails] = useState(false);
  const [isSaveModal, setIsSaveModal] = useState(false);
  const [metrics, setMetrics] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableMeta, setTableMeta] = useState({
    current_page: 1,
    from: 1,
    last_page: 1,
    per_page: 20,
    to: 0,
    total: 0,
    sort: "change_percent",
    dir: "desc",
  });

  const [sort, setSort] = useState({
    sort: "change_percent",
    dir: "desc",
  });
  const [loader, setLoader] = useState(false);
  const [loaderSub, setLoaderSub] = useState(false);
  const [loaderMetric, setLoaderMetric] = useState(false);
  const [loaderUser, setLoaderUser] = useState(false);
  const [report, setReport] = useState([]);
  const [reportObj, setReportObj] = useState();
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [header, setHeader] = useState([]);
  const [reportType, setReportType] = useState("");
  const [isNewReport, setIsNewReport] = useState(true);
  const [formsList, setFormsList] = useState([]);

  useEffect(() => {
    if (name === "User and Usage") {
      const array = data?.groups?.length ? data.groups : [];

      FetchUsers(array.map((elem) => elem.id));
      setData((prev) => ({
        ...prev,
        users: [],
      }));
    }
  }, [data.groups, name]);

  const FetchUsers = (selectedGroup) => {
    getUsersV3({ groups: selectedGroup || null, limit: 500 })
      .then((res) => {
        console.log("res", res);
        setUsers(
          res.data.list.map((elem) => ({
            ...elem,
            name: elem.first_name + " " + elem.last_name,
            title: elem.first_name + " " + elem.last_name,
          }))
        );
        setUsersVis(true);
      })
      .finally(() => setLoader(false));
  };

  useEffect(() => {
    header.forEach((elem) => {
      if (elem.default_sort) {
        setTableMeta({
          current_page: 1,
          from: 1,
          last_page: 1,
          per_page: 20,
          to: 0,
          total: 0,
          sort: elem.key,
          dir: "desc",
        });
      }
    });
  }, [reportType, header]);

  useEffect(() => {
    if (location) {
      const isnewReport = location.pathname.includes("new_report");
      setIsNewReport(isnewReport);
      const id = location.pathname.split("/")[3];
      if (isnewReport) {
        showTypes(id).then((res) => {
          setReportObj(res.data.data);
          setName(res.data.data.name);
          setTitle(res.data.data.name);
          setHeader(res.data.data.fields);
          if (+id === 2 || +id === 3 || +id === 4) {
            const obj = {
              ...data,
              event_type: {
                title: "Wellness Orientation",
                value: "Wellness Orientation",
              },
            };
            setData(obj);
            setPrevData(obj);
          }
          if (+id === 1 || +id === 7 || +id === 8 || +id === 9) {
            const obj = {
              ...data,
              date_start: moment()
                .subtract(1, "years")
                .format("MM/DD/YYYY"),
            };
            setData(obj);
            setPrevData(obj);
          }
        });
        setReportType(id);
      } else {
        showReport(id).then((res) => {
          const result = res.data.data;
          setReport(result.type.filters);
          setName(result.type.name);
          setTitle(result.name);
          setHeader(result.type.fields);
          setReportType(result.type.id);
          if (result.type.id === 1) {
            const obj = {
              age_from: { title: result.age_from, value: result.age_from },
              age_to: { title: result.age_to, value: result.age_to },
              date_end: moment(result.date_end).format("MM/DD/YYYY"),
              date_start: moment(result.date_start).format("MM/DD/YYYY"),
              groups: result.groups,
              metric: metrics.find((elem) => elem.id === result.metric_id),
              sex: { title: result.gender, value: result.gender },
              users: result.users,
            };
            setData(obj);
            setPrevData(obj);
          }
          if (result.type.id === 2 || result.type.id === 3 || result.type.id === 13 || result.type.id === 14) {
            const obj = {
              date_end: moment(result.date_end).format("MM/DD/YYYY"),
              date_start: moment(result.date_start).format("MM/DD/YYYY"),
              groups: result.groups,
              users: result.users,
              event_type: {
                title: result.event_type,
                value: result.event_type,
              },
            };
            setData(obj);
            setPrevData(obj);
          }
          if (result.type.id === 4 || result.type.id === 15) {
            const obj = {
              date_end: moment(result.date_end).format("MM/DD/YYYY"),
              date_start: moment(result.date_start).format("MM/DD/YYYY"),
              groups: result.groups,
              event_type: {
                title: result.event_type,
                value: result.event_type,
              },
            };
            setData(obj);
            setPrevData(obj);
          }
          if (result.type.id === 5) {
            const obj = {
              date_end: moment(result.date_end).format("MM/DD/YYYY"),
              date_start: moment(result.date_start).format("MM/DD/YYYY"),
              groups: result.groups,
              metric_id: metrics.find((elem) => elem.id === result.metric_id),
              users: result.users,
            };
            setData(obj);
            setPrevData(obj);
          }
          if (result.type.id === 6) {
            const obj = {
              date_end: moment(result.date_end).format("MM/DD/YYYY"),
              date_start: moment(result.date_start).format("MM/DD/YYYY"),
              groups: result.groups,
              metric_id: metrics.find((elem) => elem.id === result.metric_id),
            };
            setData(obj);
            setPrevData(obj);
          }
          if (
            result.type.id === 7 ||
            result.type.id === 8 ||
            result.type.id === 9
          ) {
            const obj = {
              date_end: moment(result.date_end).format("MM/DD/YYYY"),
              date_start: moment(result.date_start).format("MM/DD/YYYY"),
              groups: result.groups,
              users: result.users,
              task_type: { value: result.task_type, title: result.task_type },
            };
            setData(obj);
            setPrevData(obj);
          }
          if (
            result.type.id === 12 ||
            result.type.id === 10 ||
            +reportType === 11
          ) {
            const obj = {
              groups: groups.filter((item) => result.groups.includes(item.id)),
              users: users.filter((item) => result.users.includes(item.id)),
              date_end: moment(result.date_end).format("MM/DD/YYYY"),
              date_start: moment(result.date_start).format("MM/DD/YYYY"),
              forms: result.forms,
            };
            setData(obj);
            setPrevData(obj);
          }
          setIsReportDetails(true);
        });
      }
    }
  }, [location, metrics, groups.length, users.length]);

  useEffect(() => {
    if (!reportObj || !name) return;
    const data = reportObj.filters;
    const array = [];
    for (let key in data) {
      array.push(data[key]);
    }
    setReport(array);
  }, [reportObj]);

  useEffect(() => {
    setLoaderSub(true);
    setLoaderMetric(true);

    if (name && name !== "User and Usage") {
      setLoaderUser(true);
    }

    setLoaderSub(true);
    getAllGroup()
      .then((res) => {
        setLoaderSub(false);
        setGroupsVis(true);
        let arr = res.data.data.map((elem) => ({ ...elem, title: elem.name }));
        setGroups(arr);
      })
      .catch((err) => {
        setLoaderSub(false);
      });

    getMetric("analytic")
      .then((res) => {
        setLoaderMetric(false);
        let arr = res.data.data
          .filter((i) => i.pseudonym !== "progress_photo")
          .map((elem) => ({ ...elem, title: elem.metric }));
        setMetrics(arr);
      })
      .catch((err) => {
        setLoaderMetric(false);
      });
    return () => {
      location.state = null;
    };
  }, [name]);

  useEffect(() => {
    if (!name || name === "User and Usage") return;
    getAllUsers()
      .then((res) => {
        setLoaderUser(false);
        setUsersVis(true);
        let arr = res.data.data.map((elem) => ({ ...elem, title: elem.name }));
        if (role !== "supervisor") {
          setUsers(
            arr.filter(
              (item) =>
                item.type !== "admin" &&
                item.is_super_admin !== 1 &&
                currentUser.firstName + " " + currentUser.lastName !==
                  item.title
            )
          );
        } else {
          setUsers(
            arr.filter(
              (item) =>
                item.type !== "admin" &&
                item.is_super_admin !== 1 &&
                item.type !== "supervisor" &&
                currentUser.firstName + " " + currentUser.lastName !==
                  item.title
            )
          );
        }
      })
      .catch((err) => {
        setLoaderUser(false);
      });
  }, [role, name]);

  useEffect(() => {
    if (!["Profiles and Forms", "Users and Forms"].includes(name)) return;
    fetchForms({ limit: 500 }).then((res) => {
      setFormsList(res.data.forms);
      setData((prev) => ({
        ...prev,
        forms: [],
      }));
    });
  }, [name]);

  const getAgeArr = (type) => {
    const arr = [];
    let val = 18;
    if (type === "age_to") val = data.age_from?.title || 18;
    for (let i = val; i <= 120; i++) arr.push({ title: i + "", value: i });
    return arr;
  };

  const validate = () => {
    let tempError = {};

    if (
      data.date_start &&
      (parseInt(moment(data.date_start, "MM/DD/YYYY").format("YYYY")) < 1970 ||
        moment(data.date_start, "MM/DD/YYYY") === "Invalid date" ||
        data.date_start.indexOf(" ") !== -1)
    ) {
      tempError = {
        ...tempError,
        date_start: "The minimum value of the year is 1970",
      };
    }

    if (
      data.date_end &&
      (parseInt(moment(data.date_end, "MM/DD/YYYY").format("YYYY")) < 1970 ||
        moment(data.date_end, "MM/DD/YYYY") === "Invalid date" ||
        data.date_end.indexOf(" ") !== -1)
    ) {
      tempError = {
        ...tempError,
        date_end: "The minimum value of the year is 1970",
      };
    }
    if (
      (+reportType === 10 || +reportType === 11) && (Array.isArray(data?.forms)
        ? !data?.forms.length
        : !data?.forms?.id)
    ) {
      tempError = {
        ...tempError,
        forms: "Field is required!",
      };
    }
    setErrors(tempError);
    return JSON.stringify(tempError) === "{}";
  };

  useEffect(() => {
    if (isReportDetails) {
      if (+reportType === 1) {
        if (data.metric && groupsVis && usersVis) {
          handleRun(true);
        }
      }
      if (+reportType === 2 || +reportType === 3 || +reportType === 4 || +reportType === 13 || +reportType === 14 || +reportType === 15) {
        if (data.event_type && groupsVis && usersVis) {
          handleRun(true);
        }
      }
      if (+reportType === 5) {
        if (data.metric_id && groupsVis && usersVis) {
          handleRun(true);
        }
      }
      if (+reportType === 6) {
        if (data.metric_id && groupsVis) {
          handleRun(true);
        }
      }
      if (+reportType === 7 || +reportType === 8 || +reportType === 9) {
        if (data.task_type && groupsVis && usersVis) {
          handleRun(true);
        }
      }
      if (+reportType === 12) {
        if (groupsVis && usersVis) {
          handleRun(true);
        }
      }
      if (+reportType === 10 || +reportType === 11) {
        if (
          groupsVis &&
          usersVis &&
          (data?.forms?.length || !!data?.forms?.id)
        ) {
          handleRun(true);
        }
      }
    }
  }, [
    isReportDetails,
    isNewReport,
    data.metric,
    data.metric_id,
    data.task_type,
    groupsVis,
    usersVis,
    tableMeta,
  ]);

  const handleRun = (noRewrite) => {
    if (loader) return;
    let prev = {};
    if (noRewrite) {
      prev = { ...prevData };
    } else {
      prev = { ...data };
    }
    if (!validate()) {
      return;
    } else {
      setErrors({});
      setLoader(true);

      let formData = {};

      let groups = [];
      prev?.groups?.length &&
        prev.groups.forEach((elem) => {
          if (!groups.find((item) => item === (elem.id || elem))) {
            if (elem.id) {
              groups.push(elem.id);
            } else {
              groups.push(elem);
            }
          }
        });

      let users = [];

      if (
        +reportType === 1 ||
        +reportType === 2 ||
        +reportType === 3 ||
        +reportType === 5 ||
        +reportType === 7 ||
        +reportType === 8 ||
        +reportType === 12 ||
        +reportType === 10 ||
        +reportType === 11 ||
        +reportType === 13 ||
        +reportType === 14
      ) {
        prev?.users?.length &&
          prev.users.forEach((elem) => {
            if (elem?.id) {
              users.push(elem.id);
            } else {
              users.push(elem);
            }
            // if (!users.find((item) => item === (elem.id || elem))) {
            //   if (elem.id) {
            //     users.push(elem.id);
            //   } else {
            //     users.push(elem);
            //   }
            // }
          });
      }

      if (+reportType === 1) {
        formData = {
          report: {
            metric_id: prev.metric ? prev.metric.id : null,
            age_from: prev.age_from ? prev.age_from.value : null,
            age_to: prev.age_to ? prev.age_to.value : null,
            gender: prev.sex ? prev.sex.value : null,
            date_start: prev.date_start,
            date_end: prev.date_end,
          },
          groups: groups,
          users: users,
          type_id: reportType,
        };
      }
      if (+reportType === 2 || +reportType === 3 || +reportType === 13 || +reportType === 14) {
        formData = {
          report: {
            event_type: prev?.event_type?.value ? prev.event_type.value : null,
            date_start: prev.date_start,
            date_end: prev.date_end,
          },
          groups: groups,
          users: users,
          type_id: reportType,
        };
      }
      if (+reportType === 4 || +reportType === 15) {
        formData = {
          report: {
            event_type: prev?.event_type?.value ? prev.event_type.value : null,
            date_start: prev.date_start,
            date_end: prev.date_end,
          },
          groups: groups,
          type_id: reportType,
        };
      }
      if (+reportType === 5) {
        formData = {
          report: {
            metric_id: prev.metric_id ? prev.metric_id.id : null,
            date_start: prev.date_start,
            date_end: prev.date_end,
          },
          groups: groups,
          users: users,
          type_id: reportType,
        };
      }
      if (+reportType === 6) {
        formData = {
          report: {
            metric_id: prev.metric_id ? prev.metric_id.id : null,
            date_start: prev.date_start,
            date_end: prev.date_end,
          },
          groups: groups,
          type_id: reportType,
        };
      }
      if (+reportType === 7 || +reportType === 8 || +reportType === 9) {
        formData = {
          report: {
            task_type: prev.task_type ? prev.task_type.title : null,
            date_start: prev.date_start,
            date_end: prev.date_end,
          },
          groups: groups,
          users: users,
          type_id: reportType,
        };
      }

      if (+reportType === 10 || +reportType === 12 || +reportType === 11) {
        formData = {
          groups: groups,
          users: users,
          type_id: reportType,
          report: {
            forms: Array.isArray(data?.forms)
              ? data?.forms.map((item) => item.id || item)
              : data.forms?.id
              ? [data.forms?.id]
              : [],

            date_start: prev.date_start,
            date_end: prev.date_end,
          },
        };
      }

      runReport(formData, tableMeta)
        .then((res) => {
          setLoader(false);

          if (!noRewrite) {
            setPrevData(data);
          }
          setTableData(
            res.data.data.map((item) => ({
              ...item,
              change_percent: item.change_percent
                ? parseFloat(item.change_percent).toFixed(2)
                : null,
            }))
          );
          if (
            JSON.stringify(tableMeta) !==
            JSON.stringify({ ...tableMeta, ...res.data.meta })
          ) {
            setTableMeta({ ...tableMeta, ...res.data.meta });
          }
          if (res.data?.meta?.last_page < res.data?.meta?.current_page) {
            setTableMeta({ current_page: res.data.meta.last_page });
          }
          if (!res.data?.meta)
            tableMeta.total && setTableMeta((prev) => ({ ...prev, total: 0 }));
          setIsReportDetails(true);
        })
        .catch(({ response }) => {
          setLoader(false);
          if (response && response.data && response.data.errors) {
            const er = Object.keys(response.data.errors);
            let err = {};
            er.forEach((key) => {
              let keys = key.split(".");
              if (keys[0] === "report" && keys[1]) {
                err[keys[1]] =
                  typeof response.data.errors[key] === "string"
                    ? response.data.errors[key]
                    : response.data.errors[key][0];
              } else {
                err[keys[0]] =
                  typeof response.data.errors[key] === "string"
                    ? response.data.errors[key]
                    : response.data.errors[key][0];
              }
            });
            setErrors(err);
          }
        });
    }
  };

  const handleExport = () => {
    if (!validate()) {
      return;
    } else {
      setErrors({});
      setLoader(true);
      let formData = {};

      let groups = [];
      prevData?.groups?.length &&
        prevData.groups.forEach((elem) => {
          if (!groups.find((item) => item === (elem.id || elem))) {
            if (elem.id) {
              groups.push(elem.id);
            } else {
              groups.push(elem);
            }
          }
        });

      let users = [];
      if (
        +reportType === 1 ||
        +reportType === 2 ||
        +reportType === 3 ||
        +reportType === 5 ||
        +reportType === 7 ||
        +reportType === 8 ||
        +reportType === 12 ||
        +reportType === 10 ||
        +reportType === 11 ||
        +reportType === 13 ||
        +reportType === 14
      ) {
        prevData?.users?.length &&
          prevData.users.forEach((elem) => {
            if (elem?.id) {
              users.push(elem.id);
            } else {
              users.push(elem);
            }
            // if (!users.find((item) => item === (elem.id || elem))) {
            //   if (elem.id) {
            //     users.push(elem.id);
            //   } else {
            //     users.push(elem);
            //   }
            // }
          });
      }

      if (+reportType === 1) {
        formData = {
          report: {
            metric_id: prevData.metric ? prevData.metric.id : null,
            age_from: prevData.age_from ? prevData.age_from.value : null,
            age_to: prevData.age_to ? prevData.age_to.value : null,
            gender: prevData.sex ? prevData.sex.value : null,
            date_start: prevData.date_start,
            date_end: prevData.date_end,
          },
          groups: groups,
          users: users,
          type_id: reportType,
        };
      }
      if (+reportType === 2 || +reportType === 3 || +reportType === 13 || +reportType === 14) {
        formData = {
          report: {
            event_type: prevData?.event_type?.value
              ? prevData.event_type.value
              : null,
            date_start: prevData.date_start,
            date_end: prevData.date_end,
          },
          groups: groups,
          users: users,
          type_id: reportType,
        };
      }
      if (+reportType === 4 || +reportType === 15) {
        formData = {
          report: {
            event_type: prevData?.event_type?.value
              ? prevData.event_type.value
              : null,
            date_start: prevData.date_start,
            date_end: prevData.date_end,
          },
          groups: groups,
          type_id: reportType,
        };
      }
      if (+reportType === 5) {
        formData = {
          report: {
            metric_id: prevData.metric_id ? prevData.metric_id.id : null,
            date_start: prevData.date_start,
            date_end: prevData.date_end,
          },
          groups: groups,
          users: users,
          type_id: reportType,
        };
      }
      if (+reportType === 6) {
        formData = {
          report: {
            metric_id: prevData.metric_id ? prevData.metric_id.id : null,
            date_start: prevData.date_start,
            date_end: prevData.date_end,
          },
          groups: groups,
          type_id: reportType,
        };
      }
      if (+reportType === 7 || +reportType === 8 || +reportType === 9) {
        formData = {
          report: {
            task_type: prevData.task_type ? prevData.task_type.title : null,
            date_start: prevData.date_start,
            date_end: prevData.date_end,
          },
          groups: groups,
          users: users,
          type_id: reportType,
        };
      }
      if (+reportType === 12 || +reportType === 10 || +reportType === 11) {
        formData = {
          groups: groups,
          users: users,
          type_id: reportType,
          report: {
            date_start: prevData.date_start,
            date_end: prevData.date_end,
            forms: Array.isArray(data?.forms)
              ? data?.forms.map((item) => item.id || item)
              : data.forms?.id
              ? [data.forms?.id]
              : [],
          },
        };
      }

      exportReport(formData, tableMeta)
        .then((response) => {
          setLoader(false);

          const nameMetric = prevData?.metric?.metric
            ? prevData?.metric?.metric
            : prevData?.metric_id?.metric
            ? prevData?.metric_id?.metric
            : null;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          if (+reportType === 12) {
            link.setAttribute(
              "download",
              `Analytics-${name}-${moment().format("YYYY.MM.DD")}.csv`
            );
          } else {
            link.setAttribute(
              "download",
              `Analytics-Report-${name}-${
                +reportType === 1 || +reportType === 5 || +reportType === 6
                  ? nameMetric
                  : +reportType === 2 || +reportType === 3 || +reportType === 4 || +reportType === 13 || +reportType === 14 || +reportType === 15
                  ? prevData.event_type.value
                  : +reportType === 7 || +reportType === 8 || +reportType === 9
                  ? prevData?.task_type?.title
                  : "name"
              }-${moment().format("YYYY.MM.DD")}.csv`
            );
          }

          link.click();
        })
        .catch(({ response }) => {
          setLoader(false);
          if (response && response.data && response.data.errors) {
            const er = Object.keys(response.data.errors);
            let err = {};
            er.forEach((key) => {
              let keys = key.split(".");
              if (keys[0] === "report" && keys[1]) {
                err[keys[1]] =
                  typeof response.data.errors[key] === "string"
                    ? response.data.errors[key]
                    : response.data.errors[key][0];
              } else {
                err[keys[0]] =
                  typeof response.data.errors[key] === "string"
                    ? response.data.errors[key]
                    : response.data.errors[key][0];
              }
            });
            setErrors(err);
          }
        });
    }
  };

  const onOpenSaveModal = () => {
    if (!validate()) {
      return;
    } else {
      setIsSaveModal(true);
    }
  };

  const [redirect, setRedirect] = useState(null);

  const handleSave = () => {
    let formData = {};

    let groups = [];
    data?.groups?.length &&
      data.groups.forEach((elem) => {
        if (!groups.find((item) => item === (elem.id || elem))) {
          if (elem.id) {
            groups.push(elem.id);
          } else {
            groups.push(elem);
          }
        }
      });

    let users = [];
    if (
      +reportType === 1 ||
      +reportType === 2 ||
      +reportType === 3 ||
      +reportType === 5 ||
      +reportType === 7 ||
      +reportType === 8 ||
      +reportType === 12 ||
      +reportType === 10 ||
      +reportType === 11 ||
      +reportType === 13 ||
      +reportType === 14
    ) {
      data?.users?.length &&
        data.users.forEach((elem) => {
          if (elem?.id) {
            users.push(elem.id);
          } else {
            users.push(elem);
          }
          // if (!users.find((item) => item === (elem.id || elem))) {
          //   if (elem.id) {
          //     users.push(elem.id);
          //   } else {
          //     users.push(elem);
          //   }
          // }
        });
    }

    if (+reportType === 1) {
      formData = {
        report: {
          name: data.name ? data.name : null,
          description: data.description ? data.description : null,
          report_date: data.report_date,
          metric_id: data.metric ? data.metric.id : null,
          age_from: data.age_from ? data.age_from.value : null,
          age_to: data.age_to ? data.age_to.value : null,
          gender: data.sex ? data.sex.value : null,
          date_start: data.date_start,
          date_end: data.date_end,
        },
        groups: groups,
        users: users,
        type_id: reportType,
      };
    }
    if (+reportType === 2 || +reportType === 3 || +reportType === 13 || +reportType === 14) {
      formData = {
        report: {
          event_type: data?.event_type?.value ? data.event_type.value : null,
          date_start: data.date_start,
          date_end: data.date_end,
          name: data.name ? data.name : null,
          description: data.description ? data.description : null,
          report_date: data.report_date,
        },
        groups: groups,
        users: users,
        type_id: reportType,
      };
    }
    if (+reportType === 4 || +reportType === 15) {
      formData = {
        report: {
          event_type: data?.event_type?.value ? data.event_type.value : null,
          date_start: data.date_start,
          date_end: data.date_end,
          name: data.name ? data.name : null,
          description: data.description ? data.description : null,
          report_date: data.report_date,
        },
        groups: groups,
        type_id: reportType,
      };
    }
    if (+reportType === 5) {
      formData = {
        report: {
          metric_id: data.metric_id ? data.metric_id.id : null,
          date_start: data.date_start,
          date_end: data.date_end,
          name: data.name ? data.name : null,
          description: data.description ? data.description : null,
          report_date: data.report_date,
        },
        groups: groups,
        users: users,
        type_id: reportType,
      };
    }
    if (+reportType === 6) {
      formData = {
        report: {
          metric_id: data.metric_id ? data.metric_id.id : null,
          date_start: data.date_start,
          date_end: data.date_end,
          name: data.name ? data.name : null,
          description: data.description ? data.description : null,
          report_date: data.report_date,
        },
        groups: groups,
        type_id: reportType,
      };
    }
    if (+reportType === 7 || +reportType === 8 || +reportType === 9) {
      formData = {
        report: {
          task_type: data.task_type ? data.task_type.title : null,
          date_start: data.date_start,
          date_end: data.date_end,
          name: data.name ? data.name : null,
          description: data.description ? data.description : null,
          report_date: data.report_date,
        },
        groups: groups,
        users: users,
        type_id: reportType,
      };
    }
    if (+reportType === 12 || +reportType === 10 || +reportType === 11) {
      formData = {
        report: {
          name: data.name ? data.name : null,
          description: data.description ? data.description : null,
          report_date: data.report_date,
          date_start: data.date_start,
          date_end: data.date_end,
          forms: Array.isArray(data?.forms)
            ? data?.forms.map((item) => item.id || item)
            : data.forms?.id
            ? [data.forms?.id]
            : [],
        },
        groups: groups,
        users: users,
        type_id: reportType,
      };
    }

    setLoader(true);
    setErrors({});
    saveReport(formData)
      .then(() => {
        setLoader(false);
        setIsSaveModal(false);
        setRedirect(<Redirect to={`/analytics/reports-list`} />);
        dispatch(
          AlertState.actions.alert({
            text: "Report is saved",
            variant: true,
          })
        );
      })
      .catch(({ response }) => {
        setLoader(false);
        if (response && response.data && response.data.errors) {
          const er = Object.keys(response.data.errors);
          let err = {};
          er.forEach((key) => {
            let keys = key.split(".");
            if (keys[0] === "report" && keys[1]) {
              err[keys[1]] =
                typeof response.data.errors[key] === "string"
                  ? response.data.errors[key]
                  : response.data.errors[key][0];
            } else {
              err[keys[0]] =
                typeof response.data.errors[key] === "string"
                  ? response.data.errors[key]
                  : response.data.errors[key][0];
            }
          });
          setErrors(err);
        }
      });
  };

  return (
    <>
      {redirect}
      <Loader visible={loaderSub || loaderMetric || loaderUser || loader} />

      {isSaveModal && (
        <SaveModal
          setModal={setIsSaveModal}
          handleSave={handleSave}
          name={prevData.name}
          description={prevData.description}
          setData={(v) => {
            setData({ ...data, ...v });
          }}
          errors={errors}
        />
      )}

      <div className="create-report-page">
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className="top-buttons-wrapper">
              <div className="page-title">{title}</div>
              <div className="button-wrapper">
                <Button
                  variant="info"
                  style={{ margin: 5 }}
                  onClick={handleExport}
                  disabled={!isReportDetails || tableData.length === 0}
                >
                  Export
                </Button>
                <Button
                  variant="info"
                  style={{ margin: 5 }}
                  onClick={onOpenSaveModal}
                  disabled={!isReportDetails}
                >
                  Save
                </Button>
                <Button
                  variant="info"
                  style={{ margin: 5 }}
                  onClick={() => handleRun(false)}
                >
                  Run
                </Button>
              </div>
            </div>

            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">REPORT FILTERS</h3>
                </div>
              </div>
              <div className="kt-portlet__body">
                <div className="body-wrapper">
                  {(name === "Profiles and W.O. Compliance" ||
                    name === "Users and W.O. Compliance" ||
                    name === "Groups and W.O. Compliance" ||
                    name === "Profiles and Consult Compliance" ||
                    name === "Users and Consult Compliance" ||
                    name === "Groups and Consult Compliance" ||
                    name === "Users and Metric" ||
                    name === "Groups and Metric" ||
                    name === "Profiles and Tasks" ||
                    name === "Users and Tasks" ||
                    name === "Groups and Tasks" ||
                    name === "User and Usage" ||
                    name === "Profiles and Forms" ||
                    name === "Users and Forms") && (
                    <>
                      <div className="report-row">
                        {report.map((elem) => {
                          if (elem.label === "Task Type") {
                            return (
                              <div className="elem-container">
                                <div className="elem-title">{elem.label}:</div>
                                <DropdownComponent
                                  style={{ minWidth: "160px" }}
                                  label={`Select ${elem.label}`}
                                  error={errors[elem.key]}
                                  options={elem.options.map((elem) => {
                                    return { title: elem, value: elem };
                                  })}
                                  setData={setData}
                                  name={elem.key}
                                  value={data[elem.key]?.title || ""}
                                  data={data}
                                />
                              </div>
                            );
                          }
                          if (elem.label === "Form") {
                            const value = Array.isArray(data?.forms)
                              ? formsList.find((item) =>
                                  data?.forms?.includes(item.id)
                                )
                              : {
                                  id: data?.forms?.id,
                                  title:
                                    data?.forms?.name || data?.forms?.title,
                                };
                            return (
                              <div className="elem-container">
                                <div className="elem-title">{elem.label}</div>
                                {elem.type === "multiselect" ? (
                                  <DropdownMultiselect
                                    style={{ maxWidth: "200px" }}
                                    label="Select forms"
                                    error={errors.forms}
                                    options={formsList.map((item) => ({
                                      title: item.name,
                                      id: item.id,
                                    }))}
                                    setData={setData}
                                    multiple={true}
                                    name="forms"
                                    data={data}
                                    value={data.forms || []}
                                  />
                                ) : (
                                  <DropdownComponent
                                    style={{ minWidth: "160px" }}
                                    label={`Select ${elem.label}`}
                                    error={errors.forms}
                                    options={formsList.map((item) => ({
                                      title: item.name,
                                      id: item.id,
                                    }))}
                                    setData={setData}
                                    name={"forms"}
                                    value={value?.title || value?.name || ""}
                                    data={{
                                      forms: {
                                        id: value?.id,
                                        title: value?.title || value?.name,
                                      },
                                    }}
                                  />
                                )}
                              </div>
                            );
                          }
                          if (elem.label === "Metric") {
                            return (
                              <div className="elem-container">
                                <div className="elem-title">{elem.label}:</div>
                                <DropdownComponent
                                  style={{ minWidth: "160px" }}
                                  label={`Select ${elem.label}`}
                                  error={errors[elem.key]}
                                  options={metrics}
                                  setData={setData}
                                  name={elem.key}
                                  value={data[elem.key]?.title || ""}
                                  data={data}
                                />
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div className="report-row">
                        {report.map((elem) => {
                          if (elem.label === "Event Type") {
                            return (
                              <div className="elem-container">
                                <div className="elem-title">{elem.label}:</div>
                                <DropdownComponent
                                  style={{ minWidth: "160px" }}
                                  label={`Select ${elem.label}`}
                                  error={errors[elem.key]}
                                  options={elem.options.map((elem) => {
                                    return {
                                      title: elem,
                                      value: elem,
                                    };
                                  })}
                                  setData={setData}
                                  name={elem.key}
                                  value={data[elem.key]?.title || ""}
                                  data={data}
                                />
                              </div>
                            );
                          }
                        })}
                      </div>
                      <div
                        className={`report-row ${
                          name === "Groups and W.O. Compliance"
                            ? "groups_and_nfo"
                            : ""
                        }`}
                      >
                        <div className="report-row-in-row">
                          {report.map((elem) => {
                            if (elem.label === "Groups") {
                              return (
                                <div className="elem-container large">
                                  <div className="elem-title">Group:</div>
                                  <DropdownMultiselect
                                    style={{ maxWidth: "200px" }}
                                    label="Select group"
                                    error={errors.groups}
                                    options={
                                      groups.length
                                        ? groups.sort((a, b) => {
                                            const textA = a.name.toLowerCase();
                                            const textB = b.name.toLowerCase();
                                            return textA < textB
                                              ? -1
                                              : textA > textB
                                              ? 1
                                              : 0;
                                          })
                                        : []
                                    }
                                    setData={setData}
                                    multiple={true}
                                    name="groups"
                                    data={data}
                                    value={data.groups || []}
                                  />
                                </div>
                              );
                            }
                          })}
                          {report.map((elem) => {
                            if (elem.label === "Users") {
                              return (
                                <div className={`elem-container large`}>
                                  <div className="elem-title users">User:</div>
                                  <DropdownMultiselect
                                    style={{ width: "200px" }}
                                    label="Select user"
                                    error={errors.users}
                                    setData={setData}
                                    name="users"
                                    options={
                                      users.length
                                        ? users.sort((a, b) => {
                                            const textA = a.name.toLowerCase();
                                            const textB = b.name.toLowerCase();
                                            return textA < textB
                                              ? -1
                                              : textA > textB
                                              ? 1
                                              : 0;
                                          })
                                        : []
                                    }
                                    data={data}
                                    value={data.users || []}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                        <div className="report-row-in-row">
                          {report.map((elem) => {
                            if (elem.label === "Date start") {
                              return (
                                <div className="date-container">
                                  <div className="elem-title">Start Date:</div>
                                  <DatePicker
                                    width={160}
                                    label="Start"
                                    future={true}
                                    format={"MM/DD/YYYY"}
                                    onChange={(value) => {
                                      if (value === "  /  /    ") {
                                        setData((prev) => ({
                                          ...prev,
                                          date_start: null,
                                        }));
                                      } else
                                        setData((prev) => ({
                                          ...prev,
                                          date_start: value,
                                        }));
                                    }}
                                    value={data.date_start || ""}
                                    error={!!errors.date_start}
                                    helperText={
                                      errors.date_start ? errors.date_start : ""
                                    }
                                  />
                                </div>
                              );
                            }
                          })}
                          {report.map((elem) => {
                            if (elem.label === "Date end") {
                              return (
                                <div className="date-container">
                                  <div className="elem-title">End Date:</div>
                                  <DatePicker
                                    width={160}
                                    label="End"
                                    future={true}
                                    format={"MM/DD/YYYY"}
                                    onChange={(value) => {
                                      if (value === "  /  /    ") {
                                        setData((prev) => ({
                                          ...prev,
                                          date_end: null,
                                        }));
                                      } else
                                        setData((prev) => ({
                                          ...prev,
                                          date_end: value,
                                        }));
                                    }}
                                    value={data.date_end || ""}
                                    error={!!errors.date_end}
                                    helperText={
                                      errors.date_end ? errors.date_end : ""
                                    }
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </>
                  )}
                  {name === "Profiles and Metric" && (
                    <div className="column">
                      {report?.map((elem) => {
                        if (elem.type === "select" && elem.label === "Metric") {
                          return (
                            <div className="elem-container">
                              <div className="elem-title">Metric:</div>
                              <DropdownComponent
                                label="Select Metric"
                                error={errors.metric_id}
                                setData={setData}
                                options={metrics.length ? metrics : []}
                                name={"metric"}
                                value={data.metric?.title || ""}
                                data={data}
                              />
                            </div>
                          );
                        }

                        if (elem.label === "Age From") {
                          return (
                            <div className="elem-container">
                              <div className="elem-title">Age from:</div>
                              <DropdownComponent
                                label="Select"
                                error={errors.age_from}
                                setData={setData}
                                options={getAgeArr()}
                                name="age_from"
                                value={data.age_from?.title || ""}
                                data={data}
                              />
                            </div>
                          );
                        }
                        if (elem.label === "Age To") {
                          return (
                            <div className="elem-container">
                              <div className="elem-title">Age to:</div>
                              <DropdownComponent
                                label="Select"
                                error={errors.age_to}
                                setData={setData}
                                options={getAgeArr("age_to")}
                                name="age_to"
                                value={data.age_to?.title || ""}
                                data={data}
                              />
                            </div>
                          );
                        }
                        if (elem.label === "Gender") {
                          return (
                            <div className="elem-container">
                              <div className="elem-title">Sex:</div>
                              <DropdownComponent
                                label="Select a sex"
                                error={errors.sex}
                                options={[
                                  { title: "Male", value: "Male" },
                                  { title: "Female", value: "Female" },
                                ]}
                                setData={setData}
                                name="sex"
                                value={data.sex?.title || ""}
                                data={data}
                              />
                            </div>
                          );
                        }
                        if (elem.label === "Date start") {
                          return (
                            <div className="date-container">
                              <div className="elem-title">Date start:</div>
                              <DatePicker
                                label="Start"
                                future={true}
                                format={"MM/DD/YYYY"}
                                onChange={(value) => {
                                  if (value === "  /  /    ") {
                                    setData((prev) => ({
                                      ...prev,
                                      date_start: null,
                                    }));
                                  } else
                                    setData((prev) => ({
                                      ...prev,
                                      date_start: value,
                                    }));
                                }}
                                value={data.date_start || ""}
                                error={!!errors.date_start}
                                helperText={
                                  errors.date_start ? errors.date_start : ""
                                }
                              />
                            </div>
                          );
                        }
                        if (elem.label === "Date end") {
                          return (
                            <div className="date-container">
                              <div className="elem-title">Date end:</div>
                              <DatePicker
                                label="End"
                                future={true}
                                format={"MM/DD/YYYY"}
                                onChange={(value) => {
                                  if (value === "  /  /    ") {
                                    setData((prev) => ({
                                      ...prev,
                                      date_end: null,
                                    }));
                                  } else
                                    setData((prev) => ({
                                      ...prev,
                                      date_end: value,
                                    }));
                                }}
                                value={data.date_end || ""}
                                error={!!errors.date_end}
                                helperText={
                                  errors.date_end ? errors.date_end : ""
                                }
                              />
                            </div>
                          );
                        }
                        if (elem.label === "Groups") {
                          return (
                            <div className="elem-container large">
                              <div className="elem-title">Group:</div>
                              <DropdownMultiselect
                                style={{ maxWidth: "200px" }}
                                label="Select group"
                                error={errors.groups}
                                options={
                                  groups.length
                                    ? groups.sort((a, b) => {
                                        const textA = a.name.toLowerCase();
                                        const textB = b.name.toLowerCase();
                                        return textA < textB
                                          ? -1
                                          : textA > textB
                                          ? 1
                                          : 0;
                                      })
                                    : []
                                }
                                setData={setData}
                                multiple={true}
                                name="groups"
                                data={data}
                                value={data.groups || []}
                              />
                            </div>
                          );
                        }
                        if (elem.label === "Users") {
                          return (
                            <div className={`elem-container large`}>
                              <div className="elem-title">User:</div>
                              <DropdownMultiselect
                                style={{ width: "200px" }}
                                label="Select user"
                                error={errors.users}
                                setData={setData}
                                name="users"
                                options={
                                  users.length
                                    ? users.sort((a, b) => {
                                        const textA = a.name.toLowerCase();
                                        const textB = b.name.toLowerCase();
                                        return textA < textB
                                          ? -1
                                          : textA > textB
                                          ? 1
                                          : 0;
                                      })
                                    : []
                                }
                                data={data}
                                value={data.users || []}
                              />
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="main-wrapper">
              {isReportDetails && (
                <Details
                  header={header}
                  data={tableData}
                  meta={tableMeta}
                  setSort={setSort}
                  sort={sort}
                  setMeta={(v) => setTableMeta({ ...tableMeta, ...v })}
                  role={role}
                  usersVis={usersVis}
                  groupsVis={groupsVis}
                  name={name}
                  additionalData={data}
                  isChartVisible={
                    !["Profiles and Forms", "Users and Forms"].includes(name)
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  roles: state.auth.user.roles,
});

export default connect(mapState)(CreateReport);
