import React, { useState } from 'react';
import { Table } from './DayAgendaTable/Table';
import { Button } from 'react-bootstrap';
import ConfirmModal from '../../../../components/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import { deleteEvents } from '../../../../crud/dashboard.crud';
import { completeEvents } from '../../../../crud/dashboard.crud';
import * as AlertState from '../../../../store/ducks/auth.duck';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import './agenda-tab.scss';

export const DayAgenda = ({
    events,
    sort,
    setSort,
    check,
    setCheck,
    loadEvents,
    setCreateModal,
    setSelectedEventId,
    setLoader,
}) => {
    const dispatch = useDispatch()
    const accountId = useSelector(state => state.appData.account.id);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const role = useSelector(state => state.auth.user.roles);

    const handleDelete = () => {
        setDeleteLoader(true)
        deleteEvents(accountId, check)
            .then(() => {
                loadEvents();
                setDeleteModal(false);
                setCheck([])
                dispatch(
                    AlertState.actions.alert({
                        text: 'The events has been successfully deleted',
                        variant: true,
                    })
                );
            })
            .finally(() => setDeleteLoader(false))
    };

    const completeEvent = () => {
        setLoader(true)
        completeEvents(accountId, check)
            .then(() => {
                dispatch(
                    AlertState.actions.alert({
                        text: 'The events has been successfully completed',
                        variant: true,
                    })
                );
            })
            .catch(err => {
                let errText = catchErrorMessage(err);
                dispatch(
                    AlertState.actions.alert({
                        text: errText,
                        variant: false,
                    })
                );
            })
            .finally(() => setLoader(false))
    };

    return (
        <div className='agenda-tab'>
            <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                handleConfirm={handleDelete}
                title={`Are you sure you want to delete event${check.length > 1 ? 's' : ''}?`}
                submitText="Confirm"
                loader={deleteLoader}
            />

            {check.length > 0 && (
                <>
                    {role !== 'client' && <Button
                        variant="secondary"
                        onClick={completeEvent}
                        style={{ marginLeft: 15 }}
                        className='forms-list__filter-button'
                    >
                        Complete
                    </Button>}


                    {check.length === 1 && (
                        <>
                            <Button
                                variant="secondary"
                                onClick={() => {
                                    setSelectedEventId(check[0]);
                                    setCreateModal(true);
                                }}
                                style={{ marginLeft: 15 }}
                            >{role === 'client' ? 'View' : 'Edit'}</Button>
                        </>
                    )}

                    {role !== 'client' && <Button
                        variant="secondary"
                        onClick={() => setDeleteModal(true)}
                        style={{ marginLeft: 15 }}
                        className='forms-list__filter-button'
                    >
                        Delete
                    </Button>}
                </>

            )}

            <Table
                data={events}
                check={check}
                setCheck={setCheck}
                sort={sort}
                setSort={setSort}
            />
        </div>
    )
};
