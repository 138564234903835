import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL_V2;

export const fetchCRMList = (accountId) => {
  return axios.get(`${BASE_URL}accounts/${accountId}/crm`);
};

export const fetchCRMSingle = (accountId, crmId) => {
  return axios.get(`${BASE_URL}accounts/${accountId}/crm/${crmId}`);
};

export const postActivateMindBody = (
  accountId,
  locations_count,
  site_id,
  login,
  password
) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/crm/mindbody/connect`, {
    locations_count,
    site_id,
    login,
    password,
  });
};

export const postActivateMotionSoft = (accountId, customer_id) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/crm/motionsoft/connect`, {
    customer_id,
  });
};

export const postActivateABCFitness = (accountId, club_numbers) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/abc-fitness/connect`,
    {
      club_numbers,
    }
  );
};

export const postActivateClubReady = (accountId, store_ids, chain_id) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/crm/club-ready/connect`, {
    store_ids: store_ids,
    chain_id: chain_id,
  });
};

export const postActivatHapana = (accountId, data) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/hapana/connect`,
    data
  );
};

export const postActivateClubAutomation = (accountId, customer_id, club_numbers) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/club-automation/connect`,
    {
      customer_id: customer_id, locations: club_numbers
    }
  );
};

export const checkActivation = (accountId, crmId) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/crm/${crmId}/activate`);
};

export const postDeactivateCRM = (accountId, crmId) => {
  return axios.post(`${BASE_URL}accounts/${accountId}/crm/${crmId}/deactivate`);
};

export const fetchCRMStrategy = (accountId, crmId) => {
  return axios.get(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/settings/deletion`
  );
};

export const postCRMStrategy = (accountId, crmId, data) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/settings/deletion`,
    { ...data }
  );
};

export const fetchActivity = (accountId, crmId, params) => {
  const parameters = { ...params };
  if (parameters.date_start === "  /  /    ") parameters.date_start = "";
  if (parameters.date_end === "  /  /    ") parameters.date_end = "";

  return axios.get(`${BASE_URL}accounts/${accountId}/crm/${crmId}/logs`, {
    params,
  });
};

export const fetchLogsSingle = (accountId, crmId, logId) => {
  return axios.get(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/logs/${logId}`
  );
};

export const fetchMappings = (accountId, crmId, module, params) => {
  return axios.get(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/mappings/${module}`,
    { params }
  );
};

export const fetchExternals = (accountId, crmId, module, params) => {
  return axios.get(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/externals/${module}`,
    { params }
  );
};

export const postAddMapping = (accountId, crmId, module, data) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/mappings/${module}`,
    data
  );
};

export const fetchCRMSync = (accountId, crmId, module) => {
  return axios.get(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/settings/${module}/sync`
  );
};

export const postSync = (accountId, crmId, module, data) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/settings/${module}/sync`,
    data
  );
};

export const fetchCRMFields = (accountId, crmId, module) => {
  return axios.get(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/settings/${module}/fields`
  );
};

export const postCRMFields = (accountId, crmId, module, arr) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/settings/${module}/fields`,
    arr
  );
};

export const postRefresh = (accountId, crmId, module) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/mappings/${module}/refresh`
  );
};

// depricated
// export const updateMappings = module => {
//     return axios.post(`${BASE_URL}crm/update/${module}`)
// };

export const deleteMapping = (accountId, crmId, module, mappingID) => {
  return axios.delete(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/mappings/${module}/${mappingID}`
  );
};

export const postRefreshSingle = (accountId, crmId, module, id) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/mappings/${module}/refresh/${id}`
  );
};

export const getFields = (accountId, crmId, module, id) => {
  return axios.get(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/externals/${module}/${id}`
  );
};

export const saveMapping = (accountId, crmId, module, data) => {
  return axios.post(
    `${BASE_URL}accounts/${accountId}/crm/${crmId}/mappings/${module}`,
    { ...data }
  );
};

export const getEvents = (accountId, params) => {
  return axios.get(`${BASE_URL}accounts/${accountId}`, { params });
};
