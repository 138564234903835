import React from "react";
import { SignatureImageAreaWrapper } from "../SignatureModal.styles";
import { ImgComponent } from "./ImgComponent";
import { Cancel } from "@material-ui/icons";

export const SignatureImageArea = ({ path, onDelete }) => {
  return (
    <SignatureImageAreaWrapper>
      <div className="delete" onClick={onDelete}>
        <Cancel />
      </div>
      <ImgComponent src={path} alt={"signature-image"} />
    </SignatureImageAreaWrapper>
  );
};
