import React, { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";

export default function MultipleSelectCheckmarksV2({ options, setSelected, label, width, limitTags }) {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const allSelected = options.length === selectedOptions.length;

    const handleToggleOption = selectedOptions => setSelectedOptions(selectedOptions);

    const handleClearOptions = () => {
        setSelectedOptions([]);
        setSelected([]);
    };

    const getOptionLabel = option => `${option.title}`;

    const handleSelectAll = isSelected => {
        if (isSelected) {
            let result = [];
            result = options.filter(el => el.title !== "Select All");
            setSelectedOptions(options);
            setSelected(result);
        } else {
            handleClearOptions();
        }
    };

    const handleToggleSelectAll = () => {
        handleSelectAll && handleSelectAll(!allSelected);
    };

    const handleChange = (event, selectedOptions, reason) => {
        if (reason === "select-option" || reason === "remove-option") {
            if (selectedOptions.find(option => option.title === "Select All")) {
                handleToggleSelectAll();
                return;
            } else {
                handleToggleOption && handleToggleOption(selectedOptions);
                return setSelected(selectedOptions);
            }
        } else if (reason === "clear") {
            handleClearOptions && handleClearOptions();
        }
    };

    const optionRenderer = (option, { selected }) => {
        const selectAllProps =
            option.title === "Select All"
                ? { checked: allSelected }
                : {};
        return (
            <>
                <Checkbox
                    color="primary"
                    style={{ marginRight: 8 }}
                    checked={selected}
                    {...selectAllProps}
                />
                {getOptionLabel(option)}
            </>
        );
    };

    const inputRenderer = params => (
        <TextField
            {...params}
            label={label}
            placeholder={'Select CRM Profiles'}
            variant='outlined'
        />
    );

    const getOptionSelected = (option, anotherOption) => option.id === anotherOption.id;

    const filter = createFilterOptions();

    return (
        <Autocomplete
            style={{ width: width || 250 }}
            multiple
            limitTags={1}
            size="small"
            options={options}
            value={selectedOptions}
            disableCloseOnSelect
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            noOptionsText={'No options'}
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (!filtered.length) return [];
                if (params.inputValue) {
                    return filtered;
                } else {
                    return [{ title: 'Select All' }, ...filtered];
                }
            }}
            onChange={handleChange}
            renderOption={optionRenderer}
            renderInput={inputRenderer}
        />
    );
};
