import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon
} from '@material-ui/core'
import * as media from '../../../crud/media.crud'

const ImgComponent = ({ src, alt, className, style }) => {
    const [url, setUrl] = useState('')

    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src])
    return url && <img src={url} alt={alt} className={className} style={style} />
}

export default ({ data }) => {
    return (
        <ModalProgram>
            <div className="row img-row">
                {data.images.map(item => {
                    return (
                        <div className="col-6 img-wrap" key={item}>
                            <ImgComponent src={item} alt="program" className="img" />
                        </div>
                    )
                })}
            </div>
            <div className="row">
                <div className="col-6">
                    <Typography variant="h5" className='title'>
                        Target Area:
                    </Typography>
                    <List dense={false}>
                        {
                            data.target_area.map(item => {
                                return (
                                    <ListItem key={item}>
                                        <ListItemText
                                            primary={item}
                                        />
                                    </ListItem>
                                )
                            })
                        }

                    </List>
                </div>
                <div className="col-6">
                    <Typography variant="h5" className='title'>
                        Equipment:
                    </Typography>
                    <List dense={false}>
                        {
                            data.equipment.map(item => {
                                return (
                                    <ListItem key={item}>
                                        <ListItemText
                                            primary={item}
                                        />
                                    </ListItem>
                                )
                            })
                        }

                    </List>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Typography variant="h4" gutterBottom>
                        General Guidelines
                    </Typography>
                    <Typography variant="h6" gutterBottom>
                        Definition
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                        {data.definition}
                    </Typography>
                    <Typography variant="h5" className='title'>
                        Tips:
                    </Typography>
                    <List dense={false}>
                        {
                            data.tips.map(item => {
                                return (
                                    <ListItem key={item}>
                                        <ListItemIcon>
                                            {/* <DotIcon /> */}
                                            <div style={{ height: 6, width: 6, borderRadius: '100%', backgroundColor: '#646c9a' }} />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item}
                                        />
                                    </ListItem>
                                )
                            })
                        }

                    </List>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Typography variant="h4" gutterBottom>
                        General Guidelines
                    </Typography>
                </div>
                {
                    data.steps.map((item, index) => {
                        return (
                            <div className="col-6" key={index}>
                                <Typography variant="h6" gutterBottom>
                                    Step {index + 1}
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    {item}
                                </Typography>
                            </div>
                        )
                    })
                }
            </div>
        </ModalProgram>
    )
}

const ModalProgram = styled.div`
    .img {
        width: 100%;
        height: 300px;
        object-fit: contain;
    }

    .row {
        margin-bottom: 20px;

        .MuiListItemIcon-root {
            min-width: 20px;
        }
        
        .MuiTypography-gutterBottom {
            margin-bottom: 10px;
        }

        .MuiListItem-gutters {
            padding: 0 15px;
        }
    }
`;
