import React from 'react';
import { CreateEditWorkout } from '../../Workouts/CreateEditWorkout/CreateEditWorkout';
import DataOutput from '../../../../components/DataOutput'
import UserInfo from '../../../../components/UserInfo'

export const EditCreateWorkout = ({ match }) => {
    const id = match.params.profileId

    return (
        <>
            <div className="row flex-md-row-reverse" style={{ marginBottom: '20px' }}>
                <DataOutput id={id} />
                <UserInfo id={id} />
            </div>
            <CreateEditWorkout match={match} />
        </>
    )
};
