import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch } from "react-redux"
import * as AlertState from '../../store/ducks/auth.duck'
import { Redirect } from 'react-router-dom'
import DataOutput from '../../components/DataOutput'
import UserInfo from '../../components/UserInfo'
import DropZone from '../../components/DropZone'
import {
    Button,
    ButtonToolbar,
    DropdownButton,
    Dropdown,
} from 'react-bootstrap'
import {
    Portlet,
    PortletBody,
    PortletHeader
} from "../../partials/content/Portlet";
import Loader from "../../components/Loader";
import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    TextField,
} from '@material-ui/core';
import ModalBody from './TestingTabs/UpdateModal';
import TestingInput from './TestingTabs/TestingInput';
import {
    getImg,
    getProfile,
    getAssessmentList,
    getTestingFields,
    addTesting,
    getProfileValid,
} from '../../crud/profile.crud';
import { setFile } from '../../crud/file.crud';
import {
    getUserPrivileges
} from '../../crud/info.crud'
import Logout from '../auth/Logout'
import DatePicker from '../../components/DatePicker'
import moment from 'moment'
import * as media from '../../crud/media.crud'

const ImgComponent = ({ src, alt, className }) => {
    const [url, setUrl] = useState('')

    useEffect(() => {
        src && media.getImg(src)
            .then(res => setUrl(res))
            .catch(err => setUrl(err))
    }, [src])
    return url && <img src={url} alt={alt} className={className} />
}

export default (props) => {
    document.title = 'TrainerMetrics - Testing'
    const id = props.match.params.id
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false)
    const [logout, setLogout] = useState(null)
    const [assessment, setAssessment] = useState('0')
    const [assessmentKey, setAssessmentKey] = useState(0)
    const [assessmentList, setAssessmentList] = useState([])
    const [inputs, setInputs] = useState([])
    const [testting, setTesting] = useState([])
    const [loaderAssessmentList, setLoaderAssessmentList] = useState(false)
    const [loaderTestingFields, setLoaderTestingFields] = useState(false)
    const [loaderAddTesting, setLoaderAddTesting] = useState(false)
    const [loaderDate, setLoaderDate] = useState(false)
    const [errors, setErrors] = useState({})
    const [isLoadingPhoto, setIsLoadingPhoto] = useState(false);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        getProfileValid(id)
            .then(res => {
                setRedirect(!res.data.is_valid);
                if (!res.data.is_valid) {
                    dispatch(AlertState.actions.alert({
                        text: 'Please fill out the required fields below to continue',
                        variant: false
                    }));
                }
            })
    }, [id]);

    useEffect(() => {
        setLoaderAssessmentList(true)
        getAssessmentList(id)
            .then(res => {
                setLoaderAssessmentList(false)
                setAssessmentList(res.data.data)
            })
            .catch(({ response }) => {
                setLoaderAssessmentList(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })
    }, [id, modal])

    useEffect(() => {
        setAssessment(assessmentKey)
    }, [assessmentKey])

    useEffect(() => {
        loadData()
        // eslint-disable-next-line
    }, [assessment, modal, assessmentList])

    const loadData = () => {
        if (!!assessmentList[parseInt(assessment)]) {
            setLoaderTestingFields(true)
            getTestingFields(id, { assessment: assessmentList[parseInt(assessment)].name })
                .then(res => {
                    setLoaderTestingFields(false)
                    setInputs(res.data.data.inputs)
                    setTesting(res.data.data.another)
                })
                .catch(({ response }) => {
                    setLoaderTestingFields(false)
                    if (response && (response.status === 401)) {
                        setLogout(<Logout />)
                    } else if (response && (response.status === 403)) {
                        setLogout(<Redirect to="/profile-list" />)
                    }
                });
        };
    };

    const [data, setData] = useState({
        testDate: moment().format('MM/DD/YYYY')
    });

    const getData = (name, category) => {
        return !!category
            ? !!data[category]
                ? !!data[category][name]
                    ? data[category][name]
                    : ''
                : !!data[name]
                    ? data[name]
                    : ''
            : !!data[name]
                ? data[name]
                : ''
    };

    const editData = (value, name, category) => {
        let temp = { ...data };

        temp = !category
            ? {
                ...temp,
                [name]: value
            }
            : {
                ...temp,
                [category]: {
                    ...temp[category],
                    [name]: value
                }
            }

        setData(temp)
    }

    const checkErrors = () => Object.values(errors).findIndex(item => !!item === true) === -1

    const submit = (status) => {
        if (checkErrors()) {
            if (pathPhoto) addPhoto();

            let temp = { ...data }
            for (let key in temp) {
                for (let key2 in temp[key]) {
                    if ((temp[key][key2] === '') || (temp[key][key2] === '00:00:00')) delete temp[key][key2]
                }
                if (JSON.stringify(temp[key]) === '{}') delete temp[key]
            }
            setLoaderAddTesting(true)
            addTesting(id, temp)
                .then(res => {
                    setLoaderAddTesting(false)
                    dispatch(AlertState.actions.refreshStatistic())
                    setData({
                        testDate: moment().format('MM/DD/YYYY')
                    })
                    dispatch(AlertState.actions.alert({
                        text: 'Testing data is added',
                        variant: true
                    }));
                    status && setLogout(<Redirect to={`/profile/${id}/goals`} />)
                })
                .catch(({ response }) => {
                    setLoaderAddTesting(false)
                    if (response &&
                        response.data) {
                        dispatch(AlertState.actions.alert({
                            text: response.data.message || response.data.error,
                            variant: false
                        }));
                        response.data.errors && Object.keys(response.data.errors).map(item => { // eslint-disable-line
                            if (item !== 'basicData') setErrors({ ...errors, [item]: response.data.errors[item][0] })
                        })
                    }
                    if (response && (response.status === 401)) {
                        setLogout(<Logout />)
                    } else if (response && (response.status === 403)) {
                        setLogout(<Redirect to="/profile-list" />)
                    }
                })
        } else {
            const er = Object.keys(errors)
            er[0] && document.getElementById(er[0]) && document.getElementById(er[0]).scrollIntoView()
            window.scrollBy({
                top: -100
            });
        }
    }

    const [access, setAccess] = useState({
        create: false,
        activate: false,
        archive: false,
        delete: false,
        access: false,
        updateTesting: false
    });
    const [archived, setArchived] = useState(false);
    const [pathPhoto, setPathPhoto] = useState('');

    useEffect(() => {
        getUserPrivileges()
            .then(res => {
                saveAccess(res.data.data.permissions)
            })
            .catch(({ response }) => {
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            });

        getProfile(id)
            .then((res) => {
                setArchived(res.data.data.status_id === 3)
            })
    }, [id])

    const saveAccess = arr => {
        const status = name => arr.findIndex(item => item === name) !== -1

        setAccess({
            create: status('create profiles'),
            activate: status('activate profiles'),
            archive: status('archive profiles'),
            delete: status('delete profiles'),
            access: status('access profiles'),
            updateTesting: status('update testing metrics')
        })
    };

    const loadPhoto = () => {
        getImg(id)
            .then(res => {
                const sort = res.data.data.map((item, index) => { return { ...item, path: item.path } })
                setData(sort)
            })
            .catch(({ response }) => {
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })
    };

    const addPhoto = () => {
        const fileData = {
            file: {
                file_data: {
                    id: pathPhoto.id,
                    name: pathPhoto.file_name || Math.random().toString(),
                },
                test_date: data.testDate,
                format: pathPhoto.extension,
            },
        }

        setFile(id, fileData.file, 1)
            .then(() => {
                loadPhoto();
                setPathPhoto('')
                dispatch(AlertState.actions.alert({
                    text: 'Photo is added',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.message || response.data.error,
                        variant: false
                    }));
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })
    };

    const getProgressPhoto = item => {
        if (item.field_list.find(elem => elem.name === 'progress_photo')) {
            return (
                <div className="col-12 col-md-6 col-xl-4">
                    <Portlet>
                        <PortletHeader title={'PROGRESS PHOTO'} />
                        <PortletBody style={{ marginTop: '17px' }}>
                            <DropZone
                                submit={setPathPhoto}
                                setIsLoading={setIsLoadingPhoto}
                            />
                        </PortletBody>
                    </Portlet>
                </div>
            );
        };
    };

    const handleSubmit = () => {
        if (pathPhoto) addPhoto();
        setTimeout(() => {
            submit(true);
        }, 2000);
    };

    const getContent = () => <>
        <DataPickerRow
            setModal={setModal}
            editData={editData}
            data={data}
            id={id}
            setLogout={setLogout}
            setLoader={setLoaderDate}
            assessment={!!assessmentList[parseInt(assessment)] ? assessmentList[parseInt(assessment)].name : ''}
            access={access}
        />

        <div className="row" style={{ width: '100%' }}>
            {inputs && inputs.map(item => {
                if (item.group_title === "PROGRESS PHOTO") {
                    return getProgressPhoto(item)
                } else {
                    return (
                        <div className="col-12 col-md-6 col-xl-4" key={item.group_title}>
                            <Portlet>
                                <PortletHeader title={item.group_title} />
                                <PortletBody>
                                    {item.field_list.map((elem, index) => {
                                        if (elem.name === 'progress_photo') return null;
                                        return (
                                            <>
                                                <TestingInput
                                                    disabled={archived}
                                                    key={`${elem.name}-${index}`}
                                                    id={elem.category + '.' + elem.name}
                                                    elem={elem}
                                                    getData={getData}
                                                    editData={editData}
                                                    errors={errors}
                                                    setErrors={setErrors}
                                                />
                                            </>
                                        )
                                    })}
                                </PortletBody>
                            </Portlet>
                        </div>
                    )
                }
            })}
        </div>

        {testting && testting.map(parent => {
            return (
                <Portlet key={parent.group_title} style={{ width: '100%' }}>
                    <PortletHeader title={parent.group_title} />
                    <PortletBody>
                        <div className="row settingtable" style={{ width: '100%' }}>
                            {parent.subcategory
                                ? parent.groups.map(item => {
                                    return (
                                        <div className="col-12 col-lg-6 col-xl-4" key={item.subtitle || item.group_title}>
                                            <PortletHeader title={item.subtitle || item.group_title} />
                                            <div className="img-wrap">
                                                <ImgComponent src={item.img} alt="test-img" />
                                            </div>
                                            {item.fields.map(input => {
                                                return (
                                                    <FormGroup row key={input.name}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={archived}
                                                                    value={`${getData(input.name, input.category)}` === '1' ? 0 : 1}
                                                                    onChange={e => editData(e.target.value, input.name, input.category)}
                                                                    checked={`${getData(input.name, input.category)}` === '1'}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={input.label}
                                                        />
                                                    </FormGroup>
                                                )
                                            })}
                                        </div>
                                    )
                                })
                                : parent.groups && parent.groups.map((item, index) => {
                                    return (
                                        <div className="col-12 col-lg-6 col-xl-4" key={index}>
                                            <PortletHeader title={item.subtitle || item.group_title} />
                                            <div className="img-wrap">
                                                <ImgComponent src={item.img} alt="test-img" />
                                            </div>
                                            <FormGroup row>
                                                <RadioGroup
                                                    aria-label="Gender"
                                                    name="gender1"
                                                    className='group'
                                                >
                                                    <FormControlLabel value="female" control={
                                                        <>
                                                            {item.fields.map((fields, index) => {
                                                                return fields.type === 'radio' &&
                                                                    <div className="test-label" key={index}>
                                                                        {fields.label}
                                                                    </div>
                                                            })}
                                                        </>
                                                    } label="" />
                                                </RadioGroup>
                                            </FormGroup>
                                            {item.fields[0].desc.map((input, index) => {
                                                return (
                                                    <FormGroup row key={input + index} style={{ width: '100%' }}>
                                                        <RadioGroup
                                                            aria-label="Gender"
                                                            name="gender1"
                                                            className='group'
                                                        >
                                                            <FormControlLabel value="female" control={
                                                                <>
                                                                    {item.fields.map(fields => {
                                                                        return fields.type === 'radio' &&
                                                                            <Radio
                                                                                disabled={archived}
                                                                                checked={`${getData(fields.name, fields.category)}` === `${fields.values[index]}`}
                                                                                onChange={e => editData(e.target.value, fields.name, fields.category)}
                                                                                color="primary"
                                                                                value={fields.values[index]}
                                                                                name={fields.name}
                                                                                key={fields.name}
                                                                            />
                                                                    })}
                                                                </>
                                                            } label={input} />
                                                        </RadioGroup>
                                                    </FormGroup>
                                                )
                                            })}
                                            {item.fields.map(fields => {
                                                return fields.type === 'checkbox' &&
                                                    <FormGroup row key={fields.name}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={archived}
                                                                    value={`${getData(fields.name, fields.category)}` === '1' ? 0 : 1}
                                                                    onChange={e => editData(e.target.value, fields.name, fields.category)}
                                                                    checked={`${getData(fields.name, fields.category)}` === '1'}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={fields.label}
                                                        />
                                                    </FormGroup>
                                            })}

                                            {item.another && item.another.fields.map((field, index) => {
                                                return (
                                                    <>
                                                        {field.type === 'checkbox'
                                                            ? (
                                                                <div key={index}>
                                                                    {field.title && <h4>{field.title}</h4>}
                                                                    {field.subtitle && <h5>{field.subtitle}</h5>}
                                                                    <FormGroup row key={field.name}>
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox
                                                                                    checked={data[field.category] && `${data[field.category][field.name]}` === `${1}`}
                                                                                    onChange={e => {
                                                                                        setData({
                                                                                            ...data,
                                                                                            [field.category]: {
                                                                                                ...data[field.category],
                                                                                                [field.name]: e.target.value
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                    value={data[field.category] && `${data[field.category][field.name]}` === `${1}` ? 0 : 1}
                                                                                    // value={`${data[field.name]}` === `${1}` ? 0 : 1}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={field.label}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                            )
                                                            : (
                                                                <TextField
                                                                    variant="outlined"
                                                                    key={index}
                                                                    type="text"
                                                                    className='textField'
                                                                    name={field.name}
                                                                    label={field.label}
                                                                    value={data[field.category] ? data[field.category][field.name] : ''}
                                                                    onChange={e => {
                                                                        setData({
                                                                            ...data,
                                                                            [field.category]: {
                                                                                ...data[field.category],
                                                                                [field.name]: e.target.value
                                                                            }
                                                                        })
                                                                        // if (!e.target.value.length) {
                                                                        //     setErrors({
                                                                        //         ...errors,
                                                                        //         [field.name]: 'Field is required'
                                                                        //     })
                                                                        // } else {
                                                                        //     setErrors({
                                                                        //         ...errors,
                                                                        //         [field.name]: ''
                                                                        //     })
                                                                        // }
                                                                    }}
                                                                    margin="normal"
                                                                    // error={Boolean(errors[field.name])}
                                                                    helperText={errors[field.name]}
                                                                />
                                                            )}
                                                    </>
                                                )
                                            })}
                                        </div>
                                    )
                                })}
                        </div>
                    </PortletBody>
                </Portlet>
            )
        })
        }<ButtonToolbar style={{ width: '100%' }}>
            <Button
                disabled={archived}
                variant="light"
                style={{ marginRight: 15, border: '1px solid #282a3c' }}
                onClick={() => submit(false)}
            >
                Save and Refresh
            </Button>
            <Button
                disabled={archived || isLoadingPhoto}
                className="btn-blue"
                variant="primary"
                onClick={handleSubmit}
            >
                Save and Continue
            </Button>
        </ButtonToolbar>
    </>

    return (
        <StyledTesting>
            {redirect && <Redirect to={`/profile/${id}/info`} />}

            <div className="row flex-md-row-reverse" style={{ marginBottom: '20px' }}>
                <DataOutput id={id} />
                <UserInfo id={id} />
            </div>
            <Loader visible={loaderAssessmentList || loaderTestingFields || loaderAddTesting || loaderDate} />
            {logout}

            <ModalBody
                modal={modal}
                setModal={setModal}
                assessment={assessmentList[parseInt(assessment)]}
                assessmentList={assessmentList}
                id={id}
            />

            <Portlet>
                <PortletBody>
                    <div className="row acord-none-mob">
                        <Tabs
                            value={assessmentKey}
                            onChange={(e, value) => setAssessmentKey(value)}
                            indicatorColor="primary"
                            textColor="primary"
                            scrollButtons="auto"
                            variant="scrollable"
                        >
                            {assessmentList.map((item, index) => (
                                <Tab label={item.title} key={index} />
                            ))}
                        </Tabs>
                    </div>

                    <div className="row acord-none-mob" style={{ flexDirection: '', width: '100%' }}>
                        {getContent()}
                    </div>

                    {assessmentList.map((item, index) => {
                        return (
                            <div className="row acord-none-dt" key={item.name} style={{ width: '100%' }}>
                                <div className="acord-title" onClick={() => setAssessmentKey(assessmentKey === index ? false : index)}>
                                    {item.title}
                                </div>
                                {assessmentKey === index && getContent()}
                            </div>
                        )
                    })}
                </PortletBody>
            </Portlet>
        </StyledTesting>
    )
}

const DataPickerRow = (props) => {
    // const [assesseemnt, setAssessment] = useState()

    // useEffect(() => {
    //     props.assessment && props.setLoader(true)
    //     props.assessment && getTestingReport(props.id, props.assessment)
    //         .then(res => {
    //             setAssessment(res.data.data.map(item => item.name).join(','))
    //             props.setLoader(false)
    //         })
    //         .catch(() => {
    //             props.setLoader(false)
    //         })
    //     // eslint-disable-next-line
    // }, [props.assessment])

    const getPdfProgress = () => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.target = '_blank';
        a.href = `/report/${props.id}/accessenent/${props.assessment}`;
        document.body.appendChild(a);
        a.click();
    }

    const getPdfTesting = () => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.target = '_blank';
        a.href = `/report/${props.id}/card/${props.assessment}`;
        document.body.appendChild(a);
        a.click();
    }


    return (
        <StyledDPR>
            <div className="left-side">
                {props.access.updateTesting && <Button className="btn-diagram" style={{ marginRight: 15 }} variant="primary" onClick={e => { props.setModal(true) }}>Update Metrics</Button>}
                <DropdownButton id="dropdown-item-button" title="Print" className="btn-diagram" >
                    <Dropdown.Item onClick={getPdfProgress}>
                        Progress Reports
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={getPdfTesting}
                    >Testing Card</Dropdown.Item>
                </DropdownButton>
            </div>
            <div className="right-side">

                <DatePicker
                    label="Test Date"
                    format={'MM/DD/YYYY'}
                    value={props.data.testDate}
                    onChange={(value) => {
                        props.editData(value, 'testDate')
                    }}
                />
            </div>
        </StyledDPR>
    )
}

const StyledDPR = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;

    .left-side {
        display: flex;
    }
`

const StyledTesting = styled.div`

    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;
    }

    /* .nav.nav-tabs {
        margin: 0;
        font-size: 14px;
        font-weight: 450;
    } */

    .img-wrap {
        width: 100%;
        max-height: 250px;
        margin: 20px 0;

        img {
            width: 100%;
            max-width: 250px;
            height: 100%;
            object-fit: contain;
        }
    }

    .test-label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 37.5px;
        font-size: 14px;
        font-weight: 500;
    }

    .col-4 {
        margin-bottom: 15px;
    }

`