import { useEffect, useState } from "react";
import moment from "moment";

import {
  getOpportunitiesList,
  getOpportunitiesTotal,
} from "../../../../crud/opportunities.crud";
import useDebounce from "../../../../hooks/useDebounce";
import { sendDataToBackend } from "../helpers/transfromDateFilters";

const initPagination = {
  current_page: 1,
  last_page: 1,
  per_page: 10,
  total: 0,
  from: 0,
  to: 10,
};

const tableDTO = (data) => {
  return data.map((item) => ({ values: item }));
};

export const useForecastOpportuninties = ({
  initialSort = "",
  startDate,
  endDate,
  staffIds,
  inintialGroup,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(initialSort);

  const [groups, setGroups] = useState(inintialGroup);
  const [staff, setStaff] = useState(staffIds);

  const [pagination, setPagination] = useState(initPagination);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      try {
        const params = {
          start_date: start,
          end_date: end,
          page,
          per_page: perPage,
          group: groups,
          staff,
          sort_field: sort?.[0],
          sort_direction: sort?.[1],
        };
        const res = await getOpportunitiesList(params);

        setData(tableDTO(res.data.data));

        setPagination(res.data.meta);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    if (staff?.length) fetch();
  }, [groups, staff, start, end, page, perPage, sort]);

  return {
    loading,
    error,
    data,

    setSort,
    sort,
    groups,
    setGroups,
    staff,
    setStaff,
    pagination,
    setPagination,
    page,
    perPage,
    setPage,
    setPerPage,
    setData,
    setStart,
    setEnd,
  };
};
