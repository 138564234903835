import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import * as AlertState from "../../app/store/ducks/auth.duck";
import DropZone from "react-dropzone-uploader";
import Cancel from "@material-ui/icons/Cancel";
import { uploadImg } from "../crud/media.crud";

export default ({
  submit,
  onDelete,
  Content,
  setIsLoading,
  initialFile,
  disabled,
}) => {
  const [file, setFile] = useState(false);

  useEffect(() => {
    if (initialFile) setFile(initialFile);
  }, [initialFile]);

  const dispatch = useDispatch();

  const handleChangeStatus = (file, status, meta) => {
    if (status === "error_file_size") {
      file.remove();
      setFile(null);
      file = null;
    }
    if (status === "done") {
      load(file.file, meta);
      setFile(file);
    }
    if (status === "removed") {
      onDelete && onDelete();
    }
    if (status === "error_validation") {
      file.remove();
      setFile(null);
      file = null;
    }
    if (status === "rejected_file_type") {
      dispatch(
        AlertState.actions.alert({
          text: "File type should be jpg, jpeg, or png",
          variant: false,
        })
      );
    }
  };

  const load = (file, meta) => {
    let formData = new FormData();
    formData.append("file", file);
    if (setIsLoading) setIsLoading(true);
    uploadImg(formData)
      .then((res) => {
        submit(res.data.data);
      })
      .then(() => {
        if (setIsLoading) setIsLoading(false);
      })
      .finally(() => {
        if (setIsLoading) setIsLoading(false);
      });
  };

  const removeFile = () => {
    onDelete && onDelete();
    file && file.remove();
    setFile(false);
    submit("");
  };

  const validate = (file) => {
    const {
      meta: { height, size, width },
    } = file;
    if (size > 6291456) {
      dispatch(
        AlertState.actions.alert({
          text: "File size should not exceed 6MB",
          variant: false,
        })
      );
      return true;
    } else if (height < 100 || width < 100) {
      dispatch(
        AlertState.actions.alert({
          text: "Photo dimension should be at least 100x100 pixels",
          variant: false,
        })
      );
      return true;
    }
    return false;
  };

  return (
    <StyledDropZone>
      <DropZone
        disabled={disabled}
        onChangeStatus={handleChangeStatus}
        maxFiles={1}
        accept=".jpg,.jpeg,.png"
        validate={validate}
        inputContent={() => (
          <div
            key="wrap"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {Content && Content()}
            <span key="default">Drag Files or Click to Browse</span>
          </div>
        )}
      />
      <span
        style={{
          fontWeight: "10px",
        }}
      >
        Max image size: 6MB
      </span>

      {!!file ? (
        <div className="delete" onClick={removeFile}>
          <Cancel />
        </div>
      ) : null}
    </StyledDropZone>
  );
};

const StyledDropZone = styled.div`
  position: relative;

  .delete {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .dzu-dropzone {
    border: 1px dashed grey;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: 90px;
    overflow: hidden;

    .dzu-inputLabel {
      margin: 10px;

      .dzu-input {
        display: none;
      }
    }

    .dzu-previewContainer {
      height: 100%;
      justify-content: center;
      padding: 0;
      border-bottom: none;

      img {
        height: 100%;
        max-height: 200px;
        width: 100%;
        max-width: none;
        object-fit: contain;
      }

      .dzu-previewStatusContainer {
        display: none;
      }
    }
  }
`;
