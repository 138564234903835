import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import styled from "styled-components";
import DatePicker from "../../../../../components/DatePicker";
import Loader from "../../../../../components/Loader";
import * as Yup from "yup";

export const FIELDS_TYPE = {
  INPUT: 1,
  AREA: 2,
  DATE_PICKER: 3,
};

export const EditFieldModal = ({
  open,
  onSave,
  onClose,
  title,
  value,
  type,
  loading,
}) => {
  const [state, setState] = useState(value);
  const [disable, setDisable] = useState(false);
  const [error, setError] = useState(null);

  const isAnotherField = ["Sales Goal New", "Sales Goal Resign"].includes(
    title
  );

  const sizeLimit = isAnotherField ? 999999 : 999;

  const validationInputSchema = Yup.number()
    .nullable()
    .min(1, "Value must be at least 1")
    .max(sizeLimit, `Value must be at most ${sizeLimit}`)
    .typeError("Value must be a number")
    .required("Value is required");

  const validationAreaSchema = Yup.string().max(
    512,
    "Text must be at most 512 characters"
  );

  const schema = {
    [FIELDS_TYPE.INPUT]: validationInputSchema,
    [FIELDS_TYPE.AREA]: validationAreaSchema,
  };

  const handleSave = async () => {
    try {
      await schema[type].validate(state);
      onSave(state);
      setError(null);
      setDisable(false);
    } catch (error) {
      setError(error.errors[0]);
      setDisable(true);
    }
  };

  const validateValue = async () => {
    try {
      await schema[type].validate(state);
      setError(null);
      setDisable(false);
    } catch (error) {
      setError(error.errors[0]);
      setDisable(true);
    }
  };

  const handleBlur = () => {
    validateValue();
  };

  useEffect(() => {
    if (error) {
      validateValue();
    }
  }, [state]);

  return (
    <Modal show={open} onHide={onClose}>
      <Loader visible={loading} />
      <ModalInner>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="title">Edit {title}</h5>{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ModalBodyInner>
            {type === FIELDS_TYPE.INPUT && (
              <TextField
                name="title"
                style={{ width: "100%" }}
                variant="outlined"
                type="number"
                label={title}
                inputProps={{
                  maxLength: 3,
                }}
                value={state}
                onChange={(e) => setState(e.target.value)}
                onBlur={handleBlur}
                error={!!error}
                helperText={error}
              />
            )}

            {type === FIELDS_TYPE.AREA && (
              <TextField
                multiline={true}
                rows={4}
                variant="outlined"
                type="text"
                label={title}
                value={state}
                onChange={(e) => setState(e.target.value)}
                style={{ width: "100%" }}
                inputProps={{
                  maxLength: 512,
                }}
              />
            )}

            {type === FIELDS_TYPE.DATE_PICKER && (
              <DatePicker
                multiline={true}
                rows={4}
                variant="outlined"
                type="text"
                label={title}
                value={state}
                onChange={(e) => setState(e.target.value)}
                style={{ width: "100%" }}
              />
            )}
          </ModalBodyInner>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>

          <Button
            disabled={disable}
            variant="primary"
            className="btn-blue"
            onClick={handleSave}
          >
            Save
          </Button>
        </Modal.Footer>
      </ModalInner>
    </Modal>
  );
};

const ModalInner = styled.div`
  .email-form-title {
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0 20px 20px;
  }

  .modal-body {
    max-height: 300px;
    overflow-y: auto;
    padding-top: 0;
  }

  .photo-container {
    width: 45px;
    height: 45px;
    margin-right: 20px;
    background-color: #bdbdbd;
    border-radius: 50%;
  }

  .profile-photo {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%;
    font-size: 16px;
    text-transform: uppercase;
  }

  .profile-text {
    font-size: 16px;
  }
`;

const ModalBodyInner = styled.div`
  padding: 20px 0;
`;
