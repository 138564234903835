import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { PDFObject } from 'react-pdfobject'
import { getPDF } from '../../crud/library.crud';

export const ProgramPdf = ({ match }) => {
    const [pdf, setPdf] = useState()

    useEffect(() => {
        getPDF(match.params.id, 'programs')
            .then(res => {
                setPdf(res.data.data.path);
            })
    }, []);

    return <PDFwrap>
        {pdf
            ? <PDFObject url={pdf} />
            : <div className="loaderpdf">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        }
    </PDFwrap>
}

const PDFwrap = styled.div`
    height: 100%;

    .loaderpdf {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #pdfobject {
        height: 100%;
    }
`;
