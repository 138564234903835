export const TYPE = {
  TYPE: 1,
  DRAW: 2,
  UPLOAD: 3,
};

export const TYPE_TITLE = {
  1: "Type",
  2: "Draw",
  3: "Upload",
};
