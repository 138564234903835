import React from "react";
import { SignatureUploadWrapper } from "../SignatureModal.styles";
import DropZone from "../../DropZone";

export const SignatureUpload = ({ setIsPhotoLoading, setPhoto, photo }) => {
  return (
    <SignatureUploadWrapper>
      <DropZone
        setIsLoading={setIsPhotoLoading}
        submit={(e) => {
          setPhoto(e);
        }}
        photo={photo}
        onDelete={() => {
          setPhoto(null);
        }}
        Content={() => (
          <div
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "#bdbdbd",
              color: "#fafafa",
              fontSize: "28px",
              fontWeight: "700",
            }}
          >
            {/* {values.first_name && values.first_name[0].toUpperCase()}
            {values.last_name && values.last_name[0].toUpperCase()} */}
          </div>
        )}
      />
    </SignatureUploadWrapper>
  );
};
