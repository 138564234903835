import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { SpacerHorizontal } from '../../../../components/Spacer';
import ModalDeactivateCRM from './ModalDeactivateCRM';
import { fetchCRMSingle } from '../../../../crud/crm.crud';
import { useSelector } from 'react-redux';
import Loader from "../../../../components/Loader";

export const CRMConnections = ({ crm }) => {
    const [modal, setModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const accountId = useSelector(state => state.appData?.account?.id);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (crm && accountId) {
            setLoader(true);
            fetchCRMSingle(accountId, crm.id)
                .then(res => {
                    setData(res.data.single);
                })
                .finally(() => setLoader(false))
        }
    }, [crm, accountId]);

    return (
        <div className="crm-info">
            <Loader visible={loader} />

            <ModalDeactivateCRM
                modal={modal}
                setModal={setModal}
                crm={crm}
            />
            <div className="crm-info__row">
                <div className="crm-info__cell">CRM</div>
                <div className="crm-info__cell">{data?.title}</div>
            </div>
            <div className="crm-info__row">
                <div className="crm-info__cell">Number of Locations</div>
                <div className="crm-info__cell">{data?.locations_count}</div>
            </div>
            <div className="crm-info__row">
                <div className="crm-info__cell">Status</div>
                <div className="crm-info__cell">{data?.is_activated ? 'Active' : 'Not Active'}</div>
            </div>
            <div className="crm-info__row">
                <div className="crm-info__cell">Last Sync</div>
                <div className="crm-info__cell">
                    {data?.sync_at
                        ? moment(data.sync_at).format('MM/DD/YYYY hh:mm:ss a')
                        : 'sync has not happened yet'
                    }
                </div>
            </div>
            <SpacerHorizontal height="30px" />
            <Button
                variant="info"
                className="button"
                onClick={() => setModal(true)}
            >Deactivate</Button>
        </div>
    )
};
