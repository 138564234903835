import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Button, ButtonToolbar } from 'react-bootstrap';

export default ({ value, setValue, data }) => {
    const textarea = useRef();
    const [focused, setFocused] = useState();

    useEffect(() => {
        textarea.current.value = value || '';
    }, [value]);

    const changeTextarea = () => {
        setValue(textarea.current.value)
    };

    const getPlaceholder = () => {
        if (focused && data?.first_name && data?.last_name) {
            return `Add a note about ${data.first_name} ${data.last_name}`
        }
        return ''
    };

    return <NotepadStyled>
        <textarea
            className="notepad-textarea"
            ref={textarea}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            defaultValue=""
            onChange={changeTextarea}
            placeholder={getPlaceholder()}
            maxLength="1000"
        />
        <div className="notepad-btn">
            <ButtonToolbar>
                <Button variant="primary" className="btn-blue" type="submit">Save</Button>
            </ButtonToolbar>
        </div>
    </NotepadStyled>
};

const NotepadStyled = styled.div`
    width: 100%;
    height: 100%;
    
    .notepad {
        &-textarea {
            width: 100%;
            height: 100%;
            min-height: 380px;
            outline: none;
            resize: none;
            padding: 30px;
            margin-top: 20px;
            font-size: 16px;
            border: 2px solid #595d6e;
            border-radius: 3px;
        }

        &-btn {
            margin-top: 25px;
        }
    }
`