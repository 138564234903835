import React from 'react';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';

export const TableRow = ({
  item,
  check,
  setCheck,
  setCurrentForm,
  setFormViewModal,
}) => {
  const handleCheck = (itemId) => {
    const index = check.findIndex((elem) => elem === itemId);
    if (index !== -1) {
      setCheck((prev) => {
        let arr = [...prev];
        arr.splice(index, 1);
        return arr;
      });
    } else {
      setCheck((prev) => [...prev, itemId]);
    }
  };

    return (
        <tr key={item.id}>
            <td>
                <Checkbox
                    color="primary"
                    onChange={() => handleCheck(item.id)}
                    checked={check.find(elem => elem === item.id) !== undefined}
                />
            </td>
            <td
                className='left-content pointer'
                onClick={() => {
                    setCurrentForm(item);
                    setFormViewModal(true);
                }}
            >
                {item.name}
            </td>
            <td
                className='left-content pointer'
                onClick={() => {
                    setCurrentForm(item);
                    setFormViewModal(true);
                }}
            >
                {item.access === 'share' && 'Shared with All Users'}
                {item.access === 'myself' && 'Myself'}
                {item.access === 'share_groups' && 'Shared with Group'}
          
            </td>
            <td
                className='left-content pointer'
                onClick={() => {
                    setCurrentForm(item);
                    setFormViewModal(true);
                }}
            >
                {item.page.map(elem => {
                    if (elem === 'create-profile') return 'Create Profile';
                    if (elem === 'profile-info') return 'Profile Info';
                }).join(', ')}
            </td>
            <td
                className='left-content pointer'
                onClick={() => {
                    setCurrentForm(item);
                    setFormViewModal(true);
                }}
            >
                {item.created_by}
            </td>
            <td
                className='left-content pointer'
                onClick={() => {
                    setCurrentForm(item);
                    setFormViewModal(true);
                }}
            >
                {moment(item.created_at).format('MM/DD/YYYY')}
            </td>
            <td
                className='left-content pointer'
                onClick={() => {
                    setCurrentForm(item);
                    setFormViewModal(true);
                }}
            >
                {item.profiles_assigned || '-'}
            </td>
        </tr>
    );
};
