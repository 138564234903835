import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import {
    Modal,
    Button
} from 'react-bootstrap';
import {
    TextField
} from '@material-ui/core';
import {
    updateSubscription
} from '../../../crud/biling.crud';
import Logout from '../../auth/Logout';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck';
import { getCurrentSubscription } from '../../../crud/biling.crud';
import { setPlanAction } from '../../../store/actions';

export default ({
    modal,
    setModal,
    setLoaderEditSubscription,
    currentPlan,
    setCurrentPlan,
    setLogout,
    status,
    modalPlanType
}) => {
    const dispatch = useDispatch()

    const submit = () => {
        setLoaderEditSubscription(true)
        updateSubscription(modal.id)
            .then(res => {
                setLoaderEditSubscription(false);
                setCurrentPlan(res.data.data);
                dispatch(AlertState.actions.refreshStatistic());
                dispatch(AlertState.actions.alert({
                    text: 'Plan is updated',
                    variant: true
                }));
                setModal(false);

                getCurrentSubscription()
                    .then(res => {
                        dispatch(setPlanAction(res.data.data))
                    })
            })
            .catch(({ response }) => {
                setLoaderEditSubscription(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
                response.data.error &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.message || response.data.error,
                        variant: false
                    }));
            })
    };

    const back = () => {
        return modal.period === currentPlan.plan.period
            ? 0
            : next() * 2
    };

    const next = () => {
        return modal.period === 'month'
            ? modal.price
            : (modal.price * 12).toFixed(2)
    };

    return (
        <>
            <Modal
                show={modal !== false}
                onHide={e => {
                    setModal(false)
                }}
            >
                <ModalPlan>
                    <Modal.Body>
                        {
                            modal.name === 'Trainer' && currentPlan.plan.name === "Gym" && (
                                <Typography variant="body1" gutterBottom>
                                    <span style={{ color: '#aa0000' }}>Important: If you downgrade your account to Trainer, you will lose your Groups and Users data.</span>
                                </Typography>
                            )
                        }
                        {
                            !currentPlan.status === 'in_trial' && modal ? (
                                <>
                                    <Typography variant="body1" gutterBottom>
                                        <strong>
                                            You will be charged a prorated amount of ${back()} for the rest of this billing period, then ${next()} every {modal.period} until you cancel.
                                        </strong>
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        You will be charged again on a <strong>{modal.period} basis</strong>, starting on {moment(currentPlan.next_billing_at * 1000).format('MM/DD/YYYY')}.
                                    </Typography>
                                </>
                            ) : (
                                <Typography variant="body1" gutterBottom>
                                    <strong>
                                        You will be charged ${next()} when your trial period expires on {moment(new Date((currentPlan.subscription_data?.trial_end || currentPlan.subscription_data?.next_billing_at) * 1000)).format('MM/DD/YYYY')}, then ${next()} every {modal.period} until you cancel.
                                    </strong>
                                </Typography>
                            )
                        }

                        {
                            modal.name === 'Gym' && currentPlan.plan.name !== 'Gym' && (
                                <TextField
                                    key='50'
                                    label="Company Name"
                                    margin="normal"
                                    variant="outlined"
                                    // onBlur={handleBlur}
                                    // onChange={handleChange}
                                    // value={values.message}
                                    // helperText={touched.message && errors.message}
                                    // error={Boolean(touched.message && errors.message)}
                                    id="companyname"
                                />
                            )
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={e => {
                                setModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={submit}
                            className="btn-blue"
                        >
                            {modalPlanType}
                        </Button>
                    </Modal.Footer>
                </ModalPlan>
            </Modal>
        </>
    )
}

const ModalPlan = styled.div`

`