import moment from "moment";

export const sendDataToBackend = (selectedMonth, selectedYear) => {
  const startDate = moment(`${selectedYear}-${selectedMonth}-01`, "YYYY-MM-DD");

  const endDate = startDate
    .clone()
    .endOf("month")
    .format("YYYY-MM-DD");

  const dataToSend = {
    start_date: moment(startDate).format("YYYY-MM-DD"),
    end_date: moment(endDate).format("YYYY-MM-DD"),
  };

  return dataToSend;
};
