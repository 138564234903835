import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
// import Datepicker from '../../../../../components/DatePicker';
import Datepicker from '../DatePicker';
import './savemodal.scss';
import moment from 'moment';

export const SaveModal = ({ setModal, handleSave, name, description, setData, errors }) => {

    const [date, setDate] = useState({
        report_date: moment().format('MM/DD/YYYY')
    })
    useEffect(() => {
        setData({ report_date: date.report_date })
        // eslint-disable-next-line
    }, [date]);

    return (
        <Modal
            show={() => setModal(true)}
            onHide={() => {
                setData({
                    name: '',
                    report_date: null,
                    description: ''
                })
                setModal(false)
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Save Report</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Datepicker
                    value={date.report_date}
                    onChange={setDate}
                    disabled={true}
                    label={'Report Date'}
                    format={'MM/DD/YYYY'}
                    helperText={errors.report_date ? errors.report_date : ''}
                    error={Boolean(errors.report_date)}
                    defaultTouch={true}
                    width={'100%'}
                />

                <TextField
                    key={"name"}
                    className="formControl mobile-vis"
                    label="Report Name"
                    margin="normal"
                    inputProps={{
                        maxLength: 120
                    }}
                    variant="outlined"
                    value={name}
                    onChange={e => setData({ name: e.target.value })}
                    error={errors.name}
                    helperText={errors.name ? errors.name : ''}
                />

                <TextField
                    multiline
                    rows={4}
                    key={"description"}
                    className="formControl mobile-vis"
                    label="Description"
                    margin="normal"
                    inputProps={{
                        maxLength: 300
                    }}
                    variant="outlined"
                    value={description}
                    onChange={e => setData({ description: e.target.value })}
                    error={errors.description}
                    helperText={errors.description ? errors.description : ''}
                />
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setData({
                            name: '',
                            report_date: null,
                            description: ''
                        })
                        setModal(false)
                    }}
                >Close</Button>
                <button
                    className="btn-blue btn btn-primary"
                    onClick={() => handleSave(date, name, description)}
                >Save</button>
            </Modal.Footer>
        </Modal>
    )
}