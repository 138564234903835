import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as AlertState from '../../../store/ducks/auth.duck';
import { Modal, Button } from 'react-bootstrap';
import {
  getTestingRecent,
  updateMetricTesting,
} from '../../../crud/profile.crud';
import { TextField } from '@material-ui/core';
import Loader from '../../../components/Loader';
import Logout from '../../auth/Logout';
import DatePicker from '../../../components/DatePicker';
import moment from 'moment';
import {
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Checkbox,
} from '@material-ui/core';
import * as media from '../../../crud/media.crud';
import TimePicker from '../../../components/TimePicker';

const ImgComponent = ({ src, alt, className, style }) => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    src &&
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);
  return url && <img src={url} alt={alt} className={className} style={style} />;
};

export default ({
  modal,
  setModal,
  label,
  id,
  metric,
  loadData,
  category,
  check,
  setCheck,
}) => {
  const dispatch = useDispatch();
  const [input, setInput] = useState([]);
  const [data, setData] = useState({});
  const [date, setDate] = useState('');
  const [logout, setLogout] = useState(null);
  const [loader, setLoader] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (metric && check[0] && modal) {
      setLoader(true);
      getTestingRecent(id, { id: check[0], metric: metric }).then((res) => {
        setInput(res.data.data.testing);
        setLoader(false);
        let testDate = res.data.data.testing.find(
          (item) => item.name === 'testDate'
        );
        setDate(moment(testDate.value, 'YYYY-MM-DD').format('MM/DD/YYYY'));
      });
    }
  }, [modal]);

  useEffect(() => {
    input.map((item) => {
      let temp = {};
      if (item.groups) {
        item.groups.map((item) => {
          item.fields.forEach((field) => {
            if (field) {
              temp[field.name] = field.value === 1 ? 1 : 0;
            }
          });
          return null;
        });
      }
      return item.name !== 'id' && item.name !== 'testDate' && !item.groups
        ? setData((data) => ({ ...data, [item.name]: item.value }))
        : !!item.groups
        ? setData((data) => ({ ...data, ...temp }))
        : null;
    });
  }, [input]);

  const submit = () => {
    let req = {
      testDate: date,
      [category]: data,
    };
    if (data.plank_time && typeof data.plank_time !== 'number') {
      req[category] = { plank_time: data.plank_time };
    }
    setErrors({});
    setLoader(true);
    updateMetricTesting(id, check[0], req)
      .then((res) => {
        dispatch(AlertState.actions.refreshStatistic());
        setLoader(false);
        setModal(false);
        loadData();
        setCheck([]);
        dispatch(
          AlertState.actions.alert({
            text: 'Metric data is updated',
            variant: true,
          })
        );
      })
      .catch(({ response }) => {
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
        setLoader(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        }
        response &&
          response.data &&
          response.data.errors &&
          Object.keys(response.data.errors).map((item) => {
            // eslint-disable-line
            if (item !== 'basicData')
              setErrors({ ...errors, [item]: response.data.errors[item][0] });
          });
      });
  };

  const secondsToTime = (seconds) => {
    const date = new Date(0);
    date.setSeconds(seconds);
    const timeString = date.toISOString().substring(11, 19);
    return timeString;
  };

  return (
    <Modal show={modal} onHide={() => setModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader visible={loader} />
        {logout}
        {input
          .map((item) => {
            if (!item.length) return item;
            return item[1]
              ? {
                  img: item[0].img,
                  fields: {
                    [item[0].name]: item[0],
                    [item[1].name]: item[1],
                  },
                }
              : {
                  name: item[0].name,
                  img: item[0].img,
                  fields: {
                    [item[0].name]: item[0],
                  },
                };
          })
          .map((item, index) => {
            return item.name === 'id' ? null : item.name === 'testDate' ? (
              <DatePicker
                key={index}
                format={'MM/DD/YYYY'}
                label={item.label}
                value={date || null}
                onChange={(value) => {
                  setDate(value);
                }}
              />
            ) : item.fields ? (
              <div key={index}>
                {item.img && (
                  <ImgComponent
                    src={item.img}
                    style={{ width: '100%' }}
                    alt="testing"
                  />
                )}
                {!!Object.values(item.fields)[1] ? (
                  <div style={{ display: 'flex' }} className="metric-radio">
                    <FormGroup row>
                      <RadioGroup className="group">
                        <div
                          style={{
                            width: '100%',
                            margin: '10px 0',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            textAlign: 'left',
                          }}
                        >
                          L
                        </div>
                        {Object.values(item.fields)[0].values.map(
                          (elem, index) => {
                            return (
                              <FormControlLabel
                                key={'L-' + index}
                                control={
                                  <Radio
                                    key={'L-' + index}
                                    checked={
                                      data[Object.values(item.fields)[0].name]
                                        ? `${
                                            data[
                                              Object.values(item.fields)[0].name
                                            ]
                                          }` === `${elem}`
                                        : `${
                                            Object.values(item.fields)[0].value
                                          }` === `${elem}`
                                    }
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        [Object.values(item.fields)[0].name]:
                                          e.target.value,
                                      });
                                    }}
                                    color="primary"
                                    value={elem}
                                    name={Object.values(item.fields)[0].name}
                                  />
                                }
                                label={<span></span>}
                              />
                            );
                          }
                        )}
                      </RadioGroup>
                    </FormGroup>
                    <FormGroup row>
                      <RadioGroup className="group">
                        <div
                          style={{
                            margin: '10px 0 10px 5px',
                            fontWeight: 'bold',
                            fontSize: '14px',
                            textAlign: 'left',
                          }}
                        >
                          R
                        </div>
                        {Object.values(item.fields)[1].values.map(
                          (elem, index) => {
                            return (
                              <FormControlLabel
                                key={'R-' + index}
                                control={
                                  <Radio
                                    checked={
                                      data[Object.values(item.fields)[1].name]
                                        ? `${
                                            data[
                                              Object.values(item.fields)[1].name
                                            ]
                                          }` === `${elem}`
                                        : `${
                                            Object.values(item.fields)[1].value
                                          }` === `${elem}`
                                    }
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        [Object.values(item.fields)[1].name]:
                                          e.target.value,
                                      });
                                    }}
                                    color="primary"
                                    value={elem}
                                    name={Object.values(item.fields)[1].name}
                                  />
                                }
                                label={
                                  <span style={{ fontSize: '11px' }}>
                                    {Object.values(item.fields)[1].desc[index]}
                                  </span>
                                }
                              />
                            );
                          }
                        )}
                      </RadioGroup>
                    </FormGroup>
                  </div>
                ) : (
                  <div style={{ display: 'flex' }} className="metric-radio">
                    <FormGroup row>
                      <RadioGroup className="group">
                        {Object.values(item.fields)[0].values.map(
                          (elem, index) => {
                            return (
                              <FormControlLabel
                                key={'R-' + index}
                                control={
                                  <Radio
                                    checked={
                                      data[Object.values(item.fields)[0].name]
                                        ? `${
                                            data[
                                              Object.values(item.fields)[0].name
                                            ]
                                          }` === `${elem}`
                                        : `${
                                            Object.values(item.fields)[0].value
                                          }` === `${elem}`
                                    }
                                    onChange={(e) => {
                                      setData({
                                        ...data,
                                        [Object.values(item.fields)[0].name]:
                                          e.target.value,
                                      });
                                    }}
                                    color="primary"
                                    value={elem}
                                    name={Object.values(item.fields)[0].name}
                                  />
                                }
                                label={
                                  <span style={{ fontSize: '11px' }}>
                                    {Object.values(item.fields)[0].desc[index]}
                                  </span>
                                }
                              />
                            );
                          }
                        )}
                      </RadioGroup>
                    </FormGroup>
                  </div>
                )}
              </div>
            ) : item.groups ? (
              <div key={index}>
                <h4 style={{ marginTop: 20 }}>{item.group_title}</h4>
                {item.groups.map((elem, index) => {
                  return (
                    <div
                      key={index}
                      className="col-12"
                      style={{ marginTop: 20 }}
                    >
                      <h5>{elem.subtitle || elem.group_title}</h5>
                      <div className="img-wrap">
                        <ImgComponent
                          src={elem.img}
                          alt="test-img"
                          style={{ width: '100%' }}
                        />
                      </div>
                      {Object.values(elem.fields).map((input, index) => {
                        return (
                          <FormGroup row key={input.name + '-' + index}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={`${data[input.name]}` === `${1}`}
                                  onChange={(e) => {
                                    setData({
                                      ...data,
                                      [input.name]: e.target.value,
                                    });
                                  }}
                                  value={
                                    `${data[input.name]}` === `${1}` ? 0 : 1
                                  }
                                  color="primary"
                                />
                              }
                              label={input.label}
                            />
                          </FormGroup>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            ) : item.type === 'checkbox' || item.rules?.type === 'checkbox' ? (
              <div key={index}>
                {item.title && <h4>{item.title}</h4>}
                {item.subtitle && <h5>{item.subtitle}</h5>}
                <FormGroup row key={item.name}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={`${data[item.name]}` === `${1}`}
                        onChange={(e) => {
                          setData({
                            ...data,
                            [item.name]: e.target.value,
                          });
                        }}
                        value={`${data[item.name]}` === `${1}` ? 0 : 1}
                        color="primary"
                      />
                    }
                    label={item.label}
                  />
                </FormGroup>
              </div>
            ) : item.type === 'time' ? (
              <TimePicker
                onChange={(value) => {
                  setData({
                    ...data,
                    [item.name]: value,
                  });
                }}
                value={
                  !data[item.name]
                    ? '00.00.00'
                    : typeof data[item.name] === 'number'
                    ? secondsToTime(data[item.name])
                    : data[item.name]
                }
              />
            ) : (
              <TextField
                variant="outlined"
                key={index}
                type="text"
                className="textField"
                name={item.name}
                label={item.label}
                value={data[item.name] || ''}
                onChange={(e) => {
                  setData({
                    ...data,
                    [item.name]: e.target.value,
                  });
                  if (!e.target.value.length) {
                    setErrors({
                      ...errors,
                      [item.name]: 'Field is required',
                    });
                  } else {
                    setErrors({
                      ...errors,
                      [item.name]: '',
                    });
                  }
                }}
                margin="normal"
                error={Boolean(errors[item.category + '.' + item.name])}
                helperText={errors[item.category + '.' + item.name]}
              />
            );
          })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setModal(false)}>
          Close
        </Button>
        <Button variant="primary" className="btn-blue" onClick={submit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
