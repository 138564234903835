import React, { useEffect, useState } from 'react';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import { fetchCRMStrategy, postCRMStrategy } from '../../../../crud/crm.crud';
import { useSelector } from 'react-redux';
import Loader from "../../../../components/Loader";
import { useDispatch } from 'react-redux';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { fetchCRMList } from '../../../../crud/crm.crud';
import { setCRMAction } from '../../../../store/actions';

const StyledToolTip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

export const CRMSettings = () => {
    const dispatch = useDispatch();
    const [checked, setChecked] = useState({
        is_delete_records: false,
        mappings: [
            {
                sync_type: "groups",
                enabled: false
            },
            {
                sync_type: "profiles",
                enabled: false
            },
            {
                sync_type: "users",
                enabled: false
            },
            {
                sync_type: "events",
                enabled: false
            },
        ]
    });

    console.log('checked',checked)
    const plan = useSelector(state => state.user_info.subscription?.plan?.name);
    const [checkBoxes, setCheckBoxes] = useState([]);
    const [loader, setLoader] = useState(false);
    const accountId = useSelector(state => state.appData?.account?.id);
    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.is_activated === true));

    useEffect(() => {
        setLoader(true);
        fetchCRMList(accountId)
            .then(res => dispatch(setCRMAction(res.data.list)))
            .finally(() => setLoader(false))

        if (plan === 'Gym') {
            setCheckBoxes([
                { name: 'Users', value: 'users' },
                { name: 'Profiles', value: 'profiles' },
                { name: 'Events', value: 'events' }

            ]);
        } else if (plan === 'Enterprise') {
            setCheckBoxes([
                { name: 'Groups', value: 'groups' },
                { name: 'Users', value: 'users' },
                { name: 'Profiles', value: 'profiles' },
                { name: 'Events', value: 'events' }
            ]);
        };
    }, [plan]);

    useEffect(() => {
        if (accountId && crm) {
            fetchCRMStrategy(accountId, crm.id)
                .then(res => {
                    console.log('res',res)
                    setChecked(res.data.list);

                })
        }
    }, [accountId, crm]);

    const handleSwitch = () => {
        if (!checked.is_delete_records) {
            let data = {
                is_delete_records: true,
                mappings: [
                    {
                        sync_type: "groups",
                        enabled: true
                    },
                    {
                        sync_type: "profiles",
                        enabled: true
                    },
                    {
                        sync_type: "users",
                        enabled: true
                    },
                    {
                        sync_type: "events",
                        enabled: true
                    },
                ]
            }

            postCRMStrategy(accountId, crm.id, data)
                .then(res => {
                    setChecked(res.data.list);
                    setLoader(false);
                    dispatch(AlertState.actions.alert({
                        text: 'Success',
                        variant: true
                    }));
                })
                .catch(err => {
                    dispatch(AlertState.actions.alert({
                        text: err?.response?.data?.error || 'failed request',
                        variant: false
                    }));
                })
        } else {
            let data = {
                is_delete_records: false,
                mappings: [
                    {
                        sync_type: "groups",
                        enabled: false
                    },
                    {
                        sync_type: "profiles",
                        enabled: false
                    },
                    {
                        sync_type: "users",
                        enabled: false
                    }, 
                    {
                        sync_type: "events",
                        enabled: false
                    },
                ]
            }
            postCRMStrategy(accountId, crm.id, data)
                .then(res => {
                    setChecked(res.data.list);
                    setLoader(false);
                    dispatch(AlertState.actions.alert({
                        text: 'Success',
                        variant: true
                    }));
                })
                .catch(err => {
                    dispatch(AlertState.actions.alert({
                        text: err?.response?.data?.error || 'failed request',
                        variant: false
                    }));
                })
        };
    };

    const handleCheck = name => {
        let data = {
            ...checked,
            mappings: checked.mappings.map((elem) => {
                if (elem.sync_type === name) {
                    return {
                        sync_type: name,
                        enabled: !elem.enabled,
                    }
                } else {
                    return elem;
                }
            })
        }

        setLoader(true)
        postCRMStrategy(accountId, crm.id, data)
            .then(res => {
                setChecked(res.data.list);
                setLoader(false);
                dispatch(AlertState.actions.alert({
                    text: 'Success',
                    variant: true
                }));
            })
            .catch(err => {
                dispatch(AlertState.actions.alert({
                    text: err?.response?.data?.error || 'failed request',
                    variant: false
                }));
            })
    };

    return (
        <div className="crm-info">
            <Loader visible={loader} />

            <div className="crm-info__row">
                <div className="crm-info__cell">
                    Sync Deleted Records
                </div>
                <div className="crm-info__cell">
                    <div className="switcher">
                        <Switch
                            checked={checked.is_delete_records}
                            onChange={handleSwitch}
                            color="primary"
                            name="checkedA"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </div>
            </div>
            <div className="crm-info__row crm-info__row-settings">
                Delete TrainerMetrics record when the corresponding record is deleted in CRM.
            </div>
            <div>
                {checkBoxes.map((elem, index) => {
                    return (
                        <div className="crm-info__row">
                            <StyledToolTip
                                title={!checked.is_delete_records
                                    ? "Sync Deleted Records should be enabled"
                                    : ""
                                }
                                placement="bottom"
                            >
                                <div className="crm-info__cell crm-info__wide-cell crm-info__checkbox-cell">
                                    <Checkbox
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                        checked={checked.mappings.find(item => item.sync_type === elem.value)?.enabled}
                                        onChange={() => handleCheck(elem.value)}
                                        disabled={!checked.is_delete_records}
                                    />
                                </div>
                            </StyledToolTip>
                            <div className="crm-info__cell">
                                <span
                                    className={checked.is_delete_records ? 'pointer' : ''}
                                    onClick={
                                        checked.is_delete_records
                                            ? () => handleCheck(elem.value)
                                            : () => { }
                                    }
                                >
                                    {elem.name}
                                </span>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};
