import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from "../../../../store/ducks/auth.duck";
import { Redirect } from "react-router-dom";
import Loader from "../../../../components/Loader";
import styled from "styled-components";
import { Button, Pagination, Modal } from "react-bootstrap";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  ListItemText,
  OutlinedInput,
  FormHelperText,
  Typography,
  Tab,
  Tabs,
} from "@material-ui/core";

import TableList from "../../../../components/TableList";

import moment from "moment";

import catchErrorMessage from "../../../../helpers/errorCatcher";

import { useUsers } from "../hooks/useUsers";
import { useGroups } from "../hooks/useGroups";
import { useSessions } from "../hooks/useSessions";
import { PerformanceSummary } from "./components/PerformanceSummary/PerformanceSummary";

import "../../table.scss";
import "../../style.scss";

const totals = [
  { title: "new opportunities", count: 14, color: "#607CF7" },
  { title: "New sales", count: "$ 3,126", color: "#607CF7" },
  { title: "New clients", count: "6", color: "#607CF7" },
  { title: "Win Rate", count: "46,2%", color: "#607CF7" },
];

const totals2 = [
  { title: "existing clients", count: 17, color: "#607CF7" },
  { title: "expected resign revenue", count: "$5,741", color: "#607CF7" },
  { title: "retention rate", count: "63,7%", color: "#607CF7" },
  { title: "client life time value", count: "$2,496", color: "#607CF7" },
];

const initSetting = { initialPage: 1, initialSize: 10, initialSearch: "" };

export const PerformanceList = (props) => {
  document.title = "TrainerMetrics - Performance";
  const crm = useSelector((state) =>
    state.user_info.CRM_list.find((elem) => elem.is_activated === true)
  );
  const user = useSelector(
    (store) => store.auth && store.auth.user && store.auth.user
  );
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const role = useSelector((state) => state.auth?.user?.roles);
  const dispatch = useDispatch();

  const { userList, loading: loaderUser } = useUsers({ group_id: null });
  const { groupList, loading: loaderAllGroups } = useGroups();
  const {
    loading,
    error,
    data,
    page,
    setPage,
    size,
    setSize,
    search,
    setSearch,
    sort,
    setSort,
    total,
  } = useSessions(initSetting);

  const [logout, setLogout] = useState(null);
  // const [data, setData] = useState([]);
  // const [show, setShow] = useState("10");

  // const [pagination, setPagination] = useState(1);
  const [check, setCheck] = useState([]);
  const [checkGroups, setCheckGroups] = useState([]);

  const [typeKey, setTypeKey] = useState(0);

  // const [loader, setLoader] = useState(false);
  // const [sort, setSort] = useState(["name", "asc"]);

  const [year, setYear] = useState(moment().format("YYYY"));
  const [month, setMonth] = useState(moment().format("MM"));
  // const [search, setSearch] = useState("");

  //redirect if no have access
  useEffect(() => {
    if (role === "staff" || role === "client") {
      dispatch(
        AlertState.actions.alert({
          text: "User does not have the right roles",
          variant: false,
        })
      );
      setLogout(<Redirect to="/profile-list" />);
    }
  }, [role]);

  return (
    <StyledGroupLists>
      {logout}
      <Loader visible={loaderAllGroups || loaderUser || loading} />

      <Portlet>
        <PortletBody>
          <FiltersBlock>
            <div className="btn-toolbar__group">
              <FormControl variant="outlined" className="formControl">
                <InputLabel>Select Groups</InputLabel>
                <Select
                  multiple
                  value={checkGroups}
                  name="groups"
                  onChange={(e) => setCheckGroups(e.target.value)}
                  input={<OutlinedInput id={`select-multiple-checkbox`} />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (item) =>
                          groupList.find((elem) => elem.id === item).name
                      )
                      .join(", ")
                  }
                  inputProps={{
                    name: "groups",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 250,
                      },
                    },
                  }}
                >
                  {groupList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" className="formControl">
                <InputLabel>Select Users</InputLabel>
                <Select
                  multiple
                  value={checkGroups}
                  name="users"
                  onChange={(e) => setCheckGroups(e.target.value)}
                  input={<OutlinedInput id={`select-multiple-checkbox`} />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (item) =>
                          userList.find((elem) => elem.id === item).first_name
                      )
                      .join(", ")
                  }
                  inputProps={{
                    name: "groups",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 250,
                      },
                    },
                  }}
                >
                  {userList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <ListItemText
                        primary={`${item.first_name} ${item.last_name}`}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div
              className={`search-wrap`}
              style={{ display: "flex", alignItems: "center", gap: "4px" }}
            >
              <div className="btn-toolbar__group">
                <FormControl
                  variant="outlined"
                  // error={Boolean(
                  //   (touched.birthday_year ||
                  //     touched.birthday_month ||
                  //     touched.birthday_day) &&
                  //     errors.birthday
                  // )}
                  style={{ width: "100%" }}
                >
                  <InputLabel htmlFor="birthday_month">Month</InputLabel>
                  <Select
                    value={month}
                    onChange={setMonth}
                    input={
                      <OutlinedInput labelWidth={42} name="month" id="month" />
                    }
                  >
                    <MenuItem value={"01"}>January</MenuItem>
                    <MenuItem value={"02"}>February</MenuItem>
                    <MenuItem value={"03"}>March</MenuItem>
                    <MenuItem value={"04"}>April</MenuItem>
                    <MenuItem value={"05"}>May</MenuItem>
                    <MenuItem value={"06"}>June</MenuItem>
                    <MenuItem value={"07"}>July</MenuItem>
                    <MenuItem value={"08"}>August</MenuItem>
                    <MenuItem value={"09"}>September</MenuItem>
                    <MenuItem value={"10"}>October</MenuItem>
                    <MenuItem value={"11"}>November</MenuItem>
                    <MenuItem value={"12"}>December</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="btn-toolbar__group">
                <FormControl
                  variant="outlined"
                  // error={Boolean(
                  //   (touched.birthday_year ||
                  //     touched.birthday_month ||
                  //     touched.birthday_day) &&
                  //     errors.birthday
                  // )}
                  style={{ width: "100%" }}
                >
                  <InputLabel htmlFor="year">Year</InputLabel>
                  <Select
                    value={year}
                    onChange={setYear}
                    input={
                      <OutlinedInput labelWidth={30} name="year" id="year" />
                    }
                  >
                    {[...new Array(10)].map((item, index) => (
                      <MenuItem value={index + 2020} key={index}>
                        {2020 + index}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </FiltersBlock>
        </PortletBody>
        <PortletBody>
          <PerformanceSummary items={totals} />
        </PortletBody>

        <PortletBody>
          <PerformanceSummary items={totals2} />
        </PortletBody>
      </Portlet>
    </StyledGroupLists>
  );
};

const StyledGroupLists = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .btn {
    margin-right: 15px;
  }

  .btn-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &__group {
      display: flex;
      align-items: center;

      .formControl {
        margin-left: 0;
      }
    }
  }

  .edit-all {
    height: 100%;
    border-radius: 4px;
    background-color: #eef1ff;
    padding: 0 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5d78ff;
    display: flex;
    align-items: center;
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
      min-width: 290px;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }
`;

const TabsBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FiltersBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
`;
