import axios from 'axios';
import moment from 'moment';

export const BASE_URL = process.env.REACT_APP_API_URL;

export const getReports = ({
  per_page = '10',
  current_page = '1',
  sort = 'created_at',
  dir = 'desc',
  search = '',
}) => {
  return axios.get(
    `${BASE_URL}analytic/report/?sortBy[0][name]=${sort}&sortBy[0][dir]=${dir}`,
    {
      params: {
        search: !!search ? search : null,
        perPage: per_page || 10,
        page: current_page || 1,
      },
    }
  );
};

export const getReportList = () => {
  return axios.get(`${BASE_URL}analytic/report/`);
};

export const getReport = (id) => {
  return axios.get(`${BASE_URL}analytic/report/${id}`);
};

export const getTypes = (data) => {
  return axios.get(
    `${BASE_URL}analytic/report/types?order_by=${data?.sort_by}&order=${data?.dir}`
  );
};

export const showTypes = (id) => {
  return axios.get(`${BASE_URL}analytic/report/types/${id}`);
};

export const showReport = (id) => {
  return axios.get(`${BASE_URL}analytic/report/${id}`);
};

export const getMetricList = () => {
  return axios.get(`${BASE_URL}/account-metrics?type=analytic`);
};

export const deleteReport = (id) => {
  return axios.delete(`${BASE_URL}analytic/report/${id}`);
};

export const deleteReports = (arr) => {
  return axios.delete(`${BASE_URL}analytic/report-mass-destroy`, {
    data: { ids: [...arr] },
  });
};

export const updateReport = (id, date, name, description, data, type_id) => {
  return axios.put(`${BASE_URL}analytic/report/${id}`, {
    report: {
      name: name || '',
      description: description || null,
      metric_id: data.metric_id,
      age_from: data.age_from,
      age_to: data.age_to,
      gender: data.gender,
      report_date: date,
      event_type: type_id === 2 ? data.event_type : null,
      date_start:
        data.date_start && moment(data.date_start).format('MM/DD/YYYY'),
      date_end: data.date_end && moment(data.date_end).format('MM/DD/YYYY'),
    },
    groups: data.groups || [],
    users: data.users || [],
    type_id: type_id,
  });
};

export const runReport = (
  data,
  {
    per_page = 10,
    current_page = 1,
    sort = 'profiles.first_name',
    dir = 'desc',
  }
) => {
  return axios.post(
    `${BASE_URL}analytic/report/filter?perPage=${per_page}&page=${current_page}`,
    {
      ...data,
      sortBy: [
        {
          name: sort,
          dir: dir,
        },
      ],
    }
  );
};

export const saveReport = (data) => {
  return axios.post(`${BASE_URL}analytic/report`, data);
};

export const exportReport = (
  data,
  {
    per_page = 10,
    current_page = 1,
    sort = 'profiles.first_name',
    dir = 'desc',
  }
) => {
  return axios.post(
    `${BASE_URL}analytic/report/filter/export?perPage=${per_page}&page=${current_page}`,
    {
      ...data,
      sortBy: [
        {
          name: sort,
          dir: dir,
        },
      ],
    }
  );
};
