export const tableHead = [
    {
        label: 'Name',
        value: 'title',
        left: true,
        sort: true,
    },
    {
        label: 'Type',
        value: 'type',
        left: true,
        sort: true,
    },
    {
        label: 'Profile',
        value: 'profile',
        left: true,
        sort: true,
    },
    {
        label: 'Date',
        value: 'perform_at',
        left: true,
        sort: true,
    },
];
