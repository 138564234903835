
export const putNumbersFunc = (sectionsAndExercises) => {
    let newArray = [...sectionsAndExercises]
    newArray.forEach((elem, index) => {
        if (index === 0) {
            if (elem.type === 'exercise') {
                newArray.splice(index, 1, {...elem, number: 1})
            }
        } else {
            if (elem.type === 'exercise') {
                if (newArray[index - 1]?.type === 'exercise' && newArray[index - 1].number) {
                    newArray.splice(index, 1, { ...elem, number: newArray[index - 1].number + 1 })
                } else {
                    newArray.splice(index, 1, { ...elem, number: 1 })
                }
            }
        }
    })
    return newArray

}


export const handleAddExercise = (setSectionsAndExercises,sectionsAndExercises) => {
    setSectionsAndExercises(prev => ([
        ...prev,
        {
            id: 'drag-' + Math.random(),
            hasError: false,
            touched: false,
            errorText: '',
            type: 'exercise',
            number: sectionsAndExercises[sectionsAndExercises.length - 1]?.type === 'exercise' ? sectionsAndExercises[sectionsAndExercises.length - 1]?.number + 1 : 1
        }
    ]));
};

export const handleAddSection = (setSectionsAndExercises) => {
    setSectionsAndExercises(prev => ([
        ...prev,
        {
            title: '',
            id: 'drag-' + Math.random(),
            hasError: false,
            touched: false,
            errorText: '',
            type: 'section'
        }
    ]));
};
