import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const DropdownMultiselectv2 = ({
    limitTags = 1,
    style = { width: 250 },
    onChange,
    value,
    options,
    label,
    error,
    helperText,
    defaultValue = [],
    disabled = false
}) => {

    const handleChange = (e, value) => {
        onChange(value);
    };

    return (
        <Autocomplete
            disabled={disabled}
            multiple
            limitTags={limitTags}
            style={style}
            value={value}
            defaultValue={defaultValue}
            options={options}
            getOptionLabel={(option) => option.title}
            getOptionSelected={(option) => !!value.find(elem => elem.title === option.title)}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, startadornment: {} }}
                    variant="outlined"
                    label={label || 'select'}
                    placeholder="Search"
                    error={!!error}
                    helperText={helperText}
                />
            )}
        />
    );
};

export default DropdownMultiselectv2;
