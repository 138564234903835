import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as AlertState from '../../../store/ducks/auth.duck';
import { Modal, Button } from 'react-bootstrap';
import { fieldTesting, addTesting } from '../../../crud/profile.crud';
import TimePicker from '../../../components/TimePicker';
import {
    TextField,
    // Grid
} from '@material-ui/core';
// import DateFnsUtils from '@date-io/date-fns';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';
import Loader from '../../../components/Loader';
import Logout from '../../auth/Logout';
import DatePicker from '../../../components/DatePicker';
import moment from 'moment';
import {
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    Checkbox,
} from '@material-ui/core';
import './style.scss';
import * as media from '../../../crud/media.crud';

const ImgComponent = ({ src, alt, className, style }) => {
    const [url, setUrl] = useState('');

    useEffect(() => {
        src &&
            media
                .getImg(src)
                .then((res) => setUrl(res))
                .catch((err) => setUrl(err));
    }, [src]);
    return (
        url && <img src={url} alt={alt} className={className} style={style} />
    );
};

export default ({ modal, setModal, label, id, metric, loadData, category }) => {
    const dispatch = useDispatch();

    const [input, setInput] = useState([]);
    const [data, setData] = useState({});
    const [date, setDate] = useState(moment().format('MM/DD/YYYY'));
    const [logout, setLogout] = useState(null);
    const [loader, setLoader] = useState(false);

  
    useEffect(() => {
        setLoader(true);
        metric &&
            fieldTesting(id, metric)
                .then((res) => {
                    setLoader(false);
                    setInput(res.data.data);
                })
                .catch(({ response }) => {
                    setLoader(false);
                    if (response && response.status === 401) {
                        setLogout(<Logout />);
                    }
                });
    }, [id, metric]);

    const [errors, setErrors] = useState({});

    useEffect(() => {
        setData({});
        if (input) {
            let dataFields = {};
            input.map((item) => {
                if (
                    item.name !== 'testDate' &&
                    item.groups
                ) {
                    item.groups.map((elem) => {
                        for (let prop in elem.fields) {
                            dataFields[prop] = '0';
                        }
                    });
                }
            });
            setData(dataFields);
        }
    }, [input, modal]);

    const submit = () => {
        let err = {};
        input.map((item) => {
            // eslint-disable-line
            if (!data[item.name] && item.name !== 'testDate' && item.name) {
                if (item.type === 'checkbox') {
                    data[item.name] = '0';
                } else {
                    err[item.name] = 'Field is required';
                }
            }
        });
        setErrors(err);
        if (Object.values(err).length === 0) {
            let req = {
                testDate: date,
            };

            if (
                category === 'biomechanics' &&
                label === 'Movement Assessments'
            ) {
                req[category] = data;
            }

            req[category] = { ...req[category], ...data };

            if (data.plank_time && typeof data.plank_time !== 'number') {
                req[category] = { plank_time: data.plank_time };
            }

            setLoader(true);
            addTesting(id, req)
                .then((res) => {
                    dispatch(AlertState.actions.refreshStatistic());
                    setLoader(false);
                    setDate(moment().format('MM/DD/YYYY'));
                    setData({});
                    setModal(false);
                    loadData();
                    dispatch(
                        AlertState.actions.alert({
                            text: 'Metric data is added',
                            variant: true,
                        })
                    );
                })
                .catch(({ response }) => {
                    response &&
                        response.data &&
                        dispatch(
                            AlertState.actions.alert({
                                text:
                                    response.data.message ||
                                    response.data.error,
                                variant: false,
                            })
                        );
                    setLoader(false);
                    if (response && response.status === 401) {
                        setLogout(<Logout />);
                    }
                    if (response.data.errors) {
                        const key = Object.keys(response.data.errors);
                        const values = Object.values(response.data.errors);
                        let err = {};
                        key.map((item, index) => {
                            // eslint-disable-line
                            err[item.split('.')[1]] = values[index];
                        });
                        setErrors(err);
                    }
                });
        }
    };

    return (
        <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loader visible={loader} />
                {logout}

                {input.map((item, index) => {
                    return item.name === 'testDate' ? (
                        <DatePicker
                            key={index}
                            label={item.label}
                            format={'MM/DD/YYYY'}
                            value={date || null}
                            onChange={(value) => {
                                setDate(value);
                            }}
                            style={{
                                fontWeight: 300,
                                color: 'rgba(0, 0, 0, 0.87)',
                            }}
                        />
                    ) : item.fields ? (
                        Object.values(item.fields).length === 2 ? (
                            <div key={index}>
                                <ImgComponent
                                    src={item.img}
                                    style={{ width: '100%' }}
                                    alt="testing"
                                />
                                <div
                                    style={{ display: 'flex' }}
                                    className="metric-radio"
                                >
                                    <FormGroup row>
                                        <RadioGroup className="group">
                                            <div
                                                style={{
                                                    width: '100%',
                                                    margin: '10px 0',
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                L
                                            </div>
                                            {Object.values(
                                                item.fields
                                            )[0].values.map((elem, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={'L-' + index}
                                                        control={
                                                            <Radio
                                                                checked={
                                                                    `${
                                                                        data[
                                                                            Object.values(
                                                                                item.fields
                                                                            )[0]
                                                                                .name
                                                                        ]
                                                                    }` ===
                                                                    `${elem}`
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setData({
                                                                        ...data,
                                                                        [Object.values(
                                                                            item.fields
                                                                        )[0]
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    });
                                                                }}
                                                                color="primary"
                                                                value={elem}
                                                                name={
                                                                    Object.values(
                                                                        item.fields
                                                                    )[0].name
                                                                }
                                                            />
                                                        }
                                                        label={<span></span>}
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </FormGroup>
                                    <FormGroup row>
                                        <RadioGroup className="group">
                                            <div
                                                style={{
                                                    margin: '10px 0 10px 5px',
                                                    fontWeight: 'bold',
                                                    fontSize: '14px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                R
                                            </div>
                                            {Object.values(
                                                item.fields
                                            )[0].values.map((elem, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={'R-' + index}
                                                        control={
                                                            <Radio
                                                                checked={
                                                                    `${
                                                                        data[
                                                                            Object.values(
                                                                                item.fields
                                                                            )[1]
                                                                                .name
                                                                        ]
                                                                    }` ===
                                                                    `${elem}`
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setData({
                                                                        ...data,
                                                                        // [item.subcategory]: {
                                                                        //     ...data[item.subcategory],
                                                                        [Object.values(
                                                                            item.fields
                                                                        )[1]
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                        // }
                                                                    });
                                                                }}
                                                                color="primary"
                                                                value={elem}
                                                                name={
                                                                    Object.values(
                                                                        item.fields
                                                                    )[0].name
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <span
                                                                style={{
                                                                    fontSize:
                                                                        '11px',
                                                                }}
                                                            >
                                                                {
                                                                    Object.values(
                                                                        item.fields
                                                                    )[0].desc[
                                                                        index
                                                                    ]
                                                                }
                                                            </span>
                                                        }
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </FormGroup>
                                </div>
                            </div>
                        ) : (
                            <div key={index}>
                                <ImgComponent
                                    src={item.img}
                                    style={{ width: '100%' }}
                                    alt="testing"
                                />
                                <div
                                    style={{ display: 'flex' }}
                                    className="metric-radio"
                                >
                                    <FormGroup row>
                                        <RadioGroup className="group">
                                            {Object.values(
                                                item.fields
                                            )[0].values.map((elem, index) => {
                                                return (
                                                    <FormControlLabel
                                                        key={'L-' + index}
                                                        control={
                                                            <Radio
                                                                checked={
                                                                    `${
                                                                        data[
                                                                            Object.values(
                                                                                item.fields
                                                                            )[0]
                                                                                .name
                                                                        ]
                                                                    }` ===
                                                                    `${elem}`
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    setData({
                                                                        ...data,
                                                                        [Object.values(
                                                                            item.fields
                                                                        )[0]
                                                                            .name]:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    });
                                                                }}
                                                                color="primary"
                                                                value={elem}
                                                                name={
                                                                    Object.values(
                                                                        item.fields
                                                                    )[0].name
                                                                }
                                                            />
                                                        }
                                                        label={
                                                            <span>
                                                                {
                                                                    Object.values(
                                                                        item.fields
                                                                    )[0].desc[
                                                                        index
                                                                    ]
                                                                }
                                                            </span>
                                                        }
                                                    />
                                                );
                                            })}
                                        </RadioGroup>
                                    </FormGroup>
                                </div>
                            </div>
                        )
                    ) : item.groups ? (
                        <div key={index}>
                            <h4 style={{ marginTop: 20 }}>
                                {item.group_title}
                            </h4>
                            {item.groups.map((elem, index) => {
                                return (
                                    <div
                                        key={`${index}-${elem.group_title}`}
                                        className="col-12"
                                        style={{ marginTop: 20 }}
                                    >
                                        <h5>
                                            {elem.subtitle || elem.group_title}
                                        </h5>
                                        <div className="img-wrap">
                                            <ImgComponent
                                                src={elem.img}
                                                alt="test-img"
                                                style={{ width: '100%' }}
                                            />
                                        </div>
                                        {Object.values(elem.fields).map(
                                            (input, index) => {
                                                return (
                                                    <FormGroup
                                                        row
                                                        key={
                                                            input.name +
                                                            '-' +
                                                            index
                                                        }
                                                    >
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={
                                                                        `${
                                                                            data[
                                                                                input
                                                                                    .name
                                                                            ]
                                                                        }` ===
                                                                        `${1}`
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setData(
                                                                            {
                                                                                ...data,
                                                                                [input.name]:
                                                                                    e
                                                                                        .target
                                                                                        .value,
                                                                            }
                                                                        );
                                                                    }}
                                                                    value={
                                                                        `${
                                                                            data[
                                                                                input
                                                                                    .name
                                                                            ]
                                                                        }` ===
                                                                        `${1}`
                                                                            ? 0
                                                                            : 1
                                                                    }
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={input.label}
                                                        />
                                                    </FormGroup>
                                                );
                                            }
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : item.type === 'checkbox' ? (
                        <div key={index}>
                            {item.title && <h4>{item.title}</h4>}
                            {item.subtitle && <h5>{item.subtitle}</h5>}
                            <FormGroup row key={item.name}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                `${data[item.name]}` === `${1}`
                                            }
                                            onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    [item.name]: e.target.value,
                                                });
                                            }}
                                            value={
                                                `${data[item.name]}` === `${1}`
                                                    ? 0
                                                    : 1
                                            }
                                            color="primary"
                                        />
                                    }
                                    label={item.label}
                                />
                            </FormGroup>
                        </div>
                    ) : item.type === 'time' ? (
                        <TimePicker
                            onChange={(value) => {
                                setData({
                                    ...data,
                                    [item.name]: value,
                                });
                            }}
                            value={data[item.name] || '00.00.00'}
                        />
                    ) : (
                        <TextField
                            variant="outlined"
                            key={index}
                            type="text"
                            className="textField"
                            name={item.name}
                            label={item.label}
                            value={data[item.name] || ''}
                            onChange={(e) => {
                                setData({
                                    ...data,
                                    [item.name]: e.target.value,
                                });
                                if (!e.target.value.length) {
                                    setErrors({
                                        ...errors,
                                        [item.name]: 'Field is required',
                                    });
                                } else {
                                    setErrors({
                                        ...errors,
                                        [item.name]: '',
                                    });
                                }
                            }}
                            margin="normal"
                            error={Boolean(errors[item.name])}
                            helperText={errors[item.name]}
                        />
                    );
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModal(false)}>
                    Close
                </Button>
                <Button variant="primary" className="btn-blue" onClick={submit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
