import { useEffect, useState } from "react";
import { sortedUsersList } from "../helpers/sortedUsersList";
import { getUsersV2 } from "../../../../crud/info.crud";
import { getUsersV3 } from "../../../../crud/info.crud";
import catchErrorMessage from "../../../../helpers/errorCatcher";
import { useDispatch } from "react-redux";
import * as AlertState from "../../../../store/ducks/auth.duck";

export const useUsers = ({ groups = [] }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const res = await getUsersV2();
        const sorted = sortedUsersList(res.data?.list);
        setUserList(sorted);
      } catch (error) {
        let errText = catchErrorMessage(error);
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    const fetchUsersByGroup = async () => {
      setLoading(true);
      try {
        const res = await getUsersV3({ groups, limit: 500 });
        const sorted = sortedUsersList(res.data?.list);
        setUserList(sorted);
      } catch (error) {
        let errText = catchErrorMessage(error);
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    if (groups?.length) {
      fetchUsersByGroup();
    } else fetchUsers();
  }, [groups?.length]);

  return { userList, loading, error };
};
