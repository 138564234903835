import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const getDictionaryList = (params = { body_segments: 1, equipment: 1, mechanics: 1, muscles: 1, forces: 1 }) => {
    return axios.get(`${BASE_URL}dictionary`, { params });
};

export const createExercise = (data) => {
    return axios.post(`${BASE_URL}account/exercises`, { ...data });
};

export const fetchExercises = (params) => {
    return axios.get(`${BASE_URL}account/exercises`, { params });
};

export const fetchExercisesForDropdown = (params) => {
    return axios.get(`${BASE_URL}account/exercises/list`, );
};

export const fetchProfileExercises = (profileId, params) => {
    return axios.get(`${BASE_URL}profile/${profileId}/exercises`, { params });
};

export const deleteExercises = (ids) => {
    return axios.delete(`${BASE_URL}account/exercises`, { data: { ids: [...ids] } });
};

export const editExercise = (id, data) => {
    return axios.put(`${BASE_URL}account/exercises/${id}`, { ...data });
};

export const fetchExercise = (id) => {
    return axios.get(`${BASE_URL}account/exercises/${id}`);
};

export const createWorkout = (data) => {
    return axios.post(`${BASE_URL}account/workouts`, { ...data });
};

export const cloneWorkout = (profileId, workoutId, complete = 0, data, is_untie_enabled) => {
    return axios.post(`${BASE_URL}profile/${profileId}/programs/${workoutId}/clone?complete=${complete}&is_untie_enabled=${is_untie_enabled ? '1' : 0}`, { ...data });
};

export const editWorkout = (id, data) => {
    return axios.put(`${BASE_URL}account/workouts/${id}`, { ...data });
};

export const fetchWorkouts = (params) => {
    return axios.get(`${BASE_URL}account/workouts`, { params })
};

export const fetchWorkout = (id) => {
    return axios.get(`${BASE_URL}account/workouts/${id}`)
};

export const deleteWorkouts = (ids) => {
    return axios.delete(`${BASE_URL}account/workouts`, { data: { ids: [...ids] } });
};

export const fetchPrograms = (params) => {
    return axios.get(`${BASE_URL}account/programs`, { params })
};

export const fetchProgram = (id) => {
    return axios.get(`${BASE_URL}account/programs/${id}`)
};

export const createProgram = (data) => {
    return axios.post(`${BASE_URL}account/programs`, { ...data });
};

export const editProgram = (id, data) => {
    return axios.put(`${BASE_URL}account/programs/${id}`, { ...data });
};

export const deletePrograms = (ids) => {
    return axios.delete(`${BASE_URL}account/programs`, { data: { ids: [...ids] } });
};

export const fetchProfileProgram = (id, params) => {
    return axios.get(`${BASE_URL}profile/${id}/programs/all`, { params })
};

export const getProgramsTree = (params) => {
    return axios.get(`${BASE_URL}account/programs/tree`, { params })
};

export const addToProgram = (id, data) => {
    return axios.post(`${BASE_URL}profile/${id}/programs`, { ...data })
};

export function changeProgramStatus(id, data) {
    return axios.post(`${BASE_URL}profile/${id}/programs/status`, { ...data })
};

export function changeProgramStatusAll(id, data) {
    return axios.post(`${BASE_URL}profile/${id}/programs/status/all`, { ...data })
};

export const getPDF = (id, module) => {
    return axios.get(`${BASE_URL}account/${module}/${id}/pdf`);
};

export const getPDF2 = (profileId, programId) => {
    return axios.get(`${BASE_URL}profile/${profileId}/programs/${programId}/pdf`);
};

export const getWorkoutsProgram = (profileId, programId) => {
    return axios.get(`${BASE_URL}profile/${profileId}/programs/${programId}`)
};

export const setWorkoutsProgram = (profileId, programId, data) => {
    return axios.put(`${BASE_URL}profile/${profileId}/programs/${programId}`, { ...data })
};

export const getPreviousExercise = (profileId, exerciseId) => {
    return axios.get(`${BASE_URL}profile/${profileId}/programs/exercises/${exerciseId}`);
};
