// import * as AlertState from '../../../../store/ducks/auth.duck';

export const handleChangeSectionTitle = (title, index, setSections) => {
    if (title !== '' && title.length < 2) {
        return setSections(prev => {
            let arr = [...prev];
            arr[index] = {
                ...arr[index],
                title,
                touched: true,
                hasError: true,
                errorText: 'Too short - should be at least 2 characters'
            };
            return arr;
        })
    }

    if (title !== '' && title.length > 100) {
        return setSections(prev => {
            let arr = [...prev];
            arr[index] = {
                ...arr[index],
                title,
                touched: true,
                hasError: true,
                errorText: 'Too long - should not exceed 100 characters'
            };
            return arr;
        })
    }

    return setSections(prev => {
        let arr = [...prev];
        arr[index] = {
            ...arr[index],
            title,
            touched: true,
            hasError: false,
            errorText: ''
        };
        return arr;
    })
};


export const validateSectionTitle = (section, sections, setSectionsAndExercises) => {
    let isValid = true;

    if (!section.title) {
        isValid = true
    } else if (section.title.length < 2) {
        setSectionsAndExercises(prev => {
            let arr = [...prev];
            let index = arr.findIndex(elem => elem.id === section.id)
            arr[index] = {
                ...arr[index],
                touched: true,
                hasError: true,
                errorText: 'Too short - should be at least 2 characters'
            };
            return arr;
        })

        isValid = false
    } else if (section.title.length > 100) {
        setSectionsAndExercises(prev => {
            let arr = [...prev];
            let index = arr.findIndex(elem => elem.id === section.id)
            arr[index] = {
                ...arr[index],
                touched: true,
                hasError: true,
                errorText: 'Too long - should not exceed 100 characters'
            };
            return arr;
        })

        isValid = false
    }

    return isValid;
};

export const validateFullForm = (sectionsAndExercises, setSectionsAndExercises, dispatch) => {
    let isValid = true;

    const sectionsArr = sectionsAndExercises.filter(elem => elem.type === 'section')
    sectionsArr.forEach((elem) => {
        if (!validateSectionTitle(elem, sectionsArr, setSectionsAndExercises)) isValid = false;
    });

    return isValid;
};
