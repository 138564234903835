import React, { useState, useEffect } from 'react';
import { Table } from './Table/Table';
import Dropdown from '../../../../../components/DropdownComponent';
import DatePicker from '../../../../../components/DatePicker';
import { TextField } from '@material-ui/core';
import Pagination from '../../../../../components/Pagination';
import ModalAction from './ModalAction';
import { fetchActivity } from '../../../../../crud/crm.crud';
import Loader from "../../../../../components/Loader";
import { useDispatch, useSelector } from 'react-redux';
import * as AlertState from '../../../../../../app/store/ducks/auth.duck';
import moment from 'moment';
import styled from 'styled-components';
import { useIsMount } from '../../../../../hooks/useIsMount';

export const CRMActivity = ({ crm }) => {
    const dispatch = useDispatch();
    const isFirstRender = useIsMount();
    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    const [show, setShow] = useState('10');
    const [pagination, setPagination] = useState(1);
    const [page, setPage] = useState(false);
    const [modal, setModal] = useState(false);
    const [item, setItem] = useState(null);
    const [loader, setLoader] = useState(false);
    const [filter, setFilter] = useState({ title: 'All', value: '' });
    const [date_start, set_date_start] = useState(moment().subtract(1, 'years').format('MM/DD/YYYY'));
    const [date_end, set_date_end] = useState(moment(new Date()).format('MM/DD/YYYY'));
    const [lastPage, setLastPage] = useState(1);
    const [focused, setFocused] = useState(false);
    const accountId = useSelector(state => state.appData?.account?.id);

    useEffect(() => {
        if (focused || !accountId || !crm) return;
        setLoader(true);
        const date_from = date_start === '  /  /    ' ? null : date_start;
        const date_to = date_end === '  /  /    ' ? null : date_end;
        fetchActivity(accountId, crm.id, {
            page: pagination,
            limit: show,
            search,
            type: filter?.value || '',
            date_from,
            date_to
        })
            .then(res => {
                setPage({
                    ...res.data.pagination,
                    last_page: res.data.pagination.last_page,
                    per_page: res.data.pagination.limit,
                    current_page: res.data.pagination.page,
                });
                setLoader(false);
                setData(res.data.list);
                setLastPage(res.data.pagination.last_page);
            })
            .catch(err => {
                setLoader(false);
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                } else {
                    errText = 'request failed';
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    }, [accountId, show, filter, date_start, date_end, pagination, focused, crm]);

    useEffect(() => {
        if (!accountId || isFirstRender) return;
        const handler = setTimeout(() => {
            setLoader(true);
            setPagination(1);
            const date_from = date_start === '  /  /    ' ? null : date_start;
            const date_to = date_end === '  /  /    ' ? null : date_end;
            fetchActivity(accountId, crm.id, {
                page: 1,
                limit: show,
                search,
                type: filter?.value || '',
                date_from,
                date_to
            })
                .then(res => {
                    setPage({
                        ...res.data.pagination,
                        last_page: res.data.pagination.last_page,
                        per_page: res.data.pagination.limit,
                        current_page: res.data.pagination.page,
                    });
                    setLoader(false);
                    setData(res.data.list);
                    setLastPage(res.data.pagination.last_page)
                })
                .catch(err => {
                    setLoader(false);
                    dispatch(AlertState.actions.alert({
                        text: err?.response?.data?.error || 'request failed',
                        variant: false
                    }));
                })
        }, 500);
        return () => clearTimeout(handler);
    }, [search]);

    return (
        <StyledActivityPage>
            <ModalAction
                modal={modal}
                setModal={setModal}
                item={item}
                crm={crm}
            />

            <Loader visible={loader} />

            <div className="form-wrapper">
                <div className="form">
                    <div className="form__container">
                        <Dropdown
                            label="Activity Type"
                            setFormData={setFilter}
                            options={[
                                { title: 'All', value: null },
                                { title: 'Pulled Data', value: 'pulled' },
                                { title: 'Pushed Data', value: 'pushed' }
                            ]}
                            value={filter}
                        />
                    </div>

                    <div className="form__container">
                        <DatePicker
                            label="Date start"
                            value={date_start}
                            format={'MM/DD/YYYY'}
                            onChange={(value) => {
                                set_date_start(value)
                            }}
                            future
                            clear={true}
                            onFocus={() => setFocused(true)}
                            onBlur={() => {
                                setFocused(false)
                            }}
                        />
                    </div>

                    <div className="form__container">
                        <DatePicker
                            label="Date end"
                            value={date_end}
                            format={'MM/DD/YYYY'}
                            onChange={(value) => {
                                set_date_end(value)
                            }}
                            future
                            clear={true}
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                        />
                    </div>
                </div>

                <div className="search">
                    <TextField
                        key={"search"}
                        className="formControl"
                        label="Search"
                        margin="normal"
                        variant="outlined"
                        value={search}
                        style={{ marginLeft: 0 }}
                        onChange={e => { setSearch(e.target.value) }}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                setSearch(e.target.value)
                            }
                        }}
                    />
                </div>
            </div>

            <Table
                data={data}
                setModal={setModal}
                setItem={setItem}
            />

            <Pagination
                search={search}
                setSearch={setSearch}
                show={show}
                setShow={setShow}
                page={page}
                setPage={setPage}
                pagination={pagination}
                setPagination={setPagination}
                lastPage={lastPage}
                data={data}
            />
        </StyledActivityPage>
    )
};

const StyledActivityPage = styled.div`
    .search .MuiOutlinedInput-input {
        padding: 14px !important;
    }

    .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
        min-width: 90px;
    }

    .input-mask {
        width: 90px;
    }

    .search {
        height: 44px;
    }
`;
