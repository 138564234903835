import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import DataOutput from '../../../components/DataOutput'
import UserInfo from '../../../components/UserInfo'
import { Portlet } from "../../../partials/content/Portlet"
import { Tab, Tabs } from '@material-ui/core'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import Notepad from './Notepad'
import Journal from './Journal'
import { getProfileValid } from '../../../crud/profile.crud'
import { useDispatch } from 'react-redux';
import * as AlertState from '../../../store/ducks/auth.duck'

export default (props) => {
  const dispatch = useDispatch();
  const id = props.match.params.id;
  const [key, setKey] = useState(0)
  const [textNode, setTextNode] = useState("");
  const [showExitPromt, setShowExitPromt] = useState(false);
  const [visibleButton, setVisibleButton] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(true);
  const [textChanged, setTextChanged] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    getProfileValid(id)
      .then(res => {
        setRedirect(!res.data.is_valid);
        if (!res.data.is_valid) {
          dispatch(AlertState.actions.alert({
            text: 'Please fill out the required fields below to continue',
            variant: false
          }));
        }
      })
  }, [id]);

  useEffect(() => {
    const unblock = history.block(() => {
      if (textChanged) {
        return window.confirm("If you will leave this page or reload it, any unsaved data will be permanently lost");
      }
      return true;
    });

    return () => {
      unblock();
    };
  });

  useEffect(() => {
    window.onbeforeunload = (event) => {
      if (showExitPromt) {
        const e = event || window.event;
        event.preventDefault();
        if (e) {
          e.returnValue = '';
        }
        return ''
      }
    }
  }, [showExitPromt, location])

  const editKey = () => {
    if (key === 0) setKey(1)
    else setKey(0)
  }

  return <StyledNotes>
    {redirect && <Redirect to={`/profile/${id}/info`} />}

    <div className="row flex-md-row-reverse" style={{ marginBottom: '20px' }}>
      <DataOutput id={id} />
      <UserInfo id={id} />
    </div>
    <Portlet className="portlet notes">
      <Tabs
        value={key}
        onChange={(e, value) => { editKey(value) }}
        indicatorColor="primary"
        textColor="primary"
        scrollButtons="auto"
        variant="scrollable"
      >
        <Tab label="Notepad" />
        <Tab label="Journal" />
      </Tabs>
      {key === 0
        ? (
          <Notepad
            id={id}
            setTextNode={setTextNode}
            textNode={textNode}
            setShowExitPromt={setShowExitPromt}
            visibleButton={visibleButton}
            setVisibleButton={setVisibleButton}
            visibleUpdate={visibleUpdate}
            setVisibleUpdate={setVisibleUpdate}
            textChanged={textChanged}
            setTextChanged={setTextChanged}
          />
        )
        : <Journal id={id} />
      }
    </Portlet>
  </StyledNotes>
}

const StyledNotes = styled.div`
    .notes {
        padding: 20px;  
    }  

    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;
    }
`;
