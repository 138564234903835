import React, { useState, useEffect } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import DatePicker from '../../../../components/DatePicker';
import {
  fetchEventsList,
  fetchEventsListTiny,
} from '../../../../crud/dashboard.crud';
import { useSelector } from 'react-redux';
import { DayCalendar } from '../../Calendar/CalendarComponent/DayCalendar';
import Loader from '../../../../components/Loader';
import { Table } from '../../Calendar/AgendaComponent/WeekAgendaTable/Table';
import Dropdown from '../../../../components/DropdownComponent';
import Pagination from '../../../../components/Pagination';
import moment from 'moment';

export const Events = ({
  status,
  isFirstRender,
  selectedUsers,
  users,
  selectedGroup,
  isGroupLoading,
  isUsersLoading,
}) => {
  const [tab, setTab] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState([]);
  const [loader, setLoader] = useState(false);
  const accountId = useSelector((state) => state.appData.account.id);
  const [tablesArray, setTablesArray] = useState([]);
  const [tablesToRender, setTablesToRender] = useState([]);
  const [filter, setFilter] = useState({ title: 'Today', value: 0 });
  const role = useSelector((state) => state.auth.user.roles);

  const loadEvents = () => {
    if (isFirstRender) return;
    let usersIds = [];

    if (selectedGroup && users?.length < 1) {
      setEvents([]);
    }

    if (selectedUsers?.length > 0) {
      usersIds = selectedUsers.map((elem) => elem.id);
    }

    if (selectedGroup && users?.length > 0 && selectedUsers?.length < 1) {
      usersIds = users.map((elem) => elem.id);
    }

    setLoader(true);
    console.log('tab', tab);
    if (tab === 0) {
      const date1 = new Date(currentDate);
      const date2 = new Date(currentDate);
      let date_from =
        moment(date1.setDate(date1.getDate() - date1.getDay())).format(
          'YYYY-MM-DD'
        ) + ' 00:00:00';
      date_from = moment(date_from)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      let date_to =
        moment(date2.setDate(date2.getDate() + (6 - date2.getDay()))).format(
          'YYYY-MM-DD'
        ) + ' 23:59:59';
      date_to = moment(date_to)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');

      fetchEventsList(accountId, {
        date_from,
        date_to,
        user_ids: usersIds,
      })
        .then((res) => setEvents(res.data.list))
        .finally(() => setLoader(false));
    }

    if (tab === 1) {
      const date = moment(currentDate).format('YYYY-MM-DD');
      let date_from = moment(date).format('YYYY-MM-DD') + ' 00:00:00';
      date_from = moment(date_from)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');
      let date_to = moment(date).format('YYYY-MM-DD') + ' 23:59:59';
      date_to = moment(date_to)
        .utc()
        .format('YYYY-MM-DD HH:mm:ss');

      console.log('usersIds', usersIds);
      fetchEventsListTiny(accountId, {
        date_from,
        date_to,
        user_ids: usersIds,
      })
        .then((res) => setEvents(res.data.list))
        .finally(() => setLoader(false));
    }
  };

  useEffect(() => {
    if (!role) return;
    if (role === 'staff') {
      loadEvents();
    } else {
      if (!accountId || isUsersLoading || isGroupLoading) return;
      loadEvents();
    }
  }, [
    accountId,
    currentDate,
    tab,
    users,
    selectedGroup,
    selectedUsers,
    isUsersLoading,
    isGroupLoading,
    role,
  ]);

  useEffect(() => {
    if (events) {
      const date1 = new Date(currentDate);
      let dateFrom =
        moment(date1.setDate(date1.getDate() - date1.getDay())).format(
          'YYYY-MM-DD'
        ) + ' 00:00:00';
      dateFrom = moment(dateFrom).format('YYYY-MM-DD HH:mm:ss');
      let tables = [];

      for (let i = 0; i < 7; i++) {
        const setDate = () => {
          return moment(
            new Date(dateFrom).setDate(new Date(dateFrom).getDate() + i)
          ).format('dddd MM/DD');
        };

        tables.push({
          tableDate: new Date(dateFrom).setDate(
            new Date(dateFrom).getDate() + i
          ),
          displayDate: setDate(),
          events: [],
          sort: [null, null],
          check: [],

          show: 10,
          page: {
            per_page: 10,
            last_page: 10,
            total: 0,
          },
          pagination: 1,
          lastPage: 10,
        });
      }

      // push events to tables
      events.forEach((elem) => {
        const tableIndex = tables.findIndex((item) => {
          const tableDate = moment(item.tableDate).format('YYYY/MM/DD');
          const eventDate = moment(elem.start_at).format('YYYY/MM/DD');
          return tableDate === eventDate;
        });
        if (tableIndex !== -1) tables[tableIndex].events.push(elem);
      });

      // find out last page property
      tables = tables.map((elem) => {
        const lastPage = Math.ceil(elem.events?.length / elem.show);
        return {
          ...elem,
          lastPage,
          page: {
            per_page: elem.show,
            last_page: lastPage,
            total: elem.events?.length,
          },
        };
      });

      if (filter.value === 0) {
        const todaysTableIndex = tables.findIndex(
          (elem) =>
            moment(elem.tableDate).format('YYYY/MM/DD') ===
            moment().format('YYYY/MM/DD')
        );
        if (todaysTableIndex >= 0) {
          setTablesArray([tables[todaysTableIndex]]);
          const arr = [{ ...tables[todaysTableIndex] }];
          arr[0].events = [...tables[todaysTableIndex].events].splice(
            tables[todaysTableIndex].show *
              (tables[todaysTableIndex].pagination - 1),
            tables[todaysTableIndex].show
          );
          setTablesToRender(arr);
        }
      } else {
        setTablesArray(tables);

        const arr = tables.map((elem) => {
          const events = [...elem.events].splice(
            elem.show * (elem.pagination - 1),
            elem.show
          );
          return { ...elem, events };
        });
        setTablesToRender(arr);
      }
    }
  }, [events, filter]);

  const setShow = (value, tableIndex) => {
    setTablesToRender(() => {
      let arr = JSON.parse(JSON.stringify(tablesArray));
      arr[tableIndex].show = +value;
      arr[tableIndex].lastPage = Math.ceil(
        arr[tableIndex].events?.length / arr[tableIndex].show
      );
      arr[tableIndex].page = {
        per_page: +value,
        last_page: Math.ceil(
          arr[tableIndex].events?.length / arr[tableIndex].show
        ),
        total: arr[tableIndex].events?.length,
      };
      const events = [...arr[tableIndex].events].splice(
        value * (arr[tableIndex].pagination - 1),
        value
      );
      arr[tableIndex].events = events;
      return arr;
    });
  };

  const setPagination = (value, tableIndex) => {
    setTablesToRender(() => {
      let arr = JSON.parse(JSON.stringify(tablesArray));
      arr[tableIndex].pagination = +value;
      const events = [...arr[tableIndex].events].splice(
        arr[tableIndex].show * (value - 1),
        arr[tableIndex].show
      );
      arr[tableIndex].page = {
        per_page: arr[tableIndex].show,
        last_page: Math.ceil(
          arr[tableIndex].events?.length / arr[tableIndex].show
        ),
        total: arr[tableIndex].events?.length,
      };
      arr[tableIndex].events = events;
      return arr;
    });
  };

  return (
    <>
      <Loader visible={loader} />
      <div className="header-wrapper">
        <h1 className="title">Events</h1>
      </div>
      <div className="table-block mt" style={{ marginTop: 20 }}>
        <div className="table-block__header">
          <Tabs
            value={tab}
            onChange={(e, value) => setTab(value)}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="auto"
            variant="scrollable"
            style={{ marginBottom: 20 }}
          >
            <Tab label={'AGENDA'} />
            <Tab label={'CALENDAR'} />
          </Tabs>

          {tab === 0 && (
            <div className="table-block__header-dropdown">
              <Dropdown
                fetchOptions={false}
                options={[
                  { title: 'Today', value: 0 },
                  { title: 'This Week', value: 1 },
                ]}
                value={filter}
                label="Filter"
                setFormData={setFilter}
                getOptionSelected={(option, value) =>
                  option.title === value.title
                }
                disableClearable={true}
              />
            </div>
          )}

          {tab === 1 && (
            <DatePicker
              label="Start"
              future={true}
              format={'MM/DD/YYYY'}
              value={moment(currentDate).format('MM/DD/YYYY')}
              onChange={(val) => setCurrentDate(new Date(val))}
            />
          )}
        </div>

        {tab === 0 && filter && (
          <>
            {tablesToRender?.map((elem, index) => {
              return (
                <div key={index} style={{ marginBottom: 20 }}>
                  <div className="agenda-tab__buttons">
                    <h1 className="title">{elem.displayDate}</h1>
                  </div>

                  <Table
                    data={elem.events}
                    check={elem.check}
                    sort={elem.sort}
                    tableIndex={index}
                    setTablesArray={setTablesArray}
                    status={status}
                  />

                  <Pagination
                    show={elem.show}
                    setShow={(value) => setShow(value, index)}
                    page={elem.page}
                    pagination={elem.pagination}
                    setPagination={(value) => setPagination(value, index)}
                    lastPage={elem.lastPage}
                    data={tablesArray[index].events}
                    isOnFront
                  />
                </div>
              );
            })}
          </>
        )}

        {tab === 1 && (
          <DayCalendar
            events={events}
            setSelectedEventId={() => {}}
            setCreateModal={() => {}}
            isDashBoard={true}
          />
        )}
      </div>
    </>
  );
};
