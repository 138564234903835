import React from 'react';
import moment from 'moment';
import trash from '../../../../../../../assets/icons/delete.svg';

export const TableRow = ({ item, handleModal, setItem }) => {
    return (
        <tr>
            <td className="left-content cell-25">
                {item.internal?.text}
            </td>
            <td className="left-content cell-25">
                {item.external?.text}
            </td>
            <td className="left-content cell-25">
                {item.sync_at ? moment(item.sync_at).format('MM/DD/YYYY hh:mm:ss a') : '--'}
            </td>
            <td className="cell-25">
                <img
                    src={trash}
                    alt="delete"
                    onClick={() => {
                        setItem(item);
                        handleModal();
                    }}
                    className="pointer"
                />
            </td>
        </tr>
    )
};
