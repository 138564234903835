import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { Button } from 'react-bootstrap'
import { useDispatch } from "react-redux";
import * as AlertState from '../../store/ducks/auth.duck'
import {
  // FormattedMessage, 
  injectIntl
} from "react-intl";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  FormHelperText,
  FormControl,
  FormGroup,
  Select,
  MenuItem
} from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { registerTrial } from "../../crud/auth.crud";
import './style.scss'
import Footer from '../../../_metronic/layout/footer/FooterAuth'
import MuiPhoneNumber from 'material-ui-phone-number'
import jQuery from 'jquery'
import Cookies from 'js-cookie'

function Registration(props) {
  const { intl } = props
  document.title = 'TrainerMetrics - Sign Up'
  const dispatch = useDispatch();

  const [error, setError] = useState({})

  const [loading, setLoading] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  useEffect(() => {
    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdn.neverbounce.com/widget/dist/NeverBounce.js';
    script.id = 'neverbounce'
    document.getElementsByTagName('head')[0].appendChild(script);

    return () => {
      document.querySelector('#neverbounce').remove()
    }
  }, [])

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  useEffect(() => {
    function isEmpty(obj) {
      for (var key in obj) {
        if (obj.hasOwnProperty(key))
          return false;
      }
      return true;
    };

    function queryToObject() {
      if (window.location.search.length > 0) {
        var search = window.location.search.substring(1);

        return JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
      } else {
        return {}
      };
    };

    var original_ref = document.referrer;

    function extractDomain(url) {
      var domain;

      if (url.indexOf("://") > -1) {
        domain = url.split('/')[2];
      } else {
        domain = url.split('/')[0];
      }

      domain = domain.split(':')[0];
      return domain;
    };

    var ref = extractDomain(original_ref);

    if (ref.indexOf(window.location.host) > -1) {
      ref = false;
    };

    var sourceCookieValue = Cookies.get("tm_source_string") || '';

    var currentParams = queryToObject();

    function pipeIfNotBlank() {
      if (sourceCookieValue !== "") {
        sourceCookieValue += "|";
      }
    };

    if (currentParams["utm_source"]) {
      pipeIfNotBlank();
      sourceCookieValue += "utm_source=" + currentParams["utm_source"];
    } else if (currentParams["gclid"]) {
      pipeIfNotBlank();
      sourceCookieValue += "adwords_gclid=" + currentParams["gclid"];
    } else if (ref) {
      pipeIfNotBlank();
      sourceCookieValue += "referral=" + ref;
    };

    Cookies.set("tm_source_string", sourceCookieValue, { expires: 730 });

    function saveUTMs() {
      var currentParams = queryToObject();

      if (!isEmpty(currentParams)) {
        let paramsToCookie = {};
        var eligibleParams = ["utm_campaign", "utm_medium", "utm_source", "utm_content"];

        eligibleParams.forEach(function (p) {
          if (currentParams[p]) {
            paramsToCookie[p] = currentParams[p];
          } else {
            paramsToCookie[p] = "not set";
          }
        });

        Cookies.set('tm_cam_trk', paramsToCookie);
      };
    }

    saveUTMs();

    function pollForForms() {
      var count = 0;
      var formInt = setInterval(function () {
        var forms = jQuery(".sign-form");
        if (forms.length) {
          clearInterval(formInt);
          bindFormEvents(forms);
        } else if (count > 120) {
          clearInterval(formInt);
        } else {
          count++;
        };
      }, 500);
    };

    pollForForms();

    function bindFormEvents(forms) {

      let acFieldNames = ['field[11]', 'field[12]', 'field[13]', 'field[14]'];
      let realFieldNames = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];

      var cookiedUTMs = Cookies.getJSON("tm_cam_trk") || {};

      if (!isEmpty(cookiedUTMs)) {
        for (let i = 0; i < acFieldNames.length; i++) {
          if (cookiedUTMs[realFieldNames[i]]) {
            jQuery("input[name='" + acFieldNames[i] + "']").val(cookiedUTMs[realFieldNames[i]]);
          };
        };
      };

      var sourceString = Cookies.get("tm_source_string") || "";

      var touches = sourceString.split("|");
      if (typeof (touches[0]) != "undefined") {
        jQuery("input[name='field[42]']").val(touches[0]);
      };
      if (touches.length > 0) {
        jQuery("input[name='field[43]']").val(touches[touches.length - 1]);
      };

      jQuery("input[name='field[10]']").val(sourceString);
    };
  }, [])

  const afterSubmit = () => {
    if (`${typeof window.dataLayer}` !== 'undefined') {
      window.dataLayer.push({
        "event": "gaTriggerEvent",
        "gaEventCategory": 'Form Submit',
        "gaEventAction": jQuery(".sign-form").attr('class'),
        "gaEventLabel": window.location.pathname
      });
    }
  }

  return (<><div style={{ background: 'white', height: '100%' }}>
    <div className="registration-top">
      <h1 className="registration-top__h1">Create Trial Account</h1>
    </div>
    <div className="registration" style={{ padding: 0, background: '#ffffff' }}>
      <Formik
        initialValues={{
          email: "",
          firstname: "",
          lastname: "",
          phone: "",
          companyname: "",
          plan: "gym_50_month",
          owner: "",
          passcode: "",
          // password: "",
          acceptTerms: false,
          // confirmPassword: ""
        }}
        validate={values => {
          const errors = {};
          const emailField = document.querySelector('#neverbounce-valid-checkbox')

          if (!values.email) {
            errors.email = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address"
          } else if (
            emailField && (`${emailField.value}` === 'false') && process.env.REACT_APP_NEVERBOUNCE !== 'disable'
          ) {
            errors.email = "Invalid email address"
          }

          if (!values.firstname) {
            errors.firstname = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (values.firstname.length > 15) {
            errors.firstname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          } else if (values.firstname.length < 2) {
            errors.firstname = 'The First name should be at least 2 of characters'
          } else if (/[^A-Za-z0-9]/g.test(values.firstname)) {
            errors.firstname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }

          if (!values.lastname) {
            errors.lastname = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (values.lastname.length > 15) {
            errors.lastname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          } else if (values.lastname.length < 2) {
            errors.lastname = 'The Last name should be at least 2 of characters'
          } else if (/[^A-Za-z0-9]/g.test(values.lastname)) {
            errors.lastname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          }

          if (values.companyname && values.companyname.length > 50) {
            errors.companyname = intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_FIELD"
            });
          } else if (values.companyname && values.companyname.length < 2) {
            errors.companyname = 'The Сompany name should be at least 2 of characters'
          }

          if (!values.phone) {
            errors.phone = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          } else if (values.phone.length > 19 || values.phone.length < 7) {
            // if ((values.phone.length > 15 || values.phone.length < 7)) {
            errors.phone = "Phone number is invalid"
          } else if (/[^0-9 ()+-]/g.test(values.phone)) {
            errors.phone = "Phone number is invalid"
          }

          if (!values.plan) {
            errors.plan = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.owner) {
            errors.owner = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }

          if (!values.passcode) {
            errors.passcode = intl.formatMessage({
              id: "AUTH.VALIDATION.REQUIRED_FIELD"
            });
          }
          // if (!values.password) {
          //   errors.password = intl.formatMessage({
          //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
          //   });
          // }

          // if (!values.confirmPassword) {
          //   errors.confirmPassword = intl.formatMessage({
          //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
          //   });
          // } else if (values.password !== values.confirmPassword) {
          //   errors.confirmPassword =
          //     "Password and Confirm Password didn't match.";
          // }

          // if (!values.acceptTerms) {
          //   errors.acceptTerms = "Accept Terms";
          // }
          setError(errors)
          return errors;
        }}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          let temp = [11, 12, 13, 14, 42, 43]
          let field = []
          temp.map(item => {
            field[item] = jQuery("input[name='field[" + item + "]']").val()
            return item
          })
          enableLoading()
          window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6LeJua4ZAAAAACQMDff1rwrfBCzLxe8fu7R7zUi6', { action: 'signUpFree' })
              .then(function (token) {
                const emailField = document.querySelector('#neverbounce-valid-checkbox')

                registerTrial(
                  values.email,
                  values.firstname,
                  values.lastname,
                  values.phone,
                  values.companyname,
                  token,
                  'signUpFree',
                  emailField && emailField.value,
                  field,
                  values.plan,
                  values.owner,
                  values.passcode
                )
                  .then(res => {
                    disableLoading()
                    afterSubmit()
                    // props.login(res.data.data.accessToken);
                    dispatch(AlertState.actions.alert({
                      text: "Trial account created.",
                      variant: true
                    }));
                    //reset form
                    values.firstname = '';
                    values.lastname = '';
                    values.email = '';
                    values.phone = '';
                    values.companyname = '';
                    values.plan = 'gym_50_month';
                    values.owner = '';
                    values.passcode = '';
                    setSubmitting(false);
                  })
                  .catch(({ response }) => {
                    response.data.error && response.data.error && setError({
                      email: response.data.error.email && response.data.error.email[0],
                      firstname: response.data.error.first_name && response.data.error.first_name[0],
                      lastname: response.data.error.last_name && response.data.error.last_name[0],
                      phone: response.data.error.phone && response.data.error.phone[0],
                      companyname: response.data.error.companyName && response.data.error.companyName[0],
                    })
                    disableLoading()
                    setSubmitting(false);
                    setStatus(response.data.message || (typeof response.data.error === 'string' && response.data.error));
                  });
              })
          })
            .catch(() => {
              disableLoading();
              setSubmitting(false);
            })
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <div className="registration-form" style={{ margin: '0 18px 30px' }}>
            <div className="login-top">&nbsp;</div>
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
              className="sign-form"
            >
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}

              <input id="field10" name="field[10]" defaultValue="" style={{ display: 'none' }} />
              <input id="field11" name="field[11]" defaultValue="" style={{ display: 'none' }} />
              <input id="field12" name="field[12]" defaultValue="" style={{ display: 'none' }} />
              <input id="field13" name="field[13]" defaultValue="" style={{ display: 'none' }} />
              <input id="field14" name="field[14]" defaultValue="" style={{ display: 'none' }} />
              <input id="field42" name="field[42]" defaultValue="" style={{ display: 'none' }} />
              <input id="field43" name="field[43]" defaultValue="" style={{ display: 'none' }} />

              <div className="form-group mb-0">
                <TextField
                  key='12'
                  variant="outlined"
                  margin="normal"
                  label="First Name"
                  className="kt-width-full"
                  name="firstname"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 15
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstname}
                  helperText={touched.firstname && error.firstname}
                  error={Boolean(touched.firstname && error.firstname)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  key='13'
                  variant="outlined"
                  margin="normal"
                  label="Last Name"
                  className="kt-width-full"
                  name="lastname"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 15
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastname}
                  helperText={touched.lastname && error.lastname}
                  error={Boolean(touched.lastname && error.lastname)}
                />
              </div>

              <input id="neverbounce-valid-checkbox" type="text" style={{ display: 'none' }} />

              <div className="form-group mb-0">
                <TextField
                  key='14'
                  variant="outlined"
                  label="Email"
                  margin="normal"
                  className="kt-width-full texfield-email"
                  name="email"
                  inputProps={{
                    maxLength: 40,
                    'data-nb': true
                  }}
                  autoComplete={`off`}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  helperText={touched.email && error.email}
                  error={Boolean(touched.email && error.email)}
                />
              </div>

              <div className="form-group mb-0">
                <MuiPhoneNumber
                  key='15'
                  variant="outlined"
                  label="Phone"
                  margin="normal"
                  className="kt-width-full"
                  name="phone"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 19
                  }}
                  onBlur={handleBlur}
                  onChange={(value) => handleChange({ target: { value, name: 'phone' } })}
                  value={values.phone || '+1'}
                  helperText={touched.phone && error.phone}
                  error={Boolean(touched.phone && error.phone)}
                />
              </div>

              <div className="form-group mb-0">
                <TextField
                  key='16'
                  variant="outlined"
                  margin="normal"
                  label="Company Name"
                  className="kt-width-full"
                  name="companyname"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 50
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.companyname}
                  helperText={touched.companyname && error.companyname}
                  error={Boolean(touched.companyname && error.companyname)}
                />
              </div>

              <div className="form-group mb-0">
                <Select
                  variant="outlined"
                  margin="normal"
                  label="Plan"
                  className="kt-width-full MuiFormControl-marginNormal"
                  value={values.plan}
                  onChange={handleChange}
                  name="plan"
                  helperText={touched.plan && error.plan}
                  error={Boolean(touched.plan && error.plan)}
                >
                    <MenuItem value={'gym_50_month'}>Gym 50 Monthly</MenuItem>
                    <MenuItem value={'gym_50_year'}>Gym 50 Yearly</MenuItem>
                    <MenuItem value={'gym_100_month'}>Gym 100 Monthly</MenuItem>
                    <MenuItem value={'gym_100_year'}>Gym 100 Yearly</MenuItem>
                    <MenuItem value={'gym_250_month'}>Gym 250 Monthly</MenuItem>
                    <MenuItem value={'gym_250_year'}>Gym 250 Yearly</MenuItem>
                    <MenuItem value={'enterprise_100_month'}>Enterprise 100 Monthly</MenuItem>
                    <MenuItem value={'enterprise_100_year'}>Enterprise 100 Yearly</MenuItem>
                    <MenuItem value={'enterprise_250_month'}>Enterprise 250 Monthly</MenuItem>
                    <MenuItem value={'enterprise_250_year'}>Enterprise 250 Yearly</MenuItem>

                </Select>
              </div>
               
              <div className="form-group mb-0">
                <Select
                  variant="outlined"
                  margin="normal"
                  label="Contact Owner Email"
                  className="kt-width-full MuiFormControl-marginNormal"
                  value={values.owner}
                  onChange={handleChange}
                  name="owner"
                  helperText={touched.owner && error.owner}
                  error={Boolean(touched.owner && error.owner)}
                >
                    <MenuItem value={'devin.parrick@trainermetrics.com'}>devin.parrick@trainermetrics.com</MenuItem>
                    <MenuItem value={'henry.cho@trainermetrics.com'}>henry.cho@trainermetrics.com</MenuItem>
                    <MenuItem value={'marty.jones@trainermetrics.com'}>marty.jones@trainermetrics.com</MenuItem>
                </Select>
              </div>

              <div className="form-group mb-0">
                <TextField
                  key='16'
                  variant="outlined"
                  margin="normal"
                  label="Pass Code"
                  className="kt-width-full"
                  type="password"
                  name="passcode"
                  autoComplete={`off`}
                  inputProps={{
                    maxLength: 50
                  }}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.passcode}
                  helperText={touched.passcode && error.passcode}
                  error={Boolean(touched.passcode && error.passcode)}
                />
              </div>               

              <div className="kt-login__actions registration-btn" style={{'marginTop':'30px'}}>
                <Button
                  id="kt_login_signin_submit"
                  type="submit"
                  disabled={isSubmitting}
                  className={`btn btn-elevate kt-login__btn-primary btn-login btn-blue ${clsx(
                    {
                      "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                    }
                  )}`}
                  style={loadingButtonStyle}
                >
                  Create Trial Account
                  </Button>
              </div>
            </form>
          </div>
        )}
      </Formik>
    </div>
  </div>
    <Footer /></>);
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Registration)
);
