import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InputMask from 'react-input-mask';

export default ({ value, onChange, title, setDefault = true, onBlur = () => { }, error, id, position = true }) => {
    const [open, setOpen] = useState(false)
    const [touch, setTouch] = useState(false)
    const [values, setValues] = useState('00:00:00'.split(':'))
    const [tempValue, setTempValue] = useState(setDefault ? value : '')
    const [focused, setFocused] = useState(false);

    useEffect(() => {
        setValues(value.split(':').map(item => parseInt(item) >= 10 ? parseInt(item) : parseInt(item) >= 0 ? `0${parseInt(item)}` : '00'))
        setTempValue(value.split(':').findIndex(item => item !== '00') !== -1 ? value : '')
    }, [value, focused]);

    const editData = (num, type) => {
        setTouch(true)
        onBlur()
        let temp = [...values.map(item => parseInt(item))]
        if (type === '+') {
            temp[num] += 1
        } else if (type === '-') {
            temp[num] -= 1
        }

        if (temp[2] >= 60) {
            temp[2] = 0
            temp[1] += 1
        }
        if (temp[1] >= 60) {
            temp[1] = 0
            temp[0] += 1
        }
        if (temp[2] < 0) {
            temp[2] = 59
            temp[1] -= 1
        }
        if (temp[1] < 0) {
            temp[1] = 59
            temp[0] -= 1
        }
        if (temp[0] < 0) {
            temp[0] = 0
        }

        const result = temp.map(item => {
            return item < 10 ? `0${item}` : item < 0 ? '00' : `${item}`
        }).join(':')
        onChange(result)
        setTempValue(result)
    }

    const defaultChange = e => {
        if (e.target.value !== '__:__:__' && e.target.value !== '') setTouch(true)
        setTempValue(e.target.value)
    }

    const setDefaultBlur = () => {
        onBlur();
        setFocused(false);
        const temp = tempValue.split(':').map(item => parseInt(item))

        onChange([
            !temp[0] ? '00' : temp[0] < 10 ? `0${temp[0]}` : temp[0] < 0 ? '00' : `${temp[0]}`,
            !temp[1] ? '00' : temp[1] < 10 ? `0${temp[1]}` : temp[1] < 0 ? '00' : `${temp[1]}`,
            !temp[2] ? '00' : temp[2] < 10 ? `0${temp[2]}` : temp[2] < 0 ? '00' : `${temp[2]}`,
        ].join(':'))
    }

    return (
        <Picker onMouseLeave={() => setOpen(false)} className="picker-wrap">
            <div className={`picker${error && touch ? ' error' : ''}`} onClick={() => setOpen(true)}>
                <InputMask
                    mask="99:99:99"
                    className="picker-input"
                    value={tempValue}
                    onChange={defaultChange}
                    onBlur={setDefaultBlur}
                    onMouseOver={() => setFocused(true)}
                    placeholder={title}
                    id={id || null}
                />
                {
                    !!title && (
                        <div className={`picker-title${error && touch ? ' error' : ''}${tempValue !== '' ? ' top' : ''}`}>
                            {title}
                        </div>
                    )
                }
                {
                    open &&
                    <div className={`modal-p ${position ? 'bottom' : 'top'}`}>
                        <div className="modal-p__time">
                            <KeyboardArrowUpIcon onClick={() => editData(0, '+')} />
                            {isNaN(values[0]) ? '00' : values[0]}
                            <KeyboardArrowDownIcon onClick={() => editData(0, '-')} />
                        </div>
                        :
                        <div className="modal-p__time">
                            <KeyboardArrowUpIcon onClick={() => editData(1, '+')} />
                            {isNaN(values[1]) ? '00' : values[1]}
                            <KeyboardArrowDownIcon onClick={() => editData(1, '-')} />
                        </div>
                        :
                        <div className="modal-p__time">
                            <KeyboardArrowUpIcon onClick={() => editData(2, '+')} />
                            {isNaN(values[2]) ? '00' : values[2]}
                            <KeyboardArrowDownIcon onClick={() => editData(2, '-')} />
                        </div>
                    </div>
                }
            </div>
            {!!error && touch && (
                <p className="picker-input__valid">{error}</p>
            )}

        </Picker>
    )
}

const Picker = styled.div`
    margin-bottom: 8px;
    margin-top: 16px;
    font-family: 'Poppins';
    font-weight: 300;


    .picker {
        height: 40px;
        border: 1px solid #0000003b;
        border-radius: 4px;
        padding: 0 14px;
        position: relative;

        &.error {
            border: 1px solid #fd397a;
        }

        &-title {
            color: rgba(0, 0, 0, 0.54);
            font-size: 13px;
            font-family: 'Poppins';
            font-weight: 400;
            background: white;
            transform: scale(1) !important;
            padding: 0;
            display: none;

            &.error {
                color: #fd397a;
                font-weight: 500;
            }

            &.top {
                display: block;
                position: absolute;
                top: 0;
                left: -8px;
                transform: translateY(-50%) scale(0.75) !important;
                padding: 0 5px;
            }
        }

        &-input {
            height: 100%;
            width: 100%;
            border: none;
            background: transparent;
            color: rgba(0, 0, 0, 0.87);
            font-weight: 300;

            &:focus {
                outline: none;
            }

            &__valid {
                color: #fd397a;
                margin: 8px 14px 0;
                font-size: 0.75rem;
                font-family: 'Poppins';
                font-weight: 400;
            }
        }
    }

    .modal-p {
        position: absolute;
        right: 0;
        height: 112px;
        width: 180px;
        background-color: #fff;
        border: 0 solid rgba(0,0,0,.15);
        border-radius: .42rem;
        box-shadow: 0 0 50px 0 rgba(82,63,105,.15);
        z-index: 999;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.bottom {
            top: calc(100% + 1px);
            cursor: default;
        }

        &.top {
            bottom: calc(100% + 1px);
            cursor: default;
        }

        &__time {
            padding: 10px;
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: column;
        }
    }

`