import { useEffect, useState } from "react";
import moment from "moment";

import useDebounce from "../../../../hooks/useDebounce";
import { sendDataToBackend } from "../helpers/transfromDateFilters";
import { SESSIONS_TABLE_TYPE } from "../constants/constants";
import { getForecastList } from "../../../../crud/forecast.crud";

const initPagination = {
  current_page: 1,
  last_page: 1,
  per_page: 10,
  from: 0,
  to: 10,
  total: 0,
};

const tableDTO = (data) => {
  return Object.entries(data).map(([month, value]) => ({
    values: {
      ...value.totals,
      month,
      items: value.items?.map((item) => ({ ...item, row_month: month })),
    },
  }));
};

export const useForecasts = ({
  initialSort = ["", ""],

  profileId = null,
  tableStatus = SESSIONS_TABLE_TYPE.ACTIVE,
}) => {
  const currentMonth = moment().format("MM");
  const currentYear = moment().format("YYYY");
  const endMonth = moment()
    .add(5, "months")
    .format("MM");
  const endYear = moment()
    .add(5, "months")
    .format("YYYY");

  const [startMonth, setStartMonth] = useState(currentMonth);
  const [startYear, setStartYear] = useState(currentYear);
  const [endMonthState, setEndMonthState] = useState(endMonth);
  const [endYearState, setEndYearState] = useState(endYear);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(initialSort);

  const [groups, setGroups] = useState([0]);
  const [staff, setStaff] = useState([0]);
  const [status, setStatus] = useState(tableStatus);

  const [pagination, setPagination] = useState(initPagination);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const startDate = moment(`${startYear}-${startMonth}-01`)
    .startOf("month")
    .format("MM/DD/YYYY");
  const endDate = moment(`${endYearState}-${endMonthState}-01`)
    .endOf("month")
    .format("MM/DD/YYYY");

  useEffect(() => {
    const fetch = async () => {
      if (
        !moment(startDate, "MM/DD/YYYY", true).isValid() ||
        !moment(endDate, "MM/DD/YYYY", true).isValid()
      ) {
        return;
      }
      setLoading(true);

      try {
        const params = {
          page,
          per_page: perPage,
          group: groups.filter(Boolean),
          staff: staff.filter(Boolean),

          sort_field: sort?.[0],
          sort_direction: sort?.[1],
          profile: profileId ? [profileId] : undefined,
          start_date: startDate,
          end_date: endDate,
        };
        const res = await getForecastList(params);

        setData(tableDTO(res.data.data));

        setPagination(res.data.meta);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, [
    groups,
    staff,
    startYear,
    startMonth,
    endYearState,
    endMonthState,
    //  startDate, endDate,
    page,
    perPage,
    status,
    sort,
  ]);

  return {
    loading,
    error,
    data,

    setSort,
    sort,
    groups,
    setGroups,
    staff,
    setStaff,
    setStatus,
    status,
    setStartMonth,
    setStartYear,
    setEndMonthState,
    setEndYearState,

    startYear,
    startMonth,
    endYearState,
    endMonthState,
    // setStartDate,
    // setEndDate,
    startDate,
    endDate,
    pagination,
    setPagination,
    page,
    perPage,
    setPage,
    setPerPage,
    setData,
  };
};
