import React, { useEffect, useState, useRef } from 'react';
import { Table } from './Table/Table';
import Pagination from '../../../../../components/Pagination';
import { Button } from 'react-bootstrap';
import { SpacerVertical } from '../../../../../components/Spacer';
import AddMappingModal from './AddMappingModal';
import { useDispatch, useSelector } from 'react-redux';
import * as AlertState from '../../../../../../app/store/ducks/auth.duck';
import { fetchMappings, postRefresh } from '../../../../../crud/crm.crud';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import question from '../../../../../../_metronic/_assets/media/svg-icon/question.svg';
import CreateProfileModal from './CreateProfileModal';

const StyledToolTip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export const CRMMappings = ({ setLoader, crm, crmData, loadCRMSingle }) => {
  const [show, setShow] = useState('10');
  const [page, setPage] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [sort, setSort] = useState(['internal_text', 'asc']);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.appData?.account?.id);
  const [dropdown, setDropdown] = useState(false);
  const buttonRef = useRef();
  const [modalCreateFromCRM, setModalCreateFromCRM] = useState(false);
  const [isMappingsLoaded, setIsMappingsLoaded] = useState(false);
  const access = useSelector((state) =>
    state.auth?.user?.permissions?.find(
      (elem) => elem === 'create_profile_from_crm'
    )
  );

  useEffect(() => {
    function clickOutside(e) {
      if (buttonRef && !buttonRef?.current?.contains(e.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener('mousedown', clickOutside);
    return () => document.removeEventListener('mousedown', clickOutside);
  }, [buttonRef]);

  const getMappings = () => {
    if (!crm || !accountId) return;
    setLoader(true);
    fetchMappings(accountId, crm.id, 'profiles', {
      page: pagination,
      limit: show,
      'sort[by]': sort[0],
      'sort[direction]': sort[1],
    })
      .then((res) => {
        setData(res.data.list);
        setLastPage(res.data.pagination.last_page);
        setPage({
          ...res.data.pagination,
          last_page: res.data.pagination.last_page,
          per_page: res.data.pagination.limit,
          current_page: res.data.pagination.page,
        });
      })
      .catch((err) => {
        let errText = 'Not exist data mapped';
        if (err.response?.data?.error) {
          errText = err.response?.data?.error;
        } else if (err.response?.data?.message) {
          errText = err.response?.data?.message;
        }
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => {
        setIsMappingsLoaded(true);
        setLoader(false);
      });
  };

  useEffect(() => {
    getMappings();
  }, [accountId, crm, show, pagination, sort]);

  const handleRefresh = () => {
    setLoader(true);
    postRefresh(accountId, crm.id, 'profiles')
      .then(() => {
        setTimeout(() => {
          getMappings();
        }, 10000);
        dispatch(
          AlertState.actions.alert({
            text: `Sync has started`,
            variant: true,
          })
        );
        loadCRMSingle();
      })
      .catch((err) => {
        let errText = 'request failed';
        if (err.response?.data?.error) {
          errText = err.response?.data?.error;
        } else if (err.response?.data?.message) {
          errText = err.response.data.message;
        }
        dispatch(
          AlertState.actions.alert({
            text: errText,
            variant: false,
          })
        );
      })
      .finally(() => setLoader(false));
  };

  return (
    <>
      <div className="top-buttons">
        {crmData?.syncs?.profiles && (
          <div className="d-flex align-items-center">
            <div className="last-sync-block">
              {`Last Sync Started: ${moment(
                crmData?.syncs?.profiles?.sync_started_at
              ).format('MM/DD/YYYY hh:mm:ss a')}`}
            </div>
            {/* <StyledToolTip
              title={
                <>
                  Synchronization may take some time. <br />
                  To see if the synchronization is complete, please refresh the
                  page and observe the changes in this table.
                </>
              }
              placement="bottom"
            >
              <img
                src={question}
                style={{
                  cursor: 'pointer',
                  margin: '0 5px 0 10px',
                  height: '18px',
                }}
                alt="tooltip"
              />
            </StyledToolTip> */}
          </div>
        )}
        {/* <Button
                    variant="info"
                    className="button"
                    onClick={handleRefresh}
                >Refresh</Button> */}
        <SpacerVertical width="20px" />

        {access && (
          <div style={{ position: 'relative' }}>
            <Button
              className="btn-blue desktop-vis"
              onClick={() => setDropdown(true)}
              style={{ whiteSpace: 'nowrap', margin: 5 }}
              disabled={!isMappingsLoaded}
            >
              Add
            </Button>

            <div
              className="buttons-dropdown"
              style={{
                display: `${dropdown ? '' : 'none'}`,
                top: '0',
                right: '0',
              }}
              ref={buttonRef}
            >
              <div className="buttons-dropdown__elem">
                <span
                  onClick={() => {
                    setModal(true);
                    setDropdown(false);
                  }}
                >
                  Add Mapping
                </span>
              </div>
              <div className="buttons-dropdown__elem">
                <span
                  onClick={() => {
                    setModalCreateFromCRM(true);
                    setDropdown(false);
                  }}
                >
                  Create Profiles from CRM
                </span>
              </div>
              <div
                className="buttons-dropdown__arrow"
                onClick={() => setDropdown(false)}
              >
                {'>'}
              </div>
            </div>
          </div>
        )}

        {!access && (
          <Button
            className="btn-blue desktop-vis"
            onClick={() => {
              setModal(true);
              setDropdown(false);
            }}
            style={{ whiteSpace: 'nowrap', margin: 5 }}
            disabled={!isMappingsLoaded}
          >
            Add
          </Button>
        )}
      </div>

      <Table
        data={data}
        setLoader={setLoader}
        setPagination={setPagination}
        sort={sort}
        setSort={setSort}
        pagination={pagination}
        getMappings={getMappings}
        accountId={accountId}
        crm={crm}
      />

      <Pagination
        data={data}
        show={show}
        setShow={setShow}
        page={page}
        setPage={setPage}
        pagination={pagination}
        setPagination={setPagination}
        lastPage={lastPage}
      />

      {modal && (
        <AddMappingModal
          modal={modal}
          setModal={setModal}
          getMappings={getMappings}
          setLoader={setLoader}
        />
      )}

      {modalCreateFromCRM && (
        <CreateProfileModal
          modal={modalCreateFromCRM}
          setModal={setModalCreateFromCRM}
          getMappings={getMappings}
          setLoader={setLoader}
        />
      )}
    </>
  );
};
