import React, { useState, useEffect, useRef } from 'react';
import { Event } from './Event';
import moment from 'moment';
import './calendar-component.scss';
import { useLocation } from 'react-router-dom';

const timeArray = [
  '12 am',
  '1 am',
  '2 am',
  '3 am',
  '4 am',
  '5 am',
  '6 am',
  '7 am',
  '8 am',
  '9 am',
  '10 am',
  '11 am',
  '12 pm',
  '1 pm',
  '2 pm',
  '3 pm',
  '4 pm',
  '5 pm',
  '6 pm',
  '7 pm',
  '8 pm',
  '9 pm',
  '10 pm',
  '11 pm',
];

export const DayCalendar = ({
  events,
  users,
  selectedUser,
  setSelectedEventId,
  setCreateModal,
  isDashBoard = false,
}) => {
  const calendarRef = useRef();
  const location = useLocation();
  const isProfile = !!location.pathname.split('/')[3];

  const usersList = selectedUser
    ? [selectedUser]
    : isProfile
    ? [false]
    : users
    ? users
    : [false];

  if (usersList.length === 0) usersList[0] = false;
  const [dayEvents, setDayEvents] = useState([]);
  const [eventsWithProps, setEventsWithProps] = useState([]);

  console.log('dayEvents', dayEvents);

  useEffect(() => {
    if (events.length && events[0].users) {
      const eventsArray = [];

      events.forEach((elem) => {
        elem.users.forEach((item) => {
          const userIndex = elem.users.findIndex((user) => user.id === item.id);
          const usersArray = [...elem.users];
          usersArray.splice(userIndex, 1);
          usersArray.unshift({ ...item });
          eventsArray.push({
            ...elem,
            users: usersArray,
          });
        });
      });

      setDayEvents(eventsArray);
    } else {
      setDayEvents(events);
    }
  }, [events]);

  useEffect(() => {
    if (!dayEvents.length) {
      return setEventsWithProps([]);
    } else {
      setEventsWithProps(dayEvents);
    }

    // let idCount = 1;
    // const exclude = [];
    // const result = dayEvents.map((elemA) => {
    //   // if match was found before - add matchId to current event
    //   const excludedId = exclude.find((item) => {
    //     return item.id === elemA.id && item.users[0]?.id === elemA.users[0]?.id;
    //   })?.matchId;
    //   if (excludedId) {
    //     return {
    //       ...elemA,
    //       matchId: excludedId,
    //     };
    //   }

    //   const matches = dayEvents.filter((elemB) => {
    //     // find out if event is assigned to a similar user
    //     if (elemA.users[0].id !== elemB.users[0].id) return false;

    //     // find out if event is similat to itself
    //     if (elemA.id === elemB.id) return false;

    //     // find out if events overlap each other
    //     if (
    //       moment(elemA.end_at).isBetween(
    //         moment(elemB.start_at).subtract(1, 's'),
    //         moment(elemB.end_at).add(1, 's')
    //       ) ||
    //       moment(elemA.start_at).isBetween(
    //         moment(elemB.start_at).subtract(1, 's'),
    //         moment(elemB.end_at).add(1, 's')
    //       )
    //     ) {
    //       exclude.push({
    //         ...elemB,
    //         matchId: idCount,
    //       });
    //       return true;
    //     } else return false;
    //   });

    //   // add matchId to current event
    //   if (matches.length > 0) {
    //     const matchId = idCount;
    //     idCount = idCount + 1;
    //     return {
    //       ...elemA,
    //       matchId: matchId,
    //     };
    //   }

    //   // if no matches
    //   return elemA;
    // });
  }, [dayEvents]);

  const getHeight = (event) => {
    const startAt = new Date(event.start_at);
    const endAt = new Date(event.end_at);
    const diff = endAt - startAt;
    var minutes = Math.floor(diff / 60000);
    return `${minutes}px`;
  };

  const getTop = (event) => {
    const hours = moment(event.start_at).format('HH');
    const minutes = moment(event.start_at).format('mm');
    let result = +hours * 60 + +minutes;
    return `${result}px`;
  };

  const getColumnWidth = () => {
    if (usersList.length < 2) {
      return 100 / 6;
    }
    return 100 / (usersList.length + 1);
  };

  const eventWidth = () => {
    if (usersList.length < 2) {
      return 100;
    } else {
      return 100 / usersList.length;
    }
  };

  const getLeft = (event) => {
    const cuttedEventWidth = () => {
      if (event && event.matchId) {
        let eventsCount = eventsWithProps.filter((elem) => {
          if (
            elem.matchId === event.matchId &&
            elem.users[0].id === event.users[0]?.id
          )
            return true;
          return false;
        }).length;
        return eventWidth() / eventsCount;
      }
      return eventWidth();
    };

    let eventIndex = 0;

    if (event.matchId) {
      const matches = eventsWithProps.filter((elem) => {
        if (
          elem.matchId === event.matchId &&
          elem.users[0].id === event.users[0]?.id
        )
          return true;
        return false;
      });
      eventIndex = matches.findIndex((elem) => elem?.id === event?.id);
    }

    if (usersList.length < 2) {
      return `calc(${0 + 'px'} + ${eventIndex * cuttedEventWidth() + '%'})`;
    }

    const userIndex = usersList.findIndex(
      (elem) => elem?.id === event.users[0]?.id
    );

    return `calc(${userIndex * eventWidth() + '%'} + ${eventIndex *
      cuttedEventWidth() +
      '%'})`;
  };

  const getCuttedEventWidth = (event) => {
    if (event && event.matchId) {
      let eventsCount = eventsWithProps.filter((elem) => {
        if (
          elem.matchId === event.matchId &&
          elem.users[0].id === event.users[0].id
        )
          return true;
        return false;
      }).length;
      return eventWidth() / eventsCount + '%';
    }
    return eventWidth() + '%';
  };

  const getColumnsStyle = () => {
    if (window.innerWidth < 450 && usersList.length < 2) {
      return '100px 1fr';
    }

    if (usersList.length < 2) {
      return ' 1fr 5fr';
    }

    return `repeat(${usersList.length + 1}, 1fr)`;
  };

  const getLeftToBody = () => {
    if (window.innerWidth < 450 && usersList.length < 2) {
      return '100px';
    }
    return getColumnWidth() + '%';
  };

  const getWidthForBody = () => {
    if (usersList.length < 2) {
      if (window.innerWidth < 450) {
        return `calc(100% - 100px)`;
      }
      return 100 - getColumnWidth() + '%';
    } else {
      return 100 - getColumnWidth() + '%';
    }
  };

  const getMinWidth = (event) => {
    if (event && event.matchId) {
      let eventsCount = eventsWithProps.filter(
        (elem) => elem.matchId === event.matchId
      ).length;
      return 100 / eventsCount + 'px';
    }
    return '100px';
  };

  const getCalendarMinWidth = () => {
    return '100%';
  };

  const getCalendarWidth = () => {
    return 100 + 100 * usersList.length + 'px';
  };

  return (
    <div className="calendar-component" ref={calendarRef}>
      <div
        className={`calendar-component-content ${
          isProfile ? 'calendar-component-content-profile' : ''
        } ${isDashBoard ? 'calendar-component-content-dashboard' : ''}`}
        style={{
          minWidth: getCalendarMinWidth(),
          width: getCalendarWidth(),
        }}
      >
        {timeArray.map((elem, index) => (
          <div
            key={index}
            className="calendar-row"
            style={{ gridTemplateColumns: getColumnsStyle() }}
          >
            <div
              className="calendar-row__elem"
              style={{ minWidth: isDashBoard ? 'auto' : '100px' }}
            >
              {elem}
            </div>
            {!usersList[0] ? (
              <div className="calendar-row__elem"></div>
            ) : (
              usersList.map((elem) => (
                <div className="calendar-row__elem" key={elem?.id}></div>
              ))
            )}
          </div>
        ))}

        <div
          className="calendar-body"
          style={{
            left: getLeftToBody(),
            width: getWidthForBody(),
          }}
        >
          {/* {console.log('eventsWithProps', eventsWithProps)} */}
          {eventsWithProps.map((event, index) => {
            return (
              <Event
                key={'' + event?.id + index}
                item={event}
                getHeight={getHeight}
                getEventWidth={getCuttedEventWidth}
                getTop={getTop}
                getLeft={getLeft}
                getMinWidth={getMinWidth}
                onClick={() => {
                  setSelectedEventId(event?.id);
                  setCreateModal(true);
                }}
              />
            );
          })}
        </div>
      </div>

      {!usersList[0] ? (
        <div className="head__elem-first" />
      ) : (
        <div
          className="head"
          style={{ gridTemplateColumns: getColumnsStyle() }}
        >
          <div className="head__elem-first" />
          {usersList.map((elem) => (
            <div className="head__elem" key={elem?.id}>
              {elem.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
