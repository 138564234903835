import React from 'react';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';

export const TableRow = ({ item, check, setCheck }) => {
    const handleCheck = item => {
        const index = check.findIndex(elem => elem.id === item.id);
        if (index !== -1) {
            setCheck(prev => {
                let arr = [...prev];
                arr.splice(index, 1);
                return arr;
            })
        } else {
            setCheck(prev => ([...prev, { id: item.id, is_editable: item.is_editable }]))
        }
    };

    return (
        <tr key={item.id}>
            <td>
                <Checkbox
                    color="primary"
                    onChange={() => handleCheck(item)}
                    checked={check.find(elem => elem.id === item.id) !== undefined}
                />
            </td>
            <td className='left-content'>
                {item.title}
            </td>
            <td className='left-content'>
                {item.program || '-'}
            </td>
            <td className='left-content'>
                {item.created_by}
            </td>
            <td className='left-content'>
                {moment(item.created_at).format('MM/DD/YYYY')}
            </td>
            <td className='left-content'>
                {item.profiles_assigned || '0'}
            </td>
        </tr>
    )
};
