import React from "react";
import { Checkbox } from "@material-ui/core";
import moment from "moment";

export const TableRow = ({
  item,
  check,
  tableIndex,
  setTablesArray,
  status,
}) => {
  const handleCheck = (id) => {
    const index = check.findIndex((elem) => elem === id);
    if (index !== -1) {
      setTablesArray((prev) => {
        let arr = [...prev];
        arr[tableIndex].check.splice(index, 1);
        return arr;
      });
    } else {
      setTablesArray((prev) => {
        let arr = [...prev];
        let result = [...arr[tableIndex].check, id];
        arr[tableIndex].check = result;
        return arr;
      });
    }
  };

  return (
    <tr key={item.id}>
      {status !== "isDashboard" && (
        <td>
          <Checkbox
            color="primary"
            onChange={() => handleCheck(item.id)}
            checked={check.find((elem) => elem === item.id) !== undefined}
          />
        </td>
      )}

      <td className="left-content">{item.title}</td>
      <td className="left-content">
        {moment(item.start_at).format("hh:mm a")}
      </td>
      <td className="left-content">{moment(item.end_at).format("hh:mm a")}</td>
      <td className="left-content">
        {item.profiles?.length
          ? item.profiles
              .map((elem) => `${elem.first_name} ${elem.last_name}`)
              .join(", ")
          : "-"}
      </td>
      <td className="left-content">
        {item.users?.length
          ? item.users
              .map((elem) => `${elem.first_name} ${elem.last_name}`)
              .join(", ")
          : "-"}
      </td>
    </tr>
  );
};
