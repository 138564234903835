import React, { useEffect, useState } from "react";
import { Table } from "./WeekAgendaTable/Table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../../../components/Loader";
import { Button } from "react-bootstrap";
import ConfirmModal from "../../../../components/ConfirmationModal";
import { deleteEvents } from "../../../../crud/dashboard.crud";
import { completeEvents } from "../../../../crud/dashboard.crud";
import * as AlertState from "../../../../store/ducks/auth.duck";
import catchErrorMessage from "../../../../helpers/errorCatcher";
import "./agenda-tab.scss";

export const WeekAgenda = ({
  events,
  dateFrom,
  dateTo,
  loadEvents,
  setSelectedEventId,
  setCreateModal,
}) => {
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.appData.account.id);
  const [tablesArray, setTablesArray] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [currentTable, setCurrentTable] = useState(null);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const role = useSelector((state) => state.auth.user.roles);

  useEffect(() => {
    if (dateFrom && dateTo && events) {
      let difference =
        new Date(dateTo).getTime() - new Date(dateFrom).getTime();
      let daysCount = Math.ceil(difference / (1000 * 3600 * 24));
      if (daysCount > 0) daysCount += 1;
      if (daysCount === 0) daysCount = 1;
      let tables = [];

      // push dates, other fields to tables
      for (let i = 0; i < daysCount; i++) {
        const today =
          moment(
            new Date(dateFrom).setDate(new Date(dateFrom).getDate() + i)
          ).format("dddd MM/DD") === moment().format("dddd MM/DD");
        tables.push({
          tableDate: new Date(dateFrom).setDate(
            new Date(dateFrom).getDate() + i
          ),
          displayDate: today
            ? `Today: ${moment().format("dddd MM/DD")}`
            : moment(
                new Date(dateFrom).setDate(new Date(dateFrom).getDate() + i)
              ).format("dddd MM/DD"),
          events: [],
          sort: [null, null],
          check: [],
        });
      }

      // push events to tables
      events.forEach((elem) => {
        // need fix if events duration can be grater than one day
        const tableIndex = tables.findIndex((item) => {
          const tableDate = moment(item.tableDate).format("YYYY/MM/DD");
          const eventDate = moment(elem.start_at).format("YYYY/MM/DD");
          return tableDate === eventDate;
        });
        if (tableIndex !== -1) tables[tableIndex].events.push(elem);
      });

      setTablesArray(tables);
    }
  }, [dateFrom, dateTo, events]);

  const handleDelete = () => {
    if (currentTable >= 0) {
      const checked = tablesArray[currentTable].check;
      setDeleteLoader(true);
      deleteEvents(accountId, checked)
        .then(() => {
          loadEvents();
          setDeleteModal(false);
          dispatch(
            AlertState.actions.alert({
              text: "event deleted successfully",
              variant: true,
            })
          );
        })
        .finally(() => setDeleteLoader(false));
    }
  };

  const completeEvent = (index) => {
    if (index >= 0) {
      const checked = tablesArray[index].check;
      setLoader(true);
      completeEvents(accountId, checked)
        .then(() => {
          dispatch(
            AlertState.actions.alert({
              text: "The events has been successfully completed",
              variant: true,
            })
          );
        })
        .catch((err) => {
          let errText = catchErrorMessage(err);
          dispatch(
            AlertState.actions.alert({
              text: errText,
              variant: false,
            })
          );
        })
        .finally(() => setLoader(false));
    }
  };

  return (
    <div className="agenda-tab">
      <Loader visible={loader} />

      <ConfirmModal
        modal={deleteModal}
        setModal={setDeleteModal}
        handleConfirm={handleDelete}
        title={`Are you sure you want to delete events?`}
        submitText="Confirm"
        loader={deleteLoader}
      />

      {tablesArray?.map((elem, index) => {
        return (
          <div key={index} style={{ marginBottom: 20 }}>
            <div className="agenda-tab__buttons">
              <h1 className="title">{elem.displayDate}</h1>

              {elem.check.length > 0 && (
                <>
                  {role !== "client" && (
                    <Button
                      variant="secondary"
                      onClick={() => {
                        completeEvent(index);
                      }}
                      style={{ marginLeft: 15 }}
                      className="forms-list__filter-button"
                    >
                      Complete
                    </Button>
                  )}

                  {elem.check.length === 1 && (
                    <>
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setSelectedEventId(elem.check[0]);
                          setCreateModal(true);
                        }}
                        style={{ marginLeft: 15 }}
                        className="forms-list__filter-button"
                      >
                        {role === "client" ? "View" : "Edit"}
                      </Button>
                    </>
                  )}

                  {role !== "client" && (
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setCurrentTable(index);
                        setDeleteModal(true);
                      }}
                      style={{ marginLeft: 15 }}
                      className="forms-list__filter-button"
                    >
                      Delete
                    </Button>
                  )}
                </>
              )}
            </div>

            <Table
              data={elem.events}
              check={elem.check}
              sort={elem.sort}
              tableIndex={index}
              setTablesArray={setTablesArray}
            />
          </div>
        );
      })}
    </div>
  );
};
