import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import { fetchCRMSync, postSync } from '../../../../crud/crm.crud';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const StyledToolTip = withStyles((theme) => ({
    tooltip: {
        boxShadow: theme.shadows[1],
        fontSize: 14,
    },
}))(Tooltip);

export const CRMSync = ({ setLoader, crm }) => {
    const [switched, setSwitched] = useState({});
    const dispatch = useDispatch();
    const accountId = useSelector(state => state.appData?.account?.id);

    useEffect(() => {
        if (!accountId || !crm) return;
        setLoader(true);
        fetchCRMSync(accountId, crm.id, 'groups')
            .then(res => {
                setSwitched(res.data.list);
            })
            .finally(() => setLoader(false))
    }, [accountId, crm]);

    const handleSwitch = item => {
        let data = {
            ...switched,
            [item]: !switched[item],
        };
        setLoader(true);

        postSync(accountId, crm.id, 'groups', data)
            .then((res) => {
                setSwitched(res.data.list)
                dispatch(AlertState.actions.alert({
                    text: 'Update successful',
                    variant: true,
                }));
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => setLoader(false))
    };

    return (
        <div className="flex">
            <div className="block">

                <h5 className="block__title">SYNC</h5>
                <div className="block__row">
                    <div className="block__text-container">
                        <div className="block__subtitle">Polling</div>
                        <div className="block__text">Periodically poll CRM for new and changed Groups</div>
                    </div>
                    <StyledToolTip
                        title={!switched.is_inbound_update
                            ? "Inbound Update should be enabled"
                            : ""
                        }
                        placement="bottom"
                    >
                        <div className="switcher">
                            <Switch
                                checked={!!switched.is_sync_polling}
                                onChange={() => handleSwitch('is_sync_polling')}
                                color="primary"
                                name="checkedA"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                disabled={!switched.is_inbound_update}
                            />
                        </div>
                    </StyledToolTip>
                </div>
                <div className="block__row">
                    <div className="block__text-container">
                        <div className="block__subtitle">Pushing</div>
                        <div className="block__text">Automatically push changes to CRM</div>
                    </div>
                    <div className="switcher">
                        <Switch
                            checked={!!switched.is_sync_pushing}
                            onChange={() => handleSwitch('is_sync_pushing')}
                            color="primary"
                            name="checkedA"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled
                        />
                    </div>
                </div>
            </div>

            <div className="block">
                <h5 className="block__title">INBOUND</h5>
                <div className="block__row">
                    <div className="block__text-container">
                        <div className="block__subtitle">Inbound Create</div>
                        <div className="block__text">Create new Groups</div>
                    </div>
                    <div className="switcher">
                        <Switch
                            checked={!!switched.is_inbound_create}
                            onChange={() => handleSwitch('is_inbound_create')}
                            color="primary"
                            name="checkedA"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled
                        />
                    </div>
                </div>
                <div className="block__row">
                    <div className="block__text-container">
                        <div className="block__subtitle">Inbound Update</div>
                        <div className="block__text">Update existing Groups</div>
                    </div>
                    <div className="switcher">
                        <Switch
                            checked={!!switched.is_inbound_update}
                            onChange={() => handleSwitch('is_inbound_update')}
                            color="primary"
                            name="checkedA"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                        />
                    </div>
                </div>
            </div>

            <div className="block">
                <h5 className="block__title">OUTBOUND</h5>
                <div className="block__row">
                    <div className="block__text-container">
                        <div className="block__subtitle">Outbound Create</div>
                        <div className="block__text">Create new Groups</div>
                    </div>
                    <div className="switcher">
                        <Switch
                            checked={!!switched.is_outbound_create}
                            onChange={() => handleSwitch('is_outbound_create')}
                            color="primary"
                            name="checkedA"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled
                        />
                    </div>
                </div>
                <div className="block__row">
                    <div className="block__text-container">
                        <div className="block__subtitle">Outbound Update</div>
                        <div className="block__text">Update existing Groups</div>
                    </div>
                    <div className="switcher">
                        <Switch
                            checked={!!switched.is_outbound_update}
                            onChange={() => handleSwitch('is_outbound_update')}
                            color="primary"
                            name="checkedA"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};