import React, { useState, useEffect } from "react";
import { getImg } from "../../../crud/media.crud";

export const ImgComponent = ({ src, alt, className, style }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    src &&
      getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);
  return (
    url && <img src={url} alt={alt} className={className} style={style || {}} />
  );
};
