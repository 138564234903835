import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { SpacerVertical } from '../../../../../components/Spacer';
import { Table } from './Table/Table';
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from '../../../../../../app/store/ducks/auth.duck';
import { fetchCRMFields, postCRMFields } from '../../../../../crud/crm.crud';

export const CRMFields = ({ setLoader, crm }) => {
    const [data, setData] = useState([]);
    const [changed, setChanged] = useState(false);
    const dispatch = useDispatch();
    const accountId = useSelector(state => state.appData?.account?.id);

    const getFields = () => {
        if (!accountId || !crm) return;
        setLoader(true);
        fetchCRMFields(accountId, crm.id, 'events')
            .then(res => {
                let data = res.data.list.map(elem => {
                    return {
                        ...elem,
                        is_updates_in_enabled: elem.is_updates_in_enabled || false,
                        is_updates_out_enabled: elem.is_updates_out_enabled || false
                    }
                });
                setData(data);
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => setLoader(false))
    };

    useEffect(() => {
        getFields();
    }, [crm, accountId]);

    const onSave = () => {
        if (changed) {
            setLoader(true);
            postCRMFields(accountId, crm.id, 'events', { list: data })
                .then(() => {
                    setChanged(false);
                    dispatch(AlertState.actions.alert({
                        text: 'Changes has been saved',
                        variant: true,
                    }));
                })
                .catch(err => {
                    let errText = 'request failed';
                    if (err.response?.data?.error) {
                        errText = err.response?.data?.error;
                    } else if (err.response?.data?.message) {
                        errText = err.response.data.message;
                    }
                    dispatch(AlertState.actions.alert({
                        text: errText,
                        variant: false,
                    }));
                })
                .finally(() => setLoader(false))
        } else {
            dispatch(AlertState.actions.alert({
                text: 'There is nothing to save',
                variant: false,
            }));
        }
    };

    return (
        <>
            <div className="top-buttons">
                <SpacerVertical width="20px" />
                <Button
                    variant="primary"
                    className="button btn-blue desktop-vis"
                    onClick={onSave}
                >Save</Button>
            </div>

            <Table
                data={data}
                setData={setData}
                setChanged={setChanged}
            />
        </>
    )
};
