import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Modal,
    Button,
} from 'react-bootstrap';
import { SpacerHorizontal } from '../../../../../components/Spacer';
import Dropdown from '../../../../../components/MultipleSelectCheckmarksV2';
import * as AlertState from '../../../../../../app/store/ducks/auth.duck';
import Loader from '../../../../../components/Loader';
import { useDispatch, useSelector } from "react-redux";
import { fetchExternals, saveMapping, getFields } from '../../../../../crud/crm.crud';
import { createProfile } from '../../../../../crud/profile.crud';
import moment from 'moment';
import * as media from '../../../../../crud/media.crud';
import { postProfilePhoto } from '../../../../../crud/file.crud';
import './create-profile.modal.scss';

let isCancell = false;

const CreateProfileModal = ({
    modal,
    setModal,
    getMappings,
}) => {
    const [checked, setChecked] = useState([]);
    const [crmProfiles, setCrmProfiles] = useState([]);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.is_activated === true));
    const accountId = useSelector(state => state.appData?.account?.id);
    const [step, setStep] = useState(1);
    const [addedCount, setAddedCount] = useState(0);
    const [errorsCount, setErrorsCount] = useState(0);
    const [failed, setFailed] = useState([]);

    useEffect(() => {
        isCancell = false;
        if (!accountId || !crm) return;
        setLoader(true);
        fetchExternals(accountId, crm.id, 'profiles', {
            page: 1,
            limit: 1000,
        })
            .then(res => {
                const list = res.data.list.map(elem => ({
                    title: elem.email ? `${elem.first_name} ${elem.last_name} (${elem.email})` : `${elem.first_name} ${elem.last_name}`,
                    ...elem
                }))
                const sorted = list
                    .sort((a, b) => {
                        let first = a.title.toLowerCase();
                        let second = b.title.toLowerCase();
                        if (first < second) { return -1; }
                        if (first > second) { return 1; }
                        return 0;
                    })
                    .filter(elem => !elem.is_mapped)
                    .map((elem) => ({...elem, title: elem.first_name + ' ' + elem.last_name}))
                setCrmProfiles(sorted)
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => setLoader(false))
    }, []);

    const handleClose = () => {
        if (step === 2) isCancell = true;
        getMappings();
        setChecked([]);
        setLoader(false);
        setModal(false);
    };

    const handleSave = () => {
        setStep(2);

        let counter = 0;
        const intervalFunction = () => {
            let index = counter;
            if (isCancell || !checked[index]) {
                isCancell = false;
                return clearInterval(interval)
            };

            getFields(accountId, crm.id, 'profiles', checked[index].id)
                .then((res1) => {
                    const values = {
                        first_name: res1.data.single.first_name || checked[index]?.first_name || null,
                        last_name: res1.data.single.last_name || checked[index]?.last_name || null,
                        birthday_day: res1.data.single.birthday_at ? moment(res1.data.single.birthday_at).format('DD') : null,
                        birthday_month: res1.data.single.birthday_at ? moment(res1.data.single.birthday_at).format('MM') : null,
                        birthday_year: res1.data.single.birthday_at ? moment(res1.data.single.birthday_at).format('YYYY') : null,
                        gender: res1.data.single.gender || checked[index]?.gender ? checked[index].gender.charAt(0).toUpperCase() + checked[index].gender.slice(1) : null,
                        heightFt: null,
                        heightIn: null,
                        email: res1.data.single.email?.trim() || checked[index]?.email?.trim() || null,
                        heightM: null,
                        heightCm: null,
                        client_access: '0',
                        parq: [],
                        pic: res1.data.single?.photo
                            ? res1.data.single?.photo.toLocaleLowerCase().indexOf('http') !== -1
                                ? res1.data.single?.photo
                                : null
                            : null,
                        styku_email: null,
                        inbody_user_token: null,
                        groups: [],
                        staffs: [],
                        notepad_text: '',
                        measurement_system: 'imperial',
                    };

                    let temp = { ...values }
                    temp.pic = temp.pic?.path;
                    if (
                        values.birthday_year &&
                        values.birthday_month &&
                        values.birthday_day
                    ) {
                        temp.birthday = `${values.birthday_year}-${values.birthday_month}-${values.birthday_day}`
                    } else {
                        temp.birthday = null;
                    }
                    delete temp.birthday_day;
                    delete temp.birthday_month;
                    delete temp.birthday_year;
                    if (res1.data.single?.photo) {
                        temp.pic = res1.data.single.photo
                    };

                    createProfile(temp, 1)
                        .then((res) => {
                            if (res1.data.single?.id) {
                                saveMapping(accountId, crm.id, 'profiles', {
                                    "internal_id": res.data.data.id,
                                    "external_id": res1.data.single.id
                                })
                                    .then(() => setAddedCount(prev => prev + 1))
                                    .catch(() => setErrorsCount(prev => prev + 1))
                            };

                            if (res1.data.single.photo && res1.data.single.photo.indexOf('http') === -1) {
                                function urltoFile(url, mimeType) {
                                    return (fetch(url)
                                        .then(function (res) { return res.arrayBuffer(); })
                                        .then(function (buf) { return new File([buf], 'file', { type: mimeType }); })
                                    );
                                }

                                urltoFile(`data:image/png;base64,${res1.data.single.photo}`, 'image/png')
                                    .then(resFile => {
                                        const formData = new FormData();
                                        formData.append('file', resFile)
                                        media.uploadImg(formData)
                                            .then(uploadedFile => {
                                                postProfilePhoto(res.data.data.id, uploadedFile.data.data.id)
                                            })
                                    })
                            }
                        })
                        .catch((err) => {
                            setErrorsCount(prev => prev + 1);
                            if (err?.response?.status === 402) {
                                dispatch(AlertState.actions.alert({
                                    text: 'You have reached your allowable maximum number of Active profiles. Please upgrade your account to continue.',
                                    variant: false
                                }));

                                isCancell = false;
                                clearInterval(interval);

                                setFailed(prev => {
                                    if (prev.find(elem => elem === 'You have reached your allowable maximum number of Active profiles. Please upgrade your account to continue.')) {
                                        return prev;
                                    } else {
                                        return [
                                            ...prev,
                                            'You have reached your allowable maximum number of Active profiles. Please upgrade your account to continue.',
                                        ]
                                    }
                                })
                            } else {
                                setFailed(prev => {
                                    return [
                                        ...prev,
                                        values.email?.trim(),
                                    ]
                                })
                            }
                        })
                })

            counter++;
            if (counter === checked.length) {
                isCancell = false;
                clearInterval(interval);
                return;
            };
        }

        const interval = setInterval(intervalFunction, 500);
    };

    return (
        <Modal show={modal !== false} onHide={handleClose}>
            <Loader visible={loader} />

            <StyledAddProfilesModal>
                <div
                    className="close-button"
                    onClick={handleClose}
                >×</div>

                <Modal.Title>
                    <h5 className="title">{step === 1 ? 'New Profile Mapping' : 'Creating profiles from CRM'}</h5>
                </Modal.Title>

                <SpacerHorizontal height="25px" />

                <Modal.Body>
                    {step === 1 && (
                        <div className='mass-create-profile-dropdown'>
                            <Dropdown
                                selected={checked}
                                setSelected={setChecked}
                                options={crmProfiles}
                                label="Select CRM Profiles"
                                width={'100%'}
                                limitTags={1}
                            />
                        </div>
                    )}

                    {step === 2 && (
                        <>
                            <div className='mass-create-profile-title'>
                                {`${addedCount + errorsCount} of ${checked.length}`}
                            </div>

                            <div className="mass-create-profile-progress">
                                <div
                                    className="mass-create-profile-progress__value"
                                    style={{ width: `${100 / checked.length * (addedCount + errorsCount)}%` }}
                                />
                            </div>

                            <div className='mass-create-profile-count'>
                                <div className='mass-create-profile-count__text'>Created</div>
                                <div className='mass-create-profile-count__text'>{addedCount}</div>
                            </div>

                            <div className='mass-create-profile-count'>
                                <div
                                    className={`mass-create-profile-count__text ${errorsCount > 0 ? 'mass-create-profile-count__err-text' : ''}`}
                                >Failed</div>
                                <div
                                    className={`mass-create-profile-count__text ${errorsCount > 0 ? 'mass-create-profile-count__err-text' : ''}`}
                                >{errorsCount}</div>
                            </div>

                            <div style={{ marginTop: '10px', maxHeight: 150, overflow: 'auto' }}>
                                {failed.map((elem, index) => {
                                    return (
                                        <div key={index} className="mass-create-profile-count__email">
                                            {elem}
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >Close</Button>
                    {step === 1 && (
                        <Button
                            variant="primary"
                            onClick={handleSave}
                            className="btn-blue"
                            disabled={!checked.length}
                        >Save</Button>
                    )}
                </Modal.Footer>
            </StyledAddProfilesModal>
        </Modal >
    )
}

const StyledAddProfilesModal = styled.div`
    .close-button {
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        color: #696890;
        margin: 25px 0 0 25px;
        font-size: 20px;
    }

    .modal-body {
        padding: 25px 25px 25px 25px;
        border-top: 1px solid #ebedf2;
    }

    .update-link {
        cursor: pointer;
        color: blue;
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        max-height: 45px;
        overflow: hidden;
    }

    .MuiChip-sizeSmall {
        height: 30px !important;
    }

    .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium.css-iqd1hh-MuiButtonBase-root-MuiChip-root {
        display: none;
    }

    .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium.css-iqd1hh-MuiButtonBase-root-MuiChip-root:first-child {
        display: inherit;
    }
`;

export default CreateProfileModal;
