import React from "react";
import {
  SignatureTypeWrapper,
  TypeBlock,
  TypeBlockText,
} from "../SignatureModal.styles";
import { TextField } from "@material-ui/core";

export const SignatureType = ({
  handleChangeType,
  type,
  handleSelectType,
  selectedType,
}) => {
  return (
    <SignatureTypeWrapper>
      <TextField
        onChange={handleChangeType}
        variant="outlined"
        fullWidth
        placeholder="Type..."
        value={type}
      />
      <div className="blocks">
        <TypeBlock
          active={selectedType === 1}
          onClick={() => handleSelectType(1)}
        >
          <TypeBlockText>{type}</TypeBlockText>
        </TypeBlock>
        <TypeBlock
          active={selectedType === 2}
          onClick={() => handleSelectType(2)}
        >
          <TypeBlockText>{type}</TypeBlockText>
        </TypeBlock>
        <TypeBlock
          active={selectedType === 3}
          onClick={() => handleSelectType(3)}
        >
          <TypeBlockText>{type}</TypeBlockText>
        </TypeBlock>
        <TypeBlock
          active={selectedType === 4}
          onClick={() => handleSelectType(4)}
        >
          <TypeBlockText>{type}</TypeBlockText>
        </TypeBlock>
      </div>
    </SignatureTypeWrapper>
  );
};
