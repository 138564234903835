import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const StyledToolTip = withStyles((theme) => ({
  tooltip: {
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}))(Tooltip);

export const renderTextWithTooltip = (text) => {
  if (text.length < 20) return <span>{text}</span>;

  const cutText = text.slice(0, 20) + "...";

  return (
    <StyledToolTip title={text} placeholder="left">
      <span>{cutText}</span>
    </StyledToolTip>
  );
};
