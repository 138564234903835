import React, { useState } from "react";
import styled from "styled-components";
import { Typography, TextField } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import Switch from "@material-ui/core/Switch";
import {
  postActivateMindBody,
  postActivateMotionSoft,
  postActivateABCFitness,
  postActivateClubReady,
  postActivatHapana,
  postActivateClubAutomation,
  fetchCRMList,
  checkActivation,
} from "../../../crud/crm.crud";
import { useDispatch, useSelector } from "react-redux";
import { setCRMAction } from "../../../store/actions";
import * as AlertState from "../../../store/ducks/auth.duck";
import Loader from "../../../components/Loader";
import { useHistory } from "react-router-dom";
import catchErrorMessage from "../../../helpers/errorCatcher";

const ModalAddCRM = ({ modal, setModal, setModalCRMSync }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [switched, setSwitched] = useState([false, false, false, false]);
  const [locations_count, set_locations_count] = useState("");
  const [site_id, set_site_id] = useState("");
  const [siteIds, setSiteIds] = useState("");
  const [accessId, setAccesId] = useState("");
  const [store_id, set_store_id] = useState("");
  const [chain_id, set_chain_id] = useState("");
  const [club_numbers, set_club_numbers] = useState("");
  const [errors, setErrors] = useState({});
  const CRMID = switched.findIndex((elem) => elem === true) + 1;
  const [loader, setLoader] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const accountId = useSelector((state) => state.appData?.account?.id);

  const handleClose = () => {
    setSwitched([false, false, false, false]);
    set_locations_count("");
    set_site_id("");
    set_club_numbers("");
    setErrors({});
    setModal(false);
  };

  const handleSwitch = (index) => {
    setErrors({});
    setSwitched((prev) => {
      const arr = [...prev].map(() => false);
      arr[index] = !prev[index];
      return arr;
    });
  };

  const validate = () => {
    setErrors({});
    let isValid = true;
    const numbersReg = /^\d+$/;
    const commaSeparateReg = /^\w+(?:(?:,\s\w+)+(?:)+)$/gm;

    if (switched[0] && !site_id) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          site_id: {
            error: true,
            helperText: "Field is required",
          },
        };
      });
    }

    if (switched[0] && !login) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          login: {
            error: true,
            helperText: "Field is required",
          },
        };
      });
    }

    if (switched[0] && !password) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          password: {
            error: true,
            helperText: "Field is required",
          },
        };
      });
    }

    if (switched[0] && !numbersReg.test(locations_count)) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          locations_count: {
            error: true,
            helperText: "Only numbers allowed",
          },
        };
      });
    }

    if (switched[0] && (+locations_count < 1 || locations_count > 1000)) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          locations_count: {
            error: true,
            helperText: "Min value = 1, Max value = 1000",
          },
        };
      });
    }

    if (switched[0] && !locations_count) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          locations_count: {
            error: true,
            helperText: "Field is required",
          },
        };
      });
    }

    if (switched[1] && !site_id) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          site_id: {
            error: true,
            helperText: "Field is required",
          },
        };
      });
    }

    if (
      switched[2] &&
      club_numbers.split(" ").length > 1 &&
      !commaSeparateReg.test(club_numbers.trim())
    ) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          club_numbers: {
            error: true,
            helperText: "Not valid format",
          },
        };
      });
    }

    if (switched[2]) {
      let arr = club_numbers
        .split(",")
        .join("")
        .split(" ");
      arr = arr.map((elem) => elem.trim());
      arr.forEach((elem) => {
        if (!numbersReg.test(elem)) {
          isValid = false;
          setErrors((prev) => {
            return {
              ...prev,
              club_numbers: {
                error: true,
                helperText: "Only numbers allowed",
              },
            };
          });
        }
      });
    }

    if (switched[2] && !club_numbers) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          club_numbers: {
            error: true,
            helperText: "Field is required",
          },
        };
      });
    }

    if (switched[3] && !store_id) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          store_id: {
            error: true,
            helperText: "Field is required",
          },
        };
      });
    }
    if (switched[4] && !siteIds) {
      isValid = false;
      const arr = siteIds
        .split(",")
        .map((value) => value.trim())
        .filter((value) => value !== "");

      if (!arr?.length) {
        setErrors((prev) => {
          return {
            ...prev,
            siteIds: {
              error: true,
              helperText: "Field is required",
            },
          };
        });
      }
    }

    if (switched[4] && !accessId) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          accessId: {
            error: true,
            helperText: "Field is required",
          },
        };
      });
    }
    if (switched[5] && !site_id) {
      isValid = false;
      setErrors((prev) => {
        return {
          ...prev,
          site_id: {
            error: true,
            helperText: "Field is required",
          },
        };
      });
    }

    return isValid;
  };

  const handleContinue = () => {
    if (validate()) {
      setLoader(true);
      if (+CRMID === 1) {
        postActivateMindBody(
          accountId,
          locations_count,
          site_id,
          login,
          password
        )
          .then(() => {
            dispatch(
              AlertState.actions.alert({
                text: "CRM is added and pending activation",
                variant: true,
              })
            );
            fetchCRMList(accountId)
              .then((res) => {
                dispatch(setCRMAction(res.data.list));
                setModal(false);
                setModalCRMSync(true);
              })
              .finally(() => setLoader(false));
          })
          .catch((err) => {
            setLoader(false);
            let errorMessage = catchErrorMessage(err);
            dispatch(
              AlertState.actions.alert({
                text: errorMessage,
                variant: false,
              })
            );
          });
      } else if (+CRMID === 2) {
        postActivateMotionSoft(accountId, site_id)
          .then(() => {
            checkActivation(accountId, "motionsoft").then(() => {
              dispatch(
                AlertState.actions.alert({
                  text: "CRM is added",
                  variant: true,
                })
              );

              fetchCRMList(accountId)
                .then((res) => {
                  dispatch(setCRMAction(res.data.list));
                  setModal(false);
                  history.push("/account/crm/connect");
                })
                .finally(() => setLoader(false));
            });
          })
          .catch((err) => {
            setLoader(false);
            let errorMessage = catchErrorMessage(err);
            dispatch(
              AlertState.actions.alert({
                text: errorMessage,
                variant: false,
              })
            );
          });
      } else if (+CRMID === 3) {
        const clubNumbers = club_numbers
          .trim()
          .split(",")
          .join("")
          .split(" ");
        postActivateABCFitness(accountId, [...clubNumbers])
          .then(() => {
            checkActivation(accountId, "abc-fitness").then(() => {
              dispatch(
                AlertState.actions.alert({
                  text: "CRM is added",
                  variant: true,
                })
              );

              fetchCRMList(accountId)
                .then((res) => {
                  dispatch(setCRMAction(res.data.list));
                  setModal(false);
                  history.push("/account/crm/connect");
                })
                .finally(() => setLoader(false));
            });
          })
          .catch((err) => {
            setLoader(false);
            let errorMessage = catchErrorMessage(err);
            dispatch(
              AlertState.actions.alert({
                text: errorMessage,
                variant: false,
              })
            );
          });
      } else if (+CRMID === 4) {
        const storeIds = store_id
          .trim()
          .split(",")
          .filter((id) => id !== "");
        postActivateClubReady(accountId, [...storeIds], chain_id)
          .then(() => {
            checkActivation(accountId, "club-ready")
              .then(() => {
                dispatch(
                  AlertState.actions.alert({
                    text: "CRM is added",
                    variant: true,
                  })
                );

                fetchCRMList(accountId).then((res) => {
                  dispatch(setCRMAction(res.data.list));
                  setModal(false);
                  history.push("/account/crm/connect");
                });
              })
              .catch((err) => console.log("err", err));
          })
          .catch((err) => {
            let errorMessage = catchErrorMessage(err);

            dispatch(
              AlertState.actions.alert({
                text: errorMessage,
                variant: false,
              })
            );
          })
          .finally(() => setLoader(false));
      } else if (+CRMID === 5) {
        const site_ids = siteIds
          .split(",")
          .map((value) => value.trim())
          .filter((value) => value !== "");

        const access_id = accessId.trim();

        postActivatHapana(accountId, { site_ids, access_id })
          .then(() => {
            checkActivation(accountId, "hapana")
              .then(() => {
                dispatch(
                  AlertState.actions.alert({
                    text: "CRM is added",
                    variant: true,
                  })
                );

                fetchCRMList(accountId).then((res) => {
                  dispatch(setCRMAction(res.data.list));
                  setModal(false);
                  history.push("/account/crm/connect");
                });
              })
              .catch((err) => console.log("err", err));
          })
          .catch((err) => {
            let errorMessage = catchErrorMessage(err);

            dispatch(
              AlertState.actions.alert({
                text: errorMessage,
                variant: false,
              })
            );
          })
          .finally(() => setLoader(false));
      } else if (+CRMID === 6) {
        postActivateClubAutomation(accountId, site_id, club_numbers)
          .then(() => {
            checkActivation(accountId, "club-automation").then(() => {
              dispatch(
                AlertState.actions.alert({
                  text: "CRM is added",
                  variant: true,
                })
              );

              fetchCRMList(accountId)
                .then((res) => {
                  dispatch(setCRMAction(res.data.list));
                  setModal(false);
                  history.push("/account/crm/connect");
                })
                .finally(() => setLoader(false));
            });
          })
          .catch((err) => {
            setLoader(false);
            let errorMessage = catchErrorMessage(err);
            dispatch(
              AlertState.actions.alert({
                text: errorMessage,
                variant: false,
              })
            );
          });
      }
    }
  };

  return (
    <Modal show={modal !== false} onHide={handleClose}>
      <Loader visible={loader} />
      <StyledModalAddCRM>
        <div className="close-button" onClick={handleClose}>
          ×
        </div>
        <Modal.Title>
          <h5 className="title">Add CRM Sync</h5>
        </Modal.Title>
        <Modal.Body>
          <Typography>
            <div className="switcher">
              <div onClick={() => handleSwitch("0")} className="pointer">
                Mindbody Online: $15/location/month
              </div>
              <Switch
                checked={switched[0]}
                onChange={() => handleSwitch("0")}
                color="primary"
                name="checkedA"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </Typography>
          <div
            className={`switcher-inputs mindbody ${
              switched[0] ? "active" : ""
            }`}
          >
            <Typography>
              <div className="textfield-container">
                <TextField
                  id="outlined-basic"
                  label="Site ID"
                  variant="outlined"
                  value={site_id}
                  onChange={(e) => set_site_id(e.target.value)}
                  helperText={errors.site_id?.helperText}
                  error={errors.site_id?.error}
                  inputProps={{
                    maxLength: 12,
                  }}
                />
              </div>
            </Typography>
            <Typography>
              <div className="textfield-container">
                <TextField
                  id="outlined-basic"
                  label="Number of location"
                  variant="outlined"
                  value={locations_count}
                  onChange={(e) => set_locations_count(e.target.value)}
                  helperText={errors.locations_count?.helperText}
                  error={errors.locations_count?.error}
                  inputProps={{
                    maxLength: 1000,
                  }}
                />
              </div>
            </Typography>

            <Typography>
              <div className="textfield-container">
                <TextField
                  id="outlined-basic"
                  label="Mindbody username"
                  variant="outlined"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                  helperText={errors.login?.helperText}
                  error={errors.login?.error}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  autoComplete={false}
                />
              </div>
            </Typography>

            <Typography>
              <div className="textfield-container">
                <TextField
                  id="outlined-basic"
                  label="Mindbody password"
                  variant="outlined"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  helperText={errors.password?.helperText}
                  error={errors.password?.error}
                  inputProps={{
                    maxLength: 1000,
                  }}
                  type="password"
                  autoComplete={false}
                />
              </div>
            </Typography>
          </div>

          <Typography>
            <div className="switcher">
              <div className="pointer" onClick={() => handleSwitch("1")}>
                Motionsoft: $20/location/month
              </div>
              <Switch
                checked={switched[1]}
                onChange={() => handleSwitch("1")}
                color="primary"
                name="checkedC"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </Typography>
          <div
            className={`switcher-inputs motionsoft ${
              switched[1] ? "active" : ""
            }`}
          >
            <Typography>
              <div className="textfield-container">
                <TextField
                  id="outlined-basic"
                  label="Customer ID"
                  variant="outlined"
                  value={site_id}
                  onChange={(e) => set_site_id(e.target.value)}
                  helperText={errors.site_id?.helperText}
                  error={errors.site_id?.error}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </div>
            </Typography>
          </div>

          <Typography>
            <div className="switcher">
              <div className="pointer" onClick={() => handleSwitch("2")}>
                ABC Fitness: $20/location/month
              </div>
              <Switch
                checked={switched[2]}
                onChange={() => handleSwitch("2")}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </Typography>

          <div
            className={`switcher-inputs ${switched[2] ? "active" : ""}`}
            style={{ height: `${switched[2] ? "95px" : "0px"}` }}
          >
            <Typography>
              <div className="textfield-container">
                <TextField
                  id="outlined-basic"
                  label="Club Numbers"
                  variant="outlined"
                  value={club_numbers}
                  onChange={(e) => set_club_numbers(e.target.value)}
                  helperText={errors.club_numbers?.helperText}
                  error={errors.club_numbers?.error}
                />
              </div>
            </Typography>

            <p className="club-numbers-helper-text">
              Please enter club numbers <br />
              separated by commas with spaces like: 1, 20, 300
            </p>
          </div>

          <Typography>
            <div className="switcher">
              <div className="pointer" onClick={() => handleSwitch("3")}>
                ClubReady Fitness: $20/location/month
              </div>
              <Switch
                checked={switched[3]}
                onChange={() => handleSwitch("3")}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </Typography>
          <div
            className={`switcher-inputs clubready ${
              switched[3] ? "active" : ""
            }`}
          >
            <div className="switcher-inputs-wrapper">
              <Typography>
                <div className="textfield-container">
                  <TextField
                    id="outlined-basic"
                    label="Store ID"
                    variant="outlined"
                    value={store_id}
                    onChange={(e) => set_store_id(e.target.value)}
                    helperText={errors.store_id?.helperText}
                    error={errors.store_id?.error}
                    inputProps={{
                      maxLength: 256,
                    }}
                  />
                </div>
              </Typography>
            </div>
            <Typography>
              <div className="textfield-container">
                <TextField
                  id="outlined-basic"
                  label="Chain ID"
                  variant="outlined"
                  value={chain_id}
                  onChange={(e) => set_chain_id(e.target.value)}
                  helperText={errors.chain_id?.helperText}
                  error={errors.chain_id?.error}
                  inputProps={{
                    maxLength: 256,
                  }}
                />
              </div>
            </Typography>
          </div>

          {/* INTEGRATE CRM:HAPANA - START */}
          <Typography>
            <div className="switcher">
              <div className="pointer" onClick={() => handleSwitch("4")}>
                Hapana: $20/location/month
              </div>
              <Switch
                checked={switched[4]}
                onChange={() => handleSwitch("4")}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </Typography>
          <div
            className={`switcher-inputs hapana ${switched[4] ? "active" : ""}`}
          >
            <div className="switcher-inputs-wrapper">
              <Typography>
                <div className="textfield-container">
                  <TextField
                    id="outlined-basic"
                    label="Access ID"
                    variant="outlined"
                    value={accessId}
                    fullWidth
                    onChange={(e) => {
                      setAccesId(e.target.value.trim());
                      setErrors((prev) => ({ ...prev, accessId: "" }));
                    }}
                    helperText={errors.accessId?.helperText}
                    error={errors.accessId?.error}
                    style={{ width: "250px" }}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </div>
                <div className="textfield-container">
                  <TextField
                    id="outlined-basic"
                    label="Site ID"
                    variant="outlined"
                    value={siteIds}
                    onChange={(e) => {
                      setSiteIds(e.target.value.trim());
                      setErrors((prev) => ({ ...prev, siteIds: "" }));
                    }}
                    helperText={errors.siteIds?.helperText}
                    error={errors.siteIds?.error}
                    fullWidth
                    inputProps={{
                      maxLength: 256,
                    }}
                  />
                </div>
              </Typography>
            </div>
          </div>
          {/* INTEGRATE CRM:HAPANA - END */}

          {/* INTEGRATE CRM:Club Automation - START */}
          <Typography>
            <div className="switcher">
              <div className="pointer" onClick={() => handleSwitch("5")}>
                Club Automation: $20/location/month
              </div>
              <Switch
                checked={switched[5]}
                onChange={() => handleSwitch("5")}
                color="primary"
                name="checkedC"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
          </Typography>
          <div
            className={`switcher-inputs ${
              switched[5] ? "active" : ""
            }`}
          >
            <Typography>
              <div className="textfield-container">
                <TextField
                  id="outlined-basic"
                  label="Customer ID"
                  variant="outlined"
                  value={site_id}
                  onChange={(e) => set_site_id(e.target.value)}
                  helperText={errors.site_id?.helperText}
                  error={errors.site_id?.error}
                  inputProps={{
                    maxLength: 255,
                  }}
                />
              </div>
            </Typography>
            <Typography>
              <div className="textfield-container">
                <TextField
                    id="outlined-basic-1"
                    label="Location IDs"
                    variant="outlined"
                    value={club_numbers}
                    onChange={(e) => set_club_numbers(e.target.value)}
                    helperText={errors.club_numbers?.helperText}
                    error={errors.club_numbers?.error}
                    inputProps={{
                      maxLength: 255,
                    }}
                />
              </div>
            </Typography>
          </div>
          {/* INTEGRATE CRM:Club Automation - END */}
          <hr />
          <Typography className="static-text">
            In addition to your current TrainerMetrics <br />
            subscription, your payment method on file will be <br />
            charged an additional amount to sync your CRM with <br />
            TrainerMetrics
          </Typography>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handleContinue}
            className="btn-blue"
            disabled={!CRMID}
          >
            Continue
          </Button>
        </Modal.Footer>
      </StyledModalAddCRM>
    </Modal>
  );
};

const StyledModalAddCRM = styled.div`
  .close-button {
    position: absolute;
    top: 7px;
    right: 20px;
    cursor: pointer;
    color: grey;
    font-size: 2.5em;
  }

  .title {
    margin: 20px 0 0 20px;
  }

  .pointer {
    cursor: pointer;
  }

  .switcher {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 5px;
  }

  .switcher-inputs {
    height: 0px;
    opacity: 0;
    visibility: hidden;
    transition: visibility 0.5s, opacity 0.5s, height 1s;
  }

  .switcher-inputs.active {
    height: 115px;
    opacity: 1;
    visibility: visible;
    transition: visibility 1.5s, opacity 1.5s, height 1s;
  }

  .switcher-inputs-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin: 10px 0; */
  }

  .switcher-inputs.active.mindbody {
    height: 230px;
  }
  .switcher-inputs.active.clubready {
    height: 120px;
  }

  .clubready {
    .MuiOutlinedInput-input {
      width: 200px;
    }
  }

  .switcher-inputs.active.motionsoft {
    height: 50px;
  }

  .static-text {
    margin-left: 20px;
  }

  label {
    background-color: #fff;
    padding-right: 5px;
  }

  .textfield-container {
    height: 60px;
  }

  .club-numbers-helper-text {
    color: rgb(131, 131, 131);
    margin-left: 5px;
    font-size: 11px;
  }
`;

export default ModalAddCRM;
