import React from 'react';
import UserInfo from '../../components/UserInfo';
import DataOutput from '../../components/DataOutput';
import { Calendar } from './Calendar/Calendar';
import styled from 'styled-components';

const ProfileCalendar = (props) => {
    const profileId = props.match.params.id

    return (
        <StyledCalendar>
            <div className='row'>
                <UserInfo id={profileId} />
                <DataOutput id={profileId} />
            </div>

            <div className='profile-calendar__calendar'>
                <Calendar profileId={profileId} />
            </div>

        </StyledCalendar>
    )
}

const StyledCalendar = styled.div`
    .profile-calendar__calendar{
        margin-top: 20px;
    }
`;

export default ProfileCalendar;
