import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import { SpacerHorizontal } from '../../../../../components/Spacer';
import Dropdown from '../../../../../components/DropdownComponent';
import * as AlertState from '../../../../../../app/store/ducks/auth.duck';
import { getUsers } from '../../../../../crud/info.crud';
import Loader from '../../../../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
    postAddMapping,
    fetchExternals,
    // updateMappings,
    // fetchCRMList
} from '../../../../../crud/crm.crud';
// import { setCRMAction } from '../../../../../store/actions';
// import moment from 'moment';
// import Tooltip from '@material-ui/core/Tooltip';
// import { withStyles } from '@material-ui/core/styles';
// import question from '../../../../../../_metronic/_assets/media/svg-icon/question.svg';

// const StyledToolTip = withStyles((theme) => ({
//     tooltip: {
//         boxShadow: theme.shadows[1],
//         fontSize: 14,
//     },
// }))(Tooltip);

const AddGroupModal = ({ modal, setModal, getMappings }) => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({});
    const [crmUsers, setCRMUsers] = useState([]);
    const [TMUsers, setTMUsers] = useState([]);
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [tmLoader, setTmLoader] = useState(false);
    const crm = useSelector((state) =>
        state.user_info.CRM_list.find((elem) => elem.is_activated === true)
    );
    const accountId = useSelector((state) => state.appData?.account?.id);

    const loadData = (search) => {
        if (!accountId || !crm) return;
        setLoader(true);
        fetchExternals(accountId, crm.id, 'users', {
            page: 1,
            limit: 500,
            search,
        })
            .then((res) => {
                const list = res.data.list.map((elem) => ({
                    ...elem,
                    title: `${elem.first_name} ${elem.last_name}`,
                    value: elem.id,
                }));
                const sorted = list
                    .sort((a, b) => {
                        let first = a.title.toLowerCase();
                        let second = b.title.toLowerCase();
                        if (first < second) {
                            return -1;
                        }
                        if (first > second) {
                            return 1;
                        }
                        return 0;
                    })
                    .filter((elem) => elem.is_mapped === false);
                setCRMUsers(sorted);
            })
            .catch((err) => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                }
                dispatch(
                    AlertState.actions.alert({
                        text: errText,
                        variant: false,
                    })
                );
            })
            .finally(() => setLoader(false));
    };

    const getTMUsers = (search) => {
        setTmLoader(true);
        getUsers(search, 1)
            .then((res) => {
                const list = res.data?.data?.users.map((elem) => ({
                    ...elem,
                    title: elem.name,
                    value: elem.id,
                }));
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) {
                        return -1;
                    }
                    if (first > second) {
                        return 1;
                    }
                    return 0;
                });
                setTMUsers(sorted);
            })
            .catch((err) => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                }
                dispatch(
                    AlertState.actions.alert({
                        text: errText,
                        variant: false,
                    })
                );
            })
            .finally(() => setTmLoader(false));
    };

    const handleClose = () => {
        setModal(false);
        setErrors({});
        setFormData({});
    };

    const validate = () => {
        setErrors({});
        let isValid = true;

        if (!formData.external_id) {
            isValid = false;
            setErrors((prev) => {
                return {
                    ...prev,
                    external_id: {
                        error: true,
                        helperText: 'Field is required',
                    },
                };
            });
        }

        if (!formData.internal_id) {
            isValid = false;
            setErrors((prev) => {
                return {
                    ...prev,
                    internal_id: {
                        error: true,
                        helperText: 'Field is required',
                    },
                };
            });
        }

        return isValid;
    };

    const handleSave = () => {
        if (validate()) {
            setLoader(true);
            const data = {
                internal_id: formData.internal_id?.value || null,
                external_id: formData.external_id?.value + '' || null,
            };
            postAddMapping(accountId, crm.id, 'users', data)
                .then(() => {
                    handleClose();
                    loadData();
                    getMappings();
                    dispatch(
                        AlertState.actions.alert({
                            text: 'Mapping added',
                            variant: true,
                        })
                    );
                })
                .catch((err) => {
                    let errText = 'request failed';
                    if (err.response?.data?.error) {
                        errText = err.response?.data?.error;
                    } else if (err.response?.data?.message) {
                        errText = err.response.data.message;
                    }
                    dispatch(
                        AlertState.actions.alert({
                            text: errText,
                            variant: false,
                        })
                    );
                })
                .finally(() => setLoader(false));
        }
    };

    // const handleUpdate = () => {
    //     setLoader(true);
    //     updateMappings('users')
    //         .then(() => {
    //             dispatch(AlertState.actions.alert({
    //                 text: 'CRM list updating has started',
    //                 variant: true,
    //             }));
    //             fetchCRMList(accountId)
    //                 .then(res => {
    //                     dispatch(setCRMAction(res.data.list))
    //                 })
    //                 .finally(() => setLoader(false))
    //             loadData();
    //         })
    //         .catch(err => {
    //             let errText = 'request failed';
    //             if (err.response?.data?.error) {
    //                 errText = err.response?.data?.error;
    //             } else if (err.response?.data?.message) {
    //                 errText = err.response.data.message;
    //             } else {
    //                 errText = 'request failed';
    //             }
    //             dispatch(AlertState.actions.alert({
    //                 text: errText,
    //                 variant: false,
    //             }));
    //             handleClose()
    //         })
    //         .finally(() => setLoader(false))
    // };

    return (
        <Modal show={modal !== false} onHide={handleClose}>
            <Loader visible={loader || tmLoader} />

            <StyledAddGroupModal>
                <div className="close-button" onClick={handleClose}>
                    ×
                </div>
                <Modal.Title>
                    <h5 className="title">New User Mapping</h5>
                </Modal.Title>

                <SpacerHorizontal height="25px" />

                <Modal.Body>
                    {/* {crm?.name !== "Motionsoft" && (
                        <>
                            <div className='d-flex align-items-center'>
                                {crm?.last_update_started?.users
                                    ? (
                                        <div className='d-flex align-items-center'>
                                            <div className="last-sync-block">
                                                {`CRM list last updated ${moment(crm.last_update_started?.users).format('MM/DD/YYYY hh:mm:ss a')}`}
                                            </div>
                                            <StyledToolTip
                                                title={<>Synchronization may take some time. <br />
                                                    To see if the synchronization is complete, please refresh the page and observe the changes in this table.
                                                </>}
                                                placement="bottom"
                                            >
                                                <img src={question} style={{ cursor: 'pointer', margin: '0 5px 0 10px', height: '18px' }} alt="tooltip" />
                                            </StyledToolTip>
                                        </div>
                                    )
                                    : <div style={{ marginRight: '10px' }}>CRM has not been updated yet.</div>
                                }
                                <span onClick={handleUpdate} className='update-link'>
                                    Update Now
                                </span>
                            </div>
                            <SpacerHorizontal height="25px" />
                        </>
                    )} */}

                    <div className="crm-dropdown">
                        <Dropdown
                            label="Start typing to search CRM User"
                            error={errors.external_id?.error}
                            options={crmUsers}
                            setFormData={(val) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    external_id: val,
                                }));
                                setErrors((prev) => ({
                                    ...prev,
                                    external_id: {},
                                }));
                            }}
                            name="CRM User"
                            value={formData.external_id}
                            width={'100%'}
                            helperText={errors.external_id?.helperText}
                            fetchOptions={loadData}
                        />
                    </div>

                    <div className="crm-dropdown">
                        <Dropdown
                            label="TrainerMetrics User"
                            options={TMUsers}
                            setFormData={(val) => {
                                setFormData((prev) => ({
                                    ...prev,
                                    internal_id: val,
                                }));
                                setErrors((prev) => ({
                                    ...prev,
                                    internal_id: {},
                                }));
                            }}
                            name="TrainerMetrics User"
                            value={formData.internal_id}
                            width={'100%'}
                            error={errors.internal_id?.error}
                            helperText={errors.internal_id?.helperText}
                            fetchOptions={getTMUsers}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        className="btn-blue"
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </StyledAddGroupModal>
        </Modal>
    );
};

const StyledAddGroupModal = styled.div`
    .close-button {
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        color: #696890;
        margin: 25px 0 0 25px;
        font-size: 20px;
    }

    .modal-body {
        padding: 25px 25px 0px 25px;
        border-top: 1px solid #ebedf2;
    }

    .update-link {
        cursor: pointer;
        color: blue;
    }
`;

export default AddGroupModal;
