import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import "./exercise-data.scss";
import * as media from "../../../../../crud/media.crud";
import Loader from "../../../../../components/Loader";

const ImgComponent = ({ src, alt, className }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    src &&
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);

  return url && <img src={url} alt={alt} className={className} />;
};

export const ExerciseData = ({
  exerciseData,
  setExercise,
  setExerciseData,
}) => {
  const [overlayClicked, setOverlayClicked] = useState(false);

  const getVideoId = () => {
    if (
      exerciseData?.video_source.indexOf("https://www.youtube.com/watch?v=") !==
      -1
    ) {
      return exerciseData?.video_source.split(
        "https://www.youtube.com/watch?v="
      )[1];
    } else {
      return exerciseData?.video_source.split("https://youtu.be/")[1];
    }
  };

  const instructions = () => {
    return exerciseData?.instructions.split("\n").map((elem, index) => (
      <span key={index}>
        <span>{elem}</span>
        <br />
      </span>
    ));
  };

  return (
    <div className="exercise-data">
      <div className="exercise-data__page-title">
        <div className="exercise-data__page-title-text">
          {exerciseData?.title}
        </div>
        <div
          className="exercise-data__close-button"
          onClick={() => {
            setExercise(null);
            setExerciseData(null);
          }}
        >
          ✕
        </div>
      </div>

      {exerciseData?.video_source && exerciseData?.video_type && (
        <div className="exercise-data__video-title">Video</div>
      )}

      {exerciseData?.video_source && exerciseData?.video_type ? (
        exerciseData?.video_type === "youtube" ? (
          <div
            onClick={() => setOverlayClicked(true)}
            className="exercise-data__video-container"
          >
            {exerciseData?.video_source_preview && !overlayClicked && (
              <ImgComponent
                src={exerciseData.video_source_preview}
                className={"overlay-image"}
              />
            )}
            <iframe
              src={`https://www.youtube.com/embed/${getVideoId()}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ) : (
          <div
            onClick={() => setOverlayClicked(true)}
            className="exercise-data__video-container"
          >
            {exerciseData?.video_source_preview && !overlayClicked && (
              <ImgComponent
                src={exerciseData.video_source_preview}
                className={"overlay-image"}
              />
            )}
            <iframe
              title="Vimeo video player"
              src={`https://player.vimeo.com/video/${
                exerciseData?.video_source.split("https://vimeo.com/")[1]
              }?h=5ca37da63f&color=000000&title=0&byline=0&portrait=0&badge=0`}
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            />
          </div>
        )
      ) : (
        <></>
      )}

      {exerciseData?.video_source_text && (
        <div className="view-exercise__page-title">
          {exerciseData?.video_source_text}
        </div>
      )}

      {exerciseData.video_source && (
        <div className="view-exercise__textfield">
          <TextField
            variant="outlined"
            type="text"
            style={{ width: "100%" }}
            name="video_source"
            value={exerciseData.video_source}
            disabled
          />
        </div>
      )}

      {exerciseData?.instructions && (
        <div className="exercise-data__description-wrapper">
          <span className="exercise-data__description-title">Instructions</span>
          <p className="exercise-data__description-text">{instructions()}</p>
        </div>
      )}

      {exerciseData?.variables && (
        <div className="exercise-data__description-wrapper">
          <span className="exercise-data__description-title">
            Units of Measure
          </span>
          <p className="exercise-data__description-text">
            {exerciseData?.variables
              ?.map((elem) => elem.charAt(0).toUpperCase() + elem.slice(1))
              .join(", ")}
          </p>
        </div>
      )}

      <div className="exercise-data__data-wrapper">
        <div className="exercise-data__data-container">
          {exerciseData?.body_segment && (
            <div className="exercise-data__data-block">
              <div className="exercise-data__data-block-title">
                Body Segment
              </div>
              <div className="exercise-data__data-block-text">
                {exerciseData.body_segment.title}
              </div>
            </div>
          )}

          {exerciseData?.muscle && (
            <div className="exercise-data__data-block">
              <div className="exercise-data__data-block-title">
                Primary Muscle(s)
              </div>
              <div className="exercise-data__data-block-text">
                {exerciseData.muscle.title}
              </div>
            </div>
          )}

          {exerciseData?.equipment && (
            <div className="exercise-data__data-block">
              <div className="exercise-data__data-block-title">Equipment</div>
              <div className="exercise-data__data-block-text">
                {exerciseData.equipment.title}
              </div>
            </div>
          )}
        </div>

        <div className="exercise-data__data-container">
          {exerciseData?.mechanic && (
            <div className="exercise-data__data-block">
              <div className="exercise-data__data-block-title">Mechanics</div>
              <div className="exercise-data__data-block-text">
                {exerciseData.mechanic.title}
              </div>
            </div>
          )}

          {exerciseData?.force && (
            <div className="exercise-data__data-block">
              <div className="exercise-data__data-block-title">Force</div>
              <div className="exercise-data__data-block-text">
                {exerciseData.force.title}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
