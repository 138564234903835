import { Portlet, PortletBody } from "../../../../partials/content/Portlet";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from "../../../../store/ducks/auth.duck";
import { Redirect } from "react-router-dom";
import Loader from "../../../../components/Loader";
import styled from "styled-components";
import { Pagination } from "react-bootstrap";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  ListItemText,
  OutlinedInput,
  Typography,
  Avatar,
} from "@material-ui/core";

import TableList from "../../../../components/TableList";

import { useUsers } from "../hooks/useUsers";
import { useGroups } from "../hooks/useGroups";

import { useForecasts } from "../hooks/useForecasts";

import {
  EditFieldModal,
  FIELDS_TYPE,
} from "../modals/EditFieldModal/EditFieldModal";
import { PencilEditIcon } from "../../../../../assets";
import { priceToView } from "../../../../helpers/thousandSeparator";
import { renderNumericValue } from "../helpers/renderNumericValue";
import { updateForecastField } from "../../../../crud/forecast.crud";
import { OpportunitiesList } from "./OpportunitiesList";
import { ImgComponent } from "../helpers/ImgComponent";
import moment from "moment";

import "../../table.scss";
import "../../style.scss";

const initSetting = { initialPage: 1, initialSize: 10, initialSearch: "" };

const getMonthDates = (row_month) => {
  const date = moment(row_month, "MMMM YYYY");

  const start_date = date.startOf("month").format("YYYY-MM-DD");
  const end_date = date.endOf("month").format("YYYY-MM-DD");

  return { start_date, end_date };
};

const isEndDateValid = (startYear, startMonth, endYear, endMonth) => {
  const startDate = moment(`${startYear}-${startMonth}-01`);
  const endDate = moment(`${endYear}-${endMonth}-01`);
  return endDate.isSameOrAfter(startDate);
};

export const ForecastsList = (props) => {
  document.title = "TrainerMetrics - Forecasts";

  const { groupList, loading: loaderAllGroups } = useGroups();

  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const role = useSelector((state) => state.auth?.user?.roles);
  const dispatch = useDispatch();

  const {
    loading,
    error,
    data,
    setPage,
    sort,
    setSort,
    pagination,
    staff,
    setStaff,
    groups,
    setGroups,
    setStartMonth,
    setStartYear,
    setEndMonthState,
    setEndYearState,

    startYear,
    startMonth,
    endYearState,
    endMonthState,

    startDate,
    endDate,
    setPerPage,
    perPage,
    setData,
  } = useForecasts(initSetting);

  const { userList, loading: loaderUser } = useUsers({
    groups: groups.filter(Boolean),
  });

  const [logout, setLogout] = useState(null);

  const [clickedRow, setClikedRow] = useState(null);
  const [openModal, setOpenModal] = useState(null);

  const [clickedUser, setClickedUser] = useState(null);
  const [clickedUserGroup, setClickedUserGroup] = useState([]);
  const [clickedMonth, setClickedMonth] = useState("");

  const handleClick = (row) => {
    if (row?.values?.user_id) setClickedUser(row?.values?.user_id);
    if (row?.values?.groups) setClickedUserGroup(row.values.groups);

    if (row?.values?.row_month) setClickedMonth(row.values.row_month);
  };

  const handleClickOnRow = (value, name, record, title, type) => {
    setClikedRow({ value, name, record, title, type });
    setOpenModal(true);
  };

  const handleClose = () => {
    setClikedRow(null);
    setOpenModal(false);
  };

  const handleSave = async (values) => {
    try {
      const { start_date, end_date } = getMonthDates(
        clickedRow.record.row_month
      );

      const req = { start_date, end_date, [clickedRow.name]: values };

      await updateForecastField(clickedRow.record.user_id, req);

      const newData = data.map((row) => {
        if (row.values.month === clickedRow.record.row_month) {
          let newValue = parseFloat(values);

          let prevRowValue = parseFloat(row.values[clickedRow.name]);

          const newRowValue = prevRowValue - clickedRow.value + newValue;

          const newRow = {
            values: {
              ...row.values,
              [clickedRow.name]: newRowValue,
              items: row.values.items.map((item) =>
                item.user_id === clickedRow.record.user_id
                  ? {
                      ...item,
                      [clickedRow.name]: newValue,
                    }
                  : item
              ),
            },
          };

          return newRow;
        }
        return row;
      });

      setData(newData);

      handleClose();
      dispatch(
        AlertState.actions.alert({
          text: "Forecasts info is updated",
          variant: true,
        })
      );
    } catch (error) {
      console.dir(error);
      dispatch(
        AlertState.actions.alert({
          text: error?.message || "Something went wrong!",
          variant: false,
        })
      );
    }
  };

  const isValidDate = (startYear, startMonth, endYear, endMonth, cb) => {
    if (isEndDateValid(startYear, startMonth, endYear, endMonth)) {
      cb();
    } else {
      dispatch(
        AlertState.actions.alert({
          text: "End date cannot be earlier than start date",
          variant: false,
        })
      );
    }
  };

  //redirect if no have access
  useEffect(() => {
    if (role === "staff" || role === "client") {
      dispatch(
        AlertState.actions.alert({
          text: "User does not have the right roles",
          variant: false,
        })
      );
      setLogout(<Redirect to="/profile-list" />);
    }
  }, [role]);

  useEffect(() => {
    if (error) {
      dispatch(
        AlertState.actions.alert({
          text: error?.response?.data?.message || "Something went wrong!",
          variant: false,
        })
      );
    }
  }, [error]);

  const header = [
    {
      name: "month",
      title: "Month",
    },

    {
      name: "leads_new",
      title: "Leads New",
      render: renderNumericValue,
    },
    {
      name: "leads_goal",
      title: "Leads Goals",
      render: renderNumericValue,
      // render: (value, name, record) => (
      //   <EditableCol>
      //     <span>{renderNumericValue(value)}</span>

      //     <span
      //       className="pencil-icon"
      //       onClick={() =>
      //         handleClickOnRow(
      //           value,
      //           name,
      //           record,
      //           "Leads Goals",
      //           FIELDS_TYPE.INPUT
      //         )
      //       }
      //     >
      //       <PencilEditIcon />
      //     </span>
      //   </EditableCol>
      // ),
    },

    {
      name: "sales_new",
      title: "New Sales ",
      render: (value) => priceToView(value),
    },
    {
      name: "sales_goal_new",
      title: (
        <>
          <p style={{ margin: 0 }}>Sales </p>
          <p style={{ margin: 0 }}>Goal New</p>
        </>
      ),
      render: (value) => priceToView(value),
      // render: (value, name, record) => (
      //   <EditableCol>
      //     <span>{priceToView(value)}</span>
      //     <span
      //       className="pencil-icon"
      //       onClick={() =>
      //         handleClickOnRow(
      //           value,
      //           name,
      //           record,
      //           "Sales Goal New",
      //           FIELDS_TYPE.INPUT
      //         )
      //       }
      //     >
      //       <PencilEditIcon />
      //     </span>
      //   </EditableCol>
      // ),
    },
    {
      name: "sales_resign",
      title: "Sales Resign",
      render: (value) => priceToView(value),
    },

    {
      name: "sales_goal_resign",
      title: (
        <>
          <p style={{ margin: 0 }}>Sales </p>
          <p style={{ margin: 0 }}>Goal Resign</p>
        </>
      ),
      render: (value) => priceToView(value),
    },
    {
      name: "sessions_completed",

      title: (
        <>
          <p style={{ margin: 0 }}>Session </p>
          <p style={{ margin: 0 }}>Completed</p>
        </>
      ),
      render: renderNumericValue,
    },
    {
      name: "sessions_goal_completed",

      title: (
        <>
          <p style={{ margin: 0 }}>Session </p>
          <p style={{ margin: 0 }}>Goal Completed</p>
        </>
      ),
      render: renderNumericValue,
      // render: (value, name, record) => (
      //   <EditableCol>
      //     <span>{renderNumericValue(value)}</span>

      //     <span
      //       className="pencil-icon"
      //       onClick={() =>
      //         handleClickOnRow(
      //           value,
      //           name,
      //           record,
      //           "Sales Goal New",
      //           FIELDS_TYPE.INPUT
      //         )
      //       }
      //     >
      //       <PencilEditIcon />
      //     </span>
      //   </EditableCol>
      // ),
    },
    {
      name: "sales_total",
      title: "Sales Total",
      render: (value) => priceToView(value),
    },
    {
      name: "sales_goal",
      title: "Sales Goal",
      render: (value) => priceToView(value),
    },
  ];

  const expandedHeader = [
    {
      name: "user_full_name",
      title: "Month",
      render: (staff, name, record) => {
        return staff ? (
          <NameWrapper>
            {record?.user_picture ? (
              <ImgComponent
                src={record?.user_picture}
                alt="img"
                className="profile-photo"
              />
            ) : (
              <Avatar className="profile-photo">{`${record?.user_first_name[0]}${record?.user_last_name[0]}`}</Avatar>
            )}

            <p>
              {record?.user_first_name} {record?.user_last_name}
            </p>
          </NameWrapper>
        ) : (
          "--"
        );
      },
    },

    {
      name: "leads_new",
      title: "Leads New",
      render: renderNumericValue,
    },
    {
      name: "leads_goal",
      title: "Leads Goals",
      render: (value, name, record) => (
        <EditableCol>
          <span>{renderNumericValue(value)}</span>

          <span
            className="pencil-icon"
            onClick={() =>
              handleClickOnRow(
                value,
                name,
                record,
                "Leads Goals",
                FIELDS_TYPE.INPUT
              )
            }
          >
            <PencilEditIcon />
          </span>
        </EditableCol>
      ),
    },

    {
      name: "sales_new",
      title: "Sales New",
      render: (value) => priceToView(value),
    },
    {
      name: "sales_goal_new",
      title: "Sales Goal New",
      render: (value, name, record) => (
        <EditableCol>
          <span>{priceToView(value)}</span>
          <span
            className="pencil-icon"
            onClick={() =>
              handleClickOnRow(
                value,
                name,
                record,
                "Sales Goal New",
                FIELDS_TYPE.INPUT
              )
            }
          >
            <PencilEditIcon />
          </span>
        </EditableCol>
      ),
    },
    {
      name: "sales_resign",
      title: "Sales Resign",
      render: (value) => priceToView(value),
    },

    {
      name: "sales_goal_resign",
      title: "Sales Goal Resign",
      render: (value, name, record) => (
        <EditableCol>
          <span>{priceToView(value)}</span>
          <span
            className="pencil-icon"
            onClick={() =>
              handleClickOnRow(
                value,
                name,
                record,
                "Sales Goal Resign",
                FIELDS_TYPE.INPUT
              )
            }
          >
            <PencilEditIcon />
          </span>
        </EditableCol>
      ),
    },

    {
      name: "sessions_completed",
      title: "Session Completed",
      render: renderNumericValue,
    },
    {
      name: "sessions_goal_completed",
      title: "Session Goal Completed",
      render: (value, name, record) => (
        <EditableCol>
          <span>{renderNumericValue(value)}</span>

          <span
            className="pencil-icon"
            onClick={() =>
              handleClickOnRow(
                value,
                name,
                record,
                "Session Goal Completed",
                FIELDS_TYPE.INPUT
              )
            }
          >
            <PencilEditIcon />
          </span>
        </EditableCol>
      ),
    },
    {
      name: "sales_total",
      title: "Sales Total",
      render: (value) => priceToView(value),
    },
    {
      name: "sales_goal",
      title: "Sales Goal",
      render: (value) => priceToView(value || 0),
    },
  ];

  return (
    <StyledGroupLists className="nowrap">
      {logout}
      <Loader visible={loaderAllGroups || loaderUser || loading} />

      <Portlet>
        <PortletBody>
          <FiltersBlock>
            <div className="btn-toolbar__group">
              {plan === "Enterprise" && (
                <FormControl variant="outlined" className="formControl">
                  <InputLabel>Select Groups</InputLabel>
                  <Select
                    multiple
                    name="group"
                    onChange={(e, child) => {
                      if (!child.props.value) {
                        setGroups([0]);
                      } else {
                        setGroups(e.target.value.filter(Boolean));
                      }
                      setPage(1);
                      setStaff([]);
                      setClickedUser(null);
                    }}
                    value={groups}
                    input={<OutlinedInput id={`select-multiple-checkbox`} />}
                    renderValue={(selected) => (
                      <SelectedBox>
                        <span>
                          {selected
                            .map(
                              (item) =>
                                [
                                  { name: "(All Groups)", id: 0 },
                                  ...groupList,
                                ].find((elem) => elem.id === item).name
                            )
                            .join(", ")}
                        </span>
                      </SelectedBox>
                    )}
                    inputProps={{
                      name: "groups",
                    }}
                    MenuProps={{
                      autoFocus: false,
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                          width: 250,
                        },
                      },
                    }}
                  >
                    {[{ name: "(All Groups)", id: 0 }, ...groupList].map(
                      (item) => (
                        <MenuItem key={item.id} value={item.id}>
                          <ListItemText primary={item.name} />
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              )}

              <FormControl variant="outlined" className="formControl">
                <InputLabel>Select Users</InputLabel>
                <Select
                  multiple
                  value={staff}
                  name="staff"
                  onChange={(e, child) => {
                    if (!child.props.value) {
                      setStaff([0]);
                    } else {
                      setStaff(e.target.value.filter(Boolean));
                    }
                    setPage(1);
                  }}
                  input={<OutlinedInput id={`select-multiple-checkbox`} />}
                  renderValue={(selected) =>
                    selected
                      .map(
                        (item) =>
                          [
                            { first_name: "(All Users)", id: 0 },
                            ...userList,
                          ]?.find((elem) => elem.id === item).first_name
                      )
                      .join(", ")
                  }
                  inputProps={{
                    name: "staff",
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 250,
                      },
                    },
                  }}
                >
                  {[{ first_name: "(All Users)", id: 0 }, ...userList].map(
                    (item) => (
                      <MenuItem key={item.id} value={item.id}>
                        <ListItemText
                          primary={`${item.first_name} ${item.last_name || ""}`}
                        />
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </div>

            <div
              className={`search-wrap`}
              style={{ display: "flex", alignItems: "center", gap: "20px" }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <DateLabel>Start:</DateLabel>
                <div className="btn-toolbar__group">
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel htmlFor="birthday_month">Month</InputLabel>
                    <Select
                      value={startMonth}
                      onChange={(e) =>
                        isValidDate(
                          startYear,
                          e.target.value,
                          endYearState,
                          endMonthState,
                          () => setStartMonth(e.target.value)
                        )
                      }
                      input={
                        <OutlinedInput
                          labelWidth={42}
                          name="month"
                          id="month"
                        />
                      }
                    >
                      <MenuItem value={"01"}>January</MenuItem>
                      <MenuItem value={"02"}>February</MenuItem>
                      <MenuItem value={"03"}>March</MenuItem>
                      <MenuItem value={"04"}>April</MenuItem>
                      <MenuItem value={"05"}>May</MenuItem>
                      <MenuItem value={"06"}>June</MenuItem>
                      <MenuItem value={"07"}>July</MenuItem>
                      <MenuItem value={"08"}>August</MenuItem>
                      <MenuItem value={"09"}>September</MenuItem>
                      <MenuItem value={"10"}>October</MenuItem>
                      <MenuItem value={"11"}>November</MenuItem>
                      <MenuItem value={"12"}>December</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="btn-toolbar__group">
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel htmlFor="year">Year</InputLabel>
                    <Select
                      value={startYear}
                      onChange={(e) =>
                        isValidDate(
                          e.target.value,
                          startMonth,
                          endYearState,
                          endMonthState,
                          () => setStartYear(e.target.value)
                        )
                      }
                      input={
                        <OutlinedInput labelWidth={30} name="year" id="year" />
                      }
                    >
                      {[...new Array(10)].map((item, index) => (
                        <MenuItem value={index + 2020} key={index}>
                          {2020 + index}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "4px" }}
              >
                <DateLabel>End:</DateLabel>
                <div className="btn-toolbar__group">
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel htmlFor="birthday_month">Month</InputLabel>
                    <Select
                      value={endMonthState}
                      onChange={(e) =>
                        isValidDate(
                          startYear,
                          startMonth,
                          endYearState,
                          e.target.value,
                          () => setEndMonthState(e.target.value)
                        )
                      }
                      input={
                        <OutlinedInput
                          labelWidth={42}
                          name="month"
                          id="month"
                        />
                      }
                    >
                      <MenuItem value={"01"}>January</MenuItem>
                      <MenuItem value={"02"}>February</MenuItem>
                      <MenuItem value={"03"}>March</MenuItem>
                      <MenuItem value={"04"}>April</MenuItem>
                      <MenuItem value={"05"}>May</MenuItem>
                      <MenuItem value={"06"}>June</MenuItem>
                      <MenuItem value={"07"}>July</MenuItem>
                      <MenuItem value={"08"}>August</MenuItem>
                      <MenuItem value={"09"}>September</MenuItem>
                      <MenuItem value={"10"}>October</MenuItem>
                      <MenuItem value={"11"}>November</MenuItem>
                      <MenuItem value={"12"}>December</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="btn-toolbar__group">
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel htmlFor="year">Year</InputLabel>
                    <Select
                      value={endYearState}
                      onChange={(e) =>
                        isValidDate(
                          startYear,
                          startMonth,
                          e.target.value,
                          endMonthState,
                          () => setEndYearState(e.target.value)
                        )
                      }
                      input={
                        <OutlinedInput labelWidth={30} name="year" id="year" />
                      }
                    >
                      {[...new Array(10)].map((item, index) => (
                        <MenuItem value={index + 2020} key={index}>
                          {2020 + index}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
          </FiltersBlock>
          <TableList
            header={header}
            data={data}
            check={[]}
            sort={sort}
            expand={true}
            expandedHeader={expandedHeader}
            setSort={(newSort) => {
              if (!sort) {
                return setSort(newSort);
              }
              if (sort[0] !== newSort[0]) {
                return setSort([newSort[0], "asc"]);
              }

              if (sort[1] === "desc") {
                return setSort([undefined, undefined]);
              }
              setSort(newSort);
            }}
            plan={plan}
            clickOnRow={handleClick}
          />
          <div className="pagination-wrap">
            <Pagination>
              <Pagination.First
                disabled={pagination.current_page === 1}
                onClick={() => setPage(1)}
              />
              <Pagination.Prev
                disabled={pagination.current_page === 1}
                onClick={() => setPage((prev) => prev - 1)}
              />

              <Pagination.Item>{pagination.current_page}</Pagination.Item>

              <Pagination.Next
                disabled={pagination.last_page === pagination.current_page}
                onClick={() => setPage((prev) => prev + 1)}
              />
              <Pagination.Last
                disabled={pagination.last_page === pagination.current_page}
                onClick={() => setPage(pagination.last_page)}
              />
            </Pagination>
            <div className="pagination-show">
              <FormControl variant="outlined" className="formControl">
                <InputLabel>Show Entries</InputLabel>
                <Select
                  value={perPage}
                  input={<OutlinedInput />}
                  onChange={(e) => {
                    setPerPage(e.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Typography variant="body1" gutterBottom>
                {`Showing ${pagination.from} - ${pagination.to} fo ${pagination.total}`}
              </Typography>
            </div>
          </div>
        </PortletBody>
      </Portlet>
      {clickedUser && (
        <Portlet>
          <PortletBody>
            <OpportunitiesList
              groupIds={clickedUserGroup}
              selectedGroup={groupList?.filter((item) =>
                clickedUserGroup.includes(item.id)
              )}
              selectedUsers={userList?.filter(
                (item) => clickedUser === item.id
              )}
              userId={clickedUser}
              startDate={startDate}
              endDate={endDate}
              period={getMonthDates(clickedMonth)}
            />
          </PortletBody>
        </Portlet>
      )}

      {openModal && (
        <EditFieldModal
          title={clickedRow?.title}
          value={clickedRow?.value}
          type={clickedRow?.type}
          open={openModal}
          onClose={handleClose}
          onSave={handleSave}
        />
      )}
    </StyledGroupLists>
  );
};

const StyledGroupLists = styled.div`
  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .btn {
    margin-right: 15px;
  }

  .btn-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &__group {
      display: flex;
      align-items: center;

      .formControl {
        margin-left: 0;
      }
    }
  }

  .edit-all {
    height: 100%;
    border-radius: 4px;
    background-color: #eef1ff;
    padding: 0 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5d78ff;
    display: flex;
    align-items: center;
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
      min-width: 290px;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }
`;

const FiltersBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
`;

const EditableCol = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  & .pencil-icon {
    cursor: pointer;
  }
`;

const SelectedBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & .close-icon {
    margin: 0;
    color: #646c9a;
    padding: 2px;
    position: absolute;
    right: -6px;
    &:hover {
      z-index: 99999999;
    }
  }
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  & p {
    margin-bottom: 0;
    white-space: nowrap;
  }
`;

const DateLabel = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
`;
