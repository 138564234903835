import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Modal, Button } from "react-bootstrap";
import { fetchExercise } from "../../../../../crud/library.crud";
import Loader from "../../../../../components/Loader";
import "./view-exercise.scss";

import * as media from "../../../../../crud/media.crud";

const ImgComponent = ({ src, alt, className }) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    src &&
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);

  return url && <img src={url} alt={alt} className={className} />;
};

export const ViewExerciseModal = ({ modal, setModal, current }) => {
  const [loader, setLoader] = useState(false);
  const [video_type, set_video_type] = useState("");
  const [data, setData] = useState({});
  const [overlayClicked, setOverlayClicked] = useState(false);

  useEffect(() => {
    if (current) {
      setLoader(true);
      fetchExercise(current)
        .then((res) => {
          setData(res.data.data);
          if (res.data.data.video_type) {
            set_video_type(
              res.data.data.video_type.charAt(0).toUpperCase() +
                res.data.data.video_type.slice(1)
            );
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  }, [current]);

  const getVideoId = () => {
    if (data.video_source.indexOf("https://www.youtube.com/watch?v=") !== -1) {
      return data.video_source.split("https://www.youtube.com/watch?v=")[1];
    } else {
      return data.video_source.split("https://youtu.be/")[1];
    }
  };

  const instructions = () => {
    return data?.instructions.split("/n").map((elem, index) => (
      <>
        <span>{elem}</span>
        <br />
      </>
    ));
  };

  return (
    <Modal show={modal !== false} onHide={() => setModal(false)}>
      <div className="view-exercise">
        <Loader visible={loader} />
        <div
          className="view-exercise__close-button"
          onClick={() => setModal(false)}
        >
          ×
        </div>

        <Modal.Header>
          <div className="view-exercise__page-title">{data.title}</div>
        </Modal.Header>

        <Modal.Body>
          {data.video_source ? (
            video_type === "Youtube" ? (
              <div
                onClick={() => setOverlayClicked(true)}
                className="view-exercise__video-container"
              >
                {data?.video_source_preview && !overlayClicked && (
                  <ImgComponent
                    src={data.video_source_preview}
                    className={"overlay-image"}
                  />
                )}
                <iframe
                  width="560"
                  height="303"
                  src={`https://www.youtube.com/embed/${getVideoId()}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : (
              <div
                onClick={() => setOverlayClicked(true)}
                className="view-exercise__video-container"
              >
                {data?.video_source_preview && !overlayClicked && (
                  <ImgComponent
                    src={data.video_source_preview}
                    className={"overlay-image"}
                  />
                )}
                <iframe
                  title="Vimeo video player"
                  src={`https://player.vimeo.com/video/${
                    data.video_source.split("https://vimeo.com/")[1]
                  }?h=5ca37da63f&color=000000&title=0&byline=0&portrait=0&badge=0`}
                  width="560"
                  height="303"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                />
              </div>
            )
          ) : (
            <></>
          )}

          {data?.video_source_text && (
            <div className="view-exercise__page-title">
              {data?.video_source_text}
            </div>
          )}

          {data.video_source && (
            <div className="view-exercise__textfield">
              <TextField
                variant="outlined"
                type="text"
                style={{ width: "100%" }}
                name="video_source"
                value={data.video_source}
                disabled
              />
            </div>
          )}

          {data?.instructions && (
            <div className="view-exercise__description-wrapper">
              <span className="view-exercise__description-title">
                Instructions
              </span>
              <p className="view-exercise__description-text">
                <span style={{ whiteSpace: "pre-wrap" }}>{instructions()}</span>
              </p>
            </div>
          )}

          {data?.variables && (
            <div className="view-exercise__description-wrapper">
              <span className="view-exercise__description-title">
                Units of Measure
              </span>
              <p className="view-exercise__description-text">
                {data?.variables
                  ?.map((elem) => elem.charAt(0).toUpperCase() + elem.slice(1))
                  .join(", ")}
              </p>
            </div>
          )}

          <div className="view-exercise__data-wrapper">
            <div style={{ marginRight: 100 }}>
              {data?.body_segment && (
                <div className="view-exercise__data-block">
                  <div className="view-exercise__data-block-title">
                    Body Segment
                  </div>
                  <div className="view-exercise__data-block-text">
                    {data.body_segment.title}
                  </div>
                </div>
              )}

              {data?.muscle && (
                <div className="view-exercise__data-block">
                  <div className="view-exercise__data-block-title">
                    Primary Muscle(s)
                  </div>
                  <div className="view-exercise__data-block-text">
                    {data.muscle.title}
                  </div>
                </div>
              )}

              {data?.equipment && (
                <div className="view-exercise__data-block">
                  <div className="view-exercise__data-block-title">
                    Equipment
                  </div>
                  <div className="view-exercise__data-block-text">
                    {data.equipment.title}
                  </div>
                </div>
              )}
            </div>

            <div>
              {data?.mechanic && (
                <div className="view-exercise__data-block">
                  <div className="view-exercise__data-block-title">
                    Mechanics
                  </div>
                  <div className="view-exercise__data-block-text">
                    {data.mechanic.title}
                  </div>
                </div>
              )}

              {data?.force && (
                <div className="view-exercise__data-block">
                  <div className="view-exercise__data-block-title">Force</div>
                  <div className="view-exercise__data-block-text">
                    {data.force.title}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => setModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};
