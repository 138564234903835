import React from 'react'
import { Modal } from 'react-bootstrap';
import { CreateExercise } from '../../../Exercises/CreateExercise/CreateExercise';
import './add-exercise-modal.scss';
import styled from 'styled-components';

export const AddExerciseModal = ({
    modal,
    setModal,
    refreshExercises,
}) => {
    const closeModal = () => {
        setModal(false)
    }

    return (
        <Modal
            show={modal !== false}
            onHide={closeModal}
            className='create-exercise-modal'
        >
            <StyledModal>
                <div
                    className="close-button"
                    onClick={closeModal}
                >×</div>
                <CreateExercise
                    refreshExercises={refreshExercises}
                    noRedirect={true}
                    closeModal={closeModal}
                />
            </StyledModal>
        </Modal>
    )
};

const StyledModal = styled.div`
    .close-button {
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }
`;
