import React, { useState, useEffect } from 'react';
import { Redirect } from "react-router-dom"
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { PDFObject } from 'react-pdfobject'
import { getPdfName, getPdfAccessenent, getPdfCard } from '../../crud/pdf.crud'
import Logout from '../auth/Logout'

export default (props) => {

    const [type, setType] = useState(props.match.params.type)
    const [pdf, setPdf] = useState()
    const [redirect, setRedirect] = useState(null)

    useEffect(() => {
        setType(props.match.params.type)
    }, [props.match.params.type])

    useEffect(() => {
        getPdf()
    }, [type]);

    const getPdf = () => {
        switch (type) {
            case 'name':
                getName()
                break;
            case 'card':
                getCard()
                break;
            case 'accessenent':
                getAccessenent()
                break;
            default:
                setRedirect(<Redirect to="/" />)
                break;
        }
    }

    const getName = () => {
        getPdfName(props.match.params.id, props.match.params.data)
            .then(res => {
                setPdf(res.data.data.path)
            })
            .catch(({ response }) => {
                if (response && (response.status === 401)) {
                    setPdf(<Logout />)
                } else {
                    setPdf(<Redirect to="/" />)
                }
            })
    }

    const getAccessenent = () => {
        getPdfAccessenent(props.match.params.id, props.match.params.data)
            .then(res => {
                setPdf(res.data.data.path)
            })
            .catch(({ response }) => {
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else {
                    setRedirect(<Redirect to="/" />)
                }
            })
    }

    const getCard = () => {
        getPdfCard(props.match.params.id, props.match.params.data)
            .then(res => {
                setPdf(res.data.data.path)
            })
            .catch(({ response }) => {
                if (response && (response.status === 401)) {
                    setRedirect(<Logout />)
                } else {
                    setRedirect(<Redirect to="/" />)
                }
            })
    }

    return <PDFwrap>
        {redirect}
        {pdf
            ? <PDFObject url={pdf} />
            : <div className="loaderpdf">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        }
    </PDFwrap>
}

const PDFwrap = styled.div`
    height: 100%;

    .loaderpdf {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #pdfobject {
        height: 100%;
    }
`