import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { useIsMount } from '../../../../hooks/useIsMount';
import * as AlertState from '../../../../../app/store/ducks/auth.duck';
import { Button } from 'react-bootstrap';
import { TextField } from "@material-ui/core";
import DropdownMultiselect from '../../../../components/DropdownMultiselectFetchOptions';
import { Table } from './Table/Table';
import Loader from '../../../../components/Loader';
import { fetchWorkouts, deleteWorkouts, fetchPrograms } from '../../../../crud/library.crud';
import Pagination from '../../../../components/Pagination';
import { getAllUsersWithoutFiltering } from '../../../../crud/info.crud';
import ConfirmModal from '../../../../components/ConfirmationModal';
import { Tab, Tabs } from '@material-ui/core';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import './list.scss';

export const WorkoutsList = () => {
    const history = useHistory();
    const isFirstRender = useIsMount();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const [search, setSearch] = useState('');
    const [data, setData] = useState([]);
    const [show, setShow] = useState(10);
    const [page, setPage] = useState(false);
    const [pagination, setPagination] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [check, setCheck] = useState([]);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [sort, setSort] = useState([null, null]);
    const [tab, setTab] = useState(0);
    const userId = useSelector(state => state.auth.user.id);
    const permissions = useSelector(state => state.auth.user.permissions);

    const loadData = () => {
        setLoader(true);
        fetchWorkouts({
            search,
            page: pagination,
            perPage: show,
            creator_ids: tab === 1 ? [userId] : selectedUsers.map(elem => elem.id),
            program_ids: selectedPrograms.map(elem => elem.id),
            sort: sort[0],
            sortBy: sort[1],
        })
            .then(res => {
                setData(res.data.workouts);
                setLastPage(res.data.last_page);
                setPage({
                    current_page: res.data.current_page,
                    last_page: res.data.last_page,
                    per_page: res.data.per_page,
                    total: res.data.total,
                })
            })
            .finally(() => setLoader(false))
    };

    useEffect(() => {
        loadData();
    }, [pagination, show, sort, selectedPrograms, selectedUsers, tab]);

    useEffect(() => {
        if (isFirstRender) return;
        const handler = setTimeout(() => {
            setLoader(true);
            fetchWorkouts({
                search,
                page: 1,
                perPage: show,
                creator_ids: tab === 1 ? [userId] : selectedUsers.map(elem => elem.id),
                program_ids: selectedPrograms.map(elem => elem.id),
                sort: sort[0],
                sortBy: sort[1],
            })
                .then(res => {
                    setPagination(1);
                    setPage({
                        current_page: res.data.current_page,
                        last_page: res.data.last_page,
                        per_page: res.data.per_page,
                        total: res.data.total,
                    })
                    setData(res.data.workouts);
                    setLastPage(res.data.last_page);
                })
                .finally(() => setLoader(false))
        }, 1000);
        return () => clearTimeout(handler);
    }, [search]);

    const handleSearch = e => {
        setSearch(e.target.value);
    };

    const fetchUsersForOptions = () => {
        getAllUsersWithoutFiltering({ search, disable_filtering: 1 })
            .then(res => {
                const list = res.data?.data
                    .map(elem => ({ ...elem, title: elem.name }));
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                });
                setUsers(sorted);
            })
            .catch(err => {
                let errText = catchErrorMessage(err);
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => setLoader(false))
    };

    useEffect(() => {
        fetchUsersForOptions();
    }, []);

    const fetchProgramsForOptions = search => {
        setLoader(true);
        fetchPrograms({ search, page: 1, perPage: 50 })
            .then(res => {
                setLoader(false);
                const list = res.data?.programs;
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                })
                setPrograms(sorted);
            })
            .catch(err => {
                setLoader(false);
                let errText = catchErrorMessage(err);
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    };

    const handleDelete = () => {
        setLoader(true);
        deleteWorkouts(check.map(elem => elem.id))
            .then(() => {
                setLoader(false);
                loadData();
                setDeleteModal(false);
                dispatch(AlertState.actions.alert({
                    text: check.length > 1 ? 'Workout deleted successfully' : 'Workouts deleted successfully',
                    variant: true,
                }));
                setCheck([]);
            })
            .catch((err) => {
                setLoader(false);
                setDeleteModal(false);
                let errText = catchErrorMessage(err);
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    };

    return (
        <div className='workouts-list'>
            <Loader visible={loader} />

            <div className="workouts-list__header-list">
                <Tabs
                    value={tab}
                    onChange={(e, value) => setTab(value)}
                    indicatorColor="primary"
                    textColor="primary"
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab label="ALL WORKOUTS" style={{ fontSize: '12px', fontWeight: '700' }} />
                    <Tab label="MY WORKOUTS" style={{ fontSize: '12px', fontWeight: '700' }} />
                </Tabs >

                <div className='btn-container'>
                    {permissions.find(elem => elem === 'create_workouts') && <Button
                        variant="primary"
                        className="btn-blue"
                        onClick={() => history.push('/library/workouts/create')}
                    >
                        Create Workout
                    </Button>}
                </div>
            </div>

            <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                handleConfirm={handleDelete}
                title="Are you sure you want to delete selected workouts?"
                submitText="Yes"
                isCancelButton={true}
            />

            <div className='workouts-list__sort-container'>
                <div className='workouts-list__sort-wrapper'>
                    <div className='workouts-list__dropdownmultiselect workouts-list__dropdownmultiselect-1'>
                        <DropdownMultiselect
                            fetchOptions={fetchProgramsForOptions}
                            data={selectedPrograms}
                            setData={setSelectedPrograms}
                            options={programs}
                            label="Program name"
                            style={{ marginRight: 20, width: 300 }}
                        />
                    </div>

                    {tab !== 1 && (
                        <div className='workouts-list__dropdownmultiselect workouts-list__dropdownmultiselect-2'>
                            <DropdownMultiselect
                                data={selectedUsers}
                                setData={setSelectedUsers}
                                options={users}
                                label="Created by"
                                style={{ width: 300 }}
                            />
                        </div>
                    )}
                </div>

                <TextField
                    variant="outlined"
                    label="Search"
                    value={search}
                    onChange={handleSearch}
                />
            </div>

            <div className='d-flex' style={{ marginTop: 15 }}>
                {check.length === 1 && (
                    <>
                        <Link to={`/workout/${check[0].id}`} target="_blank">
                            <Button
                                variant="secondary"
                                style={{ height: '100%' }}
                            >
                                Print
                            </Button>
                        </Link>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                if (check[0].is_editable) {
                                    history.push(`/library/workouts/${check[0].id}`)
                                } else {
                                    dispatch(AlertState.actions.alert({
                                        text: 'You do not have permission to edit this workout',
                                        variant: false
                                    }))
                                }
                            }}
                            style={{ margin: '0 0 0 10px' }}
                        >
                            Edit
                        </Button>

                        <Button
                            variant="secondary"
                            onClick={() => {
                                history.push({
                                    pathname: `/library/workouts/create`,
                                    state: {
                                        workoutId: check[0].id,
                                        isCloneForLibrary: true,
                                    }
                                })
                            }}
                            style={{ margin: '0 0 0 10px' }}
                        >Duplicate</Button>                        
                    </>
                )}

                {check.length > 0 && (
                    <Button
                        variant="secondary"
                        onClick={() => setDeleteModal(true)}
                        style={{ margin: '0 0 0 10px' }}
                    >
                        Delete
                    </Button>
                )}
            </div>

            <Table
                data={data}
                check={check}
                setCheck={setCheck}
                sort={sort}
                setSort={setSort}
            />

            <Pagination
                show={show}
                setShow={setShow}
                page={page}
                setPage={setPage}
                pagination={pagination}
                setPagination={setPagination}
                lastPage={lastPage}
                data={data}
            />
        </div>
    )
};
