import React from "react";
import styled from "styled-components";
import { CARD_STATUS_COLOR, CARD_STATUS_TITLE } from "../constants/constants";

export const OpportunityCardStatus = ({ status }) => {
  return status ? (
    <Wrapper
      style={{
        backgroundColor: CARD_STATUS_COLOR[status].bg,
        color: CARD_STATUS_COLOR[status].color,
      }}
    >
      <p>{CARD_STATUS_TITLE[status]}</p>
    </Wrapper>
  ) : (
    <Wrapper>
      <p>--</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 20px;
  padding: 2px 12px 2px 12px;
  gap: 10px;
  border-radius: 28px;
  opacity: 0px;
  width: 70px;
  & p {
    font-family: "Poppins";
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    margin: 0;
  }
`;
