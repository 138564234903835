import { setTemporaryForms } from "../../../../store/actions";
import * as AlertState from '../../../../../app/store/ducks/auth.duck';

export const validateCurrentInput = (index, formik, form, dispatch, elem) => {
    let answers = [...formik.values.answers];

    if (elem.type === 'number' && answers[index].value && (+answers[index].value < +elem.from || +answers[index].value > +elem.to)) {
        answers[index] = {
            ...answers[index],
            touched: true,
            hasError: true,
            errorText: `Min: ${elem.from} - Max: ${elem.to}`,
        };

        formik.setValues({
            form_id: formik?.form_id,
            answers: [...answers],
        })
    }

    if (
        elem.type === 'short' &&
        answers[index].value.length > 255
    ) {
        answers[index] = {
            ...answers[index],
            touched: true,
            hasError: true,
            errorText: `Too long - should not exceed 255 characters`,
        };

        formik.setValues({
            form_id: formik?.form_id,
            answers: [...answers],
        })
    }

    dispatch(setTemporaryForms({
        [form?.id]: {
            form_id: formik?.form_id,
            answers: [...answers],
        }
    }))
};

export const handleChangeInput = (e, index, formik, form, elem, dispatch) => {
    let answers = [...formik.values.answers];

    answers[index] = {
        ...answers[index],
        value: e.target.value,
        touched: true,
        hasError: false,
        errorText: '',
    };

    formik.setValues({
        form_id: formik?.form_id,
        answers: [...answers],
    })

    dispatch(setTemporaryForms({
        [form?.id]: {
            form_id: formik?.form_id,
            answers: [...answers],
        }
    }))
};

export const validateFullForm = (formik, form, dispatch) => {
    let isValid = true;
    let answers = [...formik.values.answers];

    formik.values.answers.forEach((elem, index) => {
        if (
            elem.type === 'number' &&
            answers[index].value !== '' &&
            (+answers[index].value < +form?.inputs[index].from || +answers[index].value > +form?.inputs[index].to)
        ) {
            isValid = false;

            answers[index] = {
                ...answers[index],
                touched: true,
                hasError: true,
                errorText: `Min: ${+form?.inputs[index].from} - Max: ${+form?.inputs[index].to}`,
            };
        }

        if (
            elem.type === 'short' &&
            answers[index].value.length > 255
        ) {
            isValid = false;
            const errorText = `Too long - should not exceed 255 characters`;

            answers[index] = {
                ...answers[index],
                touched: true,
                hasError: true,
                errorText,
            };
        }

        if (!isValid) {
            dispatch(AlertState.actions.alert({
                text: 'The form has errors, please fix to continue.',
                variant: false,
            }));
        }        
    });

    formik.setValues({
        form_id: formik?.form_id,
        answers: [...answers],
    });

    dispatch(setTemporaryForms({
        [form?.id]: {
            form_id: formik?.form_id,
            answers: [...answers],
        }
    }))

    return isValid;
};
