import React, { forwardRef } from "react";
import { SignatureDrawWrapper } from "../SignatureModal.styles";
import SignatureCanvas from "react-signature-canvas";
import { ImgComponent } from "./ImgComponent";

export const SignatureDraw = forwardRef((props, ref) => {
  return (
    <SignatureDrawWrapper>
      <SignatureCanvas
        ref={ref}
        canvasProps={{
          width: 500,
          height: 200,
        }}
      />
      {props.photo?.path && (
        <ImgComponent
          src={props.photo.path}
          alt="img"
          style={{ objectFit: "contain" }}
        />
      )}
    </SignatureDrawWrapper>
  );
});

SignatureDraw.name = "SignatureDraw";
