import styled from "styled-components";
const Wrapper = styled.div`
  padding: 20px;

  & .modal-header {
    border-bottom: none;
  }
  & .modal-body {
    min-height: 300px;
  }
`;

const SignatureTypeWrapper = styled.div`
  & .blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px;
    padding: 20px 0;
    p:nth-child(1) span {
      font-family: "Great Vibes" !important;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
    }
    p:nth-child(2) span {
      font-family: "Courgette" !important;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
    }
    p:nth-child(3) span {
      font-family: "Sacramento" !important;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
    }
    p:nth-child(4) span {
      font-family: "Cookie" !important;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
    }
  }
`;

const TypeBlock = styled.p`
  border: 1px solid grey;
  border-color: ${(props) => (props.active ? "black" : "#ebedf2")};
  height: 40px;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TypeBlockText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const SignatureDrawWrapper = styled.div`
  border: 1px solid #ebedf2;
  border-radius: 6px;
  cursor: url("http://icons.iconarchive.com/icons/designcontest/vintage/32/Patent-Pen-icon.png")
      0 30,
    progress;
`;
const SignatureUploadWrapper = styled.div``;

const SignatureAreaWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 4px;
  border: 1px dashed grey;
  border-radius: 4px;
  cursor: pointer;
  max-width: 300px;
  min-height: 140px;
  & p {
    margin: 0;
  }
`;

const SignatureImageAreaWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 4px;
  border: 1px dashed grey;
  border-radius: 4px;

  cursor: pointer;
  max-width: 300px;
  min-height: 140px;
  & .delete {
    position: absolute;
    right: 4px;
    top: 4px;
    z-index: 1;
  }
  & img {
    width: 100%;
    max-height: 140px;
    object-fit: contain;
  }
`;

export {
  Wrapper,
  SignatureTypeWrapper,
  TypeBlock,
  TypeBlockText,
  SignatureDrawWrapper,
  SignatureUploadWrapper,
  SignatureAreaWrapper,
  SignatureImageAreaWrapper,
};
