import React, { useState, useEffect } from 'react';
import { fetchCRMSingle } from '../../../../crud/crm.crud';
import { useSelector } from 'react-redux';
import Loader from "../../../../components/Loader";

export const CRMLimits = ({ crm }) => {
    const [loader, setLoader] = useState(false);
    const accountId = useSelector(state => state.appData?.account?.id);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (crm && accountId) {
            setLoader(true);
            fetchCRMSingle(accountId, crm.id)
                .then(res => {
                    setData(res.data.single.limits);
                })
                .finally(() => setLoader(false))
        }
    }, [crm, accountId]);

    return (
        <div className="crm-info">
            <Loader visible={loader} />

            <div className="crm-info__row">
                <div className="crm-info__cell">API Call Usage</div>
                <div className="crm-info__cell crm-info__limit-cell">
                    <span>{data?.today || 0}</span>
                    <span className="crm-info__text">{` / ${data?.day_max || 0}`}</span>
                </div>
            </div>
            <div className="crm-info__row">
                <div className="crm-info__cell">API Call Threshold</div>
                <div className="crm-info__cell crm-info__limit-cell">
                    <span>{data?.day_max || 0}</span>
                </div>
            </div>
        </div>
    )
};