import React, { useState } from 'react';
// import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import ArrowDown from '@material-ui/icons/ArrowDropDown';
// import DateFnsUtils from '@date-io/date-fns';
import {
  // Grid,
  TextField
} from '@material-ui/core'
// import {
//   MuiPickersUtilsProvider,
//   KeyboardDatePicker,
// } from '@material-ui/pickers';
import DatePicker from '../components/DatePicker'
import TimePicker from './TimePicker'
// import moment from 'moment'

export default ({ disabled, data, editData, edit, editDataToos, profileInfo }) => {
  const [sort, setSort] = useState([null, null])

  const defRow = [
    {
      label: "Category",
      value: 'category',
      left: true
    },
    {
      label: "Assessment",
      value: 'metric',
      left: true
    },
    {
      label: "Recent Date",
      value: 'date',
    },
    {
      label: "Recent Result",
      value: 'result',
    },
    {
      label: "Recent Classification",
      value: 'classification',
    },
    {
      label: "Next Goal Date",
      value: 'goal_date',
      left: true,
      style: { maxWidth: '130px', width: '130px' }
    },
    {
      label: "Next Goal",
      value: 'goal_value',
      left: true,
      style: { maxWidth: '130px', width: '130px' }
    },
  ]

  const sortEdit = item => {
    setSort([item.value, `${sort[1] === 'asd' ? 'desc' : 'asd'}`])
  }

  const sortData = () => {

    let temp = data.map((item) => {
      let index = edit.findIndex(elem => elem.name === item.name)
      return index === -1
        ? item
        : {
          ...item,
          ...edit[index]
        }
    })
    return [...temp].sort((a, b) => {
      const nullType = !!a[`${sort[0]}`]
      const typeSort = sort[1] === 'asd' ? 1 : sort[1] === 'desc' ? -1 : 0
      return (
        b[`${sort[0]}`] === null
          ? -1
          : nullType
            ? a[`${sort[0]}`] > b[`${sort[0]}`]
              ? typeSort
              : typeSort * (-1)
            : 1
      )
    })
  }

  return (
    <StyledTablePl className="settingtable nowrap">
      <table>
        <thead>
          <tr>
            {
              defRow.map((item, index) => {
                return (
                  <td key={index} onClick={() => sortEdit(item)} className={`${item.left ? 'left-content' : ''}`} style={item.style && item.style}>
                    {item.label}
                    <ArrowDown
                      className={`sort-arrow${sort[0] === item.value ? ' active' : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}`}
                    />
                  </td>
                )
              })
            }
          </tr>
        </thead>
        <tbody>
          {
            sortData().map((item, index, arr) => {
              return (
                <TableRow
                  disabled={disabled}
                  editDataToos={editDataToos}
                  item={item}
                  key={index}
                  editData={editData}
                  position={(arr.length < (index + 6)) && arr.length > 6 ? 'top' : 'bottom'}
                  profileInfo={profileInfo}
                />
              )
            })
          }
          {(sortData().length <= 6 && sortData().length !== 0) && <tr style={{ height: 300 }}></tr>}
        </tbody>
      </table>
      {
        data.length === 0
          ? (
            <div
              style={{
                margin: '20px auto',
                textAlign: 'center'
              }}
            >
              No data available in table
            </div>
          ) : null
      }
    </StyledTablePl>
  )
}

const StyledTablePl = styled.div`
  .sort-arrow {
    opacity: 0.7;
    transition: all .3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg)
    }
  }
  table thead tr td.left-content {
    text-align: left;
  }
  table tbody tr td.left-content {
    text-align: left;
  }
`

const TableRow = ({ item, position, editData, editDataToos, disabled, profileInfo }) => {
  return (
    <Row>
      <td className="left-content min">
        {item.category}
      </td>
      <td className="left-content min">
        {item.metric}
      </td>
      <td className="min">
        {item.date}
      </td>
      <td className="min">
        {item.result}
      </td>
      <td className="min">
        {`${item.classification || '--'}`}
      </td>
      <td className="left-content date min" style={{ maxWidth: '130px', verticalAlign: 'top', paddingTop: 5 }}>
        {!disabled
          ? <DatePicker
            value={item.goal_date}
            format={'MM/DD/YYYY'}
            future={true}
            past={true}
            position={position}
            onChange={value => {
              value
                ? editData(item, value, 'goal_date')
                : editData(item, null, 'goal_date')
            }}
            style={{
              width: '100%',
              fontWeight: 300,
              color: 'rgba(0, 0, 0, 0.87)',
            }}
            clear={true}
          />
          : <TextField
            disabled={disabled}
            variant="outlined"
            type={'text'}
            label={'Goal Date'}
            margin="normal"
            className="kt-width-full"
          />
        }
      </td>
      <td className="left-content value min" style={{ maxWidth: '130px', witdh: '130px', verticalAlign: 'top', paddingTop: 5 }}>
        {disabled
          ? (
            <TextField
              disabled={disabled}
              variant="outlined"
              type={'text'}
              label={'Goal'}
              margin="normal"
              className="kt-width-full"
            />
          )
          : (item.type === 'time'
            ? <TimePicker
              onChange={e => editData(item, e, 'goal_value')}
              value={`${item.goal_value || '00:00:00'}`}
              position={!(position === 'top')}
            />
            : item.type === 'array'
              ? (
                profileInfo?.measurement_system === 'imperial'
                  ? (
                    <div className="double-input">
                      <TextField
                        key='4'
                        style={{ width: '100%' }}
                        variant="outlined"
                        type="text"
                        label={'Ft'}
                        margin="normal"
                        className="kt-width-full"
                        name="goal_value"
                        onChange={e => editDataToos(item, e.target.value, 0)}
                        value={`${item?.goal_value[0]?.value || ''}`}
                      />
                      <div style={{ width: '5px' }} />
                      <TextField
                        key='5'
                        style={{ width: '100%' }}
                        variant="outlined"
                        type="text"
                        label="In"
                        margin="normal"
                        className="kt-width-full"
                        name="goal_value"
                        onChange={e => editDataToos(item, e.target.value, 1)}
                        value={`${item?.goal_value[1]?.value || ''}`}
                      />
                    </div>
                  )
                  : (
                    <div className="double-input">
                      <TextField
                        key='4'
                        style={{ width: '100%' }}
                        variant="outlined"
                        type="text"
                        label={'Cm'}
                        margin="normal"
                        className="kt-width-full"
                        name="goal_value"
                        onChange={e => editDataToos(item, e.target.value, 0)}
                        value={`${item?.goal_value || ''}`}
                      />
                    </div>
                  )
              )
              : <TextField
                key='6'
                style={{ width: '100%' }}
                variant="outlined"
                type="text"
                margin="normal"
                className="kt-width-full"
                name="goal_value"
                onChange={e => editData(item, e.target.value, 'goal_value')}
                value={`${item.goal_value || ''}`}
              />
          )
        }
      </td>
    </Row>
  )
}

const Row = styled.tr`
  .date .MuiGrid-container {
    width: 150px;
  }

  .value .MuiFormControl-root {
    width: 75px;
  }

  .value .picker-wrap {
    margin-top: 0;
  }

  td input {
    border: none !important;
    border-radius: 0 !important;
    text-align: center !important;
    /* padding: 5px !important; */
  }

  .double-input {
    display: flex;
  }
`