import React, { useEffect, useState } from 'react';
import trash from '../../../../../../_metronic/_assets/media/svg-icon/delete.svg';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ConfirmModal from '../../../../../components/ConfirmationModal';
import Dropdown from '../../../../../components/DropdownComponent';
import { fetchWorkouts } from '../../../../../crud/library.crud';
import './dnd-workouts.scss';

export const DragAndDropWorkouts = ({
    workouts,
    setWorkouts,
    setLoader,
}) => {
    const [deleteModal, setDeleteModal] = useState(false);
    const [current, setCurrent] = useState(null);
    const [workoutsList, setWorkoutsList] = useState([]);

    useEffect(() => {
        fetchWorkouts({ perPage: 500 })
            .then(res => {
                setWorkoutsList(res.data.workouts);
            })
    }, []);

    const loadWorkouts = (search) => {
        if (search && typeof search === 'string' && search.trim() !== '') {
            setLoader(true);
            fetchWorkouts({ search, perPage: 500 })
                .then(res => {
                    setWorkoutsList(res.data.workouts);
                })
                .finally(() => setLoader(false));
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = result => {
        if (!result.destination) return;
        const itemsReordered = reorder(
            workouts,
            result.source.index,
            result.destination.index
        );
        setWorkouts([...itemsReordered])
    };

    const handleSelectWorkout = (val, index) => {
        setWorkouts(prev => {
            const arr = [...prev];
            arr[index] = {
                ...arr[index],
                workoutId: val?.id || '',
                title: val?.title || '',
                touched: true,
                hasError: false,
                error: '',
            };
            return arr;
        })
    };

    const handleDeleteWorkout = (index) => {
        setWorkouts(prev => {
            const arr = [...prev];
            arr.splice(index, 1);
            return arr;
        });
        setDeleteModal(false);
    };

    return (
        <>
            <ConfirmModal
                modal={deleteModal}
                setModal={setDeleteModal}
                handleConfirm={() => handleDeleteWorkout(current)}
                title="Are you sure you want to delete?"
                submitText="Yes"
            />

            <div className='workouts-dnd'>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {workouts?.map((elem, index) => (
                                    <Draggable key={elem.id} draggableId={elem.id} index={index}>
                                        {(provided, snapshot) => {
                                            return (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    <div
                                                        className='workouts-dnd__draggble'
                                                        style={{ border: snapshot.isDragging ? '3px solid #1E1E2D' : '' }}
                                                    >
                                                        <div className='d-flex'>
                                                            <div className='create-program__dropdown'>
                                                                <Dropdown
                                                                    options={workoutsList}
                                                                    setFormData={(val) => handleSelectWorkout(val, index)}
                                                                    label="Select Workout"
                                                                    width="100%"
                                                                    value={elem}
                                                                    fetchOptions={loadWorkouts}
                                                                    error={elem.touched && elem.hasError}
                                                                    helperText={elem.touched && elem.hasError && elem.errorText}
                                                                />
                                                            </div>

                                                            <img
                                                                src={trash}
                                                                className='workouts-dnd__delete'
                                                                alt='delete'
                                                                onClick={() => {
                                                                    setCurrent(index)
                                                                    setDeleteModal(true);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
        </>
    )
};
