import React from 'react';
import UserInfo from '../../components/UserInfo';
import DataOutput from '../../components/DataOutput';
import { TaskList } from './Tasks/TaskList';
import styled from 'styled-components';

const ProfileTasks = (props) => {
    const profileId = props.match.params.id

    return (
        <StyledTasks>
            <div className='row'>
                <UserInfo id={profileId} />
                <DataOutput id={profileId} />
            </div>

            <div className='profile-tasks__tasks'>
                <TaskList profileId={profileId} />
            </div>

        </StyledTasks>
    )
}

const StyledTasks = styled.div`
    .profile-tasks__tasks{
        margin-top: 20px;
    }
`;

export default ProfileTasks;
