import * as AlertState from '../../../../store/ducks/auth.duck';

export const validateWorkout = (index, workouts, setWorkouts) => {
    let isValid = true;

    if (!workouts[index].workoutId) {
        isValid = false;
        setWorkouts(prev => {
            const arr = [...prev];
            arr[index] = {
                ...arr[index],
                touched: true,
                hasError: true,
                errorText: 'Required'
            }
            return arr;
        })
    }

    return isValid;
};

export const validateFullForm = (workouts, setWorkouts, dispatch) => {
    let isValid = true

    // Workouts length must be > 0
    if (!workouts?.length) {
        isValid = false;
        dispatch(AlertState.actions.alert({
            text: 'Add at least one workout',
            variant: false
        }));
    }

    /*
    // Workouts must be different
    const matches = [];
    workouts.forEach((elem, index) => {
        if (matches.find(match => {
            if (!match.workoutId || !elem.workoutId) return false;
            return match.workoutId === elem.workoutId
        })) {
            isValid = false;

            setWorkouts(prev => {
                const arr = [...prev];
                arr[index] = {
                    ...arr[index],
                    touched: true,
                    hasError: true,
                    errorText: 'Workouts must be different'
                }
                return arr;
            });

            dispatch(AlertState.actions.alert({
                text: 'Workouts must be different',
                variant: false
            }));
        } else {
            matches.push(elem);
        }
    });
    */

    // Validate workout values
    workouts?.length && workouts.forEach((elem, index) => {
        if (!validateWorkout(index, workouts, setWorkouts)) {
            isValid = false;
        }
    });

    return isValid;
};
