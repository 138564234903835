import React from 'react';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';

export const TableRow = ({
    item,
    check,
    setCheck,
}) => {
    const handleCheck = id => {
        const index = check.findIndex(elem => elem === id);
        if (index !== -1) {
            setCheck(prev => {
                let arr = [...prev];
                arr.splice(index, 1);
                return arr;
            })
        } else {
            setCheck(prev => ([...prev, id]))
        }
    };

    return (
        <tr key={item.id}>
            <td>
                <Checkbox
                    color="primary"
                    onChange={() => handleCheck(item.id)}
                    checked={check.find(elem => elem === item.id) !== undefined}
                />
            </td>
            <td className='left-content'>
                {item.title}
            </td>
            <td className='left-content'>
                {item.is_completed ? 'Completed' : 'Open'}
            </td>
            <td className='left-content'>
                {item.type === 'complete_form'
                    ? 'Forms'
                    : item.type === 'new_goal'
                        ? 'Goals'
                        : item.type === 'complete_metric'
                            ? 'Metrics'
                            : item.type === 'general'
                                ? 'General'
                                : item.type
                }
            </td>
            <td className='left-content'>
                {item.profiles && item.profiles[0]
                    ? item.profiles[0]?.first_name + ' ' + item.profiles[0]?.last_name
                    : '-'
                }
            </td>
            <td className='left-content'>
                {moment(item.perform_at).format('MM/DD/YYYY')}
            </td>
            <td className='left-content'>
                {item.completed_at ? moment(item.completed_at).format('MM/DD/YYYY') : '--'}
            </td>
            <td className='left-content'>
                {item.completed_by ? item.completed_by.first_name + ' ' + item.completed_by.last_name : '--'}
            </td>
        </tr>
    )
};
