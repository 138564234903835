import React, { useEffect, useState } from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import styled from "styled-components";

const defaultOptions = ['No data'];

const SimpleDropdown = ({
    label,
    error,
    options,
    onChange,
    value,
    width,
    helperText,
    fetchOptions,
    disabledInput = false,
    disableClearable = false,
    multiple = false,
    content = null,
    onBlur,
    name,
    disabled = false,
    style = {},
}) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e, value) => {
        onChange(value, e, setInputValue);
    };

    useEffect(() => {
        if (fetchOptions) {
            const handler = setTimeout(() => {
                fetchOptions(inputValue);
            }, 500);
            return () => clearTimeout(handler);
        }
    }, [inputValue]);

    return (
        <StyledDropdown>
            <Autocomplete
                disabled={disabled}
                name={name}
                onBlur={onBlur}
                value={content ? ' ' : value || null}
                options={options || defaultOptions}
                getOptionLabel={option => option}
                style={{ width: width || 150 }}
                onChange={handleChange}
                multiple={multiple}
                inputValue={inputValue}
                onInputChange={(e, newInputValue) => {
                    if (!disabledInput) {
                        setInputValue(newInputValue)
                    }
                }}
                disableClearable={disableClearable}
                renderInput={params => {
                    return (
                        <TextField
                            {...params}
                            inputProps={{ ...params.inputProps, value: params.inputProps.value }}
                            label={label || 'Select'}
                            variant="outlined"
                            error={!!error}
                            helperText={helperText ? helperText : error ? error : ''}
                            disabled={disabledInput}
                            onBlur={onBlur}
                            name={name}
                        />
                    )
                }}
            />

            {content && (
                <div className="content">
                    {content}
                </div>
            )}
        </StyledDropdown>
    );
};

const StyledDropdown = styled.div`
    position: relative;

    .content {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 85%;
        height: calc(100% - 4px);
        background-color: #fff;
        display: flex;
        align-items: center;
        padding-left: 10px;
    }
`;

export default SimpleDropdown;
