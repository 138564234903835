import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
    Modal,
    Button,
} from 'react-bootstrap';
import {
    fetchExternals,
    getFields,
    // updateMappings,
    // fetchCRMList
} from '../../../crud/crm.crud';
import DropdownComponent from '../../../components/DropdownComponent';
import { useDispatch, useSelector } from 'react-redux';
import {
    setCRMFields,
    resetCRMFields,
    // setCRMAction
} from '../../../store/actions';
import { useHistory } from 'react-router';
import Loader from '../../../components/Loader';
// import * as AlertState from '../../../../app/store/ducks/auth.duck';
// import moment from 'moment';
// import Tooltip from '@material-ui/core/Tooltip';
// import { withStyles } from '@material-ui/core/styles';
// import question from '../../../../_metronic/_assets/media/svg-icon/question.svg';

// const StyledToolTip = withStyles((theme) => ({
//     tooltip: {
//         boxShadow: theme.shadows[1],
//         fontSize: 14,
//     },
// }))(Tooltip);

const ModalCreateFromCRM = ({
    modal,
    setModal,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [list, setList] = useState([]);
    const [formData, setFormData] = useState(null);
    const [error, setError] = useState({
        isError: false,
        message: ''
    });
    const [loader, setLoader] = useState(false);
    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.is_activated === true));
    const crmFields = useSelector(state => state.crmFields);
    const accountId = useSelector(state => state.appData?.account?.id);

    const loadData = search => {
        if (crm?.name === 'Motionsoft' && !search) return;
        if (!crm || !accountId) return;
        setLoader(true);
        fetchExternals(accountId, crm.id, 'groups', {
            page: 1,
            limit: 500,
            search,
        })
            .then(res => {
                const list = res.data.list.map(elem => ({ ...elem, title: elem.name || '', value: elem.id }))
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                })
                    .filter(elem => elem.is_mapped === false)
                setList(sorted);
            })
            .finally(() => setLoader(false))
    };

    useEffect(() => {
        if (formData) {
            setLoader(true)
            getFields(accountId, crm.id, 'groups', formData.id)
                .then(res => dispatch(setCRMFields(res.data.single)))
                .catch(() => dispatch(resetCRMFields()))
                .finally(() => setLoader(false))
        } else {
            dispatch(resetCRMFields());
        }
    }, [formData]);

    const handleClose = () => {
        setError({
            isError: false,
            message: ''
        });
        setModal(false);
        setFormData(null);
    };

    const validate = () => {
        let isValid = true;

        if (!formData) {
            isValid = false;
            setError({
                isError: true,
                message: 'Check group'
            });
        };

        return isValid;
    };

    const handleContinue = () => {
        if (validate()) {
            history.push('/group-create');
        };
    };

    // const handleUpdate = () => {
    //     setLoader(true);
    //     updateMappings('groups')
    //         .then(() => {
    //             dispatch(AlertState.actions.alert({
    //                 text: 'CRM list updating has started',
    //                 variant: true,
    //             }));
    //             fetchCRMList(accountId)
    //                 .then(res => {
    //                     dispatch(setCRMAction(res.data.list))
    //                 })
    //                 .finally(() => setLoader(false))
    //             loadData();
    //         })
    //         .catch(err => {
    //             let errText = 'request failed';
    //             if (err.response?.data?.error) {
    //                 errText = err.response?.data?.error;
    //             } else if (err.response?.data?.message) {
    //                 errText = err.response.data.message;
    //             } else {
    //                 errText = 'request failed';
    //             }
    //             dispatch(AlertState.actions.alert({
    //                 text: errText,
    //                 variant: false,
    //             }));
    //             handleClose()
    //         })
    //         .finally(() => setLoader(false))
    // };

    return (
        <Modal
            show={modal !== false}
            onHide={handleClose}
        >
            <Loader visible={loader} />

            <StyledModalCreateFromCRM>
                <div
                    className="close-button"
                    onClick={handleClose}
                >×</div>
                <Modal.Title>
                    <h4 className="title">Create Group from CRM</h4>
                </Modal.Title>
                <Modal.Body>
                    {/* {crm?.name !== "Motionsoft" && (
                        <div className='d-flex align-items-center' style={{ margin: '0 0 10px 4px' }}>
                            {crm?.last_update_started?.groups
                                ? (
                                    <div className='d-flex align-items-center'>
                                        <div className="last-sync-block">
                                            {`CRM list last updated ${moment(crm.last_update_started?.groups).format('MM/DD/YYYY hh:mm:ss a')}`}
                                        </div>
                                        <StyledToolTip
                                            title={<>Synchronization may take some time. <br />
                                                To see if the synchronization is complete, please refresh the page and observe the changes in this table.
                                            </>}
                                            placement="bottom"
                                        >
                                            <img src={question} style={{ cursor: 'pointer', margin: '0 5px 0 10px', height: '18px' }} alt="tooltip" />
                                        </StyledToolTip>
                                    </div>
                                )
                                : <div style={{ marginRight: '10px' }}>CRM has not been updated yet.</div>
                            }
                            <span onClick={handleUpdate} className='update-link'>
                                Update Now
                            </span>
                        </div>
                    )} */}

                    <h5 className="subtitle">CRM Group</h5>

                    <DropdownComponent
                        options={list}
                        setFormData={setFormData}
                        label="Start typing to search CRM Group"
                        width="100%"
                        error={error.isError}
                        helperText={error.message}
                        value={formData}
                        fetchOptions={loadData}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleContinue}
                        className="btn-blue"
                        disabled={!formData || !crmFields}
                    >
                        Continue
                    </Button>
                </Modal.Footer>
            </StyledModalCreateFromCRM>
        </Modal >
    )
}

const StyledModalCreateFromCRM = styled.div`
    .close-button {
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        color: #666E9C;
        margin: 10px 0 0 20px;
    }

    .subtitle {
        color: #666E9C;
        margin: 10px 0 20px 5px;
        font-weight: 600;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        height: 50px;
    }

    .MuiFormControl-root {
        width: 100%;
    }

    .MuiInputLabel-formControl {
        top: 4px;
    }

    .update-link {
        cursor: pointer;
        color: blue;
    }
`;

export default ModalCreateFromCRM;
