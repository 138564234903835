import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

export const getNotes = ({ id = 0, per_page = '10', current_page = '1', sort = 'created_at', dir = "desc" }) => {
    return axios.get(`${BASE_URL}profile/${id}/notes?sortBy[0][name]=${sort}&sortBy[0][dir]=${dir}`, {
        params: {
            perPage: per_page || 10,
            page: current_page || 1
        }
    })
};

export const getNotesV2 = (profileId, params) => {
    return axios.get(`${BASE_URL}profile/${profileId}/notes`, { params })
};

export const getNote = (id, noteId) => {
    return axios.get(`${BASE_URL}profile/${id}/notes/${noteId}`)
};

export const createNote = (id, data) => {
    return axios.post(`${BASE_URL}profile/${id}/notes`, data)
}

export const updateNote = (id, data) => {
    return axios.put(`${BASE_URL}profile/${id}/notes/${data.id}`, data)
}

export const deleteNotes = (id, arr) => {
    return axios.delete(`${BASE_URL}profile/${id}/notes/mass-destroy`, { data: { "ids": [...arr] } })
};