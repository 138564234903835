import React from "react";

const header = {
  header: {
    self: {},
    items: [
      {
        title: "Home",
        root: true,
        aligment: "left",
        page: "dashboard/main",
        desktop: true,
        submenu: [
          {
            title: "Dashboard",
            root: true,
            aligment: "left",
            page: "dashboard/main",
            desktop: true,
          },
          {
            title: "Tasks",
            root: true,
            alignment: "left",
            page: "dashboard/tasks",
            desktop: true,
          },
          {
            title: "Calendar",
            root: true,
            alignment: "left",
            page: "dashboard/calendar",
            desktop: true,
          },
        ],
      },

      {
        title: "Organization",
        root: true,
        alignment: "left",
        page: "profile-list",
        desktop: true,
        submenu: [
          {
            title: "Profiles",
            root: true,
            aligment: "left",
            page: "profile-list",
            desktop: true,
          },
          {
            title: "Users",
            root: true,
            alignment: "left",
            page: "user-list",
            desktop: true,
          },
          {
            title: "Groups",
            root: true,
            alignment: "left",
            page: "group-list",
            desktop: true,
          },
        ],
      },
      {
        title: "Sales",
        root: true,
        alignment: "left",
        page: "sales",
        desktop: true,
        submenu: [
          // {
          //   title: "Performance",
          //   root: true,
          //   aligment: "left",
          //   page: "sales/performance",
          //   desktop: true,
          // },
          {
            title: "Forecasts",
            root: true,
            alignment: "left",
            page: "sales/forecasts",
            desktop: true,
          },
          {
            title: "Leads",
            root: true,
            alignment: "left",
            page: "sales/leads",
            desktop: true,
          },
          {
            title: "Opportunities",
            root: true,
            alignment: "left",
            page: "sales/opportunities",
            desktop: true,
          },
          {
            title: "Session",
            root: true,
            aligment: "left",
            page: "sales/session",
            desktop: true,
          },
        ],
      },
      {
        title: "Library",
        root: true,
        aligment: "left",
        page: "library/forms/list",
        desktop: true,
        submenu: [
          {
            title: "Programs",
            root: true,
            aligment: "left",
            page: "library/programs/list",
            desktop: true,
          },
          {
            title: "Workouts",
            root: true,
            aligment: "left",
            page: "library/workouts/list",
            desktop: true,
          },
          {
            title: "Exercises",
            root: true,
            aligment: "left",
            page: "library/exercises/list",
            desktop: true,
          },
          {
            title: "Forms",
            root: true,
            aligment: "left",
            page: "library/forms/list",
            desktop: true,
          },
        ],
      },
      {
        title: "Analytics",
        root: true,
        aligment: "left",
        page: "analytics",
        desktop: true,
      },
      {
        title: "Account Info",
        root: true,
        aligment: "left",
        page: "account/info",
        desktop: false,
      },
      {
        title: "Settings",
        root: true,
        aligment: "left",
        page: "account/setting",
        desktop: false,
        visible: "admin",
      },
      {
        title: "CRM",
        root: true,
        aligment: "left",
        page: "account/crm/connect",
        desktop: false,
        visible: "admin",
      },
      {
        title: "Billing",
        root: true,
        aligment: "left",
        page: "account/biling",
        desktop: false,
        visible: "admin",
      },
      {
        title: "Logout",
        root: true,
        aligment: "left",
        page: "logout",
        desktop: false,
      },
    ],
  },
};

const groups = {
  title: "Groups",
  root: true,
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <path
          d="M2.56066017,10.6819805 L4.68198052,8.56066017 C5.26776695,7.97487373 6.21751442,7.97487373 6.80330086,8.56066017 L8.9246212,10.6819805 C9.51040764,11.267767 9.51040764,12.2175144 8.9246212,12.8033009 L6.80330086,14.9246212 C6.21751442,15.5104076 5.26776695,15.5104076 4.68198052,14.9246212 L2.56066017,12.8033009 C1.97487373,12.2175144 1.97487373,11.267767 2.56066017,10.6819805 Z M14.5606602,10.6819805 L16.6819805,8.56066017 C17.267767,7.97487373 18.2175144,7.97487373 18.8033009,8.56066017 L20.9246212,10.6819805 C21.5104076,11.267767 21.5104076,12.2175144 20.9246212,12.8033009 L18.8033009,14.9246212 C18.2175144,15.5104076 17.267767,15.5104076 16.6819805,14.9246212 L14.5606602,12.8033009 C13.9748737,12.2175144 13.9748737,11.267767 14.5606602,10.6819805 Z"
          fill="#000000"
          opacity="0.3"
        />
        <path
          d="M8.56066017,16.6819805 L10.6819805,14.5606602 C11.267767,13.9748737 12.2175144,13.9748737 12.8033009,14.5606602 L14.9246212,16.6819805 C15.5104076,17.267767 15.5104076,18.2175144 14.9246212,18.8033009 L12.8033009,20.9246212 C12.2175144,21.5104076 11.267767,21.5104076 10.6819805,20.9246212 L8.56066017,18.8033009 C7.97487373,18.2175144 7.97487373,17.267767 8.56066017,16.6819805 Z M8.56066017,4.68198052 L10.6819805,2.56066017 C11.267767,1.97487373 12.2175144,1.97487373 12.8033009,2.56066017 L14.9246212,4.68198052 C15.5104076,5.26776695 15.5104076,6.21751442 14.9246212,6.80330086 L12.8033009,8.9246212 C12.2175144,9.51040764 11.267767,9.51040764 10.6819805,8.9246212 L8.56066017,6.80330086 C7.97487373,6.21751442 7.97487373,5.26776695 8.56066017,4.68198052 Z"
          fill="#000000"
        />
      </g>
    </svg>
  ),
  bullet: "dot",
  page: "group-list",
};

const users = {
  title: "Users",
  root: true,
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <path
          d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z"
          fill="#000000"
          fillRule="nonzero"
          opacity="0.3"
        />
        <path
          d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z"
          fill="#000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  ),
  bullet: "dot",
  page: "user-list",
};

const profiles = {
  title: "Profiles",
  root: true,
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <path
          d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
          fill="#000000"
          opacity="0.3"
        />
        <path
          d="M12,13 C10.8954305,13 10,12.1045695 10,11 C10,9.8954305 10.8954305,9 12,9 C13.1045695,9 14,9.8954305 14,11 C14,12.1045695 13.1045695,13 12,13 Z"
          fill="#000000"
          opacity="0.3"
        />
        <path
          d="M7.00036205,18.4995035 C7.21569918,15.5165724 9.36772908,14 11.9907452,14 C14.6506758,14 16.8360465,15.4332455 16.9988413,18.5 C17.0053266,18.6221713 16.9988413,19 16.5815,19 C14.5228466,19 11.463736,19 7.4041679,19 C7.26484009,19 6.98863236,18.6619875 7.00036205,18.4995035 Z"
          fill="#000000"
          opacity="0.3"
        />
      </g>
    </svg>
  ),
  bullet: "dot",
  page: "profile-list",
};
const sales_performance = {
  title: "Performance",
  root: true,
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill="#2B4166"
      />
      <path
        d="M9.01953 13.015C9.01953 14.6742 10.3664 16.0192 12.0279 16.0192C13.6893 16.0192 15.0362 14.6742 15.0362 13.015C15.0362 12.3839 14.6009 11.5085 14.0615 10.6659C13.2548 9.40588 12.8515 8.77588 12.0279 8.77588C11.2042 8.77588 10.8009 9.40588 9.99423 10.6659C9.45482 11.5085 9.01953 12.3839 9.01953 13.015Z"
        fill="#509AF7"
      />
      <path
        d="M4.42077 5.4763C4.74602 5.09877 5.09899 4.7458 5.47653 4.42057C5.49505 4.43584 5.51301 4.45214 5.53033 4.46946L7.03033 5.96946C7.32322 6.26235 7.32322 6.73722 7.03033 7.03012C6.73744 7.32301 6.26256 7.32301 5.96967 7.03012L4.46967 5.53012C4.45234 5.51279 4.43604 5.49483 4.42077 5.4763Z"
        fill="#509AF7"
      />
      <path
        d="M2.02769 12.7498C2.00934 12.5023 2 12.2522 2 12C2 11.7476 2.00935 11.4975 2.02772 11.2498H4C4.41421 11.2498 4.75 11.5856 4.75 11.9998C4.75 12.414 4.41421 12.7498 4 12.7498H2.02769Z"
        fill="#509AF7"
      />
      <path
        d="M5.47628 19.5792C5.09877 19.254 4.74581 18.901 4.42059 18.5235C4.43591 18.5049 4.45228 18.4869 4.46967 18.4695L5.96967 16.9695C6.26256 16.6766 6.73744 16.6766 7.03033 16.9695C7.32322 17.2623 7.32322 17.7372 7.03033 18.0301L5.53033 19.5301C5.51293 19.5475 5.49489 19.5639 5.47628 19.5792Z"
        fill="#509AF7"
      />
      <path
        d="M19.5794 18.5235C19.2542 18.901 18.9012 19.254 18.5237 19.5792C18.5051 19.5639 18.4871 19.5475 18.4697 19.5301L16.9697 18.0301C16.6768 17.7372 16.6768 17.2623 16.9697 16.9695C17.2626 16.6766 17.7374 16.6766 18.0303 16.9695L19.5303 18.4695C19.5477 18.4869 19.5641 18.5049 19.5794 18.5235Z"
        fill="#509AF7"
      />
      <path
        d="M21.9723 11.2498C21.9907 11.4975 22 11.7476 22 12C22 12.2522 21.9907 12.5023 21.9723 12.7498H19.9998C19.5856 12.7498 19.2498 12.414 19.2498 11.9998C19.2498 11.5856 19.5856 11.2498 19.9998 11.2498H21.9723Z"
        fill="#509AF7"
      />
      <path
        d="M18.5235 4.42057C18.901 4.7458 19.254 5.09877 19.5792 5.4763C19.564 5.49483 19.5477 5.51279 19.5303 5.53012L18.0303 7.03012C17.7374 7.32301 17.2626 7.32301 16.9697 7.03012C16.6768 6.73722 16.6768 6.26235 16.9697 5.96946L18.4697 4.46946C18.487 4.45214 18.5049 4.43584 18.5235 4.42057Z"
        fill="#509AF7"
      />
      <path
        d="M12.75 2.0277V4C12.75 4.41421 12.4142 4.75 12 4.75C11.5858 4.75 11.25 4.41421 11.25 4V2.0277C11.4976 2.00934 11.7477 2 12 2C12.2523 2 12.5024 2.00934 12.75 2.0277Z"
        fill="#509AF7"
      />
    </svg>
  ),
  bullet: "dot",
  page: "sales/performance",
};

const sales_forecasts = {
  title: "Forecasts",
  root: true,
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
        fill="#2B4166"
      />
      <path
        d="M14.5 10.75C14.0858 10.75 13.75 10.4142 13.75 10C13.75 9.58579 14.0858 9.25 14.5 9.25H17C17.4142 9.25 17.75 9.58579 17.75 10V12.5C17.75 12.9142 17.4142 13.25 17 13.25C16.5858 13.25 16.25 12.9142 16.25 12.5V11.8107L14.2374 13.8232C13.554 14.5066 12.446 14.5066 11.7626 13.8232L10.1768 12.2374C10.0791 12.1398 9.92085 12.1398 9.82322 12.2374L7.53033 14.5303C7.23744 14.8232 6.76256 14.8232 6.46967 14.5303C6.17678 14.2374 6.17678 13.7626 6.46967 13.4697L8.76256 11.1768C9.44598 10.4934 10.554 10.4934 11.2374 11.1768L12.8232 12.7626C12.9209 12.8602 13.0791 12.8602 13.1768 12.7626L15.1893 10.75H14.5Z"
        fill="#509AF7"
      />
    </svg>
  ),
  bullet: "dot",
  page: "sales/forecasts",
};
const sales_leads = {
  title: "Leads",
  root: true,
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill="#2B4166"
      />
      <path
        d="M8 16.3333V15.6667C8 14.1939 9.19391 13 10.6667 13H13.3333C14.8061 13 16 14.1939 16 15.6667V16.3333"
        stroke="#509AF7"
        stroke-width="1.4"
        stroke-linecap="round"
      />
      <path
        d="M12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9C14 10.1046 13.1046 11 12 11Z"
        stroke="#509AF7"
        stroke-width="1.4"
        stroke-linecap="round"
      />
    </svg>
  ),
  bullet: "dot",
  page: "sales/leads",
};

const sales_opportunities = {
  title: "Opportunities",
  root: true,
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill="#2B4166"
      />
      <path
        d="M12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V6.31673C9.61957 6.60867 8.25 7.83361 8.25 9.5C8.25 11.4172 10.0628 12.75 12 12.75C13.3765 12.75 14.25 13.6557 14.25 14.5C14.25 15.3443 13.3765 16.25 12 16.25C10.6235 16.25 9.75 15.3443 9.75 14.5C9.75 14.0858 9.41421 13.75 9 13.75C8.58579 13.75 8.25 14.0858 8.25 14.5C8.25 16.1664 9.61957 17.3913 11.25 17.6833V18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V17.6833C14.3804 17.3913 15.75 16.1664 15.75 14.5C15.75 12.5828 13.9372 11.25 12 11.25C10.6235 11.25 9.75 10.3443 9.75 9.5C9.75 8.65573 10.6235 7.75 12 7.75C13.3765 7.75 14.25 8.65573 14.25 9.5C14.25 9.91421 14.5858 10.25 15 10.25C15.4142 10.25 15.75 9.91421 15.75 9.5C15.75 7.83361 14.3804 6.60867 12.75 6.31673V6Z"
        fill="#509AF7"
      />
    </svg>
  ),
  bullet: "dot",
  page: "sales/opportunities",
};
const sales_sessions = {
  title: "Sessions",
  root: true,
  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        fill="#2B4166"
      />
      <path
        d="M16.0303 8.96967C16.3232 9.26256 16.3232 9.73744 16.0303 10.0303L11.0303 15.0303C10.7374 15.3232 10.2626 15.3232 9.96967 15.0303L7.96967 13.0303C7.67678 12.7374 7.67678 12.2626 7.96967 11.9697C8.26256 11.6768 8.73744 11.6768 9.03033 11.9697L10.5 13.4393L12.7348 11.2045L14.9697 8.96967C15.2626 8.67678 15.7374 8.67678 16.0303 8.96967Z"
        fill="#509AF7"
      />
    </svg>
  ),
  bullet: "dot",
  page: "sales/session",
};

const crm = {
  title: "CRM",
  root: true,
  icon: (
    <span className="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <rect
            fill="#000000"
            opacity="0.3"
            transform="translate(13.000000, 6.000000) rotate(-450.000000) translate(-13.000000, -6.000000) "
            x="12"
            y="8.8817842e-16"
            width="2"
            height="12"
            rx="1"
          />
          <path
            d="M9.79289322,3.79289322 C10.1834175,3.40236893 10.8165825,3.40236893 11.2071068,3.79289322 C11.5976311,4.18341751 11.5976311,4.81658249 11.2071068,5.20710678 L8.20710678,8.20710678 C7.81658249,8.59763107 7.18341751,8.59763107 6.79289322,8.20710678 L3.79289322,5.20710678 C3.40236893,4.81658249 3.40236893,4.18341751 3.79289322,3.79289322 C4.18341751,3.40236893 4.81658249,3.40236893 5.20710678,3.79289322 L7.5,6.08578644 L9.79289322,3.79289322 Z"
            fill="#000000"
            fillRule="nonzero"
            transform="translate(7.500000, 6.000000) rotate(-270.000000) translate(-7.500000, -6.000000) "
          />
          <rect
            fill="#000000"
            opacity="0.3"
            transform="translate(11.000000, 18.000000) scale(1, -1) rotate(90.000000) translate(-11.000000, -18.000000) "
            x="10"
            y="12"
            width="2"
            height="12"
            rx="1"
          />
          <path
            d="M18.7928932,15.7928932 C19.1834175,15.4023689 19.8165825,15.4023689 20.2071068,15.7928932 C20.5976311,16.1834175 20.5976311,16.8165825 20.2071068,17.2071068 L17.2071068,20.2071068 C16.8165825,20.5976311 16.1834175,20.5976311 15.7928932,20.2071068 L12.7928932,17.2071068 C12.4023689,16.8165825 12.4023689,16.1834175 12.7928932,15.7928932 C13.1834175,15.4023689 13.8165825,15.4023689 14.2071068,15.7928932 L16.5,18.0857864 L18.7928932,15.7928932 Z"
            fill="#000000"
            fillRule="nonzero"
            transform="translate(16.500000, 18.000000) scale(1, -1) rotate(270.000000) translate(-16.500000, -18.000000) "
          />
        </g>
      </svg>
    </span>
  ),
  bullet: "dot",
  submenu: [
    {
      title: "Connect",
      page: "account/crm/connect",
    },
    {
      title: "Group",
      page: "account/crm/group",
    },
    {
      title: "User",
      page: "account/crm/user",
    },
    {
      title: "Profile",
      page: "account/crm/profile",
    },
    {
      title: "Event",
      page: "account/crm/event",
    },
  ],
};

const forms = {
  title: "Forms",
  root: true,
  icon: (
    <span className="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
            fill="#000000"
            opacity="0.3"
          />
          <path
            d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
            fill="#000000"
          />
        </g>
      </svg>
    </span>
  ),
  bullet: "dot",
  page: "library/forms/list",
};

const programs = {
  title: "Programs",
  root: true,
  icon: (
    <span className="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M3.5,21 L20.5,21 C21.3284271,21 22,20.3284271 22,19.5 L22,8.5 C22,7.67157288 21.3284271,7 20.5,7 L10,7 L7.43933983,4.43933983 C7.15803526,4.15803526 6.77650439,4 6.37867966,4 L3.5,4 C2.67157288,4 2,4.67157288 2,5.5 L2,19.5 C2,20.3284271 2.67157288,21 3.5,21 Z"
            fill="#000000"
            opacity="0.3"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            transform="translate(8.984240, 14.127098) rotate(-45.000000) translate(-8.984240, -14.127098) "
            x="7.41281179"
            y="12.5556689"
            width="3.14285714"
            height="3.14285714"
            rx="0.75"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            transform="translate(15.269955, 14.127098) rotate(-45.000000) translate(-15.269955, -14.127098) "
            x="13.6985261"
            y="12.5556689"
            width="3.14285714"
            height="3.14285714"
            rx="0.75"
          />
          <rect
            fill="#000000"
            transform="translate(12.127098, 17.269955) rotate(-45.000000) translate(-12.127098, -17.269955) "
            x="10.5556689"
            y="15.6985261"
            width="3.14285714"
            height="3.14285714"
            rx="0.75"
          />
          <rect
            fill="#000000"
            transform="translate(12.127098, 10.984240) rotate(-45.000000) translate(-12.127098, -10.984240) "
            x="10.5556689"
            y="9.41281179"
            width="3.14285714"
            height="3.14285714"
            rx="0.75"
          />
        </g>
      </svg>
    </span>
  ),
  bullet: "dot",
  page: "library/programs/list",
};

const workouts = {
  title: "Workouts",
  root: true,
  icon: (
    <span className="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
            fill="#000000"
            fillRule="nonzero"
            opacity="0.3"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            transform="translate(8.984240, 12.127098) rotate(-45.000000) translate(-8.984240, -12.127098) "
            x="7.41281179"
            y="10.5556689"
            width="3.14285714"
            height="3.14285714"
            rx="0.75"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            transform="translate(15.269955, 12.127098) rotate(-45.000000) translate(-15.269955, -12.127098) "
            x="13.6985261"
            y="10.5556689"
            width="3.14285714"
            height="3.14285714"
            rx="0.75"
          />
          <rect
            fill="#000000"
            transform="translate(12.127098, 15.269955) rotate(-45.000000) translate(-12.127098, -15.269955) "
            x="10.5556689"
            y="13.6985261"
            width="3.14285714"
            height="3.14285714"
            rx="0.75"
          />
          <rect
            fill="#000000"
            transform="translate(12.127098, 8.984240) rotate(-45.000000) translate(-12.127098, -8.984240) "
            x="10.5556689"
            y="7.41281179"
            width="3.14285714"
            height="3.14285714"
            rx="0.75"
          />
        </g>
      </svg>
    </span>
  ),
  bullet: "dot",
  page: "library/workouts/list",
};

const exercises = {
  title: "Exercises",
  root: true,
  icon: (
    <span className="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M11.6734943,8.3307728 L14.9993074,6.09979492 L14.1213255,5.22181303 C13.7308012,4.83128874 13.7308012,4.19812376 14.1213255,3.80759947 L15.535539,2.39338591 C15.9260633,2.00286161 16.5592283,2.00286161 16.9497526,2.39338591 L22.6066068,8.05024016 C22.9971311,8.44076445 22.9971311,9.07392943 22.6066068,9.46445372 L21.1923933,10.8786673 C20.801869,11.2691916 20.168704,11.2691916 19.7781797,10.8786673 L18.9002333,10.0007208 L16.6692373,13.3265608 C16.9264145,14.2523264 16.9984943,15.2320236 16.8664372,16.2092466 L16.4344698,19.4058049 C16.360509,19.9531149 15.8568695,20.3368403 15.3095595,20.2628795 C15.0925691,20.2335564 14.8912006,20.1338238 14.7363706,19.9789938 L5.02099894,10.2636221 C4.63047465,9.87309784 4.63047465,9.23993286 5.02099894,8.84940857 C5.17582897,8.69457854 5.37719743,8.59484594 5.59418783,8.56552292 L8.79074617,8.13355557 C9.76799113,8.00149544 10.7477104,8.0735815 11.6734943,8.3307728 Z"
            fill="#000000"
          />
          <polygon
            fill="#000000"
            opacity="0.3"
            transform="translate(7.050253, 17.949747) rotate(-315.000000) translate(-7.050253, -17.949747) "
            points="5.55025253 13.9497475 5.55025253 19.6640332 7.05025253 21.9497475 8.55025253 19.6640332 8.55025253 13.9497475"
          />
        </g>
      </svg>
    </span>
  ),
  bullet: "dot",
  page: "library/exercises/list",
};

const dashboard = {
  title: "Dashboard",
  root: true,
  icon: (
    <span class="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M12.9336061,16.072447 L19.36,10.9564761 L19.5181585,10.8312381 C20.1676248,10.3169571 20.2772143,9.3735535 19.7629333,8.72408713 C19.6917232,8.63415859 19.6104327,8.55269514 19.5206557,8.48129411 L12.9336854,3.24257445 C12.3871201,2.80788259 11.6128799,2.80788259 11.0663146,3.24257445 L4.47482784,8.48488609 C3.82645598,9.00054628 3.71887192,9.94418071 4.23453211,10.5925526 C4.30500305,10.6811601 4.38527899,10.7615046 4.47382636,10.8320511 L4.63,10.9564761 L11.0659024,16.0730648 C11.6126744,16.5077525 12.3871218,16.5074963 12.9336061,16.072447 Z"
            fill="#000000"
            fill-rule="nonzero"
          />
          <path
            d="M11.0563554,18.6706981 L5.33593024,14.122919 C4.94553994,13.8125559 4.37746707,13.8774308 4.06710397,14.2678211 C4.06471678,14.2708238 4.06234874,14.2738418 4.06,14.2768747 L4.06,14.2768747 C3.75257288,14.6738539 3.82516916,15.244888 4.22214834,15.5523151 C4.22358765,15.5534297 4.2250303,15.55454 4.22647627,15.555646 L11.0872776,20.8031356 C11.6250734,21.2144692 12.371757,21.2145375 12.909628,20.8033023 L19.7677785,15.559828 C20.1693192,15.2528257 20.2459576,14.6784381 19.9389553,14.2768974 C19.9376429,14.2751809 19.9363245,14.2734691 19.935,14.2717619 L19.935,14.2717619 C19.6266937,13.8743807 19.0546209,13.8021712 18.6572397,14.1104775 C18.654352,14.112718 18.6514778,14.1149757 18.6486172,14.1172508 L12.9235044,18.6705218 C12.377022,19.1051477 11.6029199,19.1052208 11.0563554,18.6706981 Z"
            fill="#000000"
            opacity="0.3"
          />
        </g>
      </svg>
    </span>
  ),
  bullet: "dot",
  page: "dashboard/main",
};

const tasks = {
  title: "Tasks",
  root: true,
  icon: (
    <span class="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M11.6734943,8.3307728 L14.9993074,6.09979492 L14.1213255,5.22181303 C13.7308012,4.83128874 13.7308012,4.19812376 14.1213255,3.80759947 L15.535539,2.39338591 C15.9260633,2.00286161 16.5592283,2.00286161 16.9497526,2.39338591 L22.6066068,8.05024016 C22.9971311,8.44076445 22.9971311,9.07392943 22.6066068,9.46445372 L21.1923933,10.8786673 C20.801869,11.2691916 20.168704,11.2691916 19.7781797,10.8786673 L18.9002333,10.0007208 L16.6692373,13.3265608 C16.9264145,14.2523264 16.9984943,15.2320236 16.8664372,16.2092466 L16.4344698,19.4058049 C16.360509,19.9531149 15.8568695,20.3368403 15.3095595,20.2628795 C15.0925691,20.2335564 14.8912006,20.1338238 14.7363706,19.9789938 L5.02099894,10.2636221 C4.63047465,9.87309784 4.63047465,9.23993286 5.02099894,8.84940857 C5.17582897,8.69457854 5.37719743,8.59484594 5.59418783,8.56552292 L8.79074617,8.13355557 C9.76799113,8.00149544 10.7477104,8.0735815 11.6734943,8.3307728 Z"
            fill="#000000"
          />
          <polygon
            fill="#000000"
            opacity="0.3"
            transform="translate(7.050253, 17.949747) rotate(-315.000000) translate(-7.050253, -17.949747) "
            points="5.55025253 13.9497475 5.55025253 19.6640332 7.05025253 21.9497475 8.55025253 19.6640332 8.55025253 13.9497475"
          />
        </g>
      </svg>
    </span>
  ),
  bullet: "dot",
  page: "dashboard/tasks",
};

const calendar = {
  title: "Calendar",
  root: true,
  icon: (
    <span class="svg-icon svg-icon-primary svg-icon-2x">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z"
            fill="#000000"
            opacity="0.3"
          />
          <path
            d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z"
            fill="#000000"
          />
        </g>
      </svg>
    </span>
  ),
  bullet: "dot",
  page: "dashboard/calendar",
};

const profile = [
  {
    title: "Overview",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M5.5,4 L9.5,4 C10.3284271,4 11,4.67157288 11,5.5 L11,6.5 C11,7.32842712 10.3284271,8 9.5,8 L5.5,8 C4.67157288,8 4,7.32842712 4,6.5 L4,5.5 C4,4.67157288 4.67157288,4 5.5,4 Z M14.5,16 L18.5,16 C19.3284271,16 20,16.6715729 20,17.5 L20,18.5 C20,19.3284271 19.3284271,20 18.5,20 L14.5,20 C13.6715729,20 13,19.3284271 13,18.5 L13,17.5 C13,16.6715729 13.6715729,16 14.5,16 Z"
            fill="#000000"
          />
          <path
            d="M5.5,10 L9.5,10 C10.3284271,10 11,10.6715729 11,11.5 L11,18.5 C11,19.3284271 10.3284271,20 9.5,20 L5.5,20 C4.67157288,20 4,19.3284271 4,18.5 L4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z M14.5,4 L18.5,4 C19.3284271,4 20,4.67157288 20,5.5 L20,12.5 C20,13.3284271 19.3284271,14 18.5,14 L14.5,14 C13.6715729,14 13,13.3284271 13,12.5 L13,5.5 C13,4.67157288 13.6715729,4 14.5,4 Z"
            fill="#000000"
            opacity="0.3"
          />
        </g>
      </svg>
    ),
    bullet: "dot",
    page: "profile/overview",
    id: true,
  },
  {
    title: "Testing",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M8,3 L8,3.5 C8,4.32842712 8.67157288,5 9.5,5 L14.5,5 C15.3284271,5 16,4.32842712 16,3.5 L16,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,21 C20,22.1045695 19.1045695,23 18,23 L6,23 C4.8954305,23 4,22.1045695 4,21 L4,5 C4,3.8954305 4.8954305,3 6,3 L8,3 Z"
            fill="#000000"
            opacity="0.3"
          />
          <path
            d="M11,2 C11,1.44771525 11.4477153,1 12,1 C12.5522847,1 13,1.44771525 13,2 L14.5,2 C14.7761424,2 15,2.22385763 15,2.5 L15,3.5 C15,3.77614237 14.7761424,4 14.5,4 L9.5,4 C9.22385763,4 9,3.77614237 9,3.5 L9,2.5 C9,2.22385763 9.22385763,2 9.5,2 L11,2 Z"
            fill="#000000"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            x="7"
            y="10"
            width="5"
            height="2"
            rx="1"
          />
          <rect
            fill="#000000"
            opacity="0.3"
            x="7"
            y="14"
            width="9"
            height="2"
            rx="1"
          />
        </g>
      </svg>
    ),
    bullet: "dot",
    page: "profile/testing",
    id: true,
  },
  {
    title: "Goals",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M19,11 L21,11 C21.5522847,11 22,11.4477153 22,12 C22,12.5522847 21.5522847,13 21,13 L19,13 C18.4477153,13 18,12.5522847 18,12 C18,11.4477153 18.4477153,11 19,11 Z M3,11 L5,11 C5.55228475,11 6,11.4477153 6,12 C6,12.5522847 5.55228475,13 5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 Z M12,2 C12.5522847,2 13,2.44771525 13,3 L13,5 C13,5.55228475 12.5522847,6 12,6 C11.4477153,6 11,5.55228475 11,5 L11,3 C11,2.44771525 11.4477153,2 12,2 Z M12,18 C12.5522847,18 13,18.4477153 13,19 L13,21 C13,21.5522847 12.5522847,22 12,22 C11.4477153,22 11,21.5522847 11,21 L11,19 C11,18.4477153 11.4477153,18 12,18 Z"
            fill="#000000"
            fillRule="nonzero"
            opacity="0.3"
          />
          <circle fill="#000000" cx="12" cy="12" r="3" />
        </g>
      </svg>
    ),
    bullet: "dot",
    page: "profile/goals",
    id: true,
  },
  {
    title: "Metrics",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
            fill="#000000"
            fillRule="nonzero"
          />
          <path
            d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
            fill="#000000"
            fillRule="nonzero"
            opacity="0.3"
          />
        </g>
      </svg>
    ),
    bullet: "dot",
    page: "profile/metrics",
    id: true,
  },
  {
    title: "Program",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <circle fill="#000000" opacity="0.3" cx="12" cy="9" r="8" />
          <path
            d="M14.5297296,11 L9.46184488,11 L11.9758349,17.4645458 L14.5297296,11 Z M10.5679953,19.3624463 L6.53815512,9 L17.4702704,9 L13.3744964,19.3674279 L11.9759405,18.814912 L10.5679953,19.3624463 Z"
            fill="#000000"
            fillRule="nonzero"
            opacity="0.3"
          />
          <path
            d="M10,22 L14,22 L14,22 C14,23.1045695 13.1045695,24 12,24 L12,24 C10.8954305,24 10,23.1045695 10,22 Z"
            fill="#000000"
            opacity="0.3"
          />
          <path
            d="M9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 L15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 Z"
            fill="#000000"
          />
        </g>
      </svg>
    ),
    bullet: "dot",
    page: "profile/program",
    id: true,
  },
  {
    title: "Reports",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M5.85714286,2 L13.7364114,2 C14.0910962,2 14.4343066,2.12568431 14.7051108,2.35473959 L19.4686994,6.3839416 C19.8056532,6.66894833 20,7.08787823 20,7.52920201 L20,20.0833333 C20,21.8738751 19.9795521,22 18.1428571,22 L5.85714286,22 C4.02044787,22 4,21.8738751 4,20.0833333 L4,3.91666667 C4,2.12612489 4.02044787,2 5.85714286,2 Z"
            fill="#000000"
            fillRule="nonzero"
            opacity="0.3"
          />
          <rect fill="#000000" x="6" y="11" width="9" height="2" rx="1" />
          <rect fill="#000000" x="6" y="15" width="5" height="2" rx="1" />
        </g>
      </svg>
    ),
    bullet: "dot",
    page: "profile/report",
    id: true,
  },
  {
    title: "Sales",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
          fill="#2B4166"
        />
        <path
          d="M14.5 10.75C14.0858 10.75 13.75 10.4142 13.75 10C13.75 9.58579 14.0858 9.25 14.5 9.25H17C17.4142 9.25 17.75 9.58579 17.75 10V12.5C17.75 12.9142 17.4142 13.25 17 13.25C16.5858 13.25 16.25 12.9142 16.25 12.5V11.8107L14.2374 13.8232C13.554 14.5066 12.446 14.5066 11.7626 13.8232L10.1768 12.2374C10.0791 12.1398 9.92085 12.1398 9.82322 12.2374L7.53033 14.5303C7.23744 14.8232 6.76256 14.8232 6.46967 14.5303C6.17678 14.2374 6.17678 13.7626 6.46967 13.4697L8.76256 11.1768C9.44598 10.4934 10.554 10.4934 11.2374 11.1768L12.8232 12.7626C12.9209 12.8602 13.0791 12.8602 13.1768 12.7626L15.1893 10.75H14.5Z"
          fill="#509AF7"
        />
      </svg>
    ),
    bullet: "dot",
    page: "profile/sales",
    id: true,
  },
  {
    title: "Calendar",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <polygon points="0 0 24 0 24 24 0 24" />
          <path
            d="M18.5,8 C17.1192881,8 16,6.88071187 16,5.5 C16,4.11928813 17.1192881,3 18.5,3 C19.8807119,3 21,4.11928813 21,5.5 C21,6.88071187 19.8807119,8 18.5,8 Z M18.5,21 C17.1192881,21 16,19.8807119 16,18.5 C16,17.1192881 17.1192881,16 18.5,16 C19.8807119,16 21,17.1192881 21,18.5 C21,19.8807119 19.8807119,21 18.5,21 Z M5.5,21 C4.11928813,21 3,19.8807119 3,18.5 C3,17.1192881 4.11928813,16 5.5,16 C6.88071187,16 8,17.1192881 8,18.5 C8,19.8807119 6.88071187,21 5.5,21 Z"
            fill="#000000"
            opacity="0.3"
          />
          <path
            d="M5.5,8 C4.11928813,8 3,6.88071187 3,5.5 C3,4.11928813 4.11928813,3 5.5,3 C6.88071187,3 8,4.11928813 8,5.5 C8,6.88071187 6.88071187,8 5.5,8 Z M11,4 L13,4 C13.5522847,4 14,4.44771525 14,5 C14,5.55228475 13.5522847,6 13,6 L11,6 C10.4477153,6 10,5.55228475 10,5 C10,4.44771525 10.4477153,4 11,4 Z M11,18 L13,18 C13.5522847,18 14,18.4477153 14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 C10,18.4477153 10.4477153,18 11,18 Z M5,10 C5.55228475,10 6,10.4477153 6,11 L6,13 C6,13.5522847 5.55228475,14 5,14 C4.44771525,14 4,13.5522847 4,13 L4,11 C4,10.4477153 4.44771525,10 5,10 Z M19,10 C19.5522847,10 20,10.4477153 20,11 L20,13 C20,13.5522847 19.5522847,14 19,14 C18.4477153,14 18,13.5522847 18,13 L18,11 C18,10.4477153 18.4477153,10 19,10 Z"
            fill="#000000"
          />
        </g>
      </svg>
    ),
    bullet: "dot",
    page: "profile/calendar",
    id: true,
  },
  {
    title: "Tasks",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M11.6734943,8.3307728 L14.9993074,6.09979492 L14.1213255,5.22181303 C13.7308012,4.83128874 13.7308012,4.19812376 14.1213255,3.80759947 L15.535539,2.39338591 C15.9260633,2.00286161 16.5592283,2.00286161 16.9497526,2.39338591 L22.6066068,8.05024016 C22.9971311,8.44076445 22.9971311,9.07392943 22.6066068,9.46445372 L21.1923933,10.8786673 C20.801869,11.2691916 20.168704,11.2691916 19.7781797,10.8786673 L18.9002333,10.0007208 L16.6692373,13.3265608 C16.9264145,14.2523264 16.9984943,15.2320236 16.8664372,16.2092466 L16.4344698,19.4058049 C16.360509,19.9531149 15.8568695,20.3368403 15.3095595,20.2628795 C15.0925691,20.2335564 14.8912006,20.1338238 14.7363706,19.9789938 L5.02099894,10.2636221 C4.63047465,9.87309784 4.63047465,9.23993286 5.02099894,8.84940857 C5.17582897,8.69457854 5.37719743,8.59484594 5.59418783,8.56552292 L8.79074617,8.13355557 C9.76799113,8.00149544 10.7477104,8.0735815 11.6734943,8.3307728 Z"
            fill="#000000"
          />
          <polygon
            fill="#000000"
            opacity="0.3"
            transform="translate(7.050253, 17.949747) rotate(-315.000000) translate(-7.050253, -17.949747) "
            points="5.55025253 13.9497475 5.55025253 19.6640332 7.05025253 21.9497475 8.55025253 19.6640332 8.55025253 13.9497475"
          />
        </g>
      </svg>
    ),
    bullet: "dot",
    page: "profile/tasks",
    id: true,
  },
  {
    title: "Notes",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 448 512"
      >
        <path
          fill="#25436b"
          d="M312 320h136V56c0-13.3-10.7-24-24-24H24C10.7 32 0 42.7 0 56v400c0 13.3 10.7 24 24 24h264V344c0-13.2 10.8-24 24-24zm129 55l-98 98c-4.5 4.5-10.6 7-17 7h-6V352h128v6.1c0 6.3-2.5 12.4-7 16.9z"
        ></path>
      </svg>
    ),
    bullet: "dot",
    page: "profile/notes",
    id: true,
  },
  {
    title: "Files",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 640 512"
      >
        <path
          fill="#25436b"
          d="M425.7 256c-16.9 0-32.8-9-41.4-23.4L320 126l-64.2 106.6c-8.7 14.5-24.6 23.5-41.5 23.5-4.5 0-9-.6-13.3-1.9L64 215v178c0 14.7 10 27.5 24.2 31l216.2 54.1c10.2 2.5 20.9 2.5 31 0L551.8 424c14.2-3.6 24.2-16.4 24.2-31V215l-137 39.1c-4.3 1.3-8.8 1.9-13.3 1.9zm212.6-112.2L586.8 41c-3.1-6.2-9.8-9.8-16.7-8.9L320 64l91.7 152.1c3.8 6.3 11.4 9.3 18.5 7.3l197.9-56.5c9.9-2.9 14.7-13.9 10.2-23.1zM53.2 41L1.7 143.8c-4.6 9.2.3 20.2 10.1 23l197.9 56.5c7.1 2 14.7-1 18.5-7.3L320 64 69.8 32.1c-6.9-.8-13.5 2.7-16.6 8.9z"
        ></path>
      </svg>
    ),
    bullet: "dot",
    page: "profile/files",
    id: true,
  },
  {
    title: "Profile Info",
    root: true,
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        version="1.1"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <rect x="0" y="0" width="24" height="24" />
          <path
            d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
            fill="#000000"
            opacity="0.3"
          />
          <path
            d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
            fill="#000000"
            opacity="0.3"
          />
          <path
            d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
            fill="#000000"
            opacity="0.3"
          />
        </g>
      </svg>
    ),
    bullet: "dot",
    page: "profile/info",
    id: true,
  },
];

const getPathType = (url) => {
  if (url.indexOf("/group-") !== -1) {
    return "group";
  } else if (url.indexOf("/user-") !== -1) {
    return "user";
  } else if (url.indexOf("/profiles-") !== -1) {
    return "profiles";
  } else if (url.indexOf("/profile/") !== -1) {
    return "profile";
  } else if (url.indexOf("/account/") !== -1) {
    return "account";
  } else if (url.indexOf("/analytics") !== -1) {
    return "analytics";
  } else if (url.indexOf("/library") !== -1) {
    return "library";
  } else if (url.indexOf("/dashboard") !== -1) {
    return "dashboard";
  } else if (url.indexOf("/sales") !== -1) {
    return "sales";
  } else {
    return "profiles";
  }
};

const getRoleType = (role) => {
  if (role.toLowerCase() === "super-admin") {
    return "superadmin";
  } else if (role.toLowerCase() === "admin") {
    return "admin";
  } else if (role.toLowerCase() === "supervisor") {
    return "supervisor";
  } else if (role.toLowerCase() === "staff") {
    return "staff";
  } else if (role.toLowerCase() === "client") {
    return "client";
  } else {
    return "client";
  }
};

export const getName = (url, role) => {
  return `${getRoleType(role)}_${getPathType(url)}`;
};

export const superadmin_group = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const superadmin_user = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const superadmin_profiles = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};
export const superadmin_sales = {
  ...header,
  aside: {
    self: {},
    items: [
      // sales_performance,
      sales_forecasts,
      sales_leads,
      sales_opportunities,
      sales_sessions,
    ],
  },
};

export const superadmin_profile = {
  ...header,
  aside: {
    self: {},
    items: [...profile],
  },
};

export const superadmin_account = {
  ...header,
  aside: {
    self: {},
    items: [
      {
        title: "Account Info",
        root: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                fill="#000000"
                fillRule="nonzero"
              />
            </g>
          </svg>
        ),
        bullet: "dot",
        page: "account/info",
      },
      crm,
      {
        title: "Settings",
        root: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
                fill="#000000"
              />
            </g>
          </svg>
        ),
        bullet: "dot",
        page: "account/setting",
      },
      {
        title: "Billing",
        root: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <rect
                fill="#000000"
                opacity="0.3"
                x="2"
                y="5"
                width="20"
                height="14"
                rx="2"
              />
              <rect fill="#000000" x="2" y="8" width="20" height="3" />
              <rect
                fill="#000000"
                opacity="0.3"
                x="16"
                y="14"
                width="4"
                height="2"
                rx="1"
              />
            </g>
          </svg>
        ),
        bullet: "dot",
        page: "account/biling",
      },
    ],
  },
};

export const admin_group = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const admin_user = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const admin_profiles = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const admin_sales = {
  ...header,
  aside: {
    self: {},
    items: [
      // sales_performance,
      sales_forecasts,
      sales_leads,
      sales_opportunities,
      sales_sessions,
    ],
  },
};

export const admin_profile = {
  ...header,
  aside: {
    self: {},
    items: [...profile],
  },
};

export const admin_account = {
  ...header,
  aside: {
    self: {},
    items: [
      {
        title: "Account Info",
        root: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                fill="#000000"
                fillRule="nonzero"
              />
            </g>
          </svg>
        ),
        bullet: "dot",
        page: "account/info",
        visible: "/account/",
      },
      crm,
      {
        title: "Settings",
        root: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M5,8.6862915 L5,5 L8.6862915,5 L11.5857864,2.10050506 L14.4852814,5 L19,5 L19,9.51471863 L21.4852814,12 L19,14.4852814 L19,19 L14.4852814,19 L11.5857864,21.8994949 L8.6862915,19 L5,19 L5,15.3137085 L1.6862915,12 L5,8.6862915 Z M12,15 C13.6568542,15 15,13.6568542 15,12 C15,10.3431458 13.6568542,9 12,9 C10.3431458,9 9,10.3431458 9,12 C9,13.6568542 10.3431458,15 12,15 Z"
                fill="#000000"
              />
            </g>
          </svg>
        ),
        bullet: "dot",
        page: "account/setting",
      },
      {
        title: "Billing",
        root: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <rect
                fill="#000000"
                opacity="0.3"
                x="2"
                y="5"
                width="20"
                height="14"
                rx="2"
              />
              <rect fill="#000000" x="2" y="8" width="20" height="3" />
              <rect
                fill="#000000"
                opacity="0.3"
                x="16"
                y="14"
                width="4"
                height="2"
                rx="1"
              />
            </g>
          </svg>
        ),
        bullet: "dot",
        page: "account/biling",
      },
    ],
  },
};

export const supervisor_group = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const supervisor_user = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const supervisor_profiles = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const supervisor_profile = {
  ...header,
  aside: {
    self: {},
    items: [...profile],
  },
};

export const supervisor_account = {
  ...header,
  aside: {
    self: {},
    items: [
      {
        title: "Account Info",
        root: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                fill="#000000"
                fillRule="nonzero"
              />
            </g>
          </svg>
        ),
        bullet: "dot",
        page: "account/info",
        visible: "/account/",
      },
    ],
  },
};
export const supervisor_sales = {
  ...header,
  aside: {
    self: {},
    items: [
      // sales_performance,
      sales_forecasts,
      sales_leads,
      sales_opportunities,
      sales_sessions,
    ],
  },
};

export const staff_group = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const staff_user = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const staff_profiles = {
  ...header,
  aside: {
    self: {},
    items: [profiles, users, groups],
  },
};

export const staff_profile = {
  ...header,
  aside: {
    self: {},
    items: [...profile],
  },
};

export const staff_account = {
  ...header,
  aside: {
    self: {},
    items: [
      {
        title: "Account Info",
        root: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                fill="#000000"
                fillRule="nonzero"
                opacity="0.3"
              />
              <path
                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                fill="#000000"
                fillRule="nonzero"
              />
            </g>
          </svg>
        ),
        bullet: "dot",
        page: "account/info",
        visible: "/account/",
      },
    ],
  },
};
export const staff_sales = {
  ...header,
  aside: {
    self: {},
    items: [
      // sales_performance,
      sales_forecasts,
      sales_leads,
      sales_opportunities,
      sales_sessions,
    ],
  },
};

export const client_group = {
  ...header,
  aside: {
    self: {},
    items: [],
  },
};

export const client_user = {
  ...header,
  aside: {
    self: {},
    items: [],
  },
};

export const client_profiles = {
  ...header,
  aside: {
    self: {},
    items: [],
  },
};

export const client_profile = {
  ...header,
  aside: {
    self: {},
    items: [...profile],
  },
};

export const client_account = {
  ...header,
  aside: {
    self: {},
    items: [
      {
        title: "My Profile",
        root: true,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            version="1.1"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <rect x="0" y="0" width="24" height="24" />
              <path
                d="M4,4 L11.6314229,2.5691082 C11.8750185,2.52343403 12.1249815,2.52343403 12.3685771,2.5691082 L20,4 L20,13.2830094 C20,16.2173861 18.4883464,18.9447835 16,20.5 L12.5299989,22.6687507 C12.2057287,22.8714196 11.7942713,22.8714196 11.4700011,22.6687507 L8,20.5 C5.51165358,18.9447835 4,16.2173861 4,13.2830094 L4,4 Z"
                fill="#000000"
                opacity="0.3"
              />
              <path
                d="M12,11 C10.8954305,11 10,10.1045695 10,9 C10,7.8954305 10.8954305,7 12,7 C13.1045695,7 14,7.8954305 14,9 C14,10.1045695 13.1045695,11 12,11 Z"
                fill="#000000"
                opacity="0.3"
              />
              <path
                d="M7.00036205,16.4995035 C7.21569918,13.5165724 9.36772908,12 11.9907452,12 C14.6506758,12 16.8360465,13.4332455 16.9988413,16.5 C17.0053266,16.6221713 16.9988413,17 16.5815,17 C14.5228466,17 11.463736,17 7.4041679,17 C7.26484009,17 6.98863236,16.6619875 7.00036205,16.4995035 Z"
                fill="#000000"
                opacity="0.3"
              />
            </g>
          </svg>
        ),
        bullet: "dot",
        page: "profile-list",
      },
    ],
  },
};

const analytics = {
  title: "Analytics",
  root: true,

  icon: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24" />
        <path
          d="M5,19 L20,19 C20.5522847,19 21,19.4477153 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,4 C3,3.44771525 3.44771525,3 4,3 C4.55228475,3 5,3.44771525 5,4 L5,19 Z"
          fill="#000000"
          fillRule="nonzero"
        />
        <path
          d="M8.7295372,14.6839411 C8.35180695,15.0868534 7.71897114,15.1072675 7.31605887,14.7295372 C6.9131466,14.3518069 6.89273254,13.7189711 7.2704628,13.3160589 L11.0204628,9.31605887 C11.3857725,8.92639521 11.9928179,8.89260288 12.3991193,9.23931335 L15.358855,11.7649545 L19.2151172,6.88035571 C19.5573373,6.44687693 20.1861655,6.37289714 20.6196443,6.71511723 C21.0531231,7.05733733 21.1271029,7.68616551 20.7848828,8.11964429 L16.2848828,13.8196443 C15.9333973,14.2648593 15.2823707,14.3288915 14.8508807,13.9606866 L11.8268294,11.3801628 L8.7295372,14.6839411 Z"
          fill="#000000"
          fillRule="nonzero"
          opacity="0.3"
        />
      </g>
    </svg>
  ),
  page: "analytics/reports-list",
  bullet: "dot",
  // submenu: [
  //   {
  //     title: 'Reports List',
  //     page: 'analytics/reports-list',
  //   },
  //   {
  //     title: 'Create Report',
  //     page: 'analytics/create-report',
  //   }
  // ]
};

export const superadmin_analytics = {
  ...header,
  aside: {
    self: {},
    items: [analytics],
  },
};

export const admin_analytics = {
  ...header,
  aside: {
    self: {},
    items: [analytics],
  },
};

export const supervisor_analytics = {
  ...header,
  aside: {
    self: {},
    items: [analytics],
  },
};

export const staff_analytics = {
  ...header,
  aside: {
    self: {},
    items: [analytics],
  },
};

export const client_analytics = {
  ...header,
  aside: {
    self: {},
    items: [analytics],
  },
};

export const superadmin_dashboard = {
  ...header,
  aside: {
    self: {},
    items: [dashboard, tasks, calendar],
  },
};

export const admin_dashboard = {
  ...header,
  aside: {
    self: {},
    items: [dashboard, tasks, calendar],
  },
};

export const supervisor_dashboard = {
  ...header,
  aside: {
    self: {},
    items: [dashboard, tasks, calendar],
  },
};

export const staff_dashboard = {
  ...header,
  aside: {
    self: {},
    items: [dashboard, tasks, calendar],
  },
};

export const superadmin_library = {
  ...header,
  aside: {
    self: {},
    items: [programs, workouts, exercises, forms],
  },
};

export const admin_library = {
  ...header,
  aside: {
    self: {},
    items: [programs, workouts, exercises, forms],
  },
};

export const supervisor_library = {
  ...header,
  aside: {
    self: {},
    items: [programs, workouts, exercises, forms],
  },
};

export const staff_library = {
  ...header,
  aside: {
    self: {},
    items: [programs, workouts, exercises, forms],
  },
};
