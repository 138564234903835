import React, { useState } from 'react';
import { TableRow } from './TableRow';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import ConfirmModal from '../../../../../../components/ConfirmationModal';
import * as AlertState from '../../../../../../../app/store/ducks/auth.duck';
import { useDispatch } from 'react-redux';
import { deleteMapping } from '../../../../../../crud/crm.crud';
import styled from 'styled-components';

const defRow = [
    {
        label: 'TrainerMetrics Group',
        value: 'internal_text',
        left: true,
        sort: true
    },
    {
        label: 'CRM Group',
        value: 'external_text',
        left: true,
        sort: true
    },
    {
        label: 'Last Sync Date',
        value: 'sync_at',
        left: true,
        sort: true
    },
    {
        label: 'Remove',
        value: 'remove',
        left: false,
        sort: false
    },
];

export const Table = ({
    data,
    setLoader,
    sort,
    setSort,
    setPagination,
    pagination,
    getMappings,
    accountId,
    crm,
}) => {
    const [modal, setModal] = useState(false);
    const [currentItem, setItem] = useState(null);
    const dispatch = useDispatch();

    const sortEdit = (item) => {
        item.sort && setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
    };

    const handleDelete = () => {
        setLoader(true);
        deleteMapping(accountId, crm.id, 'groups', currentItem.id)
            .then(() => {
                setModal(false);
                if (+pagination === 1) {
                    getMappings();
                } else {
                    setPagination(1);
                }
                dispatch(AlertState.actions.alert({
                    text: 'Mapping was removed',
                    variant: true,
                }))
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => setLoader(false))
    };

    return (
        <>
            <ConfirmModal
                modal={modal}
                setModal={setModal}
                handleConfirm={handleDelete}
                title="Are you sure you want to remove this mapping?"
                submitText="Remove"
            />

            <StyledTable className="settingtable nowrap">
                <table>
                    <thead>
                        <tr>
                            {defRow.map((item, index) => {
                                return (
                                    <td
                                        key={index}
                                        onClick={() => sortEdit(item)}
                                        className={
                                            `${item.left ? 'left-content' : ''} cell-25`
                                        }
                                        style={{ cursor: !item.sort && 'default' }}
                                    >
                                        {item.label}
                                        {item.sort &&
                                            <ArrowDown
                                                className={`sort-arrow
                                                ${sort[0] === item.value
                                                        ? ' active'
                                                        : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}
                                                `}
                                            />
                                        }
                                    </td>
                                )
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((item, index) => {
                            return (
                                <TableRow
                                    item={item}
                                    handleModal={() => setModal(true)}
                                    key={index}
                                    setItem={setItem}
                                />
                            )
                        })}
                    </tbody>
                </table>

                {!data.length &&
                    <div
                        style={{
                            margin: '20px auto',
                            textAlign: 'center'
                        }}
                    >
                        No data available in table
                    </div>
                }
            </StyledTable>
        </>
    )
};

const StyledTable = styled.div`
  .sort-arrow {
    opacity: 0.7;
    transition: all .3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg)
    }
  }

  table thead tr td.left-content {
    text-align: left;
  }

  table tbody tr td.left-content {
    text-align: left;
  }
`;
