import { useEffect, useState } from "react";

import { getAllGroup } from "../../../../crud/info.crud";

export const useGroups = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [groupList, setGroupList] = useState([]);

  useEffect(() => {
    const fetchGroups = async () => {
      setLoading(true);
      try {
        const res = await getAllGroup();
        setGroupList(res.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, []);

  return { groupList, loading, error };
};
