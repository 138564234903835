export const validate = (formik) => {
  let isValid = true;

  formik.validateForm().then((res) => {
    const errors = { ...res };
    formik.setErrors(errors);
    // formik.setTouched({
    //   title: true,
    //   date: true,
    //   time: true,
    //   priority: true,
    //   profiles: true,
    //   users: true,
    //   description: true,
    // })
  });

  return isValid;
};
