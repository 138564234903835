import React, { useEffect, useState } from 'react';
import './NewReportModal.scss';
import { Button, Modal } from 'react-bootstrap';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import { getTypes } from '../../../../../crud/analytics.crud';
import { useHistory } from 'react-router-dom';
import { setDate } from 'date-fns';
import Loader from '../../../../../components/Loader';
import { useSelector } from 'react-redux';

export const NewReportModal = ({ openModal, onClose }) => {
  const [selected, setSelected] = useState('');
  const [data, setData] = useState([]);
  let role = useSelector((store) => store.auth.user.roles);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [sort, setSort] = useState({
    sort_by: '',
    dir: '',
  });

  useEffect(() => {
    setLoader(true);
    getTypes(sort)
      .then((res) => setData(res.data.data))
      .finally(() => setLoader(false));
  }, [sort]);

  const openReport = () => {
    history.push(`/analytics/new_report/${selected}`);
  };

  useEffect(() => {
    const container = document.querySelector('.scrollable-container'); // Замените на ваш класс контейнера
    if (container) {
      container.classList.add('force-scroll');
    }

    return () => {
      if (container) {
        container.classList.remove('force-scroll');
      }
    };
  }, []);

  return (
    <>
      <Loader visible={loader} />
      <Modal show={openModal} onHide={onClose}>
        <div className="report-modal">
          <div className="report-modal__title">
            Select Report Type
            <span className="report-modal__close-btn" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 5L15 15"
                  stroke="#808080"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M15 5L5 15"
                  stroke="#808080"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </div>
          <div className="report-modal__wrap">
            <div className="report-modal__head head">
              <div className="head__item select">Select</div>
              <div
                className="head__item type"
                onClick={() => {
                  setSort({
                    sort_by: 'name',
                    dir: sort.dir === 'desc' ? 'asc' : 'desc',
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                Report Type{' '}
                <ArrowDown
                  style={
                    sort.sort_by === 'name' && sort.dir === 'asc'
                      ? { transform: 'rotate(180deg)' }
                      : {}
                  }
                />
              </div>
              <div
                className="head__item type"
                onClick={() => {
                  setSort({
                    sort_by: 'category',
                    dir: sort.dir === 'desc' ? 'asc' : 'desc',
                  });
                }}
                style={{ cursor: 'pointer' }}
              >
                Category{' '}
                <ArrowDown
                  style={
                    sort.sort_by === 'category' && sort.dir === 'asc'
                      ? { transform: 'rotate(180deg)' }
                      : {}
                  }
                />
              </div>
            </div>
            <div className="report-modal__main main">
              {data?.map((elem) => {
                if (
                  role === 'staff' &&
                  (elem.name === 'Profiles and W.O. Compliance' ||
                    elem.name === 'Users and W.O. Compliance' ||
                    elem.name === 'Groups and W.O. Compliance' ||
                    elem.name === 'Users and Metric' ||
                    elem.name === 'Groups and Metric' ||
                    elem.name === 'User and Usage')
                ) {
                  return;
                } else {
                  return (
                    <div
                      className="main__item"
                      onClick={() => setSelected(elem.id)}
                    >
                      <div className="main__item select">
                        <div
                          className={`main__checkbox ${
                            selected === elem.id ? 'active' : ''
                          }`}
                        ></div>
                      </div>
                      <div className="main__item type">{elem.name}</div>
                      <div className="main__item type">{elem.category}</div>
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>

        <div className="report-modal__btnblock">
          <div className="report-modal__cancel" onClick={onClose}>
            Cancel
          </div>
          <div
            className={`report-modal__open ${selected ? '' : 'disabled'}`}
            onClick={selected ? openReport : ''}
          >
            Open
          </div>
        </div>
      </Modal>
    </>
  );
};
