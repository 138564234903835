import { Portlet, PortletBody } from "../../partials/content/Portlet";
import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { Button, Pagination, Modal } from "react-bootstrap";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Tabs,
  Tab,
  OutlinedInput,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
} from "@material-ui/core";
import TableList from "./ProfileListTabs/TableProfileList";
import DataOutput from "../../components/DataOutputList";
import {
  getProfilesV2,
  sendMailAccess,
  attachProfile,
} from "../../crud/profile.crud";
import {
  getStatusList,
  getBoard,
  editStatus,
  getAllGroup,
  getUserPrivileges,
  fieldsUser,
  fieldsGroups,
  getUsersV3,
} from "../../crud/info.crud";
import Logout from "../auth/Logout";
import Loader from "../../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from "../../store/ducks/auth.duck";
import ModalCreateFromCRM from "./ModalCreateFromCrm";
import { SendFormModal } from "./ProfileListModals/EmailFormModal/SendFormModal";
import { EmailFormModal } from "./ProfileListModals/EmailFormModal/EmailFormModal";
import { ChooseProgramModal } from "./ProfileListModals/AddToProgramModal/ChooseProgramModal";
import { AddToProgramModal } from "./ProfileListModals/AddToProgramModal/AddToProgramModal";
import { addFiltersProfileList } from "../../store/actions";
import { useIsMount } from "../../hooks/useIsMount";
import "./table.scss";
import "./style.scss";

export default (props) => {
  document.title = "TrainerMetrics - Profiles List";
  const crm = useSelector((state) =>
    state.user_info.CRM_list.find((elem) => elem.is_activated === true)
  );
  const permissions = useSelector((state) => state.auth.user.permissions);
  const dispatch = useDispatch();
  let role = useSelector((store) => store.auth.user.roles);
  const returnQuery = (label) => {
    let query = props.location.search.slice(1).split("&");
    const index = query.findIndex((item) => item.split("=")[0] === label);
    return index === -1 ? "" : query[index].split("=")[1];
  };
  const [logout, setLogout] = useState(null);
  const [data, setData] = useState([]);
  const [show, setShow] = useState("10");
  const [page, setPage] = useState(false);
  const [pagination, setPagination] = useState(1);
  const [check, setCheck] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderStatusList, setLoaderStatusList] = useState(true);
  const [loaderProfileList, setLoaderProfileList] = useState(false);
  const [loaderAllGroup, setLoaderAllGroup] = useState(true);
  const [loaderAllUser, setLoaderAllUser] = useState(true);
  const [sendMail, setSendMail] = useState(false);
  const [key, setKey] = useState(0);
  const [keyList, setKeyList] = useState([]);
  const [sort, setSort] = useState(["name", "asc"]);
  const [typeFilter, setTypeFilter] = useState("");
  const [dataTypeFilter, setDataTypeFilter] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupListData, setGroupListData] = useState("");
  const [staffList, setStaffList] = useState([]);
  const [staffListData, setStaffListData] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);
  const [access, setAccess] = useState({
    create: false,
    activate: false,
    archive: false,
    delete: false,
    access: false,
    updateTesting: false,
  });
  const [staffAttach, setStaffAttach] = useState([]);
  const [staffAttachData, setStaffAttachData] = useState([]);
  const [groupAttach, setGroupAttach] = useState([]);
  const [groupAttachData, setGroupAttachData] = useState([]);
  const plan = useSelector((state) => state.user_info.subscription?.plan?.name);
  const [permUser, setPermUser] = useState(false);
  const [permGroup, setPermGroup] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [modalCreateFromCRM, setModalCreateFromCRM] = useState(false);
  const [sendFormModal, setSendFormModal] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [emailFormModal, setEmailFormModal] = useState(false);
  const [chooseProgramModal, setChooseProgramModal] = useState(false);
  const [addProgramModal, setAddProgramModal] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [search, setSearch] = useState("");
  const profileListFilters = useSelector(
    (state) => state.temporaryData.profileListFilters
  );
  const isFirstRender = useIsMount();

  useEffect(() => {
    if (profileListFilters.groups) setGroupListData(profileListFilters.groups);
    if (profileListFilters.staffs) setStaffListData(profileListFilters.staffs);
  }, []);

  useEffect(() => {
    setLoaderStatusList(true);
    getStatusList()
      .then((res) => {
        setLoaderStatusList(false);
        setKeyList(res.data.data.filter((item) => !!item.is_show));
        setKey(0);
      })
      .catch(({ response }) => {
        setLoaderStatusList(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });

    const group =
      profileListFilters.groups === "all" ||
      profileListFilters.groups === "" ||
      profileListFilters.groups === "all_group_dev"
        ? null
        : profileListFilters.groups;

    const staff =
      profileListFilters.staffs === "all" ||
      profileListFilters.staffs === "" ||
      profileListFilters.staffs === "all_staff_dev"
        ? null
        : profileListFilters.staffs;

    getBoard({
      group,
      staff,
      with_groups: profileListFilters.group === "all_group_dev" ? 1 : null,
      with_staff: profileListFilters.staffs === "all_staff_dev" ? 1 : null,
    })
      .then((res) => {
        setDataTypeFilter(res.data.data);
      })
      .catch(({ response }) => {
        if (response && response.status === 401) {
          setLogout(<Logout />);
        }
      });

    getUserPrivileges()
      .then((res) => {
        saveAccess(res.data.data.permissions);
      })
      .catch(({ response }) => {
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });

    fieldsUser().then(() => {
      setPermUser(true);
    });

    fieldsGroups().then(() => {
      setPermGroup(true);
    });
  }, [groupListData, staffListData]);

  const saveAccess = (arr) => {
    const status = (name) => arr.findIndex((item) => item === name) !== -1;

    setAccess({
      create: status("create profiles"),
      activate: status("activate profiles"),
      archive: status("archive profiles"),
      delete: status("delete profiles"),
      access: status("access profiles"),
      updateTesting: status("update testing metrics"),
    });
  };

  useEffect(() => {
    loadData();
  }, [pagination, show, key, sort, profileListFilters, typeFilter]);

  const loadData = () => {
    setLoaderProfileList(true);
    const status =
      key === 0 ? 2 : key === 1 ? 1 : key === 2 ? 3 : key === 3 ? 5 : null;

    const group =
      profileListFilters.groups === "all" ||
      profileListFilters.groups === "" ||
      profileListFilters.groups === "all_group_dev"
        ? null
        : profileListFilters.groups;

    const staff =
      profileListFilters.staffs === "all" ||
      profileListFilters.staffs === "" ||
      profileListFilters.staffs === "all_staff_dev"
        ? null
        : profileListFilters.staffs;

    getProfilesV2({
      page: pagination,
      perPage: show,
      status: role === "client" ? null : status,
      search: search,
      sortBy: sort[1],
      sort: sort[0],
      group: group,
      staff: staff,
      without_goal: typeFilter === "without_goal" ? 1 : null,
      without_completed_metrics:
        typeFilter === "without_completed_metrics" ? 1 : null,
      without_completed_parq:
        typeFilter === "without_completed_parq" ? 1 : null,
      with_overdue_metrics: typeFilter === "with_overdue_metrics" ? 1 : null,
      with_groups: profileListFilters.group === "all_group_dev" ? 1 : null,
      with_staff: profileListFilters.staffs === "all_staff_dev" ? 1 : null,
    })
      .then((res) => {
        setLoaderProfileList(false);
        setData(res.data.data.profiles);
        if (
          !!(role === "client") &&
          !!res.data.data.profiles &&
          !!res.data.data.profiles[0]
        ) {
          window.location.pathname = `/profile/${res.data.data.profiles[0].profile_id}/overview`;
        }

        setPage({
          last_page: res.data.data.last_page,
          per_page: res.data.data.per_page,
          total: res.data.data.total,
        });
      })
      .catch(({ response }) => {
        setLoaderProfileList(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  useEffect(() => {
    setCheck([]);
  }, [data, groupListData, staffListData, key, sort, typeFilter]);

  useEffect(() => {
    if (isFirstRender) return;
    const handler = setTimeout(() => {
      setLoaderProfileList(true);
      const status =
        key === 0 ? 2 : key === 1 ? 1 : key === 2 ? 3 : key === 3 ? 5 : null;

      const group =
        profileListFilters.groups === "all" ||
        profileListFilters.groups === "" ||
        profileListFilters.groups === "all_group_dev"
          ? null
          : profileListFilters.groups;

      const staff =
        profileListFilters.staffs === "all" ||
        profileListFilters.staffs === "" ||
        profileListFilters.staffs === "all_staff_dev"
          ? null
          : profileListFilters.staffs;

      getProfilesV2({
        page: 1,
        perPage: show,
        status: role === "client" ? null : status,
        search: search,
        sortBy: sort[1],
        sort: sort[0],
        group: group,
        staff: staff,
        without_goal: typeFilter === "without_goal" ? 1 : null,
        without_completed_metrics:
          typeFilter === "without_completed_metrics" ? 1 : null,
        without_completed_parq:
          typeFilter === "without_completed_parq" ? 1 : null,
        with_overdue_metrics: typeFilter === "with_overdue_metrics" ? 1 : null,
        with_groups: groupListData === "all_group_dev" ? 1 : null,
        with_staff: staffListData === "all_staff_dev" ? 1 : null,
      })
        .then((res) => {
          setCheck([]);
          setPagination(1);
          setLoaderProfileList(false);
          setData(res.data.data.profiles);
          if (
            !!(role === "client") &&
            !!res.data.data.profiles &&
            !!res.data.data.profiles[0]
          ) {
            window.location.pathname = `/profile/${res.data.data.profiles[0].profile_id}/overview`;
          }

          setPage({
            last_page: res.data.data.last_page,
            per_page: res.data.data.per_page,
            total: res.data.data.total,
          });
        })
        .catch(({ response }) => {
          setLoaderProfileList(false);
          if (response && response.status === 401) {
            setLogout(<Logout />);
          } else if (response && response.status === 403) {
            setLogout(<Redirect to="/profile-list" />);
          }
        });
    }, 1000);
    return () => clearTimeout(handler);
  }, [search]);

  useEffect(() => {
    setLoaderAllGroup(true);
    getAllGroup()
      .then((res) => {
        setLoaderAllGroup(false);
        setGroupList(res.data.data);
        setGroupAttachData(res.data.data);
      })
      .catch(({ response }) => {
        setLoaderAllGroup(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        }
      });
  }, []);

  useEffect(() => {
    setLoaderAllUser(true);

    const group =
      groupListData === "all" ||
      groupListData === "" ||
      groupListData === "all_group_dev"
        ? null
        : groupListData;

    getUsersV3({ group_id: group || null, limit: 500 })
      .then((res) => {
        setLoaderAllUser(false);
        const staffs = res.data.list
          .filter((item) => item.role === "staff")
          .map((elem) => {
            return { ...elem, name: `${elem.first_name} ${elem.last_name}` };
          });
        const sortedStaffs = staffs.sort((a, b) => {
          let first = a.name.toLowerCase();
          let second = b.name.toLowerCase();
          if (first < second) return -1;
          if (first > second) return 1;
          return 0;
        });
        setStaffList(sortedStaffs);
        setStaffAttachData(
          res.data.list
            .filter((item) => item.role === "staff")
            .map((elem) => ({
              ...elem,
              name: `${elem.first_name} ${elem.last_name}`,
            }))
        );
        if (returnQuery("user")) {
          dispatch(
            addFiltersProfileList({ staffs: parseInt(returnQuery("user")) })
          );
          setStaffListData(parseInt(returnQuery("user")));
        }
      })
      .catch(({ response }) => {
        setLoaderAllUser(false);
        if (response && response.status === 401) {
          setLogout(<Logout />);
        }
      });
  }, [groupListData]);

  const checkAll = () => {
    setCheck(() => data.map((item) => item.profile_id));
  };

  const changeStatus = (type) => {
    editStatus({
      status_id: type,
      profiles: check,
    })
      .then((res) => {
        loadData();
        setCheck([]);
        type === 4 && setPagination(1);
        dispatch(
          AlertState.actions.alert({
            text: res.data.message,
            variant: true,
          })
        );
      })
      .catch(({ response }) => {
        setLoaderAllUser(false);
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
        if (response && response.status === 401) {
          setLogout(<Logout />);
        } else if (response && response.status === 403) {
          setLogout(<Redirect to="/profile-list" />);
        }
      });
  };

  const inputLabel = useRef();
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
  }, [inputLabel, check]);

  const inputLabel_2 = useRef();
  const [labelWidth_2, setLabelWidth_2] = useState(0);

  useEffect(() => {
    inputLabel_2.current && setLabelWidth_2(inputLabel_2.current.offsetWidth);
  }, [inputLabel_2, check]);

  const inputLabel_3 = useRef();
  const [labelWidth_3, setLabelWidth_3] = useState(0);

  useEffect(() => {
    inputLabel_3.current && setLabelWidth_3(inputLabel_3.current.offsetWidth);
  }, [inputLabel_3, check]);

  const inputLabel_4 = useRef();
  const [labelWidth_4, setLabelWidth_4] = useState(0);

  useEffect(() => {
    inputLabel_4.current && setLabelWidth_4(inputLabel_4.current.offsetWidth);
  }, [inputLabel_4, check]);

  const inputLabel_5 = useRef();
  const [labelWidth_5, setLabelWidth_5] = useState(0);

  useEffect(() => {
    inputLabel_5.current && setLabelWidth_5(inputLabel_5.current.offsetWidth);
  }, [inputLabel_5, check]);

  const attachProfileEvent = () => {
    attachProfile({
      profiles: check,
      staffs: staffAttach,
      groups: groupAttach,
    })
      .then((res) => {
        loadData();
        dispatch(
          AlertState.actions.alert({
            text: res.data.data,
            variant: true,
          })
        );
        setCheck([]);
        setStaffAttach([]);
        setGroupAttach([]);
      })
      .catch(({ response }) => {
        response &&
          response.data &&
          dispatch(
            AlertState.actions.alert({
              text: response.data.message || response.data.error,
              variant: false,
            })
          );
      });
  };

  const [checkClient, setCheckClient] = useState([]);

  const archiveEvent = () => {
    setCheckClient([]);
    const client_profile = data.filter((item) => {
      const result = check.findIndex((elem) => {
        return elem === item.profile_id;
      });
      return result !== -1 && item.client_access === 0;
    });

    if (!client_profile.length) {
      changeStatus(3);
    } else {
      setCheckClient(client_profile.map((item) => item.profile_id));
      setOpenArchiveModal(true);
    }
  };

  const buttonRef = useRef();
  useEffect(() => {
    if (!crm) return;
    function clickOutside(e) {
      if (buttonRef && !buttonRef?.current?.contains(e.target)) {
        setDropdown(false);
      }
    }

    document.addEventListener("mousedown", clickOutside);
    return () => document.removeEventListener("mousedown", clickOutside);
  }, [buttonRef, crm]);

  return role === "client" ? (
    logout
  ) : (
    <StyledProfileLists>
      {logout}
      <Loader
        visible={
          loaderStatusList ||
          loaderProfileList ||
          loaderAllGroup ||
          loaderAllUser ||
          loader
        }
      />
      <DataOutput
        typeFilter={typeFilter}
        setTypeFilter={setTypeFilter}
        data={dataTypeFilter}
        setKey={setKey}
      />

      {sendFormModal && (
        <SendFormModal
          sendFormModal={sendFormModal}
          setSendFormModal={setSendFormModal}
          setEmailFormModal={setEmailFormModal}
          selectedForm={selectedForm}
          setSelectedForm={setSelectedForm}
        />
      )}

      {emailFormModal && (
        <EmailFormModal
          emailFormModal={emailFormModal}
          setEmailFormModal={setEmailFormModal}
          selectedForm={selectedForm}
          data={data}
          check={check}
        />
      )}

      {chooseProgramModal && (
        <ChooseProgramModal
          chooseProgramModal={chooseProgramModal}
          setChooseProgramModal={setChooseProgramModal}
          setAddProgramModal={setAddProgramModal}
          selectedProgram={selectedProgram}
          setSelectedProgram={setSelectedProgram}
        />
      )}

      {addProgramModal && (
        <AddToProgramModal
          addProgramModal={addProgramModal}
          setAddProgramModal={setAddProgramModal}
          selectedProgram={selectedProgram}
          data={data}
          check={check}
        />
      )}

      <Modal
        show={openDeleteModal}
        onHide={(e) => {
          setOpenDeleteModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete selected profiles?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setOpenDeleteModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              changeStatus(4);
              setOpenDeleteModal(false);
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openArchiveModal}
        onHide={(e) => {
          setOpenArchiveModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Archive selected profiles?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography variant="h6" gutterBottom>
            Attention:
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you archive this account, it will not count towards the number of
            profiles you pay for. However, this also means that you will not be
            able to re-activate this profile for 30 days.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Please check here if you would like to send an email to the person
            you created this profile for so that they'll have access to the
            information you've recorded for them.
          </Typography>
          <Typography variant="h6" gutterBottom>
            Client Access
          </Typography>
          <RadioGroup
            value={sendMail}
            onChange={(e) => {
              setSendMail(e.target.value);
            }}
          >
            <FormControlLabel
              value={"1"}
              control={<Radio color="primary" />}
              label="Allow access to Client Profile"
            />
            <FormControlLabel
              value={"0"}
              control={<Radio color="primary" />}
              label="Do not allow access to Client Profile"
            />
          </RadioGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={(e) => {
              setOpenArchiveModal(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={sendMail === false}
            onClick={(e) => {
              changeStatus(3);
              setOpenArchiveModal(false);
              checkClient.map((item) => {
                sendMailAccess(item, { client_access: sendMail })
                  .then((res) => {
                    dispatch(
                      AlertState.actions.alert({
                        text: "E-mail is send",
                        variant: true,
                      })
                    );
                  })
                  .catch(({ response }) => {
                    response &&
                      response.data &&
                      response.data.error &&
                      dispatch(
                        AlertState.actions.alert({
                          text: response.data.error,
                          variant: false,
                        })
                      );
                  });
                return null;
              });
            }}
          >
            Archive
          </Button>
        </Modal.Footer>
      </Modal>

      <Portlet>
        <PortletBody>
          {returnQuery("user") && (
            <Typography variant="h5" gutterBottom>
              Profiles belonging to{" "}
              <strong>
                {// eslint-disable-next-line
                staffList.find((item) => item.id == returnQuery("user")) &&
                  // eslint-disable-next-line
                  staffList.find((item) => item.id == returnQuery("user")).name}
              </strong>
            </Typography>
          )}

          <div className="header-list">
            <Tabs
              value={key}
              onChange={(e, value) => setKey(value)}
              indicatorColor="primary"
              textColor="primary"
              scrollButtons="auto"
              variant="scrollable"
            >
              {role !== "client"
                ? keyList
                    .filter((item) => !!item.is_show)
                    .map((item, index) => (
                      <Tab label={item.name} key={item.name} />
                    ))
                : keyList
                    .filter((item) => !!item.is_default)
                    .map((item, index) => (
                      <Tab label={item.name} key={item.name} />
                    ))}
              {role !== "client" && <Tab label="All" />}
            </Tabs>

            <ModalCreateFromCRM
              setLoader={setLoader}
              modal={modalCreateFromCRM}
              setModal={setModalCreateFromCRM}
            />

            {access.create && (
              <div style={{ position: "relative" }}>
                <Button
                  className="btn-blue desktop-vis"
                  onClick={() => {
                    if (
                      crm &&
                      permissions.find(
                        (elem) => elem === "create_profile_from_crm"
                      )
                    ) {
                      setDropdown(true);
                    } else {
                      setLogout(<Redirect to="/profile-create" />);
                    }
                  }}
                  style={{ whiteSpace: "nowrap", margin: 5 }}
                >
                  Create Profile
                </Button>

                <div
                  className="buttons-dropdown"
                  style={{
                    display: `${dropdown ? "" : "none"}`,
                    top: "0",
                    left: "-82px",
                  }}
                  ref={buttonRef}
                >
                  <div className="buttons-dropdown__elem">
                    <span
                      onClick={() =>
                        setLogout(<Redirect to="/profile-create" />)
                      }
                    >
                      Create Profile
                    </span>
                  </div>
                  <div className="buttons-dropdown__elem">
                    <span onClick={() => setModalCreateFromCRM(true)}>
                      Create Profile from CRM
                    </span>
                  </div>
                  <div
                    className="buttons-dropdown__arrow"
                    onClick={() => setDropdown(false)}
                  >
                    {">"}
                  </div>
                </div>
              </div>
            )}
          </div>
          {role !== "client" && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "10px 0",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  {!!data && !!data.length && check.length > 0 ? (
                    <>
                      {(keyList[key] ? keyList[key].id !== 1 : true) &&
                        (keyList[key] ? keyList[key].id !== 5 : true) && (
                          <div
                            style={{ display: "flex" }}
                            className="mobile-width-100p"
                          >
                            {access.create && (
                              <Button
                                className="btn-blue mobile-vis"
                                onClick={() =>
                                  setLogout(<Redirect to="/profile-create" />)
                                }
                                style={{ whiteSpace: "nowrap", margin: 5 }}
                              >
                                Create Profile
                              </Button>
                            )}
                            {access.activate &&
                              (keyList[key] ? keyList[key].id !== 2 : true) && (
                                <Button
                                  variant="primary"
                                  style={{ margin: 5 }}
                                  onClick={() => changeStatus(2)}
                                >
                                  Active
                                </Button>
                              )}
                            {access.archive &&
                              (keyList[key] ? keyList[key].id !== 3 : true) && (
                                <Button
                                  variant="primary"
                                  style={{ margin: 5 }}
                                  onClick={archiveEvent}
                                >
                                  Archive
                                </Button>
                              )}
                            {access.delete && (
                              <Button
                                variant="primary"
                                style={{ margin: 5 }}
                                onClick={(e) => setOpenDeleteModal(true)}
                              >
                                Delete
                              </Button>
                            )}
                            {
                              <Button
                                variant="primary"
                                style={{ margin: 5 }}
                                onClick={() => setChooseProgramModal(true)}
                              >
                                Add Program
                              </Button>
                            }
                            {
                              <Button
                                variant="primary"
                                style={{ margin: 5 }}
                                onClick={() => setSendFormModal(true)}
                              >
                                Send Form
                              </Button>
                            }
                          </div>
                        )}

                      <div
                        style={{ minWidth: "100%" }}
                        className="mobile-vis"
                      />
                      <TextField
                        key={"search"}
                        className="formControl mobile-vis"
                        label="Search"
                        style={{ margin: 5 }}
                        margin="normal"
                        variant="outlined"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            setSearch(e.target.value);
                          }
                        }}
                      />

                      {(keyList[key] ? keyList[key].id !== 1 : true) &&
                        (keyList[key] ? keyList[key].id !== 5 : true) &&
                        permUser && (
                          <FormControl
                            variant="outlined"
                            className="formControl"
                            style={{ margin: 5 }}
                          >
                            <InputLabel ref={inputLabel_4} key={"inputLabel_4"}>
                              Staff
                            </InputLabel>
                            <Select
                              multiple
                              value={staffAttach}
                              input={
                                <OutlinedInput labelWidth={labelWidth_4} />
                              }
                              onChange={(e) => {
                                setStaffAttach(e.target.value);
                              }}
                            >
                              {staffAttachData.map((item) => (
                                <MenuItem
                                  key={item.id}
                                  value={parseInt(item.id)}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}

                      {(keyList[key] ? keyList[key].id !== 1 : true) &&
                        (keyList[key] ? keyList[key].id !== 5 : true) &&
                        permGroup &&
                        plan === "Enterprise" && (
                          <>
                            <FormControl
                              variant="outlined"
                              className="formControl"
                              style={{ margin: 5 }}
                            >
                              <InputLabel
                                ref={inputLabel_5}
                                key={"inputLabel_5"}
                              >
                                Group
                              </InputLabel>
                              <Select
                                multiple
                                value={groupAttach}
                                input={
                                  <OutlinedInput labelWidth={labelWidth_5} />
                                }
                                onChange={(e) => {
                                  setGroupAttach(e.target.value);
                                }}
                              >
                                {groupAttachData.map((item) => (
                                  <MenuItem
                                    key={item.id}
                                    value={parseInt(item.id)}
                                  >
                                    {item.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>{" "}
                          </>
                        )}

                      {(keyList[key] ? keyList[key].id !== 1 : true) &&
                        (keyList[key] ? keyList[key].id !== 5 : true) &&
                        role !== "staff" && (
                          <Button
                            className="btn-blue"
                            style={{ margin: 5 }}
                            onClick={attachProfileEvent}
                          >
                            Save
                          </Button>
                        )}
                    </>
                  ) : (
                    <>
                      {access.create && (
                        <Button
                          className="btn-blue mobile-vis"
                          onClick={() => {
                            if (
                              crm &&
                              permissions.find(
                                (elem) => elem === "create_profile_from_crm"
                              )
                            ) {
                              setDropdown(true);
                            } else {
                              setLogout(<Redirect to="/profile-create" />);
                            }
                          }}
                          style={{ whiteSpace: "nowrap", margin: 5 }}
                        >
                          Create Profile
                        </Button>
                      )}
                      {!!data &&
                        !!data.length &&
                        (keyList[key] ? keyList[key].id !== 1 : true) &&
                        (keyList[key] ? keyList[key].id !== 5 : true) && (
                          <Button
                            variant="info"
                            onClick={checkAll}
                            style={{ margin: 5 }}
                          >
                            Edit All
                          </Button>
                        )}

                      <div
                        style={{ minWidth: "100%" }}
                        className="mobile-vis"
                      />
                      <TextField
                        key={"search"}
                        className="formControl mobile-vis"
                        label="Search"
                        style={{ margin: 5 }}
                        margin="normal"
                        variant="outlined"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            setSearch(e.target.value);
                          }
                        }}
                      />
                      {(plan === "Enterprise" || role === "supevisor") && (
                        <FormControl
                          variant="outlined"
                          className="formControl"
                          style={{ margin: 5 }}
                        >
                          <InputLabel ref={inputLabel_2}>Group</InputLabel>
                          <Select
                            value={groupListData}
                            input={<OutlinedInput labelWidth={labelWidth_2} />}
                            onChange={(e) => {
                              dispatch(
                                addFiltersProfileList({
                                  groups: e.target.value,
                                })
                              );
                              setGroupListData(e.target.value);
                            }}
                          >
                            <MenuItem value={"all"}>All profiles</MenuItem>
                            <MenuItem value={"all_group_dev"}>
                              (All groups)
                            </MenuItem>
                            {groupList.map((item) => (
                              <MenuItem key={item.id} value={parseInt(item.id)}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      {permUser && (
                        <FormControl
                          variant="outlined"
                          className="formControl"
                          style={{ margin: 5 }}
                        >
                          <InputLabel ref={inputLabel_3}>Staff</InputLabel>
                          <Select
                            value={staffListData}
                            input={<OutlinedInput labelWidth={labelWidth_3} />}
                            onChange={(e) => {
                              dispatch(
                                addFiltersProfileList({
                                  staffs: e.target.value,
                                })
                              );
                              setStaffListData(e.target.value);
                            }}
                          >
                            <MenuItem value={"all"}>All profiles</MenuItem>
                            <MenuItem value={"all_staff_dev"}>
                              (All staffs)
                            </MenuItem>
                            {staffList.map((item) => (
                              <MenuItem key={item.id} value={parseInt(item.id)}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </>
                  )}
                </div>
              </div>

              <div className={`search-wrap desktop-vis`}>
                <TextField
                  key={"search"}
                  className="formControl"
                  label="Search"
                  style={{ margin: 5 }}
                  margin="normal"
                  variant="outlined"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      setSearch(e.target.value);
                    }
                  }}
                />
              </div>
            </div>
          )}
          <TableList
            data={data}
            check={check}
            setCheck={setCheck}
            sort={sort}
            setSort={setSort}
            plan={plan}
          />
          <div className="pagination-wrap">
            <Pagination>
              <Pagination.First
                onClick={() => setPagination(1)}
                disabled={pagination === 1}
              />
              <Pagination.Prev
                onClick={() => setPagination((p) => p - 1)}
                disabled={pagination === 1}
              />
              <Pagination.Item>{pagination}</Pagination.Item>
              <Pagination.Next
                onClick={() => setPagination((p) => p + 1)}
                disabled={pagination === page.last_page}
              />
              <Pagination.Last
                onClick={() => setPagination(page.last_page)}
                disabled={pagination === page.last_page}
              />
            </Pagination>
            <div className="pagination-show">
              <FormControl variant="outlined" className="formControl">
                <InputLabel ref={inputLabel}>Show Entries</InputLabel>
                <Select
                  value={show}
                  input={<OutlinedInput labelWidth={labelWidth} />}
                  onChange={(e) => {
                    setShow(e.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
              <Typography variant="body1" gutterBottom>
                {page &&
                  `Showing ${(pagination - 1) * page.per_page +
                    (page.total ? 1 : 0)} - ${
                    pagination * page.per_page > page.total
                      ? page.total
                      : pagination * page.per_page
                  } of ${page.total}`}
              </Typography>
            </div>
          </div>
        </PortletBody>
      </Portlet>
    </StyledProfileLists>
  );
};

const StyledProfileLists = styled.div`
  .desktop-vis {
    display: none;

    @media screen and (min-width: 760px) {
      display: flex;
    }
  }

  .mobile-width-100p {
    @media screen and (max-width: 760px) {
      width: 100%;
    }
  }

  .mobile-vis {
    display: flex;

    @media screen and (min-width: 760px) {
      display: none;
    }
  }

  .header-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .btn {
    margin-right: 15px;
  }

  .edit-all {
    height: 100%;
    border-radius: 4px;
    background-color: #eef1ff;
    padding: 0 12px;
    font-weight: 600;
    font-size: 13px;
    line-height: 19px;
    color: #5d78ff;
    display: flex;
    align-items: center;
  }

  .pagination {
    margin: 0;

    @media (max-width: 600px) {
      margin: 10px auto;
    }

    &-wrap {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 10px;

      .MuiSelect-select {
        padding: 7px;
      }
    }

    &-show {
      display: flex;
      align-items: center;
      min-width: 290px;
    }
  }

  .formControl {
    width: 150px;
    margin: 5px 10px;
  }
`;
