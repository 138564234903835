import React from 'react';
import TimePicker from '../TimePicker';
import './custom-time-picker.scss';

export default ({
  value,
  onChange,
  className,
  inputProps = {
    min: '00:00:00',
    max: '23:59:59',
  },
  error,
  helperText,
}) => {
  const handleChange = (value) => {
    let hours = +value.split(':')[0];
    let minutes = +value.split(':')[1];
    let seconds = +value.split(':')[2];

    // max
    let maxHours = +inputProps.max.split(':')[0];
    let maxMinutes = +inputProps.max.split(':')[1];
    let maxSeconds = +inputProps.max.split(':')[2];

    if (+hours > +maxHours) {
      hours = (maxHours + '').length > 1 ? maxHours + '' : '0' + maxHours;
      minutes = maxMinutes;
      seconds = maxSeconds;
    }

    if (hours === maxHours && (minutes > 0 || seconds > 0)) {
      hours = maxHours.length > 1 ? maxHours + '' : '0' + maxHours;
      minutes = maxMinutes;
      seconds = maxSeconds;
    }

    if (hours === 0) hours = '00';
    if (minutes === 0 || minutes > 59) minutes = '00';
    if (seconds === 0 || seconds > 59) seconds = '00';

    if ((hours + '').length === 1) hours = '0' + hours;
    if ((minutes + '').length === 1) minutes = '0' + minutes;
    if ((seconds + '').length === 1) seconds = '0' + seconds;

    // min

    let minHours = +inputProps.min.split(':')[0];
    let minMinutes = +inputProps.min.split(':')[1];
    let minSeconds = +inputProps.min.split(':')[2];

    if (+hours < +minHours) {
      if (+minHours <= 9) {
        hours = `0${minHours}`;
        minutes = minMinutes
        seconds = minSeconds
      } else {
        hours = `${minHours}`
        minutes = minMinutes
        seconds = minSeconds
      }

    }

    onChange(`${hours}:${minutes}:${seconds}`);
  };

  return (
    <div
      className={`custom-timepicker ${error ? 'custom-timepicker__error-border' : ''
        }`}
    >
      <TimePicker value={value} onChange={handleChange} className={className} />

      {error && (
        <div className="custom-timepicker__error-text">{helperText}</div>
      )}
    </div>
  );
};
