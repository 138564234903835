import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default ({
    label,
    options,
    error,
    setData,
    data,
    fetchOptions,
    style,
}) => {
    const [inputValue, setInputValue] = useState('');

    const handleChange = (e, value) => {
        setData(value);
    };

    useEffect(() => {
        if (fetchOptions) fetchOptions();
    }, []);

    useEffect(() => {
        if (inputValue && fetchOptions) {
            const handler = setTimeout(() => {
                fetchOptions(inputValue);
            }, 1000);

            return () => clearTimeout(handler);
        }
    }, [inputValue]);

    return (
        <Autocomplete
            multiple
            limitTags={1}
            style={style}
            id="tags-outlined"
            value={data || []}
            options={options || []}
            getOptionLabel={(option) => option.title}
            onChange={handleChange}
            inputValue={inputValue}
            renderInput={(params) => (
                <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, value: params.inputProps.value }}
                    variant="outlined"
                    label={label || 'select'}
                    placeholder="Search"
                    error={!!error}
                    helperText={error ? error : ''}
                    onChange={e => setInputValue(e.target.value)}
                />
            )}
        />
    );
};
