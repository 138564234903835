import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import * as AlertState from '../../../store/ducks/auth.duck'
import {
    Modal,
    Button
} from 'react-bootstrap'
import { createGoal, updateGoals } from '../../../crud/profile.crud'
import {
    TextField,
    // Grid
} from '@material-ui/core'
// import DateFnsUtils from '@date-io/date-fns';
// import {
//     MuiPickersUtilsProvider,
//     KeyboardDatePicker,
// } from '@material-ui/pickers';
import Loader from '../../../components/Loader'
import Logout from '../../auth/Logout'
import DatePicker from '../../../components/DatePicker'
import moment from 'moment'
import TimePicker from '../../../components/TimePicker';

export default ({ modal, setModal, label, id, metric, loadData }) => {

    const dispatch = useDispatch();

    const [input, setInput] = useState([])
    const [data, setData] = useState({
        goal_date: moment().format('MM/DD/YYYY')
    })
    const [logout, setLogout] = useState(null)
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setData({
            goal_date: moment().add(1, 'days').format('MM/DD/YYYY')
        })
    }, [modal])

    useEffect(() => {
        setLoader(true)
        metric && createGoal(id, metric)
            .then(res => {
                setLoader(false)
                setInput(res.data.data)
            })
            .catch(({ response }) => {
                setLoader(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                }
            })
    }, [id, metric])

    const [errors, setErrors] = useState({})

    const submit = () => {
        let err = {}
        input.map(item => { // eslint-disable-line
            if (!data[item.name] && item.name !== 'goal_date') {
                err[item.name] = 'Field is required'
            }
        })
        setErrors(err)
        if (Object.values(err).length === 0) {
            let req = [{
                name: metric,
                ...data
            }]
            if (req[0].name && req[0].name === 'plank_time' && typeof req[0].goal_value !== 'number') {
                req[0].goal_value = timeToSeconds(req[0].goal_value)
            }
            setLoader(true)
            updateGoals(id, req)
                .then(res => {
                    setLoader(false)
                    dispatch(AlertState.actions.refreshStatistic())
                    setData({ goal_date: moment().add(1, 'days').format('MM/DD/YYYY') })
                    setModal(false)
                    loadData()
                    dispatch(AlertState.actions.alert({
                        text: 'Goal data is added',
                        variant: true
                    }));
                })
                .catch(({ response }) => {
                    response &&
                        response.data &&
                        dispatch(AlertState.actions.alert({
                            text: response.data.message || response.data.error,
                            variant: false
                        }));
                    setLoader(false)
                    if (response && (response.status === 401)) {
                        setLogout(<Logout />)
                    }
                    if (response.data.errors) {
                        const key = Object.keys(response.data.errors)
                        const values = Object.values(response.data.errors)
                        let err = {}
                        key.map((item, index) => { // eslint-disable-line
                            err[item.split('.')[1]] = values[index]
                        })
                        setErrors(err)
                    }
                })
        }
    }

    const timeToSeconds = (time) => {
        var a = time.split(':');
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds
    }

    return (
        <Modal show={modal} onHide={() => setModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{label}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Loader visible={loader} />
                {logout}
                {
                    input.map((item, index) => {
                        return item.name === 'goal_date'
                            ? (
                                <DatePicker
                                    key={index}
                                    format={'MM/DD/YYYY'}
                                    label={item.label}
                                    value={data[item.name] || null}
                                    past={true}
                                    future={true}
                                    onChange={(value) => {
                                        setData({
                                            ...data,
                                            [item.name]: value
                                        })
                                    }}
                                    style={{
                                        fontWeight: 300,
                                        color: 'rgba(0, 0, 0, 0.87)',
                                    }}
                                />
                            )
                            : item.label === 'Goal Plank Time (hh:mm:ss):' ? (
                                <TimePicker
                                    onChange={value => {
                                        setData({
                                            ...data,
                                            [item.name]: value
                                        })
                                    }}
                                    value={data[item.name] || "00.00.00"}
                                />
                            ) : (
                                <TextField
                                    variant="outlined"
                                    key={index}
                                    type="text"
                                    className='textField'
                                    name={item.name}
                                    label={item.label}
                                    value={data[item.name] || ''}
                                    onChange={e => {
                                        setData({
                                            ...data,
                                            [item.name]: e.target.value
                                        })
                                        if (!e.target.value.length) {
                                            setErrors({
                                                ...errors,
                                                [item.name]: 'Field is required'
                                            })
                                        } else {
                                            setErrors({
                                                ...errors,
                                                [item.name]: ''
                                            })
                                        }
                                    }}
                                    margin="normal"
                                    error={Boolean(errors[item.name])}
                                    helperText={errors[item.name]}
                                />
                            )
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setModal(false)}>
                    Close
                </Button>
                <Button variant="primary" className="btn-blue" onClick={submit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}