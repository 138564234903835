import {
    Portlet, PortletBody, PortletHeader
} from "../../partials/content/Portlet";
import React, { useEffect, useRef, useState } from "react";
import { Link, Redirect } from 'react-router-dom'
import { useDispatch } from "react-redux";
import * as AlertState from '../../store/ducks/auth.duck'
import { Chart } from "chart.js";
import styled from 'styled-components'
import DataOutput from '../../components/DataOutput'
import UserInfo from '../../components/UserInfo'
import {
    ButtonToolbar,
    Button,
    Dropdown,
    Modal
} from 'react-bootstrap'
import Logout from '../auth/Logout'
import Loader from '../../components/Loader'
import { getActiveMetrics, deleteTesting } from '../../crud/profile.crud'
import TabNav from './MetricsTabs/TabNav'
import TabNavMobile from './MetricsTabs/TabNavMobile'
import {
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    OutlinedInput
} from '@material-ui/core'
import moment from 'moment'

import ModalProtocol from './MetricsTabs/ModalProtocol'
import ModalAddMetrics from './MetricsTabs/ModalAddMetrics'
import ModalUpdateMetrics from './MetricsTabs/ModalUpdateMetrics'
import ModalAddGoals from './MetricsTabs/ModalAddGoals'
import ModalUpdateGoals from './MetricsTabs/ModalUpdateGoals'
import {
    getGraphData,
    getRecentMetric,
    getProtocol,
    getProfileValid,
} from '../../crud/profile.crud'
import ResentMetricsTable from './MetricsTabs/RecentMetricsTable'
import CompariosTable from './MetricsTabs/ComparisonTable'
import './table.scss'
import DatePicker from '../../components/DatePicker'
import EqualizerIcon from '@material-ui/icons/Equalizer';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import MetricTableChild from './MetricsTabs/MetricsTable';

export default (props) => {
    document.title = 'TrainerMetrics - Metrics'
    const dispatch = useDispatch();
    const id = props.match.params.id
    const [key, setKey] = useState(0)
    const [label, setLabel] = useState(null)
    const [metricId, setMetricId] = useState([null, null])
    const [menu, setMenu] = useState([])
    const [check, setCheck] = useState([])
    const [metric, setMetric] = useState(null)
    const [start_date, set_start_date] = useState('01/01/2020');
    const [end_date, set_end_date] = useState(moment().format('MM/DD/YYYY'));
    const [loader, setLoader] = useState(false)
    const [logout, setLogout] = useState(null)
    const [category, setCategory] = useState()
    const [resentMetrics, setResentMetrics] = useState([])
    const [recentMetricsLabel, setRecentMetricsLabel] = useState('')
    const [comparison, setComparison] = useState([]);
    const [redirect, setRedirect] = useState(false);

    useEffect(() => {
        getProfileValid(id)
            .then(res => {
                setRedirect(!res.data.is_valid);
                if (!res.data.is_valid) {
                    dispatch(AlertState.actions.alert({
                        text: 'Please fill out the required fields below to continue',
                        variant: false
                    }));
                }
            })
    }, [id]);

    useEffect(() => {
        setLabel(() => {
            return metricId[0] !== null
                ? metricId[1] !== null
                    ? menu[metricId[0]].childs[metricId[1]].label
                    : menu[metricId[0]].label
                : ''
        })
        setCheck([])
    }, [metricId, menu]);

    useEffect(() => {
        getActiveMetrics(id)
            .then(res => {
                setMenu(res.data.data)
                const stm = props.location.search.split('visible=')[1]
                if (stm) {
                    setOutputMetric(1)
                    res.data.data.map((item, index) => {
                        if (item.metric === stm) {
                            setMetricId([index, 0])
                            setMetric(stm)
                            setCategory(item.category)
                        } else if (item.childs) {
                            item.childs.map((elem, index2) => {
                                if (elem.metric === stm) {
                                    setMetricId([index, index2])
                                    setMetric(stm)
                                    setCategory(item.category)
                                } else if (elem.childs) {
                                    elem.childs.map((elem2) => {
                                        if (elem2.metric === stm) {
                                            setMetricId([index, index2])
                                            setMetric(stm)
                                            setCategory(item.category)
                                        }
                                        return ''
                                    })
                                }
                                return ''
                            })
                        }
                        return ''
                    })
                } else {
                    setLabel('res.data.data[0].label')
                    setMetricId([0, 0])
                    setMetric(() => {
                        return (
                            res.data.data[0].metric || res.data.data[0].childs[0].metric
                        )
                    })
                    setCategory(res.data.data[0].category)
                }
            })
    }, [id, props.location.search])

    useEffect(() => {
        const startDate = start_date === '  /  /    ' ? null : start_date;
        const endDate = end_date === '  /  /    ' ? null : end_date;
        if (startDate && endDate) loadData();
    }, [start_date, end_date, metricId]);

    useEffect(() => {
        getFirstDate();
    }, [metric]);

    const [protocol, setProtocol] = useState([]);

    const loadProtocol = () => {
        setLoader(true)
        getProtocol(metric)
            .then(res => {
                setLoader(false)
                setProtocol(res.data.data)
                setModalProtocol(true)
            })
            .catch(({ response }) => {
                setLoader(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                }
            })
    }

    const getFirstDate = () => {
        setLoader(true)
        getGraphData(id, {
            start: '01/01/2000',
            end: moment(new Date()).format('MM/DD/YYYY'),
            type: metric
        })
            .then(res => {
                setLoader(false)
                if (res.data.data.metrics[0]) {
                    set_start_date(res.data.data.metrics[0].date);
                    set_end_date(moment().format('MM/DD/YYYY'));
                } else {
                    loadData()
                }
            })
            .catch(() => {
                setLoader(false)
            })
    }

    const loadData = () => {
        setGrafData([])
        if (metric) {
            setLoader(true)
            getGraphData(id, {
                start: start_date,
                end: end_date,
                type: metric
            })
                .then(res => {
                    setLoader(false)
                    setGrafData(res.data.data)
                })
                .catch(({ response }) => {
                    setLoader(false)
                    if (response && (response.status === 401)) {
                        setLogout(<Logout />)
                    }
                })
            getRecentMetric(id, metric)
                .then(res => {
                    setResentMetrics(res.data.data.metrics || '')
                    setRecentMetricsLabel(res.data.data.label || '')
                    setComparison(res.data.data.comparison ? res.data.data.comparison : [])
                })
                .catch(({ response }) => {
                    if (response && (response.status === 401)) {
                        setLogout(<Logout />)
                    }
                })
        }
    };

    const [color, setColor] = useState(['54, 105, 255, ', '255, 162, 85, '])

    // eslint-disable-next-line
    const getColor = () => {
        const randColor = () => `${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},${Math.floor(Math.random() * 255)},`
        let rand = [...color]
        data.datasets.forEach(() => rand.push(randColor()))
        setColor(rand)
    };

    const datasets = {
        fill: true,
        lineTension: 0.1,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
    }

    const [grafData, setGrafData] = useState([])

    const data = {
        labels: grafData && grafData.metrics ? grafData.metrics.map(item => item.date) : [],
        datasets: [
            {
                ...datasets,
                label: grafData.label || '',
                backgroundColor: `rgba(${color[0]}0.4)`,
                borderColor: `rgba(${color[0]}1)`,
                pointBorderColor: `rgba(${color[0]}1)`,
                data: grafData && grafData.metrics && grafData.metrics.map((item, index, arr) => {
                    return (!!item.result || item.result === 0)
                        ? item.result
                        : null
                })
            },
            {
                ...datasets,
                label: grafData.goal_label || '',
                backgroundColor: `rgba(${color[1]}0.4)`,
                borderColor: `rgba(${color[1]}1)`,
                pointBorderColor: `rgba(${color[1]}1)`,
                data: grafData && grafData.metrics && grafData.metrics.map((item, index, arr) => {
                    return (!!item.goal || item.goal === 0)
                        ? item.goal
                        : null
                })
            }
        ]
    };

    const [modalProtocol, setModalProtocol] = useState(false)
    const [modalAddMetrics, setModalAddMetrics] = useState(false)
    const [modalAddGoals, setModalAddGoals] = useState(false)
    const [modalUpdateMetrics, setModalUpdateMetrics] = useState(false)
    const [modalUpdateGoals, setModalUpdateGoals] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [show, setShow] = useState(10)
    const [currentPage, setCurrentPage] = useState(1)
    const [typeOutputMetric, setOutputMetric] = useState(0)

    const getPdf = () => {
        return `/report/${id}/name/${metric}`
    }

    const ChangeMetricOutput = () => {
        return (
            <div className="toolbarm">
                <div className={`toolbarm-elem ${typeOutputMetric === 0 ? 'active' : ''}`} onClick={() => setOutputMetric(0)}>
                    <EqualizerIcon />
                </div>
                <div className={`toolbarm-elem ${typeOutputMetric === 1 ? 'active' : ''}`} onClick={() => setOutputMetric(1)}>
                    <FormatAlignJustifyIcon />
                </div>
            </div>
        )
    }

    const MetricChart = ({ display }) => {
        const ref = useRef();

        useEffect(() => {
            let min = Math.min.apply([], grafData && grafData.metrics && grafData.metrics.map((item) => {
                return Math.min.apply([], [parseFloat(item.result || 0), parseFloat(item.goal || 0)].filter(item => (item === 0) || !!item))
            }))
            let max = Math.max.apply([], grafData && grafData.metrics && grafData.metrics.map((item) => {
                return Math.max.apply([], [parseFloat(item.result || 0), parseFloat(item.goal || 0)].filter(item => (item === 0) || !!item))
            }))
            const chart = new Chart(ref.current, {
                data,
                type: "line",
                options: {
                    spanGaps: true,
                    maintainAspectRatio: false,
                    legend: {
                        position: "top",
                        align: 'end',
                        labels: {
                            align: 'end'
                        }
                    },
                    aspectRatio: 2.6,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                drawOnChartArea: false
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                min: min,
                                max: max + 10
                            },
                            gridLines: {
                                drawOnChartArea: false
                            }
                        }]
                    },

                    animation: false,
                    tooltips: {
                        enabled: true,
                        intersect: false,
                        mode: "nearest",
                        bodySpacing: 5,
                        yPadding: 10,
                        xPadding: 10,
                        caretPadding: 0,
                        displayColors: false,
                        backgroundColor: "#1e1e2d",
                        titleFontColor: "#ffffff",
                        cornerRadius: 4,
                        footerSpacing: 0,
                        titleSpacing: 0
                    }
                }
            });

            return () => {
                chart.destroy();
            };
        }, [data]);

        return (
            <div style={{ display: display ? 'block' : 'none' }}>
                <div className="row">
                    <div className="col action-row">
                        <ButtonToolbar className="btn-toolbar-margin">

                            <Dropdown>
                                <Dropdown.Toggle>
                                    Add Data
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setModalAddMetrics(true)}>Metrics</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setModalAddGoals(true)}>Goals</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                            <Button
                                variant="primary"
                                onClick={() => setOutputMetric(1)}
                            >Update Data</Button>

                            <div>
                                <Link to={getPdf()} target="_blank">
                                    <Button
                                        variant="primary"
                                    >Print</Button>
                                </Link>
                            </div>

                            <Button
                                variant="primary"
                                onClick={() => loadProtocol()}
                            >Protocol</Button>
                        </ButtonToolbar>

                        <div className="data">
                            <div>
                                <DatePicker
                                    label="Start"
                                    future={true}
                                    format={'MM/DD/YYYY'}
                                    value={start_date}
                                    onChange={set_start_date}
                                />
                            </div>
                            <div>
                                <DatePicker
                                    label="End"
                                    future={true}
                                    format={'MM/DD/YYYY'}
                                    value={end_date}
                                    onChange={set_end_date}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div style={{ height: '400px' }}>
                            <canvas
                                ref={ref}
                                id="kt_chart_order_statistics"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const MetricTable = ({ display }) => {
        return (
            <div style={{ display: display ? 'block' : 'none' }}>
                <MetricTableChild
                    data={grafData}
                    start_date={start_date}
                    end_date={end_date}
                    set_start_date={set_start_date}
                    set_end_date={set_end_date}
                    loadProtocol={loadProtocol}
                    id={id}
                    metric={metric}
                    category={category}
                    setModalAddGoals={setModalAddGoals}
                    setModalAddMetrics={setModalAddMetrics}
                    setModalUpdateMetrics={setModalUpdateMetrics}
                    setModalUpdateGoals={setModalUpdateGoals}
                    check={check}
                    setCheck={setCheck}
                    setModalDelete={setModalDelete}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    show={show}
                    setShow={setShow}
                />
            </div>
        )
    };

    const deleteMetric = () => {
        setLoader(true)
        deleteTesting(id, {
            id: check,
            metric: metric
        })
            .then(res => {
                setLoader(false)
                setCheck([])
                setModalDelete(false)
                loadData()
                dispatch(AlertState.actions.alert({
                    text: 'Data is deleted',
                    variant: true
                }));
            })
            .catch(({ response }) => {
                response &&
                    response.data &&
                    dispatch(AlertState.actions.alert({
                        text: response.data.message || response.data.error,
                        variant: false
                    }));
                setLoader(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                }
            })
    };

    const GetContent = () => {
        const inputLabel = useRef();
        const [labelWidth, setLabelWidth] = useState(0);

        useEffect(() => {
            inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
        }, [inputLabel, metricId]); // eslint-disable-line

        return (
            <div style={{ width: '100%' }}>
                <Portlet>
                    <PortletHeader
                        title={label}
                        toolbar={<ChangeMetricOutput />}
                    />
                    <PortletBody>
                        {
                            metricId[0] !== null &&
                            metricId[1] !== null &&
                            menu &&
                            menu[metricId[0]].childs &&
                            menu[metricId[0]].childs[metricId[1]].childs &&
                            <div className="row" style={{ marginBottom: 10 }}>
                                <div className="col">
                                    <FormControl variant="outlined" className='formControl select-metric'>
                                        <InputLabel ref={inputLabel}>SELECT CALCULATION:</InputLabel>
                                        <Select
                                            value={metric}
                                            input={<OutlinedInput labelWidth={labelWidth} />}
                                            onChange={e => setMetric(e.target.value)}
                                        >
                                            {
                                                menu &&
                                                menu[metricId[0]].childs &&
                                                menu[metricId[0]].childs[metricId[1]].childs &&
                                                menu[metricId[0]].childs[metricId[1]].childs.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.metric}>{item.label}</MenuItem>
                                                    )
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                        }
                        <MetricChart display={typeOutputMetric === 0} />
                        <MetricTable display={typeOutputMetric === 1} />
                    </PortletBody>
                </Portlet>

                <Portlet>
                    <PortletHeader title="RECENT METRICS" />
                    <PortletBody>
                        <div className="wrap-setting">
                            <ResentMetricsTable
                                data={resentMetrics}
                                label={recentMetricsLabel}
                                label2={label}
                            />
                        </div>
                    </PortletBody>
                </Portlet>
                {
                    !!comparison.length && (
                        <Portlet>
                            <PortletHeader title="COMPARISON" />
                            <PortletBody>
                                <div className="wrap-setting">
                                    <CompariosTable
                                        data={comparison}
                                    />
                                </div>
                            </PortletBody>
                        </Portlet>
                    )
                }
            </div>
        )
    };

    const getUser = data => {
    }

    return (
        <StyledMetrics>

            {redirect && <Redirect to={`/profile/${id}/info`} />}

            <ModalProtocol
                modal={modalProtocol}
                setModal={setModalProtocol}
                data={protocol}
                label={label}
            />

            <ModalAddMetrics
                id={id}
                metric={metric}
                modal={modalAddMetrics}
                setModal={setModalAddMetrics}
                label={label}
                loadData={loadData}
                category={category}
            />

            <ModalUpdateMetrics
                id={id}
                metric={metric}
                modal={modalUpdateMetrics}
                setModal={setModalUpdateMetrics}
                label={label}
                loadData={loadData}
                category={category}
                check={check}
                setCheck={setCheck}
            />

            <ModalAddGoals
                id={id}
                metric={metric}
                modal={modalAddGoals}
                setModal={setModalAddGoals}
                label={label}
                loadData={loadData}
            />

            <ModalUpdateGoals
                id={id}
                metric={metric}
                modal={modalUpdateGoals}
                setModal={setModalUpdateGoals}
                label={label}
                loadData={loadData}
                category={category}
                check={check}
                setCheck={setCheck}
            />

            <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete selected data?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalDelete(false)}>
                        No
                    </Button>
                    <Button variant="primary" onClick={deleteMetric}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            {logout}

            <Loader visible={loader} />

            <div className="row flex-md-row-reverse" style={{ marginBottom: '20px' }}>
                <DataOutput id={id} />
                <UserInfo id={id} getUser={getUser} />
            </div>

            <Portlet>
                <PortletBody>
                    <ButtonToolbar className="custom-btn acord-none-mob" style={{ width: '90%' }}>
                        {menu.map((item, index) => {
                            return (
                                <div
                                    variant="light"
                                    key={item.label}
                                    style={{
                                        margin: '0 5px',
                                        borderBottom: item.category === category ? '2px solid #5d78ff' : '',
                                        fontSize: '12px',
                                        fontWeight: '700',
                                        padding: '6px 12px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <TabNav
                                        key={index}
                                        id={index}
                                        item={item}
                                        setId={setMetricId}
                                        metric={metric}
                                        setMetric={setMetric}
                                        category={category}
                                        setCategory={setCategory}
                                    />
                                </div>
                            )
                        })}
                    </ButtonToolbar>

                    <div className="acord-none-mob" style={{ flexDirection: 'column' }}>
                        <GetContent />
                    </div>

                    <div className="acord-none-dt" style={{ flexDirection: 'column' }}>
                        {menu.map((item, index) => {
                            return <TabNavMobile
                                key={index}
                                index={index}
                                keys={key}
                                setKey={setKey}
                                item={item}
                                Content={GetContent}
                                setMetric={setMetric}
                                setId={setMetricId}
                            />
                        })}
                    </div>
                </PortletBody>
            </Portlet>
        </StyledMetrics>
    )
};

const StyledMetrics = styled.div`
    .btn {
        margin-bottom: 10px;
    }

    .custom-btn {
        margin-bottom: 20px;

        .btn {
            padding: 0;
        }
    }

    .btn-toolbar-margin {
        & > div,
        & > button {
            margin-right: 15px;
        }
    }
    
    .select-metric {
        min-width: 300px;
    }

    .action-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .data {
        display: flex;
        flex-wrap: wrap;

        & > div {
            margin: 0 20px 0 0;
        }

        .MuiFormControl-marginNormal {
            margin: 0;
        }
    }

    .toolbarm {
        display: flex;
        align-items: center;

        &-elem {
            border-radius: 4px;
            padding: 3px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.active {
                background: #e5e9f0;
            }
        }
    }
`;
