import React, { useState } from 'react';
import {
    Button,
    Modal
} from 'react-bootstrap';
import Dropdown from '../../../../components/DropdownComponent';
import Loader from '../../../../components/Loader';
import { getProgramsTree } from '../../../../crud/library.crud';
import '../../style.scss';
import './add-to-program-modal.scss';

export const ChooseProgramModal = ({
    chooseProgramModal,
    setChooseProgramModal,
    setAddProgramModal,
    selectedProgram,
    setSelectedProgram,
}) => {
    const [loader, setLoader] = useState(false);
    const [options, setOptions] = useState([]);

    const loadData = search => {
        setLoader(true);
        getProgramsTree({ search, page: 1, perPage: 500 })
            .then(res => {
                setOptions(res.data.programs.filter(elem => {
                    return elem.title !== 'Unattached' &&
                        elem.workouts.length > 0
                }));
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            })
    };

    const handleCountinue = () => {
        setChooseProgramModal(false);
        setAddProgramModal(true);
    };

    return (
        <div className='add-to-program-modal'>
            <Loader visible={loader} />
            <Modal
                show={chooseProgramModal}
                onHide={e => {
                    setChooseProgramModal(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>All Programs</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='dropdown-container add-to-program-modal-dropdown' style={{ height: '36px' }}>
                        <Dropdown
                            width="100%"
                            name="access"
                            label="Search Program"
                            value={selectedProgram}
                            fetchOptions={loadData}
                            options={options}
                            setFormData={val => setSelectedProgram(val)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        className="btn-blue"
                        onClick={handleCountinue}
                        disabled={!selectedProgram}
                    >Continue</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
};
