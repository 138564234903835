export const generateImageFromText = (text, fontFamily, fontSize = "30px") => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const width = 300; // ширина вашего canvas
  const height = 100; // высота вашего canvas

  canvas.width = width;
  canvas.height = height;

  context.fillStyle = "white";
  context.fillRect(0, 0, width, height);

  context.font = `${fontSize} ${fontFamily}`;
  context.fillStyle = "black";
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(text, width / 2, height / 2);

  return canvas.toDataURL("image/png");
};

export const dataURLToBlob = (dataURL) => {
  const [header, data] = dataURL.split(",");
  const byteString = atob(data);
  const mimeString = header.split(":")[1].split(";")[0];

  const buffer = new ArrayBuffer(byteString.length);
  const dataArray = new Uint8Array(buffer);

  for (let i = 0; i < byteString.length; i++) {
    dataArray[i] = byteString.charCodeAt(i);
  }

  return new Blob([buffer], { type: mimeString });
};
