import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import DatePicker from '../../../components/DatePicker'
import { Link } from 'react-router-dom'
import {
    ButtonToolbar,
    Button,
    Dropdown,
    Pagination,
    // Modal
} from 'react-bootstrap'
import {
    Checkbox,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    OutlinedInput
} from '@material-ui/core/';
import ArrowDown from '@material-ui/icons/ArrowDropDown';
import moment from 'moment'
// import Loader from '../../../components/Loader'

export default ({
    data,
    start_date,
    end_date,
    set_start_date,
    set_end_date,
    setModalDelete,
    loadProtocol,
    id,
    metric,
    category,
    setModalAddGoals,
    setModalAddMetrics,
    setModalUpdateMetrics,
    setModalUpdateGoals,
    check,
    setCheck,
    currentPage,
    setCurrentPage,
    show,
    setShow
}) => {
    const defRow = [
        {
            label: "Test Date",
            value: 'date'
        },
        {
            label: `Metric Value`,
            value: 'result'
        },
        {
            label: "Goal Date",
            value: 'date'
        },
        {
            label: `Goal Value`,
            value: 'goal'
        }
    ]

    const [sort, setSort] = useState(["date", "asc"])
    const [totelPage, setTotalPage] = useState(0)
    const [totalRow, setTotalRow] = useState(0)

    useEffect(() => {
        if (data && data.metrics && data.metrics.length) {
            setTotalRow(data.metrics.length)
            setTotalPage(Math.floor(data.metrics.length / show) + 1)
        }
    }, [data, show]);

    const sortEdit = item => {
        setSort([item.value, `${sort[1] === 'asc' ? 'desc' : 'asc'}`])
    };

    const statusAll = () => {
        const datat = (data && data.metrics) ? filterData() : [];
        return check.length === datat.length
    };

    const filterData = () => {
        let temp = (data && data.metrics) ? [...data.metrics] : []
        if (sort[0] === 'date') {
            temp = temp.sort((prev, next) => moment(prev[sort[0]], 'MM/DD/YYYY').format('YYYYMMDD') > moment(next[sort[0]], 'MM/DD/YYYY').format('YYYYMMDD') ? 1 : -1)
        } else {
            temp = temp.sort((prev, next) => parseInt(prev[sort[0]]) > parseInt(next[sort[0]]) ? 1 : -1)
        }

        if (sort[1] === 'desc') {
            temp = temp.reverse()
        }
        return temp.slice((currentPage - 1) * show, currentPage * show)
    };

    const editPagination = (page) => {
        setCurrentPage(page)
        setCheck([])
    };

    const getCheck = (item) => check.findIndex(id => id === item.id) !== -1

    const chbAll = () => {
        if (check.length === filterData().length) {
            setCheck([])
        } else {
            setCheck(filterData().map(item => item.id))
        }
    };

    const editCheck = (item) => {
        let temp = [...check]
        temp.splice(check.indexOf(item.id), 1)
        getCheck(item)
            ? setCheck(temp)
            : setCheck([...check, item.id])
    };

    const deleteData = () => {
        setModalDelete(true)
    };

    const inputLabel = useRef();
    const [labelWidth, setLabelWidth] = useState(0);

    useEffect(() => {
        inputLabel.current && setLabelWidth(inputLabel.current.offsetWidth);
    }, [inputLabel]);

    // const [loader, setLoader] = useState(false)

    const getPdf = () => {
        return `/report/${id}/name/${metric}`
    };

    return (
        <Table>
            <div className="row">
                {/* <Loader visible={loader} /> */}
                <div className="col action-row">
                    <ButtonToolbar className="btn-toolbar-margin">

                        {check.length === 0 && (
                            <Dropdown>
                                <Dropdown.Toggle>
                                    Add Data
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setModalAddMetrics(true)}>Metrics</Dropdown.Item>
                                    <Dropdown.Item onClick={() => setModalAddGoals(true)}>Goals</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        )}
                        {check.length === 0 && (
                            <div>
                                <Link to={getPdf()} target="_blank">
                                    <Button
                                        variant="primary"
                                    >
                                        Print
                                    </Button>
                                </Link>
                            </div>
                        )}
                        {check.length === 0 && (
                            <Button
                                variant="primary"
                                onClick={() => loadProtocol()}
                            >Protocol</Button>
                        )}
                        {
                            check.length === 1 && (
                                <Dropdown>
                                    <Dropdown.Toggle>
                                        Update Data
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => setModalUpdateMetrics(true)}>Metrics</Dropdown.Item>
                                        <Dropdown.Item onClick={() => setModalUpdateGoals(true)}>Goals</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )
                        }
                        {
                            check.length > 0 && (
                                <Button
                                    variant="primary"
                                    onClick={deleteData}
                                >Delete Data</Button>
                            )
                        }
                    </ButtonToolbar>

                    <div className="data">
                        <div>
                            <DatePicker
                                label="Start"
                                future={true}
                                format={'MM/DD/YYYY'}
                                value={start_date}
                                onChange={set_start_date}
                            />
                        </div>
                        <div>
                            <DatePicker
                                label="End"
                                future={true}
                                format={'MM/DD/YYYY'}
                                value={end_date}
                                onChange={set_end_date}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col settingtable nowrap">
                    <table>
                        <thead>
                            <tr>
                                <td>
                                    <Checkbox
                                        checked={statusAll()}
                                        onChange={chbAll}
                                        color="primary"
                                    />
                                </td>
                                {
                                    defRow.map((item, index) => {
                                        return (
                                            <td key={index} onClick={() => sortEdit(item)} className={`${item.left ? 'left-content' : ''}${sort[0] === item.value ? ' active' : ''}`}>
                                                {item.label}
                                                <ArrowDown
                                                    className={`sort-arrow${sort[0] === item.value ? ' active' : ''}${sort[0] === item.value && sort[1] === 'desc' ? ' reverse' : ''}`}
                                                />
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                filterData().map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Checkbox
                                                    checked={getCheck(item)}
                                                    onChange={() => editCheck(item)}
                                                    color="primary"
                                                />
                                            </td>
                                            <td>
                                                {item.date}
                                            </td>
                                            <td>
                                                {item.result}
                                            </td>
                                            <td>
                                                {item.date}
                                            </td>
                                            <td>
                                                {item.goal}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    {
                        data && data.metrics && data.metrics.length === 0
                            ? (
                                <div
                                    style={{
                                        margin: '20px auto',
                                        textAlign: 'center'
                                    }}
                                >
                                    No data available in table
                                </div>
                            ) : null
                    }
                </div>
                {
                    data && data.metrics && data.metrics.length !== 0
                        ? (
                            <div className="pagination-wrap">
                                <Pagination>
                                    <Pagination.First onClick={() => editPagination(1)} disabled={currentPage === 1} />
                                    <Pagination.Prev onClick={() => editPagination(currentPage - 1)} disabled={currentPage === 1} />

                                    <Pagination.Item>{currentPage}</Pagination.Item>

                                    <Pagination.Next onClick={() => editPagination(currentPage + 1)} disabled={currentPage === totelPage} />
                                    <Pagination.Last onClick={() => editPagination(totelPage)} disabled={currentPage === totelPage} />
                                </Pagination>
                                <div className="pagination-show">
                                    <FormControl variant="outlined" className="formControl">
                                        <InputLabel ref={inputLabel}>Show Entries</InputLabel>
                                        <Select
                                            value={show.toString()}
                                            input={<OutlinedInput labelWidth={labelWidth} />}
                                            onChange={e => {
                                                setShow(parseInt(e.target.value))
                                                setCurrentPage(1)
                                            }}
                                        >
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                            <MenuItem value={50}>50</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <Typography variant="body1" gutterBottom>
                                        {
                                            `Showing ${(currentPage - 1) * show + 1} - ${currentPage * show > totalRow ? totalRow : currentPage * show} fo ${totalRow}`
                                        }
                                    </Typography>
                                </div>
                            </div>
                        ) : null
                }
            </div>
        </Table>
    )
}



const Table = styled.div`
    td.active {
        font-weight: 600;
    }
    
    .sort-arrow {
        opacity: 0.7;
        transition: all .3s ease-out;

        &.active {
            opacity: 1;
        }

        &.reverse {
            transform: rotate(180deg)
        }
    }

    .pagination {
        margin: 0;

        &-wrap {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            .MuiSelect-select {
                padding: 7px;
            }
        }

        &-show {
            display: flex;
            align-items: center;
        }
    }

    .formControl {
        width: 150px;
        margin: 5px 10px;
    }
`