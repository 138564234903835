import React from 'react';
import { Checkbox } from '@material-ui/core';

export const TableRow = ({ item, check, setCheck, filterObject, onClick }) => {
    const handleCheck = item => {
        const index = check.findIndex(elem => elem.id === item.id);
        if (index !== -1) {
            setCheck(prev => {
                let arr = [...prev];
                arr.splice(index, 1);
                return arr;
            })
        } else {
            setCheck(prev => ([...prev, { id: item.id, is_editable: item.is_editable }]))
        }
    };

    return (
        <tr key={item.id}>
            <td>
                <Checkbox
                    color="primary"
                    onChange={() => handleCheck(item)}
                    checked={check.find(elem => elem.id === item.id) !== undefined}
                />
            </td>

            {filterObject['Exercise Name'] &&
                <td onClick={onClick} className='left-content pointer'>
                    {item.title}
                </td>
            }
            {filterObject['Body Segment'] &&
                <td onClick={onClick} className='left-content pointer'>
                    {item.body_segment ? item.body_segment.title : '--'}
                </td>
            }
            {filterObject['Primary Muscle(s)'] &&
                <td onClick={onClick} className='left-content pointer'>
                    {item.muscle ? item.muscle.title : '--'}
                </td>
            }
            {filterObject['Equipment'] &&
                <td onClick={onClick} className='left-content pointer'>
                    {item.equipment ? item.equipment.title : '--'}
                </td>
            }
            {filterObject['Mechanics'] &&
                <td onClick={onClick} className='left-content pointer'>
                    {item.mechanic ? item.mechanic.title : '--'}
                </td>
            }
            {filterObject['Force'] &&
                <td onClick={onClick} className='left-content pointer'>
                    {item.force ? item.force.title : '--'}
                </td>
            }
            {filterObject['Units of Measure'] &&
                <td onClick={onClick} className='left-content pointer'>
                    {item.variables.length > 0
                        ? item.variables
                            .map(elem => elem.charAt(0).toUpperCase() + elem.slice(1))
                            .join(', ')
                        : '--'}
                </td>
            }
        </tr>
    )
};
