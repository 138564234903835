import React, { useState, useEffect } from 'react';
import { getProgramList, getProgram } from '../../../../crud/profile.crud';
import { Redirect } from 'react-router-dom';
import Logout from '../../../auth/Logout';
import {
    Portlet,
    PortletBody,
    PortletHeader,
} from "../../../../partials/content/Portlet";
import { Modal, Button } from 'react-bootstrap';
import ModalBodyInfo from '../../ProgramTabs/ModalProgram';
import Loader from "../../../../components/Loader";
import './references.scss';

export const References = ({ id }) => {
    const [loaderProgram, setLoaderProgram] = useState(false);
    const [loaderProgramList, setLoaderProgramList] = useState(false);
    const [logout, setLogout] = useState(null);
    const [data, setData] = useState(null);
    const [currentModal, setCurrentModal] = useState({});
    const [modal, setModal] = useState(false);
    const [keyList, setKeyList] = useState([]);

    useEffect(() => {
        currentModal.title && setModal(true);
    }, [currentModal]);

    useEffect(() => {
        setLoaderProgramList(true)
        getProgramList(id)
            .then(res => {
                setLoaderProgramList(false);
                setKeyList(res.data.data.map(elem => elem.name));
            })
            .catch(({ response }) => {
                setLoaderProgramList(false)
                if (response && (response.status === 401)) {
                    setLogout(<Logout />)
                } else if (response && (response.status === 403)) {
                    setLogout(<Redirect to="/profile-list" />)
                }
            })
    }, []);

    useEffect(() => {
        if (keyList.length) {
            keyList.forEach(keyName => {
                setLoaderProgram(true)
                getProgram(id, keyName)
                    .then(res => {
                        setLoaderProgram(false);
                        setData(prev => ({
                            ...prev,
                            [keyName]: res.data.data,
                        }))
                    })
                    .catch(({ response }) => {
                        setLoaderProgram(false)
                        if (response && (response.status === 401)) {
                            setLogout(<Logout />)
                        } else if (response && (response.status === 403)) {
                            setLogout(<Redirect to="/profile-list" />)
                        }
                    })
            });
        }
    }, [keyList]);

    const maxCol = item => {
        let maxIndex = 0
        item.cols.forEach((elem, index) => {
            if (elem.values.length > maxIndex) {
                maxIndex = index
            }
        })
        return item.cols[maxIndex].values
    };

    const getData = (module) => {
        if (data && data[module]) {
            return data[module].map((item, index2) => {
                return (
                    <div className={data[module].length === 1 ? `col-12` : data[module].length === 2 ? 'col-12 col-md-6' : 'col-12 col-md-6 col-xl-4'} key={`${index2}`}>
                        <Portlet>
                            <PortletHeader title={item.title} />
                            <PortletBody className={`${data[module].length === 1 && 'settingtable'}`}>
                                <table className="settingtable">
                                    <thead>
                                        <tr>
                                            {item.cols.map((title, index3) => {
                                                return (
                                                    <td
                                                        key={`${index2}${index3}`}
                                                        style={{ textAlign: item.title === 'Corrective Exercises' || item.title === 'Muscular Imbalance' ? 'center' : '' }}
                                                    >
                                                        {title.title}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {maxCol(item).map((label, index3) => {
                                            return (
                                                <tr key={`${index2}${index3}`}>
                                                    {item.cols.map((col, index4) => {
                                                        return typeof col.values[index3] !== 'object'
                                                            ? (
                                                                <td
                                                                    className={index4 > 0 ? "theme-data" : ''}
                                                                    key={`${index2}${index3}${index4}`}
                                                                    style={{ textAlign: item.title === 'Corrective Exercises' || item.title === 'Muscular Imbalance' ? 'center' : '' }}
                                                                >
                                                                    {col.values[index3]}
                                                                </td>
                                                            )
                                                            : (
                                                                <td
                                                                    className={col.values[index3] && col.values[index3].title ? "theme-data" : ''}
                                                                    key={`${index2}${index3}${index4}`}
                                                                    style={{
                                                                        textAlign: item.title === 'Corrective Exercises' || item.title === 'Muscular Imbalance' ? 'center' : '',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => setCurrentModal(col.values[index3])}
                                                                >
                                                                    {!!col.values[index3] && (col.values[index3].name || '')}
                                                                </td>
                                                            )
                                                    })}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </PortletBody>
                        </Portlet>
                    </div>
                )
            })
        };
        return <></>;
    };

    return (
        <>
            {logout}
            <Loader visible={loaderProgram || loaderProgramList} />

            <Modal
                size="lg"
                show={modal}
                onHide={e => {
                    setModal(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{currentModal.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalBodyInfo data={currentModal} />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={e => {
                            setModal(false)
                        }}
                    >
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>

            {keyList.map(elem => (
                getData(elem)
            ))}
        </>
    )
};
