import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Button } from 'react-bootstrap';
import { SpacerHorizontal } from '../../../../../components/Spacer';
import Dropdown from '../../../../../components/DropdownComponent';
import * as AlertState from '../../../../../../app/store/ducks/auth.duck';
import Loader from '../../../../../components/Loader';
import {  fetchEventsListTiny } from '../../../../../crud/dashboard.crud';
import { useDispatch, useSelector } from "react-redux";
import { postAddMapping, fetchExternals } from '../../../../../crud/crm.crud';

const AddEventModal = ({
    modal,
    setModal,
    getMappings,
}) => {
    const [formData, setFormData] = useState({});
    const [crmEventsList, setCrmEventsList] = useState([]);
    const [trainerMetricsEventList, seTtrainerMetricsEventList] = useState([]);
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);
    const [tmLoader, setTmLoader] = useState(false);
    const crm = useSelector(state => state.user_info.CRM_list.find(elem => elem.is_activated === true));
    const dispatch = useDispatch();
    const accountId = useSelector(state => state.appData?.account?.id);

    const loadData = search => {
        if (!accountId || !crm) return;
        setLoader(true);
        fetchExternals(accountId, crm.id, 'events', {
            page: 1,
            limit: 500,
            search,
        })
            .then(res => {
                const list = res.data.list
                    .map(elem => ({ ...elem, value: elem.id }))
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                })
                    .filter(elem => elem.is_mapped === false)
                setCrmEventsList(sorted);
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => setLoader(false))
    };

    const loadTMEvents = search => {
        setTmLoader(true);
        fetchEventsListTiny(accountId, {"date_from": "2022-01-01"})
            .then(res => {
                const list = res.data.list.map(elem => ({ ...elem, title: elem.title, value: elem.id }));
                const sorted = list.sort((a, b) => {
                    let first = a.title.toLowerCase();
                    let second = b.title.toLowerCase();
                    if (first < second) { return -1; }
                    if (first > second) { return 1; }
                    return 0;
                })
                seTtrainerMetricsEventList(sorted)
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => setTmLoader(false))
    };

    const handleClose = () => {
        setModal(false);
        setErrors({});
        setFormData({});
    };

    const validate = () => {
        setErrors({});
        let isValid = true;

        if (!formData.external_id) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    external_id: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        if (!formData.internal_id) {
            isValid = false;
            setErrors(prev => {
                return {
                    ...prev,
                    internal_id: {
                        error: true,
                        helperText: 'Field is required',
                    }
                }
            })
        };

        return isValid;
    };

    const handleSave = () => {
        if (validate()) {
            setLoader(true);
            const data = {
                internal_id: formData.internal_id?.value || null,
                external_id: formData.external_id?.value + '' || ''
            };
            postAddMapping(accountId, crm.id, 'events', data)
                .then(() => {
                    loadData();
                    handleClose();
                    getMappings();
                    dispatch(AlertState.actions.alert({
                        text: 'Mapping added',
                        variant: true,
                    }));
                })
                .catch(err => {
                    let errText = 'request failed';
                    if (err.response?.data?.error) {
                        errText = err.response?.data?.error;
                    } else if (err.response?.data?.message) {
                        errText = err.response.data.message;
                    };
                    dispatch(AlertState.actions.alert({
                        text: errText,
                        variant: false,
                    }));
                })
                .finally(() => setLoader(false))
        }
    };

    return (
        <Modal show={modal !== false} onHide={handleClose}>
            <Loader visible={loader || tmLoader} />

            <StyledAddUserModal>
                <div
                    className="close-button"
                    onClick={handleClose}
                >×</div>
                <Modal.Title>
                    <h5 className="title">New Event Mapping</h5>
                </Modal.Title>

                <SpacerHorizontal height="25px" />

                <Modal.Body>
                    <div className="crm-dropdown">
                        <Dropdown
                            label="Start typing to search CRM Event"
                            error={errors.external_id?.error}
                            options={crmEventsList}
                            setFormData={val => {
                                setFormData(prev => ({ ...prev, external_id: val }));
                                setErrors(prev => ({ ...prev, external_id: {} }));
                            }}
                            name="CRM Event"
                            value={formData.external_id}
                            width={'100%'}
                            helperText={errors.external_id?.helperText}
                            fetchOptions={loadData}
                        />
                    </div>

                    <div className="crm-dropdown">
                        <Dropdown
                            label="TrainerMetrics Event"
                            options={trainerMetricsEventList}
                            setFormData={val => {
                                setFormData(prev => ({ ...prev, internal_id: val }));
                                setErrors(prev => ({ ...prev, internal_id: {} }))
                            }}
                            name="TrainerMetrics Event"
                            value={formData.internal_id}
                            width={'100%'}
                            error={errors.internal_id?.error}
                            helperText={errors.internal_id?.helperText}
                            fetchOptions={loadTMEvents}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSave}
                        className="btn-blue"
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </StyledAddUserModal>
        </Modal >
    )
};

const StyledAddUserModal = styled.div`
    .close-button {
        position: absolute;
        top: 15px;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        color: #696890;
        margin: 25px 0 0 25px;
        font-size: 20px;
    }

    .modal-body {
        padding: 25px 25px 0px 25px;
        border-top: 1px solid #ebedf2;
    }

    .update-link {
        cursor: pointer;
        color: blue;
    }
`;

export default AddEventModal;
