export const thousandSeparator = (str, isAfterDotShow) => {
  str = str + "";
  const isNegative = +str < 0 || str === "-";
  if (isNegative) str = str.replace(/-/g, "");

  let parts = str?.replaceAll(",", "")?.split(".");
  if (parts[0]) parts[0] = "" + +parts[0];
  let main = parts[0],
    len = main?.length,
    output = "",
    i = len - 1;

  while (i >= 0) {
    output = main.charAt(i) + output;
    if ((len - i) % 3 === 0 && i > 0) {
      output = "," + output;
    }
    --i;
  }

  if (parts?.length > 1) {
    if (parts[1]?.length > 2) parts[1] = parts[1].replace(/.$/, "");
    /*  if (+parts[1] !== 0 || isAfterDotShow) {
      // if (!isAfterDotShow && parts[1]?.length < 2) parts[1] += '0'
    } */
    // Logic for remove second zero after dot
    // if (parts[1]?.length === 2 && parts[1][1] === '0') {
    //   parts[1] = parts[1].slice(0, -1)
    // }
    output += "." + parts[1];
    /* if(+parts[1] !== 0 || !isAfterDotShow){
      if (parts[1]?.length < 2) parts[1] += '0'
    } */
  }

  if (isNegative) output = "-" + output;
  return output.replace(/\.00$/, "");
};

export const priceToState = (price, allowNegative) => {
  let newPrice = `${price}`.trim();
  const isNegative = /^-/.test(newPrice);
  newPrice = newPrice.replace(/[^\d.]/g, "");
  newPrice = newPrice.replace(/(\.\d{2})(\d+)$/, "$1");
  if (newPrice === ".") newPrice = "0.";
  //if (newPrice && isNaN(newPrice)) return newPrice.replace(/.$/, '')
  return allowNegative === "allowNegative" && isNegative
    ? newPrice === ""
      ? "-"
      : `-${newPrice}`
    : newPrice;
};

const l10nUSD = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
// const l10nEN = new Intl.NumberFormat('en-US')

export const onBlurPriceToState = (price) =>
  (price === "-" ? "0" : l10nUSD.format(price).replace(/[^\d.]/g, "")).replace(
    /\.00$/,
    ""
  );

export const priceToView = (
  price,
  isAfterDotShow,
  maximumFractionDigits = 2
) => {
  let newPrice = "" + price;
  newPrice = newPrice.replace(/[^-\d.]/g, "");
  // if (newPrice !== '' && newPrice !== '-') newPrice = l10nUSD.format(newPrice)
  if (newPrice !== "" && newPrice !== "-")
    newPrice = (+newPrice).toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits,
    });

  return /\.00$/.test(newPrice) && !isAfterDotShow
    ? newPrice.replace(/\.00$/, "")
    : newPrice;
};

export const textFieldPriceFormatter = (value) => {
  let amountValue = value.toString();
  amountValue = amountValue.replace(/^-+/, "-");
  amountValue = amountValue.replace(/,/g, "");
  const checkValue = amountValue.replace(/^-/, "");
  const isNumber = !isNaN(checkValue);
  if (isNumber) {
    const parts = amountValue.split(".");
    if (parts?.length > 1 && parts[1]?.length > 2) {
      parts[1].replace(/.$/, "");
      amountValue = parts.join(".");
    }
  } else {
    amountValue = amountValue.replace(/[^0-9.]/g, "");
  }
  return amountValue;
};
