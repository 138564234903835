export const tableHead = [
    {
        label: 'Task Name',
        value: 'title',
        left: true,
        sort: true,
    },
    {
        label: 'Status',
        value: 'is_completed',
        left: true,
        sort: true,
    },
    {
        label: 'Task Type',
        value: 'type',
        left: true,
        sort: true,
    },
    {
        label: 'Profile',
        value: 'profile',
        left: true,
        sort: true,
    },
    {
        label: 'Task Date',
        value: 'perform_at',
        left: true,
        sort: true,
    },
    {
        label: 'Completed Date',
        value: 'completed_at',
        left: true,
        sort: true,
    },
    {
        label: 'Completed By',
        value: 'completed_by',
        left: true,
        sort: true,
    },
];
