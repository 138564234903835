import React, { useState } from "react";
import "./details.scss";
import { ReportDetails } from "./ReportDetails/ReportDetails";
import { Schedule } from "./Schedule/Schedule";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";

export const Details = ({
  header,
  data,
  meta,
  setMeta,
  role,
  usersVis,
  groupsVis,
  name,
  setSort,
  sort,
  additionalData,
  isChartVisible,
}) => {
  const [isReportDetails, setIsReportDetails] = useState(true);

  return (
    <>
      <div className="kt-portlet generated-report-details">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title">REPORT DETAILS</h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <div className="buttons-container">
              <div className="toolbarm">
                {isChartVisible && (
                  <div
                    className={`toolbarm-elem ${
                      !isReportDetails ? "active" : ""
                    }`}
                    onClick={() => setIsReportDetails(false)}
                  >
                    <EqualizerIcon />
                  </div>
                )}

                <div
                  className={`toolbarm-elem ${isReportDetails ? "active" : ""}`}
                  onClick={() => setIsReportDetails(true)}
                >
                  <FormatAlignJustifyIcon />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="kt-portlet__body">
          {isReportDetails ? (
            <ReportDetails
              header={header}
              data={data}
              meta={meta}
              setMeta={setMeta}
              role={role}
              usersVis={usersVis}
              groupsVis={groupsVis}
              setSort={setSort}
              sort={sort}
              additionalData={additionalData}
            />
          ) : (
            <Schedule data={data} name={name} header={header} />
          )}
        </div>
      </div>
    </>
  );
};
