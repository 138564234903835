import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import {
    Modal,
    Button,
} from 'react-bootstrap';
import { SpacerHorizontal } from '../../../../components/Spacer';
import {
    postDeactivateCRM,
    fetchCRMList,
} from '../../../../crud/crm.crud';
import { setCRMAction } from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as AlertState from '../../../../store/ducks/auth.duck';

const ModalDeactivateCRM = ({
    modal,
    setModal,
    crm,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const accountId = useSelector(state => state.appData?.account?.id);

    const handleClose = () => {
        setModal(false);
    };

    const handleContinue = () => {
        postDeactivateCRM(accountId, crm.id)
            .then(() => {
                history.push('/account/biling');
                handleClose();
                dispatch(AlertState.actions.alert({
                    text: "CRM deactivated",
                    variant: true
                }));
                fetchCRMList(accountId)
                    .then(res => dispatch(setCRMAction(res.data.list)))
            })
            .catch(err => {
                let errText = 'request failed';
                if (err.response?.data?.error) {
                    errText = err.response?.data?.error;
                } else if (err.response?.data?.message) {
                    errText = err.response.data.message;
                }
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
    };

    return (
        <Modal show={modal !== false} onHide={() => setModal(false)}>
            <StyledModalDeactivateCRM>
                <div
                    className="close-button"
                    onClick={() => setModal(false)}
                >×</div>
                <Modal.Title>
                    <h5 className="title">Disable CRM Integrations</h5>
                </Modal.Title>
                <Modal.Body>
                    <Typography className="static-text">
                        If you choose to disable your CRM integration
                        all data will immediately stop syncing from your CRM
                        and your subscription will update accordingly
                    </Typography>
                    <SpacerHorizontal height="30px" />
                    <Typography className="static-text">
                        Click the Disable button below to continue
                    </Typography>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleContinue}
                        className="btn-blue"
                    >
                        Disable
                    </Button>
                </Modal.Footer>
            </StyledModalDeactivateCRM>
        </Modal >
    )
};

const StyledModalDeactivateCRM = styled.div`
    .close-button {
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        margin: 10px 0 0 20px;
    }

    .subtitle {
        margin: 10px 0 0 20px;
        color: #000;
        font-weight: 600;
    }

    .static-text {
        font-size: 1.2em;
        margin: 5px;
        color: #000;
    }
`;

export default ModalDeactivateCRM;